import { formatCurrency, formatPaymentMethodLabel } from 'core/utils';

import { EntityFields } from '../../../utils/index';
import { Typography } from '@mui/material';
import { DROPDOWN_FIELD } from 'core/model/interface';

export const PAYMENT_ENTITY_NAME = 'Payment';

export type PaymentInput = {
  amount: number;
  payment_method: string;
};

export const paymentInitialValues: PaymentInput = {
  amount: 0,
  payment_method: 'cash',
};

export const paymentFields: EntityFields[] = [
  {
    fieldName: 'payment_date',
    displayName: 'Payment Date',
    type: 'date',
  },
  {
    fieldName: 'amount',
    displayName: 'Amount',
    type: 'currency',
  },
  {
    fieldName: 'payment_method',
    displayName: 'Payment Method',
    type: 'select',
    options: [
      { key: 'Cash', value: 'cash' },
      { key: 'HMO', value: 'hmo' },
      { key: 'Bank Transfer', value: 'bank' },
      { key: 'Gcash', value: 'gcash' },
      { key: 'Maya', value: 'maya' },
      { key: 'Debit', value: 'debit' },
      { key: 'Credit', value: 'credit' },
      { key: 'P.O.', value: 'po' },
    ],
    renderCell: ({ value }) => formatPaymentMethodLabel(value),
  },
  // {
  //   fieldName: 'hmo_id',
  //   displayName: 'HMO',
  //   type: DROPDOWN_FIELD.HMO,
  //   hiddenBasedOnOtherField: (data) => data?.payment_method != 'hmo',
  //   // disabled: !!patient?.hmo_id,
  // },
  // {
  //   fieldName: 'hmo_card_number',
  //   displayName: 'HMO Card Number',
  //   hiddenBasedOnOtherField: (data) => data?.payment_method != 'hmo' || !data?.hmo_id,
  //   optional: true,
  //   // disabled: !!patient?.hmo_card_number,
  // },
  // {
  //   fieldName: 'approval_code',
  //   displayName: 'LOA Approval Code',
  //   hiddenBasedOnOtherField: (data) => data?.payment_method != 'hmo' || !data?.hmo_id,
  //   optional: true,
  // },
  // {
  //   fieldName: 'approved_by',
  //   displayName: 'LOA Approved By',
  //   hiddenBasedOnOtherField: (data) => data?.payment_method != 'hmo' || !data?.hmo_id,
  //   optional: true,
  // },
  {
    fieldName: 'payment_difference',
    type: 'component',
    hiddenBasedOnOtherField: (data) => parseFloat(data.amount) >= parseFloat(data.remaining),
    displayComponentBasedOnFormFields: (data) => (
      <Typography>
        Payment Difference: {formatCurrency(parseFloat(data.remaining) - parseFloat(data.amount))}
      </Typography>
    ),
  },
  {
    fieldName: 'invoice_number',
    displayName: 'Invoice #',
  },
  {
    fieldName: 'full_name',
    displayName: 'Patient',
    generateLink: (data) => `/company/patient-management?patient_id=${data.patient_id}`,
  },
];

export const paymentTableColumns: string[] = ['payment_date', 'amount', 'payment_method'];

export const paymentFormFields: string[] = [
  'amount',
  'payment_method',
  'payment_difference',
  // 'hmo_id',
  // 'hmo_card_number',
  // 'approval_code',
  // 'approved_by',
];
