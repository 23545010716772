import React from 'react';
import { Box, Button, CircularProgress, SxProps, Theme, Typography } from '@mui/material';
import { keyframes } from '@emotion/react';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';

type AnimatedRegularButtonProps = {
  label?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  loading?: boolean;
  styles?: SxProps<Theme>;
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  variant?: 'text' | 'outlined' | 'contained';
  size?: 'small' | 'medium' | 'large';
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  id?: string;
  fullWidth?: boolean;
  type?: 'button' | 'submit' | 'reset';
  helperText?: string;
  children?: React.ReactNode;
};

const pulse = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 140, 60, 0.6);
  }
  70% {
    transform: scale(1.02);
    box-shadow: 0 0 0 10px rgba(255, 140, 60, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 140, 60, 0);
  }
`;

const AnimatedRegularButton: React.FC<AnimatedRegularButtonProps> = ({
  label = 'Submit',
  onClick,
  disabled,
  loading,
  styles,
  color = 'primary',
  variant = 'contained',
  size,
  startIcon,
  endIcon,
  id,
  fullWidth,
  type,
  helperText,
  children,
}) => {
  return (
    <Box position="relative" width={fullWidth ? '100%' : undefined}>
      <Button
        type={type}
        onClick={onClick}
        variant={variant}
        size={size}
        fullWidth={fullWidth}
        id={id}
        sx={{
          textTransform: 'none',
          borderRadius: 2,
          padding: size === 'small' ? '4px 18px' : '7px 25px',
          fontSize: '12px',
          animation: `${pulse} 2s infinite`,
          transition: 'all 0.3s ease-in-out',
          backgroundColor: '#EF7935',
          color: variant === 'contained' ? '#fff' : '#EF7935',
          '&:hover': {
            backgroundColor: '#d66b2d',
            transform: 'scale(1.05)',
            animation: 'none',
            boxShadow: '0 4px 12px rgba(239, 121, 53, 0.25)',
          },
          '@media screen and (max-width: 768px)': {
            height: '40px',
            padding: '4px 10px',
          },
          ...(variant === 'outlined' && {
            borderColor: '#EF7935',
            '&:hover': {
              borderColor: '#d66b2d',
            },
          }),
          ...styles,
        }}
        startIcon={startIcon}
        endIcon={endIcon}
        disabled={disabled || loading}
        color={color}
      >
        {!loading ? children ? children : label : <CircularProgress color="inherit" size="1.3rem" />}
      </Button>
      <Typography position="absolute" fontSize="10px" top="-15px" width="100%" textAlign="center" lineHeight="10px">
        {helperText}
      </Typography>
    </Box>
  );
};

export default AnimatedRegularButton;
