import { APPOINTMENT_ENTITY_NAME, appointmentFields, appointmentTableColumns } from './AppointmentModel';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ChangeEvent, forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import {
  CustomSelectField,
  CustomSwitch,
  HideForInactiveCompany,
  ProtectedComponent,
  RegularButton,
} from 'core/components';
import { Drawer, IconButton } from '@mui/material';
import { all, upcoming } from 'core/icons';
import { exportAppointments, getAppointments } from 'company/api/appointments';

import { ApiQuery } from 'core/model/interface';
import AppointmentForm from './AppointmentForm';
import AppointmentSettings from 'company/screens/Appointments/components/AppointmentSettings';
import { ContainerRow } from 'core/components/containers';
import CustomAutoCompleteMultipleSelect from 'core/components/CustomAutoCompleteMultipleSelect';
import CustomFilter from 'core/components/CustomFilter';
import { FacilityContext } from 'core/context/facility.context';
import FilterListIcon from '@mui/icons-material/FilterList';
import { SIZES } from 'theme/constants';
import ServiceProviderSearchDropdown from 'company/components/dropdown/ServiceProviderSearchDropdown';
import TemplateTable from '../../../Template/TemplateTable';
import { UserContext } from 'core/context/user.context';
import ViewInvoiceClick from 'company/screens/RegistrationBoard/components/ViewInvoiceClick';
import { WaitForFacility } from 'company/components';
import { getAllAppointmentLegends } from 'company/api/appointment-legends';
import { getCompanyTags } from 'company/api/company-tags';
import { getDoctorsAssigned } from 'company/api/doctors';
import { isDevMode } from 'core/components/HideOrShowComponent';
import { status_options } from 'company/screens/Appointments/data/list';
import { tokens } from 'theme/theme';

type Props = {
  visibleColumns?: string[];
  setDrawerSelectedRow?: (row: any) => void;
  drawerSelectedRow?: any;
  tableRef?: any;
  patientId?: number;
  packagePatientId?: number;
  packageStageId?: number;
  stackHeaderComponents?: boolean;
  fromHealthSession?: boolean;
  providerViewRef?: any;
};

interface ServiceProvider {
  provider_id: number;
  service_provider: string;
  service_provider_with_title: string | null;
}

interface FilterDrawerProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const AppointmentTable = forwardRef((props: Props, ref) => {
  const {
    visibleColumns,
    setDrawerSelectedRow,
    drawerSelectedRow,
    tableRef,
    patientId,
    packagePatientId,
    packageStageId,
    stackHeaderComponents,
    fromHealthSession,
    providerViewRef,
  } = props;
  useImperativeHandle(ref, () => ({ refreshTable }));

  const backupRef: any = useRef();
  const mainRef: any = tableRef ?? backupRef;
  const { facility } = useContext(FacilityContext);
  const { user } = useContext(UserContext);
  const [selectedRow, setSelectedRow] = useState<any>();

  const [tags, setTags] = useState<any[]>([]);
  const [viewUpcoming, setViewUpcoming] = useState<boolean>(false);
  const [otherFilter, setOtherFilter] = useState<any>({});
  const [selectedTags, setSelectedTags] = useState<any[]>([]);
  const [appointmentDetails, setAppointmentDetails] = useState<any[]>([]);

  const [statusFilter, setStatusFilter] = useState<string>('');
  const [legendFilters, setLegendFilters] = useState<string[]>([]);
  const [allLegends, setAllLegends] = useState<any[]>([]);

  const [serviceProviderFilter, setServiceProviderFilter] = useState<string>('all');

  const [forceTimelineRefresh, setForceTimelineRefresh] = useState(0);

  const [openViewInvoice, setOpenViewInvoice] = useState<boolean>(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<number>();

  const [serviceProviders, setServiceProviders] = useState<ServiceProvider[]>([]);

  const isMobile = useMediaQuery('(max-width:768px)');
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);

  const FilterDrawer: React.FC<FilterDrawerProps> = ({ open, onClose, children }) => (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box sx={{ width: 250, p: 2 }}>{children}</Box>
    </Drawer>
  );

  const showAppointmentActionsButton = true;

  const getData = (query: any) => {
    if (packagePatientId && packageStageId) {
      query.package_patient_id = packagePatientId;
      query.package_stage_id = packageStageId;
    }
    if (patientId) {
      query.patient_id = patientId;
    }
    if (serviceProviderFilter !== 'all') {
      query.provider_id = serviceProviderFilter;
    }
    if (legendFilters.length > 0) {
      query.legendFilter = legendFilters.join(',');
    }

    return getAppointments(facility.id, query).then((response) => {
      setAppointmentDetails(response.data.data);
      return response;
    });
  };

  const getTags = () => {
    if (facility) {
      getCompanyTags(facility.id).then((res) => {
        setTags(
          res.data.data.map((u: any) => {
            return { key: u.tag_name, value: u.id };
          })
        );
      });
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setViewUpcoming(event.target.checked);
    setOtherFilter((prev: any) => ({ ...prev, get_upcoming: event.target.checked ? 1 : undefined }));
  };

  const refreshTable = () => {
    mainRef.current.refreshTable();
  };

  const callbackAfterSubmit = () => {
    mainRef.current.refreshTable();
    mainRef.current.closeFormModal();
    setForceTimelineRefresh(forceTimelineRefresh + 1);
  };

  const handleTagsChange = (event: any, newTags: any[]) => {
    setSelectedTags(newTags);
    mainRef.current.refreshTable();
  };

  const handleStatusChange = (event: ChangeEvent<HTMLInputElement>) => {
    setStatusFilter(event.target.value);
    mainRef.current.refreshTable();
  };

  const handleServiceProviderChange = (newValue: string) => {
    setServiceProviderFilter(newValue);
  };

  const exportRecords = (query: ApiQuery) => {
    return exportAppointments(facility.id, facility.facility_name, { ...query });
  };

  useEffect(() => {
    if (facility) {
      getAllAppointmentLegends(facility.id).then((res) => {
        setAllLegends(res.data.data);
      });
    }
  }, [facility]);

  const legendOptions = useMemo(() => {
    return allLegends.map((legend: any) => ({
      label: legend.tag_name,
      id: legend.id.toString(),
    }));
  }, [allLegends]);

  useEffect(() => {
    getTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facility]);

  useEffect(() => {
    if (mainRef) mainRef.current?.refreshTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packagePatientId, packageStageId, patientId, legendFilters]);

  // useEffect(() => {
  //   getTags();
  //   const tags = Array.isArray(selectedTags) ? selectedTags.map((tag) => tag.value) : [];
  //   setOtherFilter((prev: any) => ({
  //     ...prev,
  //     tags: JSON.stringify(tags),
  //     status: statusFilter !== 'all' ? statusFilter : '',
  //   }));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedTags, statusFilter]);

  useEffect(() => {
    if (facility) {
      getDoctorsAssigned(facility.id, { length: 100 })
        .then((response) => {
          if (response && response.data && response.data.data && Array.isArray(response.data.data)) {
            const doctors = response.data.data.map((doctor: any) => ({
              provider_id: doctor.doctor_id,
              service_provider: `${doctor.first_name} ${doctor.last_name}`,
              service_provider_with_title: doctor.full_name_with_title || `${doctor.first_name} ${doctor.last_name}`,
            }));

            setServiceProviders(doctors);
          } else {
            console.error('Unexpected response structure:', response);
          }
        })
        .catch((error) => {
          console.error('Error fetching doctors:', error);
        });
    }
  }, [facility]);

  useEffect(() => {
    const tags = Array.isArray(selectedTags) ? selectedTags.map((tag) => tag.value) : [];
    const newFilter = {
      ...otherFilter,
      tags: JSON.stringify(tags),
      status: statusFilter !== 'all' ? statusFilter : '',
      provider_id: serviceProviderFilter !== 'all' ? serviceProviderFilter : undefined,
    };
    setOtherFilter(newFilter);

    if (mainRef.current) {
      mainRef.current.refreshTable();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTags, statusFilter, serviceProviderFilter]);

  return (
    <WaitForFacility facility={facility}>
      <TemplateTable
        ref={mainRef}
        entityName={APPOINTMENT_ENTITY_NAME}
        fields={appointmentFields}
        visibleColumns={(visibleColumns ?? appointmentTableColumns).filter(
          (col) => col !== 'invoice_number' || user?.company?.with_pos_and_patient_transactions
        )}
        getData={getData}
        stackHeaderComponents={stackHeaderComponents}
        hideAddUpdateDeleteAction={!user?.authorities?.includes('MANAGE_APPOINTMENTS') && facility?.is_admin}
        hideUpdateAction
        hideDeleteAction
        // hideSearch={isMobile ? true : false}
        hideAddButton={
          !!(packagePatientId && packageStageId) ||
          !user?.authorities?.includes('MANAGE_APPOINTMENTS') ||
          facility?.is_admin
        }
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        drawerSelectedRow={drawerSelectedRow}
        setDrawerSelectedRow={setDrawerSelectedRow}
        useColoredTable
        showAppointmentActionsButton={showAppointmentActionsButton}
        tableComponent={{
          initialOrderBy: 'schedule',
          initialOrder: 'desc',
          otherFilter: otherFilter,
          stickyTop: patientId ? 95 : -1,
          rowHeight: 70,
          exportRecords: user?.authorities?.includes('EXPORT_PATIENT_APPOINTMENTS') ? exportRecords : undefined,
          filterComponent: (
            <HideForInactiveCompany>
              <Box display="flex" flexDirection="column" width="100%">
                <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={isMobile ? 'space-between' : ''}
                    minWidth={isMobile ? '100%' : ''}
                  >
                    {!packagePatientId && (
                      <CustomSwitch
                        label1="All"
                        label2="Upcoming"
                        image1={all}
                        image2={upcoming}
                        value={viewUpcoming}
                        handleChange={handleChange}
                      />
                    )}
                    {isMobile ? (
                      !fromHealthSession && (
                        <IconButton onClick={() => setFilterDrawerOpen(true)}>
                          <FilterListIcon style={{ color: colors.primary }} />
                        </IconButton>
                      )
                    ) : (
                      <ContainerRow gap={SIZES.paddingS}>
                        {isDevMode() && tags && !patientId && !(packagePatientId && packageStageId) && (
                          <Box width="200px" marginLeft="10px">
                            <CustomAutoCompleteMultipleSelect
                              options={tags}
                              label={'Patient Groups'}
                              fieldName={'tags'}
                              value={selectedTags}
                              handleChange={handleTagsChange}
                            />
                          </Box>
                        )}
                        {!packagePatientId && (
                          <Box width="200px">
                            <ServiceProviderSearchDropdown
                              handleChangeCallback={(value) => handleServiceProviderChange(value ? value.value : 'all')}
                              hideAddOption
                            />
                          </Box>
                          // <Box width="200px" marginLeft="10px">
                          //   <CustomSelectField
                          //     label={'Service Provider'}
                          //     handleChange={handleServiceProviderChange}
                          //     fieldName={'provider_id'}
                          //     value={serviceProviderFilter}
                          //     options={[
                          //       { key: 'All', value: 'all' },
                          //       ...serviceProviders.map((provider) => ({
                          //         key: provider.service_provider_with_title || provider.service_provider,
                          //         value: provider.provider_id.toString(),
                          //       })),
                          //     ]}
                          //   />
                          // </Box>
                        )}
                        {!packagePatientId && (
                          <Box width="200px">
                            <CustomSelectField
                              label={'Status'}
                              handleChange={handleStatusChange}
                              fieldName={'status'}
                              value={'all'}
                              options={status_options.map((u) => {
                                return { key: u.key, value: u.value };
                              })}
                            />
                          </Box>
                        )}
                      </ContainerRow>
                    )}
                  </Box>
                  {legendOptions.length > 0 && (
                    <Box display={'flex'} alignItems={'flex-end'} justifyContent={'center'} mb={-1}>
                      <CustomFilter
                        buttonLabel="Legend Filter"
                        span={2}
                        optionList={legendOptions}
                        selected={legendFilters}
                        setSelected={setLegendFilters}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </HideForInactiveCompany>
          ),
          mobileViewConfig: (data) => {
            return {
              title_fields: ['schedule'],
              title_link: `/company/patient-management?patient_id=${data.patient_id}`,
              subtitle_fields: ['full_name', 'service_names'],
            };
          },
        }}
        tableAction={{
          addMobileLabel: isMobile ? 'Add Appointment' : '',
          addHeaderContainerStyles: isMobile
            ? {
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                boxSizing: 'border-box',
                marginBottom: '10px',
                color: colors.primary,
              }
            : {},
          addButtonLabel: isMobile ? '' : 'Add Appointment',
          addButtonStyles: isMobile
            ? {
                minWidth: '40px',
                boxShadow: 'none',
                color: 'white',
                backgroundColor: colors.blueAccent,
                justifyContent: 'center',
                paddingLeft: '12px',
              }
            : {},
          rowActionsComponent: (data) =>
            !facility.is_admin && (
              <ProtectedComponent
                requiredAuth={['MANAGE_APPOINTMENTS']}
                requiredRole={['COMPANY_ADMIN', 'COMPANY_STAFF', 'CLINIC_STAFF', 'COMPANY_DOCTOR']}
              >
                <AppointmentSettings
                  appointment={data}
                  updateAppointmentData={() => {
                    mainRef.current.refreshTable();
                    if (providerViewRef) {
                      providerViewRef.current?.getAppointments();
                    }
                  }}
                  navigateToEditor
                />
              </ProtectedComponent>
            ),
          actionFlex: 0.5,
        }}
        templateForm={
          facility ? (
            <AppointmentForm facility={facility} callbackAfterSubmit={callbackAfterSubmit} patientId={patientId} />
          ) : undefined
        }
        customColumns={[
          {
            fieldName: 'invoice_number',
            displayName: 'Invoice No',
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
              <RegularButton
                label={params.value}
                variant="text"
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  // getPrescription(params.row, false);
                  setSelectedInvoiceId(params.row.invoice_id);
                  setOpenViewInvoice(true);
                }}
              />
            ),
          },
        ]}
      />
      {isMobile && (
        <FilterDrawer open={filterDrawerOpen} onClose={() => setFilterDrawerOpen(false)}>
          <Box display="flex" flexDirection="column" gap="10px">
            <Typography sx={{ marginBottom: '20px', color: colors.primary }}>Appointment Filters</Typography>
            {isDevMode() && tags && !patientId && !(packagePatientId && packageStageId) && (
              <Box width="100%">
                <CustomAutoCompleteMultipleSelect
                  options={tags}
                  label={'Patient Groups'}
                  fieldName={'tags'}
                  value={selectedTags}
                  handleChange={handleTagsChange}
                />
              </Box>
            )}
            {!packagePatientId && (
              <Box width="100%">
                <ServiceProviderSearchDropdown
                  handleChangeCallback={(value) => handleServiceProviderChange(value)}
                  hideAddOption
                />
              </Box>
            )}
            {!packagePatientId && (
              <Box width="100%">
                <CustomSelectField
                  label={'Status'}
                  handleChange={handleStatusChange}
                  fieldName={'status'}
                  value={'all'}
                  options={status_options.map((u) => {
                    return { key: u.key, value: u.value };
                  })}
                />
              </Box>
            )}
          </Box>
        </FilterDrawer>
      )}
      <ViewInvoiceClick
        invoiceId={selectedInvoiceId}
        openModal={openViewInvoice}
        setOpenModal={setOpenViewInvoice}
        onInvoiceUpdate={refreshTable}
      />
    </WaitForFacility>
  );
});

export default AppointmentTable;
