import React from 'react';
import { Assessments } from 'company/screens/HealthAssessments';
import HealthAssessmentBatch from 'company/screens/HealthAssessments/HealthAssessmentBatch';
import { CustomTabs } from 'core/components';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import { CareGoPage } from 'core/PageBuilder';
import { useContext, useEffect, useState } from 'react';
import DirectionsWalkOutlinedIcon from '@mui/icons-material/DirectionsWalkOutlined';
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';
import SelfRegistrationConfirmation from './SelfRegistrationConfirmation';
import SelfRegistrationManual from './SelfRegistrationManual';

interface SelfRegistrationPortalProps {}

const SelfRegistrationPortal: React.FC<SelfRegistrationPortalProps> = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [selected, setSelected] = useState(0);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    setBreadcrumb([
      { label: 'Patient Management', link: '/company/patient-management' },
      { label: 'Self-Registration Portal' },
    ]);
  }, [setBreadcrumb]);

  useEffect(() => {
    localStorage.setItem('previousPath', window.location.pathname + window.location.search);
  }, []);

  return (
    <CareGoPage header="Self-Registration Portal">
      <CustomTabs
        selected={selected}
        setSelected={(newValue) => {
          setSelected(newValue);
          setRefresh((prev) => prev + 1);
        }}
        tabs={[
          {
            icon: <PublishedWithChangesOutlinedIcon />,
            label: 'Registration Confirmations',
            content: <SelfRegistrationConfirmation key={refresh} />,
          },
          {
            icon: <DirectionsWalkOutlinedIcon />,
            label: 'Manual Self-Register',
            content: <SelfRegistrationManual key={refresh} />,
          },
        ]}
      />
    </CareGoPage>
  );
};

export default SelfRegistrationPortal;
