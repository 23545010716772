import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ConfirmationDialog, CustomModal, RegularButton } from 'core/components';
import { addServices, deleteGroup, getAllGroups, getGroup, removeService } from 'company/api/service-groups';
import { useContext, useEffect, useRef, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { ApiQuery } from 'core/model/interface';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ClinicsTable from '../../ClinicsTable';
import { ContainerRow } from 'core/components/containers';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FacilityContext } from 'core/context/facility.context';
import GroupServiceTable from 'company/entities/modules/ClinicManagement/Service/GroupServiceTable';
import InfiniteScrollContainer from 'core/components/dataView/InfiniteScrollContainer';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import SectionDropdown from 'core/components/SectionDropdown';
import ServiceGroupForm from 'company/entities/modules/ClinicManagement/Service/ServiceGroupForm';
import ServiceGroupsLinkedNotesDragList from './ServiceGroupsLinkedNotesDragList';
import ServiceSearchDropdown from 'company/components/dropdown/ServiceSearchDropdown';
import { UserContext } from 'core/context/user.context';
import { getServicePackageByGroupId } from 'company/api/service-packages';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

export default function ServiceGroups({ setOpenServiceGroups }: any) {
  const { user } = useContext(UserContext);

  const { facility } = useContext(FacilityContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedGroup, setSelectedGroup] = useState<number>();
  const [selectedGroupDetails, setSelectedGroupDetails] = useState<any>();
  const [selectedServicePackageDetails, setSelectedServicePackageDetails] = useState<any>();

  const [addFormOpen, setAddFormOpen] = useState<boolean>(false);

  const [openAddServicesForm, setOpenAddServicesForm] = useState<boolean>(false);
  const [addServicesLoading, setAddServicesLoading] = useState<boolean>(false);
  const [selectedServicesToAdd, setSelectedServicesToAdd] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [deleteGroupDialogOpen, setDeleteGroupDialogOpen] = useState(false);
  const [newlyAddedGroupID, setNewlyAddedGroupID] = useState<number>();
  const [updatedGroup, setUpdatedGroup] = useState<number | null>(null);

  const [deletedID, setDeletedID] = useState<number>();
  const [toDeleteID, setToDeleteID] = useState<number>();
  const [loadingDeleteGroup, setLoadingDeleteGroup] = useState<boolean>(false);
  const [servicePackageItems, setServicePackageItems] = useState<any[]>([]);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [loadingSelectedServicePackageDetails, setLoadingSelectedServicePackageDetails] = useState<boolean>(false);

  const tableRef: any = useRef();
  const isMobilePhone = useMediaQuery('(max-width:768px)');
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    async function getGroupDetails() {
      if (!selectedGroup) return;
      const res = await getGroup(selectedGroup);
      setSelectedGroupDetails(res.data);
    }
    getGroupDetails();
    if (selectedGroup) tableRef.current?.refreshTable();
  }, [selectedGroup]);

  async function addMultipleServices() {
    const data = {
      group_id: selectedGroup,
      services: selectedServicesToAdd,
    };
    setAddServicesLoading(true);
    await addServices(data);
    setAddServicesLoading(false);
    setOpenAddServicesForm(false);
    tableRef.current?.refreshTable();
  }

  const getData = async (query: ApiQuery) => {
    const response = await getAllGroups(facility.id, query);
    setServicePackageItems(response.data.data);
    return response;
  };

  const handleRefreshExternalDetails = (value: number | null) => {
    if (value === null) {
      const currentIndex = servicePackageItems.findIndex((item) => item.id === selectedGroup);
      if (currentIndex >= 0) {
        const nextItem = servicePackageItems[currentIndex + 1] || servicePackageItems[currentIndex - 1];
        if (nextItem) {
          setSelectedGroup(nextItem.id);
          setSelectedGroupDetails(nextItem);
        } else {
          setSelectedGroup(undefined);
          setSelectedGroupDetails(undefined);
        }
      }

      setRefreshTrigger((prev) => prev + 1);
    } else {
      refreshServiceGroupDetails();
    }
  };

  const refreshServiceGroupDetails = () => {
    if (selectedGroup) {
      getGroup(selectedGroup).then((response: any) => {
        setSelectedGroupDetails(response.data);
      });
    }
  };

  function getSingleData(id: number) {
    return getGroup(id);
  }

  async function handleDeleteGroup(id: number) {
    setLoadingDeleteGroup(true);
    await deleteGroup(id);
    setDeletedID(id);
    setLoadingDeleteGroup(false);
    tableRef.current?.refreshTable();
    enqueueSnackbar('Service package successfully deleted!', { variant: 'success' });
  }

  useEffect(() => {
    tableRef.current?.refreshTable();
  }, [selectedGroup]);

  const displayItem = (item: any) => {
    return (
      <Box display="flex" flexDirection="column" gap="5px" width="100%">
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          color={item.id === selectedGroup ? 'white' : colors.text}
        >
          <Typography variant="h5" fontWeight="600">
            {item.group_name}
          </Typography>
        </Box>
      </Box>
    );
  };

  const handleDelete = async (data: any) => {
    const dataToDelete = {
      service_id: data,
      group_id: selectedGroup,
    };
    try {
      await removeService(facility.id, dataToDelete);
      tableRef.current.refreshTable();
    } catch (error) {
      console.error('Error deleting the item:', error);
    }
  };

  const getPackageDetails = async () => {
    if (selectedGroup) {
      setLoadingSelectedServicePackageDetails(true);
      const response = await getServicePackageByGroupId(selectedGroup);
      setSelectedServicePackageDetails(response.data);
      setLoadingSelectedServicePackageDetails(false);
    } else {
      console.error('Error fetching package details:');
    }
  };

  useEffect(() => {
    getPackageDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroup]);

  const sections = selectedGroup
    ? [
        {
          icon: <MedicalServicesOutlinedIcon />,
          label: 'Services',
          content: (
            <>
              <GroupServiceTable
                tableRef={tableRef}
                selectedGroup={selectedGroup}
                tableAction={{
                  includeDeleteAction: true,
                  includeUpdateAction: false,
                  deleteButtonLabel: 'Remove',
                  deleteApiFunction: handleDelete,
                  alertCustomObjectColumn: 'service_name',
                  customDeleteSnackBarMessageOnSuccess: 'removed from group',
                }}
              ></GroupServiceTable>

              {!loadingSelectedServicePackageDetails && (
                <ClinicsTable
                  source="Service Packages"
                  selectedServicePackage={selectedServicePackageDetails}
                  setRefreshExternalDetails={handleRefreshExternalDetails}
                />
              )}
            </>
          ),
        },
        {
          icon: <EditNoteOutlinedIcon />,
          label: 'Linked Notes',
          content: <ServiceGroupsLinkedNotesDragList group_id={selectedGroup} />,
        },
      ]
    : [];
  return (
    <InfiniteScrollContainer
      key={refreshTrigger}
      title="List of Service Packages"
      getData={getData}
      deleteId={deletedID}
      updatedItem={updatedGroup}
      addedNewItem={newlyAddedGroupID}
      getSingleData={(id) => getSingleData(id)}
      selected={selectedGroup}
      setSelected={setSelectedGroup}
      onChange={(selected) => {
        tableRef.current?.refreshTable();
      }}
      actions={[
        {
          icon: <AddIcon />,
          label: 'Add Services',
          action: (data) => {
            setOpenAddServicesForm(true);
          },
          hidden: user?.remaining_storage <= 0,
        },
        {
          icon: <EditOutlinedIcon />,
          label: 'Update Group',
          action: async (data) => {
            setIsUpdate(true);
            setAddFormOpen(true);
          },
        },
        {
          icon: <DeleteOutlinedIcon />,
          label: 'Delete',
          color: 'error',
          action: (data) => {
            setToDeleteID(data);
            setDeleteGroupDialogOpen(true);
          },
        },
      ]}
      renderItem={displayItem}
      urlKeyId="sgid"
      titleComponents={
        !isMobilePhone && (
          <RegularButton
            onClick={() => {
              setAddFormOpen(true);
              setIsUpdate(false);
            }}
            label="Add"
            startIcon={<AddIcon />}
            size="small"
            disabled={user.remaining_storage <= 0}
          />
        )
      }
      displaySelectedTitle={() => (
        <ContainerRow sx={{ display: 'flex', justifyContent: 'start', gap: '5px' }}>
          {setOpenServiceGroups && (
            <RegularButton
              variant="text"
              size="small"
              startIcon={<ArrowBackIosOutlinedIcon />}
              label="Back"
              onClick={() => setOpenServiceGroups(false)}
              styles={{ padding: '2px 5px 2px 20px', marginLeft: '-20px' }}
            />
          )}
          <Typography variant="h3" fontWeight={700} color={colors.primary}>
            {selectedGroupDetails?.group_name}
          </Typography>
        </ContainerRow>
      )}
    >
      <Box mt={'10px'}>
        {selectedGroup && (
          <SectionDropdown optionList={sections} popupContainerStyle={{ padding: '10px' }} urlKeyId="section" />
        )}
      </Box>

      {/*  FORM FOR CREATING NEW GROUP */}
      <CustomModal
        open={addFormOpen}
        setOpen={setAddFormOpen}
        header={!isUpdate ? `Create New Service Group` : 'Update Service Group'}
        onClose={() => setIsUpdate(false)}
      >
        <ServiceGroupForm
          serviceGroup={isUpdate ? selectedGroupDetails : undefined}
          callbackAfterSubmit={(data) => {
            if (isUpdate) {
              setUpdatedGroup(data.data);
              setSelectedGroupDetails(data.data);
            } else setNewlyAddedGroupID(data.data.id);

            setAddFormOpen(false);
          }}
        />
      </CustomModal>

      {/* FORM FOR ADDING SERVICES TO A GROUP */}
      <CustomModal
        open={openAddServicesForm}
        setOpen={setOpenAddServicesForm}
        header={`Add services to group ${selectedGroupDetails?.group_name}`}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px', justifyContent: 'space-between' }}>
          <ServiceSearchDropdown
            useGetNotAddedServiceAPI
            groupId={selectedGroup}
            limit={20}
            handleChangeCallback={(record) => {
              setSelectedServicesToAdd(record);
            }}
            multiple
            fieldName="service_id"
          ></ServiceSearchDropdown>

          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
            <RegularButton onClick={() => addMultipleServices()} loading={addServicesLoading}></RegularButton>
          </Box>
        </Box>
      </CustomModal>

      <ConfirmationDialog
        loadingConfirm={loadingDeleteGroup}
        setOpen={setDeleteGroupDialogOpen}
        open={deleteGroupDialogOpen}
        title="Confirm Delete Group"
        content={`Are you sure you want to delete this group?`}
        onConfirm={() => {
          if (!toDeleteID) return;
          handleDeleteGroup(toDeleteID);
        }}
      ></ConfirmationDialog>
    </InfiniteScrollContainer>
  );
}
