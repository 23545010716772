import { Box, Chip, Divider, Typography, useTheme } from '@mui/material';
import { DISPLAY_ONLY_FIELDS, FORM_MODE, FieldInput, SelectOption } from 'core/model/interface';
import { ReactNode, useCallback, useEffect, useState } from 'react';

import AbcOutlinedIcon from '@mui/icons-material/AbcOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import BorderAllOutlinedIcon from '@mui/icons-material/BorderAllOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import ConfirmationDialog from '../modals/ConfirmationDialog';
import CustomAnimatedDrawer from '../CustomAnimatedDrawer';
import CustomForm from './CustomForm';
import CustomIconButton from '../buttons/IconButton';
import CustomTextField from '../CustomTextField';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DraggableList from '../DraggableList';
import { DropResult } from 'react-beautiful-dnd';
import Dropdown from '../Dropdown';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FlakyOutlinedIcon from '@mui/icons-material/FlakyOutlined';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined';
import InsertInvitationOutlinedIcon from '@mui/icons-material/InsertInvitationOutlined';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import NotesIcon from '@mui/icons-material/Notes';
import PlaylistAddOutlinedIcon from '@mui/icons-material/PlaylistAddOutlined';
import PrimaryButton from '../buttons/PrimaryButton';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { SIZES } from 'theme/constants';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import ShortTextIcon from '@mui/icons-material/ShortText';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import TitleOutlinedIcon from '@mui/icons-material/TitleOutlined';
import { isDevMode } from '../HideOrShowComponent';
import { tokens } from 'theme/theme';
import uuid from 'react-uuid';

type CustomFormProps = {
  field: FieldInput;
  index: number;
  span: number;
  columnNumber: number;
  formMode?: FORM_MODE;
  options?: any[];
  updateOptions?: (options: any[]) => void;
  handleChange?: (value: any) => void;

  setFormFields?: React.Dispatch<React.SetStateAction<FieldInput[]>>;
  setIsEditCheckboxOptionsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  children: ReactNode;
  userInputFields: FieldInput[];
};

const FormField: React.FC<CustomFormProps> = ({
  field,
  formMode,
  index,
  span,
  columnNumber,
  setFormFields,
  children,
  options,
  updateOptions,
  handleChange,
  userInputFields,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedFieldInput, setSelectedFieldInput] = useState<FieldInput | null>(null);
  const [editFieldInitialValues, setEditFieldInitialValues] = useState<any>({
    field_name: '',
    span: 'half',
    default_value: '',
    add_dependency: false,
    hide_in_print: false,
    parent_field: '',
    condition: '',
    condition_value: '',
    add_formula: false,
    formula_text: '',
  });
  const [fieldIndexToDelete, setFieldIndexToDelete] = useState<number | null>(null);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [optionList, setOptionList] = useState<any[]>(options ?? []);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [editedOption, setEditedOption] = useState('');
  const [conditionValueOptions, setConditionValueOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    if (selectedFieldInput) {
      setEditFieldInitialValues((prev: any) => ({
        ...prev,
        display_name: selectedFieldInput.display_name,
        span: selectedFieldInput.span === span ? 'full' : 'half',
        default_value: selectedFieldInput.default_value ?? '',
        add_dependency: !!selectedFieldInput.parent_field,
        hide_in_print: selectedFieldInput.hide_in_print,
        parent_field: selectedFieldInput.parent_field,
        condition: selectedFieldInput.condition,
        condition_value: selectedFieldInput.condition_value,
        add_formula: !!selectedFieldInput.formula_text,
        formula_text: selectedFieldInput.formula_text ?? '',
      }));
      const selectedField = userInputFields.find((field) => field.field_name === selectedFieldInput.parent_field);
      setConditionValueOptions(selectedField?.options ?? []);
    } else {
      setEditFieldInitialValues((prev: any) => ({
        ...prev,
        display_name: '',
        span: 'half',
        default_value: '',
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFieldInput, span]);

  const addNewFormField = (type: string) => {
    addNewField(index, type, span, columnNumber, setFormFields, openEditModal);
  };

  const getDropdown = (index: number, field: FieldInput) => {
    return (
      <Dropdown
        isIcon
        icon={<PlaylistAddOutlinedIcon color="primary" />}
        optionList={[
          {
            label: 'Input Fields',
            parent: true,
          },
          {
            label: 'Short Text',
            icon: <ShortTextIcon />,
            action: () => addNewFormField('text'),
          },
          {
            label: 'Long Text',
            icon: <NotesIcon />,
            action: () => addNewFormField('long_text'),
          },
          {
            label: 'Rich Text',
            icon: <StickyNote2OutlinedIcon />,
            action: () => addNewFormField('rich_text'),
          },
          {
            label: 'Yes/No',
            icon: <FlakyOutlinedIcon />,
            action: () => addNewFormField('yes_no'),
          },
          {
            label: 'Radio Group',
            icon: <RadioButtonCheckedIcon />,
            action: () => addNewFormField('radio_group'),
          },
          {
            label: 'Checkbox Group',
            icon: <CheckBoxOutlinedIcon />,
            action: () => addNewFormField('checkbox_group'),
            hidden: !isDevMode(),
          },
          {
            label: 'Checkbox',
            icon: <CheckBoxOutlinedIcon />,
            action: () => addNewFormField('checkbox'),
          },
          {
            label: 'Dropdown',
            icon: <ArrowDropDownCircleIcon />,
            action: () => addNewFormField('dropdown'),
          },
          {
            label: 'Date',
            icon: <InsertInvitationOutlinedIcon />,
            action: () => addNewFormField('date'),
          },
          {
            label: 'Time',
            icon: <AccessTimeIcon />,
            action: () => addNewFormField('time'),
          },
          {
            label: 'DateTime',
            icon: <DateRangeOutlinedIcon />,
            action: () => addNewFormField('datetime'),
          },
          {
            label: 'Table',
            icon: <BorderAllOutlinedIcon />,
            action: () => addNewFormField('table'),
          },
          {
            label: 'File Upload',
            icon: <FileUploadIcon />,
            action: () => addNewFormField('file_upload'),
          },
          {
            label: 'divider',
          },
          {
            label: 'Divider',
            icon: <HorizontalRuleOutlinedIcon />,
            action: () => addNewFormField('divider'),
          },
          {
            label: 'Section Header',
            icon: <TitleOutlinedIcon />,
            action: () => addNewFormField('section_header'),
          },
          {
            label: 'Subsection Header',
            icon: <FormatSizeIcon />,
            action: () => addNewFormField('subsection_header'),
          },
          // {
          //   label: 'Group Header',
          //   icon: <FormatSizeIcon />,
          //   action: () => addNewFormField('group_header'),
          // },
          {
            label: 'Body Text',
            icon: <AbcOutlinedIcon />,
            action: () => addNewFormField('body_text'),
          },
        ]}
      />
    );
  };

  const openEditModal = (fieldInput: FieldInput) => {
    setSelectedFieldInput(fieldInput);
    setIsEditModalOpen(true);
    setOptionList(fieldInput.options ?? []);
  };

  const deleteField = (index: number) => {
    setFieldIndexToDelete(index);
    setConfirmationDialogOpen(true);
  };

  const cancelDeleteField = () => {
    setFieldIndexToDelete(null);

    setConfirmationDialogOpen(false);
  };

  const confirmDeleteField = () => {
    if (fieldIndexToDelete !== null) {
      setFormFields &&
        setFormFields((prevFormFields) => {
          const newList = [...prevFormFields];
          newList.splice(fieldIndexToDelete, 1);
          return newList;
        });

      setFieldIndexToDelete(null);
    }

    setConfirmationDialogOpen(false);
  };

  const processField = (data: any, field: any) => {
    const dependency = data.add_dependency
      ? {
          parent_field: data.parent_field,
          condition: data.condition,
          condition_value: data.condition_value,
          hide_in_print: data.hide_in_print,
        }
      : {
          parent_field: '',
          condition: '',
          condition_value: '',
          hide_in_print: data.hide_in_print,
        };

    if (selectedFieldInput?.section_header) {
      return {
        ...field,
        ...dependency,
        display_name: data.display_name,
        section_header: data.display_name,
        span: data.span === 'full' ? span : span / columnNumber,
      };
    }

    if (selectedFieldInput?.body_text) {
      return {
        ...field,
        ...dependency,
        display_name: data.display_name,
        body_text: data.display_name,
        span: data.span === 'full' ? span : span / columnNumber,
      };
    }

    return {
      ...field,
      ...dependency,
      display_name: data.display_name,
      span: data.span === 'full' ? span : span / columnNumber,
      formula_text: data.formula_text ?? '',
      default_value: data.default_value,
    };
  };

  const handleEditFieldName = (data: any) => {
    if (selectedFieldInput) {
      setFormFields &&
        setFormFields((prevFormFields) =>
          prevFormFields.map((field) => (field === selectedFieldInput ? processField(data, field) : field))
        );
    }
    setIsEditModalOpen(false);
  };

  const handleRadioOptionsDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const reorderedOptions = Array.from(optionList);
    const [movedItem] = reorderedOptions.splice(result.source.index, 1);
    reorderedOptions.splice(result.destination.index, 0, movedItem);
    setOptionList(reorderedOptions);
    updateOptions && updateOptions(reorderedOptions);
  };

  const handleSaveOption = (index: number) => {
    setEditingIndex(-1);
    setEditedOption('');
    handleSaveEditedOption(index, editedOption);
  };

  const handleEditOption = (index: number, currentKey: string) => {
    setEditingIndex(index);
    setEditedOption(currentKey);
  };

  const handleSaveEditedOption = (index: number, updatedKey: string) => {
    const updatedOptions = optionList.map((option, i) =>
      i === index ? { ...option, value: updatedKey, key: updatedKey } : option
    );
    const newList = [...updatedOptions];
    setOptionList(newList);
    updateOptions && updateOptions(newList);
  };

  const handleDeleteOption = (indexToDelete: number) => {
    const updatedOptions = optionList.filter((_, index) => index !== indexToDelete);
    const newList = [...updatedOptions];
    setOptionList(newList);
    updateOptions && updateOptions(newList);
  };

  const handleAddNewOption = () => {
    const newOption = {
      key: `Option ${optionList.length + 1}`,
      value: `Option ${optionList.length + 1}`,
    };

    const newList = [...optionList, newOption];
    setOptionList(newList);
    updateOptions && updateOptions(newList);
  };

  const handleMoveField = (move: number) => {
    setFormFields &&
      setFormFields((prev) => {
        if ((move < 0 && index === 0) || (move > 0 && index === prev.length - 1)) return prev;

        const newItems: any = [...prev];
        [newItems[index], newItems[index + move]] = [newItems[index + move], newItems[index]];
        return newItems;
      });
  };

  const getDefaultSpan = useCallback(
    () =>
      [
        'subsection_header',
        'section_header',
        'group_header',
        'body_text',
        'divider',
        'component',
        'rich_text',
      ].includes(field.type as string)
        ? span
        : span / columnNumber,
    [field, span, columnNumber]
  );

  return (
    <>
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        key={field.field_name}
        gap="5px"
        sx={{
          gridColumn: 'span ' + (field.span ?? getDefaultSpan()),
          ...(formMode === FORM_MODE.EDIT
            ? {
                borderRadius: SIZES.borderRadiusS,
                padding: SIZES.paddingS,
                border: '1px solid ' + colors.border,
                cursor: 'pointer',
              }
            : {}),
        }}
      >
        <Box width="100%">{children}</Box>
        {formMode === FORM_MODE.EDIT && (
          <Box sx={{ width: '100%', display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
            <Box sx={{ width: '100%', display: 'flex', gap: '10px' }}>
              {getDropdown(index, field)}
              <CustomIconButton icon={<EditOutlinedIcon />} onClick={() => openEditModal(field)} tooltip="Edit Field" />
              <CustomIconButton
                icon={<DeleteOutlineIcon />}
                color="error"
                onClick={() => deleteField(index)}
                tooltip="Delete Field"
              />
              {!!field.parent_field && <Chip label="With Dependency" size="small" />}
            </Box>
            <Box sx={{ display: 'flex', gap: '2px', justifyContent: 'flex-end', alignItems: 'center' }}>
              <Typography
                sx={{
                  flex: 1,
                  backgroundColor: colors.light_blue_background,
                  color: colors.primary,
                  padding: '3px 10px',
                  fontSize: '10px',
                  borderRadius: '5px',
                  fontWeight: '600',
                }}
              >
                {field.type}
              </Typography>
              <CustomIconButton
                icon={<KeyboardArrowLeftOutlinedIcon />}
                onClick={() => handleMoveField(-1)}
                tooltip="Move Left"
              />
              <CustomIconButton
                icon={<KeyboardArrowRightOutlinedIcon />}
                onClick={() => handleMoveField(1)}
                tooltip="Move Right"
              />
            </Box>
          </Box>
        )}
      </Box>

      {formMode === FORM_MODE.EDIT && (
        <>
          <CustomAnimatedDrawer
            open={isEditModalOpen}
            setOpen={setIsEditModalOpen}
            header="Edit Field"
            onClose={() => setSelectedFieldInput(null)}
            id="editFieldModal"
            width={500}
          >
            <CustomForm
              onSubmit={handleEditFieldName}
              fields={[
                {
                  field_name: 'display_name',
                  display_name: 'Display Name',
                  type: 'text',
                  span: 4,
                  hidden: ['body_text', 'divider'].includes(selectedFieldInput?.type as string),
                },
                {
                  field_name: 'span',
                  span: 4,
                  display_name: 'Width',
                  type: 'select',
                  options: [
                    { key: 'Default Width', value: 'half' },
                    { key: 'Full Width', value: 'full' },
                  ],
                },
                {
                  field_name: 'default_value',
                  span: 4,
                  display_name: 'Default Value',
                  optional: true,
                  type: selectedFieldInput?.type === 'select' ? 'select' : 'text',
                  hidden: DISPLAY_ONLY_FIELDS.includes(selectedFieldInput?.type as string),
                  options: optionList,
                },
                {
                  field_name: 'add_dependency',
                  display_name: 'Add Dependency',
                  type: 'checkbox',
                  span: 4,
                },
                {
                  field_name: 'hide_in_print',
                  display_name: 'Hide In Print',
                  type: 'checkbox',
                  span: 4,
                },
                {
                  type: 'subsection_header',
                  subsection_header: 'Field Dependency',
                  hiddenBasedOnOtherField: (data) => !data.add_dependency,
                },
                {
                  field_name: 'parent_field',
                  display_name: 'Parent Field',
                  type: 'select',
                  options: userInputFields
                    .filter((inputField) => inputField.field_name !== field.field_name)
                    .map((inputField) => ({
                      key: inputField.display_name + '',
                      value: inputField.field_name,
                    })),
                  span: 4,
                  hiddenBasedOnOtherField: (data) => !data.add_dependency,
                  onChange: (value, setFieldValue) => {
                    const selectedField = userInputFields.find((field) => field.field_name === value);
                    setConditionValueOptions(selectedField?.options ?? []);
                    setFieldValue && setFieldValue('condition', 'equal');
                  },
                },
                {
                  field_name: 'condition',
                  display_name: 'Hide Field if Parent',
                  type: 'select',
                  options: [
                    { key: 'is Equal to', value: 'equal' },
                    { key: 'is Not Equal to', value: 'not_equal' },
                  ],
                  span: 4,
                  hiddenBasedOnOtherField: (data) => !data.add_dependency,
                },
                {
                  field_name: 'condition_value',
                  display_name: 'Value',
                  type: 'select',
                  options: conditionValueOptions,
                  span: 4,
                  hiddenBasedOnOtherField: (data) => !data.add_dependency,
                },
                {
                  field_name: 'add_formula',
                  display_name: 'Add Formula',
                  type: 'checkbox',
                  span: 4,
                  hiddenBasedOnOtherField: () => !['text', 'select'].includes(selectedFieldInput?.type as string),
                },
                {
                  field_name: 'formula_text',
                  display_name: 'Formula',
                  type: 'text',
                  span: 4,
                  multiline: true,
                  hiddenBasedOnOtherField: (data) => !data.add_formula,
                },
                {
                  field_name: 'legend',
                  display_name: 'Legend',
                  type: 'component',
                  span: 4,
                  component: (
                    <Box>
                      {userInputFields
                        .filter((inputField) => inputField.field_name !== field.field_name)
                        .map((inputField, index) => (
                          <Box display="grid" gridTemplateColumns="1fr 2fr" gap="10px" key={index}>
                            <Typography fontSize="12px">{inputField.display_name}:</Typography>
                            <Typography fontSize="12px" color="primary" fontWeight={500}>
                              {inputField.field_name}
                            </Typography>
                          </Box>
                        ))}
                    </Box>
                  ),
                  hiddenBasedOnOtherField: (data) => !data.add_formula,
                },
              ]}
              initialValues={editFieldInitialValues}
              loading={false}
            />
            {['radiogroup', 'select', 'checkbox_group'].includes(selectedFieldInput?.type as string) && (
              <Box>
                <Divider sx={{ marginBlock: '20px' }} />
                <Typography fontWeight="bold">Options</Typography>
                <Box margin="20px 0px">
                  <DraggableList
                    droppableId="radioOptions"
                    fromModal
                    handleDragEnd={handleRadioOptionsDragEnd}
                    list={optionList}
                    displayItem={(item, index) => (
                      <Box key={index} display="flex" justifyContent="space-between" alignItems="center">
                        {editingIndex === index ? (
                          <Box gap="10px" display="flex" width="100%" alignItems="center">
                            <CustomTextField
                              fieldName="edit_option"
                              label="Edit Option"
                              value={editedOption}
                              handleChangeCallback={(value) => setEditedOption(value)}
                            />
                            <CustomIconButton
                              icon={<SaveOutlinedIcon color="primary" />}
                              onClick={() => handleSaveOption(index)}
                            />
                          </Box>
                        ) : (
                          <>
                            <Typography>{item.key}</Typography>
                            <Dropdown
                              isIcon
                              icon={<MoreVertOutlinedIcon />}
                              optionList={[
                                {
                                  label: 'Edit',
                                  icon: <EditOutlinedIcon />,
                                  action: () => handleEditOption(index, item.key),
                                },
                                {
                                  label: 'Delete',
                                  icon: <DeleteOutlinedIcon style={{ color: 'red' }} />,
                                  action: () => handleDeleteOption(index),
                                },
                              ]}
                            />
                          </>
                        )}
                      </Box>
                    )}
                  />
                </Box>
                <PrimaryButton label="Add new option" onClick={handleAddNewOption} />
              </Box>
            )}
          </CustomAnimatedDrawer>
          <ConfirmationDialog
            open={confirmationDialogOpen}
            onClose={cancelDeleteField}
            onConfirm={confirmDeleteField}
            title="Confirm Deletion"
            content="Are you sure you want to delete this field?"
            setOpen={setConfirmationDialogOpen}
          />
        </>
      )}
    </>
  );
};

export default FormField;

export const addNewField = (
  index: number,
  type: string,
  span: number,
  columnNumber: number,
  setFormFields?: React.Dispatch<React.SetStateAction<FieldInput[]>>,
  openEditModal?: (fieldInput: FieldInput) => void
) => {
  let newField: FieldInput = {
    field_name: '',
    display_name: '',
    type: '',
    span: 0,
  };

  const field_name = uuid();

  if (type === 'text') {
    newField = {
      field_name,
      display_name: 'Short Text',
      type: 'text',
      span: span / columnNumber,
    };
  } else if (type === 'long_text') {
    newField = {
      field_name,
      display_name: 'Long Text',
      type: 'text',
      multiline: true,
      rows: 3,
      span: span,
    };
  } else if (type === 'rich_text') {
    newField = {
      field_name,
      display_name: 'Rich Text',
      type: 'rich_text',
      span: span,
      height: '200px',
      onChange: (value: string, setFieldValue: any) => {
        setFieldValue('newRich_text', value);
      },
    };
  } else if (type === 'yes_no') {
    newField = {
      field_name,
      display_name: 'Yes/No Field',
      type: 'radiogroup',
      span: span / columnNumber,
      options: [
        { key: 'Yes', value: 'Yes' },
        { key: 'No', value: 'No' },
      ],
    };
  } else if (type === 'radio_group' || type === 'dropdown' || type === 'checkbox_group') {
    newField = {
      field_name,
      display_name: 'Some Field',
      type: type === 'radio_group' ? 'radiogroup' : type === 'dropdown' ? 'select' : type,
      span: span / columnNumber,
      options: [
        { key: 'Option 1', value: 'option1' },
        { key: 'Option 2', value: 'option2' },
      ],
    };
  } else if (type === 'date') {
    newField = {
      field_name,
      display_name: 'Date Field',
      type: 'date',
      span: span / columnNumber,
      onChange: (value: any, setFieldValue: any) => {
        setFieldValue('newDateField', value);
      },
    };
  } else if (type === 'time') {
    newField = {
      field_name,
      display_name: 'Time Field',
      type: 'time',
      span: span / columnNumber,
      onChange: (value: any, setFieldValue: any) => {
        setFieldValue('newTimeField', value);
      },
    };
  } else if (type === 'datetime') {
    newField = {
      field_name,
      display_name: 'DateTime Field',
      type: 'datetime',
      span: span / columnNumber,
      onChange: (value: any, setFieldValue: any) => {
        setFieldValue('newDateTimeField', value);
      },
    };
  } else if (type === 'divider') {
    newField = {
      type: 'divider',
      span: span,
    };
  } else if (type === 'section_header') {
    newField = {
      type: 'section_header',
      section_header: 'Section Header',
      display_name: 'Section Header',
      span: span,
    };
  } else if (type === 'subsection_header') {
    newField = {
      type: 'subsection_header',
      section_header: 'Subsection Header',
      display_name: 'Subsection Header',
      span: span,
    };
  } else if (type === 'group_header') {
    newField = {
      type: 'group_header',
      display_name: 'Group Header',
      span: span,
    };
  } else if (type === 'body_text') {
    newField = {
      type: 'body_text',
      body_text: 'Body text',
      display_name: 'Body text',
      span: span,
    };
  } else if (type === 'checkbox') {
    newField = {
      field_name,
      display_name: 'Some Field',
      type: 'checkbox',
      span: span / columnNumber,
    };
  } else if (type === 'file_upload') {
    newField = {
      field_name,
      display_name: 'File Upload',
      type: 'file_upload',
      span: span,
    };
  } else if (type === 'table') {
    newField = {
      field_name,
      display_name: 'Table',
      type: 'table',
      span: span,
      columns: [
        { fieldName: uuid(), displayName: 'Column 1', type: 'text' },
        { fieldName: uuid(), displayName: 'Column 2', type: 'text' },
      ],
      fixedRows: true,
    };
  }

  setFormFields &&
    setFormFields((prevFormFields) => {
      const newList = [...prevFormFields];
      newList.splice(index + 1, 0, newField);
      return newList;
    });

  if (!(type === 'radio_group' || type === 'dropdown' || type === 'checkbox_group'))
    openEditModal && openEditModal(newField);
};
