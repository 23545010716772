import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useTheme } from '@mui/material/styles';
import { tokens } from 'theme/theme';
import { SIZES } from 'theme/constants';
import { ColoredTable } from 'core/components';
import { GridColDef } from '@mui/x-data-grid';

interface DispenseRecord {
  dispensed_quantity: number;
  remarks: string;
  created_at: string;
  dispensed_by: string;
}

interface DispenseHistoryProps {
  history: DispenseRecord[];
}

const DispenseHistory: React.FC<DispenseHistoryProps> = ({ history }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const columns: GridColDef[] = [
    {
      field: 'created_at',
      headerName: 'Date',
      sortable: false,
      flex: 1,
      renderCell: (params) => format(new Date(params.value), 'MMM dd, yyyy HH:mm'),
    },
    {
      field: 'dispensed_quantity',
      headerName: 'Quantity',
      sortable: false,
      flex: 1,
      align: 'right',
      headerAlign: 'right',
    },
    {
      field: 'remarks',
      headerName: 'Remarks',
      sortable: false,
      flex: 2,
    },
    {
      field: 'dispensed_by',
      headerName: 'Dispensed By',
      sortable: false,
      flex: 1,
    },
  ];

  const rows = useMemo(() => {
    return history.map((record, index) => ({
      id: index,
      ...record,
    }));
  }, [history]);

  if (history.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" padding={SIZES.padding} color={colors.text}>
        <Typography>No dispense history found</Typography>
      </Box>
    );
  }

  return <ColoredTable columns={columns} dataRows={rows} rowHeight={50} />;
};

export default DispenseHistory;
