import { Box } from '@mui/material';
import MalvarAssessmentForm from 'company/screens/HealthAssessments/SummaryTemplate/MalvarAssessmentForm';
import StElsewhere from 'company/screens/HealthAssessments/SummaryTemplate/StElsewhere';
import UnifiedLabForm from 'company/screens/HealthAssessments/SummaryTemplate/UnifiedLabTemplate/UnifiedLabForm';
const assessment = {
  id: 645,
  patient_id: 4,
  company_id: 4,
  facility_id: 5,
  service_id: null,
  provider_id: null,
  schedule: '2024-12-06 18:19:28',
  service_names: 'MMA 5',
  attendance: 0,
  consultation_purpose: null,
  previous_consultation: null,
  lab_tests: null,
  appointment_notes: null,
  type: null,
  status: 'pending',
  created_by: 4,
  is_active: 1,
  created_at: '2024-12-06 18:19:34',
  updated_at: '2024-12-06 18:19:35',
  package_patient_id: null,
  stage_id: null,
  health_assessment_id: 118,
  approval_code: null,
  approved_by: null,
  batch_id: 55,
  appointment_tag_id: null,
  first_name: 'Tom',
  last_name: 'Aspinall',
  full_name: 'Aspinall, Tom ',
  birthday: '1999-12-22',
  mobile_number: '09101010101',
  employee_id: null,
  address: null,
  province_city: null,
  civil_status: null,
  department_name: null,
  age: null,
  sex: 'Male',
  case_number: null,
  invoice_id: null,
  invoice_number: null,
  service_provider: null,
  service_provider_with_title: null,
  due_amount: null,
  total_amount: null,
  payment_status: null,
  reversal_id: null,
  duration: 0,
  total_price: '600.00',
  tag_name: null,
  health_assessment_status: 'pending',
  automated_findings: null,
  automated_recommendations: null,
  findings: null,
  recommendations: null,
  class: null,
  company_name: null,
  notes: [
    {
      id: 928,
      company_id: 4,
      facility_id: 5,
      patient_id: 4,
      appointment_id: 645,
      parent_note_id: null,
      header: 'Sample Template',
      responses: {
        first_field: 'this is their answer',
      },
      footer: null,
      is_active: 1,
      created_by: 4,
      last_edited_by: 4,
      created_at: '2024-12-06T10:19:35.000000Z',
      updated_at: '2025-01-06T04:06:03.000000Z',
      collection_id: 3,
      date_recorded: '2024-12-06',
      order: 1,
      template_id: 7,
      is_done: 0,
      collection_name: 'Assessment',
      first_name: 'Tom',
      middle_name: '',
      last_name: 'Aspinall',
      schedule: '2024-12-06 18:19:28',
      service_name: null,
      facility_name: 'CPHI',
      template_body: [
        {
          id: '2024-09-25 11:13:51',
          type: 'form',
          title: '',
          description: '',
          fields: [
            {
              field_name: 'first_field',
              display_name: 'Sample Field',
              type: 'text',
              span: 12,
              parent_field: '',
              condition: '',
              condition_value: '',
            },
          ],
          responses: {
            first_field: '',
          },
          item_id: '8e9b90e3-aec7-5a22-1a80-de1481b2ff3e',
        },
        {
          id: '1727234057829',
          type: 'chart',
          title: '',
          description: '',
          fields: [
            {
              field_name: 'first_field',
              display_name: 'Field Name',
              type: 'text',
              span: 6,
            },
          ],
          responses: {
            first_field: '',
            dental_chart: [
              {
                id: 1,
                condition: '',
                treatment: '',
              },
              {
                id: 2,
                condition: '',
                treatment: '',
              },
              {
                id: 3,
                condition: '',
                treatment: '',
              },
              {
                id: 4,
                condition: '',
                treatment: '',
              },
              {
                id: 5,
                condition: '',
                treatment: '',
              },
              {
                id: 6,
                condition: '',
                treatment: '',
              },
              {
                id: 7,
                condition: '',
                treatment: '',
              },
              {
                id: 8,
                condition: '',
                treatment: '',
              },
              {
                id: 9,
                condition: '',
                treatment: '',
              },
              {
                id: 10,
                condition: '',
                treatment: '',
              },
              {
                id: 11,
                condition: '',
                treatment: '',
              },
              {
                id: 12,
                condition: '',
                treatment: '',
              },
              {
                id: 13,
                condition: '',
                treatment: '',
              },
              {
                id: 14,
                condition: '',
                treatment: '',
              },
              {
                id: 15,
                condition: '',
                treatment: '',
              },
              {
                id: 16,
                condition: '',
                treatment: '',
              },
              {
                id: 17,
                condition: '',
                treatment: '',
              },
              {
                id: 18,
                condition: '',
                treatment: '',
              },
              {
                id: 19,
                condition: '',
                treatment: '',
              },
              {
                id: 20,
                condition: '',
                treatment: '',
              },
              {
                id: 21,
                condition: '',
                treatment: '',
              },
              {
                id: 22,
                condition: '',
                treatment: '',
              },
              {
                id: 23,
                condition: '',
                treatment: '',
              },
              {
                id: 24,
                condition: '',
                treatment: '',
              },
              {
                id: 25,
                condition: '',
                treatment: '',
              },
              {
                id: 26,
                condition: '',
                treatment: '',
              },
              {
                id: 27,
                condition: '',
                treatment: '',
              },
              {
                id: 28,
                condition: '',
                treatment: '',
              },
              {
                id: 29,
                condition: '',
                treatment: '',
              },
              {
                id: 30,
                condition: '',
                treatment: '',
              },
              {
                id: 31,
                condition: '',
                treatment: '',
              },
              {
                id: 32,
                condition: '',
                treatment: '',
              },
            ],
          },
          item_id: 'c087a2da-9507-605b-6ba2-26179cad4a26',
        },
      ],
      creator_name: 'Admin Client',
      last_editor_name: 'Admin Client',
      service_notes_order: 1,
    },
    {
      id: 927,
      company_id: 4,
      facility_id: 5,
      patient_id: 4,
      appointment_id: 645,
      parent_note_id: null,
      header: 'CLEAN',
      responses: {
        first_field: '',
      },
      footer: null,
      is_active: 1,
      created_by: 4,
      last_edited_by: null,
      created_at: '2024-12-06T10:19:35.000000Z',
      updated_at: '2025-01-06T04:06:03.000000Z',
      collection_id: 3,
      date_recorded: '2024-12-06',
      order: 1,
      template_id: 5,
      is_done: 0,
      collection_name: 'Assessment',
      first_name: 'Tom',
      middle_name: '',
      last_name: 'Aspinall',
      schedule: '2024-12-06 18:19:28',
      service_name: null,
      facility_name: 'CPHI',
      template_body: [
        {
          fields: [
            {
              field_name: '77334a74-dfef-c255-0223-291f45be18d7',
              display_name: 'Assessment',
              type: 'checkbox_group',
              span: 12,
              options: [
                {
                  key: 'Check Down',
                  value: 'Check Down',
                  item_id: 'c4e0590c-3f15-46f3-248d-8b50c696ad49',
                },
                {
                  key: 'Check Up',
                  value: 'Check Up',
                  item_id: '1bb76fda-e932-c1a6-8827-eea735ee29af',
                },
              ],
              parent_field: '',
              condition: '',
              condition_value: '',
            },
            {
              field_name: '1d17b472-b332-9f5b-ec4e-a025b2742000',
              display_name: 'Yes/No Field',
              type: 'radiogroup',
              span: 12,
              options: [
                {
                  key: 'Yes',
                  value: 'Yes',
                  item_id: 'fb8d1d9c-3b40-ae78-54ab-c1b1418ae001',
                },
                {
                  key: 'No',
                  value: 'No',
                  item_id: '3d621842-6e59-ec98-5cd5-14b82fc45585',
                },
              ],
              parent_field: '',
              condition: '',
              condition_value: '',
              formula_text: '',
              default_value: '',
            },
          ],
          responses: {
            first_field: '',
          },
        },
      ],
      creator_name: 'Admin Client',
      last_editor_name: null,
      service_notes_order: 2,
    },
    {
      id: 926,
      company_id: 4,
      facility_id: 5,
      patient_id: 4,
      appointment_id: 645,
      parent_note_id: null,
      header: 'SOAP',
      responses: {},
      footer: null,
      is_active: 1,
      created_by: 4,
      last_edited_by: 4,
      created_at: '2024-12-06T10:19:35.000000Z',
      updated_at: '2025-01-06T04:09:30.000000Z',
      collection_id: 3,
      date_recorded: '2024-12-06',
      order: 1,
      template_id: 4,
      is_done: 0,
      collection_name: 'Assessment',
      first_name: 'Tom',
      middle_name: '',
      last_name: 'Aspinall',
      schedule: '2024-12-06 18:19:28',
      service_name: null,
      facility_name: 'CPHI',
      template_body: [
        {
          fields: [
            {
              type: 'section_header',
              section_header: 'Subjective',
              display_name: 'Subjective',
              span: 12,
              item_id: '11a17f2c-aba8-1ee3-50da-e1c0089c9f5a',
            },
            {
              field_name: '5da04a1f-fbbf-3991-ba26-c408ce82b0a4',
              display_name: 'Chief Complaints',
              type: 'text',
              multiline: true,
              rows: 3,
              span: 12,
              item_id: 'e29c4f51-5435-7126-8a92-01b43614de28',
            },
            {
              field_name: '7ef0e796-20c2-ab75-971d-fb32f76fef81',
              display_name: 'History of Present Illness',
              type: 'text',
              multiline: true,
              rows: 3,
              span: 12,
              item_id: '5a058005-35ae-8916-2738-e5625ea18eb3',
            },
            {
              type: 'subsection_header',
              section_header: 'Medical History',
              display_name: 'Medical History',
              span: 12,
              item_id: '7777e8b1-15a9-833d-e878-c954adc9bf84',
            },
            {
              field_name: 'a250cf13-9aa2-e991-fe20-19fb669051fc',
              display_name: 'Medical History',
              type: 'text',
              multiline: true,
              rows: 3,
              span: 12,
              item_id: '98b09452-396f-ecf6-e67e-869e8f391416',
            },
            {
              field_name: '3b423aef-12c3-28e1-3c26-7f1c613a9d81',
              display_name: 'Allergies',
              type: 'text',
              span: 12,
              item_id: 'f58862a4-c007-04e6-c7ad-5e0b43db5a4a',
            },
            {
              type: 'divider',
              span: 12,
            },
            {
              type: 'section_header',
              section_header: 'Objective',
              display_name: 'Objective',
              span: 12,
              item_id: 'cbcf6f44-fa94-5264-dcfe-886e2ce8cc58',
            },
            {
              type: 'subsection_header',
              section_header: 'Vitals',
              display_name: 'Vitals',
              span: 12,
              item_id: 'b36fb521-5619-fda0-c23f-e5da0cf14d31',
            },
            {
              field_name: '2426fe56-6879-2d75-5ca4-94f79f9049dd',
              display_name: 'Blood Pressure',
              type: 'text',
              span: 6,
              item_id: '45a60975-848c-f0f2-8574-925d36165e83',
            },
            {
              field_name: '9ae69e8c-bb88-c8c5-cb58-b3cfde149c5f',
              display_name: 'Heart Rate',
              type: 'text',
              span: 6,
              item_id: '26ded9d6-847a-1464-cf75-c3dfcddfb576',
            },
            {
              field_name: '424229cb-b712-0a1c-393e-cdff42abd6ed',
              display_name: 'Temperature',
              type: 'text',
              span: 6,
              item_id: '12fd912b-4594-5ad5-8065-0131630b518d',
            },
            {
              field_name: 'ee612546-3401-0cc4-cf27-abf6d6f26793',
              display_name: 'Clinical Exam',
              type: 'rich_text',
              span: 12,
              height: '200px',
              item_id: 'c97ce1f6-f1d3-1d51-48e3-c70056cf2ef2',
            },
            {
              type: 'divider',
              span: 12,
            },
            {
              field_name: '1661b1f6-3e35-5627-46e6-144a1140ffb3',
              display_name: 'Assessment',
              type: 'rich_text',
              span: 12,
              height: '200px',
              item_id: 'aeebf7e2-58eb-ac76-5cf7-3bc0caa96abc',
            },
            {
              type: 'section_header',
              section_header: 'Assessment',
              display_name: 'Assessment',
              span: 12,
              item_id: 'eb8c7ebb-48c9-243b-cdfc-4b4b9454c8f1',
            },
            {
              type: 'divider',
              span: 12,
            },
            {
              type: 'section_header',
              section_header: 'Plan',
              display_name: 'Plan',
              span: 12,
              item_id: '56afed4d-0cbe-9fa2-380e-7022e018dc90',
            },
            {
              field_name: '31ec9707-3d1a-4f94-9766-17be218ffe2d',
              display_name: 'Plan',
              type: 'rich_text',
              span: 12,
              height: '200px',
              item_id: 'e4424f81-3696-6a47-529b-56c9d14197fb',
            },
            {
              field_name: 'c79ef7c6-1830-14c5-9d82-bdfcc2b0fd57',
              display_name: 'Treatment Rendered Today',
              type: 'rich_text',
              span: 12,
              height: '200px',
              item_id: 'd08c8ae3-7310-1cd7-b279-4c5ac23c7f83',
            },
            {
              field_name: '969b4864-1f62-227a-b258-c4af0921f963',
              display_name: 'Some Field',
              type: 'radiogroup',
              span: 4,
              options: [
                {
                  key: 'Option A',
                  value: 'Option A',
                  item_id: '9de16fb5-4417-905e-7866-616ea0c79e47',
                },
                {
                  key: 'Option B',
                  value: 'Option B',
                  item_id: '520beb6a-07c2-d32e-900a-bfbe794d562d',
                },
              ],
            },
            {
              field_name: 'cdb82113-2f44-6ab7-85a9-676f0cd6721d',
              display_name: 'Yes/No Field',
              type: 'radiogroup',
              span: 4,
              options: [
                {
                  key: 'Yes',
                  value: 'Yes',
                  item_id: 'f93505ed-4c16-4a90-534b-89558de57c10',
                },
                {
                  key: 'No',
                  value: 'No',
                  item_id: '7a2cbe1c-b1c8-b96e-ab8c-720383001467',
                },
              ],
            },
          ],
          responses: [],
        },
      ],
      creator_name: 'Admin Client',
      last_editor_name: 'Admin Client',
      service_notes_order: 3,
    },
  ],
};
const Sandbox = () => {
  return (
    <Box>
      {/* <CustomTableForm
        fixedRows
        columns={[
          { fieldName: 'service', displayName: 'Service', type: 'fixed_text' },
          { fieldName: 'left', displayName: 'Left' },
          { fieldName: 'right', displayName: 'Right' },
          {
            fieldName: 'yes',
            displayName: 'Right',
            type: 'select',
            options: [
              { key: 'YES', value: 'YES' },
              { key: 'NO', value: 'NO' },
            ],
          },
        ]}
        field={{}}
        initialValues={[
          {
            service: 'Service 1',
            left: 1,
            right: 1,
            yes: 'NO',
          },
        ]}
        handleChange={(rows: any[]) => undefined}
      /> */}

      {/* <CustomLocationSearchGroupDropdown /> */}
      {/* <StElsewhere assessment={assessment} printMode={true} /> */}
      {/* <MalvarAssessmentForm assessment={assessment} /> */}
      <UnifiedLabForm assessment={assessment} />
    </Box>
  );
};

export default Sandbox;
