import {
  USER_ACCESS_ENTITY_NAME,
  UserAccessFields,
  UserAccessFormContent,
  UserAccessInitialValues,
  UserAccessModel,
} from './UserAccessModel';
import { getUserFacilities, updateUserAccount } from 'company/api/users';
import { useContext, useEffect, useState } from 'react';

import { ClinicModel } from '../Clinic/ClinicModel';
import TemplateForm from '../../../Template/TemplateForm';
import { UserContext } from 'core/context/user.context';

type Props = {
  userAccess?: UserAccessModel;
  showSubmitAtTop?: boolean;
  readonly?: boolean;
  callbackAfterSubmit?: (data: any) => void;
};

const UserAccessForm: React.FC<Props> = (props) => {
  const { userAccess, readonly, showSubmitAtTop, callbackAfterSubmit } = props;
  const { user } = useContext(UserContext);
  const [userData, setUserData] = useState<UserAccessModel>();
  const [dropdownOptions, setDropdownOptions] = useState<any>([]);

  useEffect(() => {
    if (user) {
      setDropdownOptions({
        facilities: user
          ? user?.facilities?.map((facility) => ({
              key: facility.facility_name,
              value: facility.id,
            }))
          : [],
      });
    }
  }, [user]);

  const handleSubmitApi = (data: any) => {
    var assigned_facilities = data.facilities
      ? data.facilities.map((facility: any) => facility.value.toString())
      : '[]';

    data['assigned_facilities'] = JSON.stringify(assigned_facilities);

    return updateUserAccount(data.id, data);
  };

  const getAssignedFacilities = async () => {
    if (userAccess) {
      const res = await getUserFacilities(userAccess.id);

      const selectedClinics = res.data.data.map((clinic: ClinicModel) => ({
        key: clinic.facility_name,
        value: clinic.facility_id,
      }));

      userAccess.facilities = selectedClinics;
      setUserData(userAccess);
    }
  };

  useEffect(() => {
    getAssignedFacilities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccess]);

  if (userAccess && !userData) return <></>;

  return (
    <TemplateForm
      entity={userData}
      useInitialValue
      entityName={USER_ACCESS_ENTITY_NAME}
      initialValues={userAccess ? userData : UserAccessInitialValues}
      fields={UserAccessFields}
      visibleFields={UserAccessFormContent}
      formSubmitApiFunction={handleSubmitApi}
      callbackAfterSubmit={callbackAfterSubmit}
      dropdownOptions={dropdownOptions}
      readOnly={readonly}
      showSubmitAtTop={showSubmitAtTop}
      submitButtonId={'add_company_user_submit_button'}
    />
  );
};

export default UserAccessForm;
