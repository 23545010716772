import { Box, Typography, useTheme } from '@mui/material';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { FacilityContext } from 'core/context/facility.context';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import { QRCode } from 'react-qrcode-logo';
import React from 'react';
import { formatDateTime } from 'core/utils';
import { tokens } from 'theme/theme';

const PrintableSelfRegistration = React.forwardRef((props, ref) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { facility } = React.useContext(FacilityContext);

  const steps = [
    {
      title: 'Access Registration',
      description: `Scan the QR code or visit our self-registration page: ${process.env.REACT_APP_BASE_URL}/self-registration/${facility?.id}`,
    },
    {
      title: 'Fill Information',
      description: 'Complete all required personal and medical information in the registration form.',
    },
    {
      title: 'Submit & Wait',
      description: 'Submit your registration and wait for confirmation from our clinic staff.',
    },
  ];

  return (
    <Box ref={ref} sx={{ p: 4, maxWidth: '800px', mx: 'auto' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 4 }}>
        <Typography variant="h3" sx={{ fontWeight: 700, color: colors.accent }}>
          {facility?.facility_name}
        </Typography>
        <img
          src={process.env.PUBLIC_URL + '/assets/carego_health_suite_logo_white.png'}
          alt="Logo"
          style={{ height: '64px' }}
        />
      </Box>

      <Typography variant="h2" sx={{ fontWeight: 700, mb: 3, color: colors.dark_grey_text }}>
        Self-Registration Instructions
      </Typography>

      <Box sx={{ mb: 4 }}>
        {steps.map((step, index) => (
          <Box key={index} sx={{ display: 'flex', gap: 2, mb: 3 }}>
            {/* <Box
              sx={{
                bgcolor: colors.primary,
                borderRadius: '12px',
                p: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
              }}
            >
              {step.icon}
            </Box> */}
            <Box>
              <Typography variant="h3" sx={{ fontWeight: 500, mb: 2 }}>
                {index + 1}. {step.title}
              </Typography>
              <Typography variant="h4" sx={{ mb: 2 }}>
                {step.description}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>

      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h2" sx={{ fontWeight: 700, mb: 2, color: colors.dark_grey_text }}>
          Scan to Register
        </Typography>
        <Box
          sx={{
            display: 'inline-block',
            bgcolor: colors.lightBackground,
            p: 3,
            borderRadius: '12px',
          }}
        >
          <QRCode
            value={`${process.env.REACT_APP_BASE_URL}/self-registration/${facility?.id}`}
            size={300}
            logoImage={process.env.PUBLIC_URL + '/assets/carego_health_suite_logo_white.png'}
            removeQrCodeBehindLogo
            eyeRadius={10}
            fgColor={colors.black_text}
          />
        </Box>
      </Box>

      <Box position="fixed" bottom="0" width="calc(100% - 64px)" left="32px">
        <hr
          style={{
            border: 'none',
            borderTop: `0.5px solid ${colors.primary}`,
            width: '100%',
            transform: 'scaleY(0.5)',
          }}
        />

        <Box width="100%">
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
            <img
              alt="cphi-logo"
              width="30px"
              height="auto"
              id="logo"
              src={process.env.PUBLIC_URL + `/assets/carego_health_suite_logo.png`}
            />

            <Box sx={{ textAlign: 'left', gap: '4px' }}>
              <Typography sx={{ fontSize: '9px', color: colors.accent }}>
                Document generated using CareGo Health Suite on {formatDateTime(new Date())}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
});

export default PrintableSelfRegistration;
