import React, { ReactNode } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography, useTheme } from '@mui/material';
import EjectIcon from '@mui/icons-material/Eject';
import { tokens } from 'theme/theme';

type CustomAccordionProps = {
  title: string;
  children: ReactNode;
  titleIcon?: ReactNode;
};

function CustomAccordion({ title, children, titleIcon }: CustomAccordionProps) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Accordion
      sx={{
        backgroundColor: 'none',
        boxShadow: 'none',
        '&:before': { display: 'none' },
        '& .MuiAccordionSummary-root': {
          borderRadius: '15px',
          padding: '0 20px',
          backgroundColor: colors.light_blue_background,
          marginTop: '20px',
          fontWeight: 'bold',
          '& .Mui-expanded': {
            borderRadius: '50px',
          },
        },
        '& .MuiAccordionDetails-root': {
          padding: '16px 5px',
        },
      }}
    >
      <AccordionSummary
        expandIcon={
          <EjectIcon
            sx={{
              rotate: '180deg',
              color: colors.primary,
              fontSize: '24px',
            }}
          />
        }
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography fontWeight={'600'} sx={{ display: 'flex', alignItems: 'center', gap: 1, color: colors.primary }}>
          {titleIcon}
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}

export default CustomAccordion;
