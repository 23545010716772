import './Calendar.css';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  ButtonGroup,
  Divider,
  IconButton,
  Popover,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Close, Schedule } from '@mui/icons-material';
import { CustomAnimatedDrawer, CustomIconButton, CustomModal, RegularButton } from 'core/components';
import {
  HolidayEventModel,
  OutOfOfficeModel,
} from 'company/entities/modules/ClinicManagement/CalendarEvent/CalendarEventModel';
import React, {
  ReactNode,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { addMinutes, format, parse } from 'date-fns';
import { capitalizeWord, formatTimeWithoutAMPM } from 'core/utils';
import { getAllHolidayEvents, getAllOutOfOfficeEvents } from 'company/api/calendar-events';

import AppointmentSettings from 'company/screens/Appointments/components/AppointmentSettings';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { CompanyAppointmentModel } from 'company/entities/modules/ClinicManagement/Appointment/AppointmentModel';
import { ContainerRow } from 'core/components/containers';
import CustomFilter from 'core/components/CustomFilter';
import CustomLoadingIndicator from 'core/components/CustomLoadingIndicator';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import DayView from './DayView';
import DeckingTable from './DeckingTable';
import EjectIcon from '@mui/icons-material/Eject';
import { FacilityContext } from 'core/context/facility.context';
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import PatientField from 'company/screens/PatientManagement/components/PatientField';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { PositionedEvent } from './utils';
import { ProtectedComponent } from 'core/components';
import ProviderScheduledTable from './ProviderScheduledTable';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { SIZES } from 'theme/constants';
import ServiceProviderDrawer from 'company/screens/Appointments/screens/ServiceProviderDrawer';
import { ServiceProviderModel } from 'company/entities/modules/ClinicManagement/ServiceProvider/ServiceProviderModel';
import StatTable from './StatTable';
import { UserContext } from 'core/context/user.context';
import WeekView from './WeekView';
import { getAllAppointmentLegends } from 'company/api/appointment-legends';
import { getAppointmentInDateRange } from 'company/api/appointments';
import moment from 'moment';
import styled from 'styled-components';
import { tokens } from 'theme/theme';
import { useNavigate } from 'react-router-dom';

interface CalendarEventProps {
  event: PositionedEvent;
  staffColor: string | null;
  dayView?: boolean;
  staffCount: number;
  refreshEvents: () => void;
}

interface ScheduleHour {
  start: string;
  end: string;
}

interface ScheduleDay {
  day: string;
  week_day: string;
  hours: ScheduleHour[];
}

export interface ViewProps {
  date: Date;
  staff: ServiceProviderModel[];
  events: CompanyAppointmentModel[];
  hours: number[];
  startHour: number;
  endHour: number;
  setOpenServiceProviders: React.Dispatch<React.SetStateAction<boolean>>;
  facilitySchedule: ScheduleDay[];
  refreshEvents: () => void;
  holidays?: HolidayEventModel[];
  outOfOfficeEvents?: OutOfOfficeModel[];
}

export interface WeekViewProps extends ViewProps {
  startOfTheWeek: number;
  openDays: number;
}

export const CalendarEvent: React.FC<CalendarEventProps> = ({
  event,
  staffColor,
  dayView,
  staffCount,
  refreshEvents,
}) => {
  const [openPopover, setOpenPopover] = useState(false);
  const anchorRef = useRef(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const { facility } = useContext(FacilityContext);

  const formatTimeRange = (startDateTime: string, durationInMinutes: number) => {
    const startDate = parse(startDateTime, 'yyyy-MM-dd HH:mm:ss', new Date());
    const endDate = addMinutes(startDate, durationInMinutes);
    return `${format(startDate, 'MMM d, yyyy h:mm a')} - ${format(endDate, 'h:mm a')}`;
  };

  const handlePopover = (event?: any) => {
    event && event.stopPropagation();
    setOpenPopover((prevOpen) => !prevOpen);
  };

  const Status = () => {
    return (
      <Box
        sx={{
          borderRadius: '5px',
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          backgroundColor: colors.accent,
          color: 'white',
          padding: '3px 10px',
          fontSize: '10px',
        }}
      >
        <CalendarMonthIcon sx={{ fontSize: '13px' }} />
        <Typography sx={{ fontSize: '10px' }}>{capitalizeWord(event.status)}</Typography>
      </Box>
    );
  };

  return (
    <>
      <Box
        key={event.id}
        className="event"
        sx={{
          top: `${event.top}px`,
          height: `${event.height}px`,
          left: `${event.left * (100 / event.width)}%`,
          width: `${100 / event.width}%`,
          overflow: 'hidden',
          cursor: 'pointer',
          backgroundColor: `${staffColor}`,
          borderColor: `${staffColor}`,
          borderLeft: event.tag_color ? `4px solid ${event.tag_color}` : undefined,
          ':hover': { height: 'auto', zIndex: 2 },
        }}
        display="flex"
        alignItems="flex-start"
        // flexDirection="column"
        ref={anchorRef}
        onClick={handlePopover}
        gap={SIZES.paddingS}
      >
        {dayView && staffCount <= 4 && <Status />}
        {/* <Typography fontSize="12px" color={colors.black_text}>
            <span style={{ fontWeight: 'bold' }}>
              {event.full_name} - [{event.service_names}]
            </span>
            {formatTimeWithoutAMPM(event.start!)} - {formatTimeWithoutAMPM(event.end!)}:{' '}
          </Typography> */}
        <Box>
          <Typography fontSize="12px" color={colors.black_text} fontWeight="600">
            {event.full_name}
          </Typography>
          <Typography fontSize="12px" color={colors.black_text}>
            [{event.service_names}]
          </Typography>
          <Typography fontSize="12px" color={colors.black_text}>
            {formatTimeWithoutAMPM(event.start!)} - {formatTimeWithoutAMPM(event.end!)}
          </Typography>
        </Box>
      </Box>
      <Popover
        open={openPopover}
        anchorEl={anchorRef.current}
        onClose={handlePopover}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        sx={{ zIndex: 2 }}
      >
        <Box width="300px">
          <Box
            padding="10px 20px"
            sx={{ backgroundColor: colors.light_blue_background_2 }}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" gap="10px" alignItems="center" width="100%">
              <Typography
                variant="h4"
                fontWeight="bold"
                color={colors.primary}
                display="flex"
                gap="10px"
                alignItems="center"
              >
                <Span onClick={() => navigate(`/company/patient-management?patient_id=${event?.patient_id}`)}>
                  {event && event.full_name}
                </Span>
              </Typography>
              {!facility?.is_admin && (
                <ProtectedComponent
                  requiredAuth={['MANAGE_APPOINTMENTS']}
                  requiredRole={['COMPANY_ADMIN', 'COMPANY_STAFF', 'CLINIC_STAFF', 'COMPANY_DOCTOR']}
                >
                  <AppointmentSettings
                    appointment={event!}
                    updateAppointmentData={() => {
                      handlePopover();
                      refreshEvents();
                    }}
                    navigateToEditor
                  />
                </ProtectedComponent>
              )}
            </Box>
            <IconButton onClick={handlePopover}>
              <Close />
            </IconButton>
          </Box>
          <Divider sx={event.tag_color ? { borderColor: event.tag_color, borderWidth: '1.5px' } : undefined} />
          <Box padding="20px">
            <Box display="flex" flexDirection="column" gap="10px">
              <Box display="flex" alignItems="center" gap="5px" mb="10px">
                <Schedule sx={{ color: colors.primary, fontSize: '20px' }} />
                <Span onClick={() => navigate(`/company/appointments/${event?.id}`)}>
                  <Typography fontSize={'14px'} fontWeight={600}>
                    {formatTimeRange(event.schedule, event.height)}
                  </Typography>
                </Span>
              </Box>
              <PatientField label="Mobile Number" value={event.mobile_number} />
              <PatientField label="Service" value={event.service_names} />
              <PatientField
                label="Service Provider"
                value={event.service_provider_with_title ?? event.service_provider}
              />
              <PatientField
                label="Status"
                valueIsComponent
                value={<Status />}
                sx={{ flexDirection: 'row', gap: '10px' }}
              />
            </Box>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

interface ServiceProviderCalendarProps {
  scheduleViewRef?: any;
}
const ServiceProviderCalendar = forwardRef((props: ServiceProviderCalendarProps, ref) => {
  const { scheduleViewRef } = props;
  const { facility } = useContext(FacilityContext);
  const { user } = useContext(UserContext);
  const [currentDate, setCurrentDate] = useState<any>();
  const [view, setView] = useState<'week' | 'day'>('week');
  const [openServiceProviders, setOpenServiceProviders] = useState<boolean>(false);
  const [openStats, setOpenStats] = useState<boolean>(false);

  const [selectedProviders, setSelectedProviders] = useState<ServiceProviderModel[]>();
  const [events, setEvents] = useState<CompanyAppointmentModel[]>([]);
  const isMobile = useMediaQuery('(max-width:768px)');

  const [facilitySchedule, setFacilitySchedule] = useState<ScheduleDay[]>([]);
  const [openDays, setOpenDays] = useState(7);
  const [startOfTheWeek, setStartOfTheWeek] = useState(0);
  const [startHour, setStartHour] = useState(6);
  const [endHour, setEndHour] = useState(19);

  const [legendFilters, setLegendFilters] = useState<string[]>([]);
  const [allLegends, setAllLegends] = useState<any[]>([]);
  const [holidayEvents, setHolidayEvents] = useState<HolidayEventModel[]>([]);
  const [outOfOfficeEvents, setOutOfOfficeEvents] = useState<OutOfOfficeModel[]>([]);
  const serviceProviderRef: any = useRef(null);
  const [allServiceProviders, setAllServiceProviders] = useState<ServiceProviderModel[]>([]);
  const [selectedServiceProviderFilter, setSelectedServiceProviderFilter] = useState<string[]>([]);
  const [weeklyTallyData, setWeeklyTallyData] = useState([
    { day: 'Sunday', actual: 0, scheduled: 0, cancellations: 0, noShow: 0 },
    { day: 'Monday', actual: 0, scheduled: 0, cancellations: 0, noShow: 0 },
    { day: 'Tuesday', actual: 0, scheduled: 0, cancellations: 0, noShow: 0 },
    { day: 'Wednesday', actual: 0, scheduled: 0, cancellations: 0, noShow: 0 },
    { day: 'Thursday', actual: 0, scheduled: 0, cancellations: 0, noShow: 0 },
    { day: 'Friday', actual: 0, scheduled: 0, cancellations: 0, noShow: 0 },
    { day: 'Saturday', actual: 0, scheduled: 0, cancellations: 0, noShow: 0 },
  ]);

  const handleGetServiceProviders = () => {
    if (serviceProviderRef.current) {
      const serviceProviders = serviceProviderRef.current.getServiceProviders();
      setAllServiceProviders(serviceProviders); // Access serviceProviders here
    }
  };

  const calculateWeeklyTally = useMemo(() => {
    const tally = [
      { day: 'Sunday', actual: 0, scheduled: 0, cancellations: 0, noShow: 0 },
      { day: 'Monday', actual: 0, scheduled: 0, cancellations: 0, noShow: 0 },
      { day: 'Tuesday', actual: 0, scheduled: 0, cancellations: 0, noShow: 0 },
      { day: 'Wednesday', actual: 0, scheduled: 0, cancellations: 0, noShow: 0 },
      { day: 'Thursday', actual: 0, scheduled: 0, cancellations: 0, noShow: 0 },
      { day: 'Friday', actual: 0, scheduled: 0, cancellations: 0, noShow: 0 },
      { day: 'Saturday', actual: 0, scheduled: 0, cancellations: 0, noShow: 0 },
    ];

    const providerFilter = selectedServiceProviderFilter?.map((id) => +id);
    const eventsCopy = [...events];

    eventsCopy
      .filter((event) => {
        if (!providerFilter || providerFilter.length <= 0) return true;
        return event.provider_id && providerFilter.includes(event.provider_id);
      })
      .forEach((event) => {
        const eventDate = moment(event.schedule).toDate();
        const dayIndex = eventDate.getDay();
        const dayData = tally[dayIndex];

        switch (event.status) {
          case 'done':
            dayData.actual++;
            break;
          case 'pending':
          case 'rescheduled':
            dayData.scheduled++;
            break;
          case 'cancelled':
            dayData.cancellations++;
            break;
          case 'no-show':
            dayData.noShow++;
            break;
        }
      });

    return tally;
  }, [events, selectedServiceProviderFilter]);

  useEffect(() => {
    handleGetServiceProviders();
    setWeeklyTallyData(calculateWeeklyTally);
  }, [calculateWeeklyTally]);

  const updateCalendarSettings = (schedule: ScheduleDay[]) => {
    let earliest = 23;
    let latest = 0;
    let startDayFound = false;
    let lastDay = 7;
    let startDay = 0;

    schedule.forEach((day: ScheduleDay, index) => {
      if (day.hours.length) {
        lastDay = index;
        if (!startDayFound) {
          setStartOfTheWeek(index);
          startDayFound = true;
          startDay = index;
        }
      }
      day.hours.forEach((hour: ScheduleHour) => {
        const starthourAMPM = hour.start.split(' ')[1];
        const startHour = parseInt(hour.start.split(':')[0]) + (starthourAMPM === 'PM' ? 12 : 0);
        const endHourAMPM = hour.end.split(' ')[1];
        const endHour = parseInt(hour.end.split(':')[0]) + (endHourAMPM === 'PM' ? 13 : 1);

        earliest = Math.min(earliest, startHour);
        latest = Math.max(latest, endHour);
      });
    });

    setStartHour(earliest);
    setEndHour(latest);
    setOpenDays(lastDay - startDay + 1);
  };

  const hours = useMemo(
    () => Array.from({ length: endHour - startHour + 1 }, (_, i) => i + startHour),
    [endHour, startHour]
  );

  useEffect(() => {
    if (facility) {
      let schedule = facility.schedule;
      if (!schedule && user?.facilities?.length) {
        schedule = user.facilities[0].schedule;
      }
      const parsedSchedule: ScheduleDay[] = JSON.parse(schedule ?? '[]');
      setFacilitySchedule(parsedSchedule);
      updateCalendarSettings(parsedSchedule);
    }
  }, [facility, user]);

  useImperativeHandle(ref, () => ({ getAppointments }));

  const prev = () => {
    if (view === 'week') {
      setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() - 7)));
    } else {
      setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() - 1)));
    }
  };

  const next = () => {
    if (view === 'week') {
      setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() + 7)));
    } else {
      setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() + 1)));
    }
  };

  const formatHeader = () => {
    if (view === 'week') {
      const startOfWeek = new Date(currentDate);
      startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6);
      const options: any = { month: 'short', day: 'numeric' };
      const startDateStr = startOfWeek.toLocaleDateString('en-US', options);
      const endDateStr =
        startOfWeek.getMonth() === endOfWeek.getMonth()
          ? endOfWeek.toLocaleDateString('en-US', { day: 'numeric' })
          : endOfWeek.toLocaleDateString('en-US', options);
      return `${startDateStr} - ${endDateStr}`;
    } else if (view === 'day') {
      return currentDate.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' });
    }
  };

  useEffect(() => {
    getAppointments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facility?.id, currentDate, legendFilters]);

  useEffect(() => {
    const date = new Date();
    const startOfWeek = new Date(date.setDate(date.getDate() - date.getDay()));
    setCurrentDate(startOfWeek);
  }, []);

  useEffect(() => {
    if (facility) {
      getAllAppointmentLegends(facility.id).then((res) => {
        setAllLegends(res.data.data);
      });
    }
  }, [facility]);

  async function getAllHolidays() {
    if (!facility) return;
    const res = await getAllHolidayEvents(facility.id, { length: 1000 });
    setHolidayEvents(res.data?.data);
  }

  async function getAllOutOfOffice(startDate?: Date, endDate?: Date) {
    if (!facility) return;
    const res = await getAllOutOfOfficeEvents(facility.id, { length: 1000, startDate, endDate });
    setOutOfOfficeEvents(res.data?.data);
  }
  // useEffect(() => {
  //   if (!currentDate) return;
  //   const daysOfWeek = Array.from(
  //     { length: openDays },
  //     (_, i) => new Date(currentDate.getTime() + (i + startOfTheWeek) * 86400000)
  //   );
  //   const startDay = daysOfWeek[0];
  //   const endDay = daysOfWeek.slice(-1).pop();

  //   getAllOutOfOffice(startDay, endDay);
  //   getAllHolidays();
  // }, [facility, currentDate]);

  function handleRefreshAppointments() {
    getAppointments();
    scheduleViewRef && scheduleViewRef?.current?.refreshTable();
  }

  const legendOptions = useMemo(() => {
    return allLegends.map((legend: any) => ({
      label: legend.tag_name,
      id: legend.id.toString(),
    }));
  }, [allLegends]);

  const serviceProviderOptions = useMemo(() => {
    if (!allServiceProviders) return [];
    return allServiceProviders
      .filter((serviceProvider: ServiceProviderModel) => !!serviceProvider.doctor_id)
      .map((serviceProvider: ServiceProviderModel) => ({
        label: serviceProvider.full_name,
        id: serviceProvider.doctor_id?.toString(),
      }));
  }, [allServiceProviders]);

  const getAppointments = () => {
    if (facility && currentDate) {
      const start = currentDate;
      const end = new Date(currentDate);
      end.setDate(end.getDate() + (view === 'week' ? 7 : 1));

      const query = {
        legendFilter: legendFilters.length > 0 ? legendFilters.join(',') : '',
      };

      getAppointmentInDateRange(
        facility.id,
        moment(start).format('YYYY-MM-DD'),
        moment(end).format('YYYY-MM-DD'),
        query
      ).then((res) => {
        setEvents(
          res.data.data.map((appointment: CompanyAppointmentModel) => ({
            ...appointment,
            start: appointment.schedule,
            end: moment(appointment.schedule)
              .add(appointment.duration > 0 ? appointment.duration : 30, 'minutes')
              .format(),
          }))
        );
      });

      const daysOfWeek = Array.from(
        { length: openDays },
        (_, i) => new Date(currentDate.getTime() + (i + startOfTheWeek) * 86400000)
      );
      const startDay = daysOfWeek[0];
      const endDay = daysOfWeek.slice(-1).pop();
      view === 'week' ? getAllOutOfOffice(startDay, endDay) : getAllOutOfOffice();
      getAllHolidays();
    }
    // getAllHolidays();
    // getAllOutOfOffice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <Box width="100%">
      <div className="calendar">
        <Box
          display="flex"
          gap="10px"
          flexDirection={isMobile ? 'column' : 'row'}
          justifyContent="space-between"
          mb={SIZES.padding}
        >
          <Box display="flex" gap="10px">
            <RegularButton
              variant={'outlined'}
              onClick={() => {
                setCurrentDate(new Date());
              }}
              label="Today"
              size="small"
            />
            <ContainerRow sx={{ justifyContent: 'center', alignItems: 'center' }}>
              <CustomIconButton onClick={prev} icon={<ChevronLeftIcon sx={{ fontSize: '30px' }} />} />
              <Typography
                variant={isMobile ? 'h4' : 'h3'}
                fontWeight="bold"
                width={isMobile ? '100px' : '150px'}
                textAlign="center"
              >
                {formatHeader()}
              </Typography>
              <CustomIconButton onClick={next} icon={<ChevronRightIcon sx={{ fontSize: '30px' }} />} />
            </ContainerRow>
          </Box>

          <Box display="flex" gap="10px" justifyContent="space-between">
            {legendOptions.length > 0 && (
              <CustomFilter
                buttonLabel="Legend Filter"
                span={2}
                optionList={legendOptions}
                selected={legendFilters}
                setSelected={setLegendFilters}
                buttonSize="small"
              />
            )}

            <RegularButton
              size="small"
              label={(isMobile ? '' : 'View ') + 'Service Provider'}
              onClick={() => setOpenServiceProviders(true)}
              startIcon={isMobile ? undefined : <RemoveRedEyeOutlinedIcon />}
            />
            <RegularButton
              size="small"
              label={(isMobile ? '' : 'View ') + 'Stats'}
              onClick={() => setOpenStats(true)}
              startIcon={isMobile ? undefined : <QueryStatsOutlinedIcon />}
            />

            <ButtonGroup>
              <RegularButton
                variant={view === 'day' ? 'outlined' : undefined}
                onClick={() => {
                  setView('week');
                  setCurrentDate(new Date());
                }}
                label="Week"
                size="small"
              />
              <RegularButton
                variant={view === 'week' ? 'outlined' : undefined}
                onClick={() => {
                  setView('day');
                  setCurrentDate(new Date());
                }}
                label="Day"
                size="small"
              />
            </ButtonGroup>
          </Box>
        </Box>
        {/* <Box height="calc(100vh - 200px)" sx={{ overflowX: 'auto' }}> */}
        {!selectedProviders ? (
          <Box height="400px" display="flex" justifyContent="center" alignItems="center">
            <CustomLoadingIndicator />
          </Box>
        ) : (
          <Box sx={{ overflowX: 'auto' }}>
            {view === 'week' && (
              <WeekView
                date={currentDate}
                staff={selectedProviders}
                events={events}
                hours={hours}
                startHour={startHour}
                endHour={endHour}
                setOpenServiceProviders={setOpenServiceProviders}
                facilitySchedule={facilitySchedule}
                startOfTheWeek={startOfTheWeek}
                openDays={openDays}
                refreshEvents={() => {
                  getAppointments();
                  scheduleViewRef && scheduleViewRef?.current?.refreshTable();
                }}
                holidays={holidayEvents}
                outOfOfficeEvents={outOfOfficeEvents}
              />
            )}
            {view === 'day' && (
              <DayView
                date={currentDate}
                staff={selectedProviders}
                events={events}
                hours={hours}
                startHour={startHour}
                endHour={endHour}
                facilitySchedule={facilitySchedule}
                setOpenServiceProviders={setOpenServiceProviders}
                refreshEvents={() => {
                  getAppointments();
                  scheduleViewRef && scheduleViewRef?.current?.refreshTable();
                }}
                holidays={holidayEvents}
                outOfOfficeEvents={outOfOfficeEvents}
              />
            )}
          </Box>
        )}
      </div>

      <CustomAnimatedDrawer
        open={openServiceProviders}
        setOpen={setOpenServiceProviders}
        header="Service Providers"
        width={600}
      >
        <ServiceProviderDrawer
          callbackAfterSubmit={handleRefreshAppointments}
          ref={serviceProviderRef}
          setOpenServiceProviders={setOpenServiceProviders}
          handleSelectProviders={(data: any) => setSelectedProviders(data)}
        />
      </CustomAnimatedDrawer>

      <CustomModal open={openStats} setOpen={setOpenStats} header={`Statistics (${formatHeader()})`} width={'1000px'}>
        {/* <Box sx={{ paddingTop: '8px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography fontWeight={'600'} sx={{ display: 'flex', alignItems: 'center' }}>
              <DateRangeOutlinedIcon sx={{ mr: '10px' }} />
              Weekly Tally
            </Typography>
            <CustomFilter
              selected={selectedServiceProviderFilter}
              setSelected={setSelectedServiceProviderFilter}
              optionList={serviceProviderOptions}
              buttonSize="small"
              buttonLabel="Service Provider Filter"
            ></CustomFilter>
          </Box>
          <StatTable rows={weeklyTallyData} />
          <br />
        </Box> */}

        <CustomAccordionContent title="Weekly Tally" titleIcon={<DateRangeOutlinedIcon sx={{ mr: '10px' }} />}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {/* <Typography fontWeight={'600'} sx={{ display: 'flex', alignItems: 'center' }}>
              <DateRangeOutlinedIcon sx={{ mr: '10px' }} />
              Weekly Tally
            </Typography> */}
            <Box> </Box>
            <CustomFilter
              selected={selectedServiceProviderFilter}
              setSelected={setSelectedServiceProviderFilter}
              optionList={serviceProviderOptions}
              buttonSize="small"
              buttonLabel="Service Provider Filter"
            ></CustomFilter>
          </Box>
          <StatTable rows={weeklyTallyData} />
          <br />
        </CustomAccordionContent>
        <CustomAccordionContent title="Decking View" titleIcon={<PersonOutlineOutlinedIcon sx={{ mr: '10px' }} />}>
          <DeckingTable events={events} />
        </CustomAccordionContent>
        {/* <Box mt="20px">
          <Typography fontWeight={'600'} sx={{ display: 'flex', alignItems: 'center' }}>
            <FormatListNumberedOutlinedIcon sx={{ mr: '10px' }} />
            No. of Scheduled Appointments
          </Typography>
          <ProviderScheduledTable events={events} />
        </Box> */}

        <CustomAccordionContent
          title="No. of Scheduled Appointments"
          titleIcon={<FormatListNumberedOutlinedIcon sx={{ mr: '10px' }} />}
        >
          <ProviderScheduledTable events={events} />
        </CustomAccordionContent>
      </CustomModal>
    </Box>
  );
});

export default ServiceProviderCalendar;
const Span = styled.span`
  cursor: pointer;
  &:hover {
    color: #25638b;
  }
`;

type CustomAccordionProps = {
  title: string;
  children: ReactNode;
  titleIcon?: ReactNode;
};

function CustomAccordionContent({ title, children, titleIcon }: CustomAccordionProps) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Accordion
      sx={{
        backgroundColor: 'none',
        boxShadow: 'none',
        '&:before': { display: 'none' },
        '& .MuiAccordionSummary-root': {
          borderRadius: '15px',
          padding: '0 20px',
          backgroundColor: colors.light_blue_background,
          marginTop: '20px',
          fontWeight: 'bold',
          '& .Mui-expanded': {
            borderRadius: '50px',
          },
        },
        '& .MuiAccordionDetails-root': {
          padding: '16px 5px',
        },
      }}
    >
      <AccordionSummary
        expandIcon={
          <EjectIcon
            sx={{
              rotate: '180deg',
              color: colors.primary,
              fontSize: '24px',
            }}
          />
        }
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography fontWeight={'600'} sx={{ display: 'flex', alignItems: 'center' }}>
          {titleIcon}
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}
