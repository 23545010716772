import { Box, Divider, Typography, useTheme } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';

import { ContainerColumn } from 'core/components/containers';
import CustomLoadingIndicator from 'core/components/CustomLoadingIndicator';
import { FacilityContext } from 'core/context/facility.context';
import { FacilityDetails } from 'company/screens/DocumentEditor/components/PrintablePatientNote';
import HealthOneAssessmentForm from '../SummaryTemplate/HealthOneAssessmentForm';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import MalvarAssessmentForm from '../SummaryTemplate/MalvarAssessmentForm';
import { RegularButton } from 'core/components';
import StElsewhere from '../SummaryTemplate/StElsewhere';
import UnifiedLabForm from '../SummaryTemplate/UnifiedLabTemplate/UnifiedLabForm';
import { UserContext } from 'core/context/user.context';
import { formatArray } from 'core/utils';
import { getNotesByAppointment } from 'company/api/patient-notes';
import { useReactToPrint } from 'react-to-print';

type Props = {
  assessments: any[];
};

const CompiledAPE: React.FC<Props> = ({ assessments }) => {
  const theme = useTheme();
  const { user } = useContext(UserContext);
  const { facility } = useContext(FacilityContext);
  const [finalAssessments, setFinalAssessments] = useState<any[]>([]);
  const [printMode, setPrintMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const componentRef = useRef<HTMLDivElement | null>(null);

  const getNotes = async () => {
    setLoading(true);
    try {
      const data = await Promise.all(
        assessments.map(async (assessment) => {
          const { data } = await getNotesByAppointment(facility.id, assessment.id, { length: 100 });
          assessment.notes = data.data;
          return assessment;
        })
      );
      setFinalAssessments(data);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    const timeoutId = setTimeout(() => {
      getNotes();
    }, 1000);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessments]);

  const printNote = () => {
    document.title = `ANNUAL PHYSICAL EXAMINATION - ${
      assessments.length > 1 ? assessments[0].company_name ?? '' : assessments[0]?.full_name
    }`;
    setPrintMode(true);
    setTimeout(() => {
      handlePrint();
    }, 1000);
    setTimeout(() => {
      document.title = `CareGo Health Suite`;
    }, 2000);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        setTimeout(resolve, 100);
      });
    },
    onAfterPrint: () => {
      setPrintMode(false);
    },
    pageStyle: `
      @page {
        margin: 0.635cm;
      }
      @media print {
        body {
          margin: 0;
          -webkit-print-color-adjust: exact !important;
          print-color-adjust: exact !important;
        }
        .page-break-inside-avoid {
          page-break-inside: avoid;
        }
        .page-break-after-auto {
          page-break-after: auto;
        }
        .page-break-before-auto {
          page-break-before: auto;
        }
      }
    `,
  });

  return (
    <Box>
      <style>{styles}</style>
      {loading && <CustomLoadingIndicator />}
      <Box width="100%" display="flex" justifyContent="flex-end">
        <RegularButton onClick={() => printNote()} label="Print" startIcon={<LocalPrintshopOutlinedIcon />} />
      </Box>
      <Box ref={componentRef}>
        {finalAssessments.map((assessment, index) => {
          const PageContainer = ({ children, formName }: { children: any; formName?: string }) => {
            return (
              <Box
                className="page-break"
                // ref={componentRef}
                sx={{
                  padding: 2,
                  position: 'relative',
                  '& .body p': { fontSize: '8px', textWrap: 'wrap', whiteSpace: 'pre-wrap', wordBreak: 'break-word' },
                  '& .body': { padding: '5px 3px', margin: '0', pageBreakInside: 'auto' },
                  '& .header p': {
                    fontSize: '12px',
                    padding: '3px 5px',
                    margin: '0',
                    fontWeight: 600,
                    backgroundColor: '#E0ED7B',
                    pageBreakAfter: 'avoid',
                  },
                  '& p.header': {
                    fontSize: '12px',
                    padding: '3px 5px',
                    margin: '0',
                    fontWeight: 600,
                    backgroundColor: '#E0ED7B',
                    pageBreakAfter: 'avoid',
                  },
                  '& p.smaller': { fontSize: '7px', paddingRight: '10px', marginBottom: '5px' },
                  '& .signature-section': {
                    pageBreakInside: 'avoid',
                    marginTop: 'auto',
                  },
                }}
              >
                <Typography fontSize="18px" fontWeight="600" style={{ position: 'absolute', right: 0, top: 0 }}>
                  {assessment.case_number}
                </Typography>
                <ContainerColumn sx={{ alignItems: 'center', mb: '5px', position: 'relative' }}>
                  <img
                    alt="profile-user"
                    width="60px"
                    height="auto"
                    id="logo"
                    style={{ position: 'absolute', left: 0, top: '-20px' }}
                    src={
                      theme.palette.mode === 'light'
                        ? user.company?.company_logo
                          ? user.company?.company_logo
                          : process.env.PUBLIC_URL + '/assets/carego_health_suite_logo.png'
                        : user.company?.company_logo_dark
                        ? user.company?.company_logo_dark
                        : process.env.PUBLIC_URL + '/assets/carego_health_suite_logo_white.png'
                    }
                  />
                  <ContainerColumn gap="1px" sx={{ width: '60%', textAlign: 'center' }}>
                    <Typography variant="h3" fontSize="16px" fontWeight="bold" textAlign="center">
                      {facility.facility_name}
                    </Typography>
                    <FacilityDetails
                      label={formatArray(
                        [facility?.smart_mobile_number, facility?.globe_mobile_number, facility.email],
                        ' | '
                      )}
                      fontSizes={{ body: '10px', header: '12px' }}
                    />
                    <FacilityDetails
                      label={formatArray([facility.address, facility.municipality_name, facility.province_name])}
                      fontSizes={{ body: '10px', header: '12px' }}
                    />
                  </ContainerColumn>
                </ContainerColumn>
                {children}
                {!printMode && assessments.length > 1 && (
                  <Box marginTop="20px">
                    <Typography sx={{ textAlign: 'center', fontSize: '12px' }}>
                      End of patient {index + 1} {formName ? ' - ' + formName : ''}
                    </Typography>
                    <Divider sx={{ borderColor: '#E0ED7B', borderWidth: '2px', borderStyle: 'dashed' }} />
                  </Box>
                )}
              </Box>
            );
          };

          return (
            <Box>
              {user.company?.company_name.includes('HealthOne Diagnostic ') ? (
                <PageContainer>
                  <HealthOneAssessmentForm assessment={assessment} printMode={printMode} />
                </PageContainer>
              ) : facility.facility_name === 'St. Elsewhere - Malvar' ? (
                <>
                  <PageContainer formName="PE Form">
                    <MalvarAssessmentForm assessment={assessment} printMode={printMode} />
                  </PageContainer>
                  <PageContainer formName="Unified Lab Form">
                    <UnifiedLabForm assessment={assessment} printMode={printMode} />
                  </PageContainer>
                </>
              ) : (
                <>
                  <PageContainer>
                    <StElsewhere assessment={assessment} printMode={printMode} />
                  </PageContainer>

                  {/*  */}
                </>
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default CompiledAPE;

const styles = `
 .page-break:not(:first-child) {
    page-break-before: always;
  }

  .page-break {
    display: block;
    page-break-inside: avoid;    
  }

`;
