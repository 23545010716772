import { PatientNoteModel } from 'company/model/Entities';

export const hematology_tests_1 = [
  { test: 'Hemoglobin', ref_value: '(M)133-180 : (F)123-157' },
  { test: 'Hematocrit', ref_value: '(M)0.40-0.54 : (F)0.37-0.47' },
  { test: 'RBC Count', ref_value: '3.8 - 5.8 x 10 12/L' },
  { test: 'WBC Count', ref_value: '5-10 x 10 12/L' },
  { test: 'Reticulocytes Count', ref_value: '0.5 - 1.5 x 10 -3/L' },
  { test: 'ESR', ref_value: '0-20 (F) : 0-15 (M) mm/hr' },
  { test: 'Blood Typing', ref_value: undefined },
  { test: 'RH Typing', ref_value: undefined },
  { test: 'Clotting Time', ref_value: '2-5 mins' },
  { test: 'Bleeding Time', ref_value: '1-3 mins' },
  { test: 'Platelet Count', ref_value: '150 - 400 /cumm' },
];
export const differential_count = [
  'Segmenters',
  'Lymphocytes',
  'Monocytes',
  'Eosinophils',
  'Basophils',
  'Band',
  'Metamyelocyte',
  'Myelocyte',
  'Blasts',
  'Malaria Smear',
];
export const hematology_tests_2 = [
  { test: 'Segmenters', ref_value: '0.35 - 0.65' },
  { test: 'Lymphocytes', ref_value: '0.25 - 0.45' },
  { test: 'Band', ref_value: '0.00 - 0.50' },
  { test: 'Monocytes', ref_value: '0.0 - 0.08' },
  { test: 'Eosinophils', ref_value: '0.0 - 0.03' },
  { test: 'Basophils', ref_value: '0.0 - 0.01' },
  { test: 'Metamyelocyte', ref_value: '0' },
  { test: 'Myelocyte', ref_value: '0' },
  { test: 'Blasts', ref_value: '0' },
  { test: 'Malaria Smear', ref_value: '0' },
];

export const hematology_mapping = hematology_tests_1
  .map((h) => h.test)
  .reduce((acc: any, condition) => {
    if (condition === 'Blood Typing' || condition === 'RH Typing') {
      acc[condition] = { template_name: 'Hematology' };
      return acc;
    }
    acc[condition] = {
      template_name: 'Hematology',
      source_type: 'table',
      table_name: '',
      table_column_name: 'Test',
      table_column_name_answer: 'Result',
    };
    return acc;
  }, {});

export const differential_count_mapping = differential_count.reduce((acc: any, condition) => {
  acc['DC_' + condition] = {
    template_name: 'Hematology',
    source_type: 'table',
    table_name: 'Differential Count',
    table_column_name: 'Test',
    table_column_name_answer: 'Result',
  };
  return acc;
}, {});

export const urinalysis = [
  'Color',
  'Transparency',
  'pH',
  'Specific Gravity',
  'Protein',
  'Sugar',
  'WBC',
  'RBC',
  'Epithelial Cells',
  'Mucus Threads',
  'Bacteria',
  'Crystals',
  'AM Urates',
  'AM Phosphates',
  'CaOx',
];
export const urinalysis_mapping = urinalysis.reduce(
  (acc: any, condition) => {
    acc['U_' + condition] = {
      template_name: 'Urinalysis',
    };
    return acc;
  },
  {
    'Remarks (Significant Findings based on Result)': {
      template_name: 'Urinalysis',
    },
    'With Menstruation': {
      template_name: 'Urinalysis',
    },
  }
);

export const fecalysis = [
  'Color',
  'Consistency',
  'WBC',
  'RBC',
  'Parasites',
  'Parasite Seen',
  'Bacteria',
  'Occult Blood',
  'Fat Globules',
  'Yeast Cells',
  'Cyst',
  'Tropozoites',
];
export const fecalysis_mapping = fecalysis.reduce((acc: any, condition) => {
  acc['F_' + condition] = {
    template_name: 'Fecalysis',
  };
  acc['Remarks'] = {
    template_name: 'Fecalysis',
  };
  return acc;
}, {});

export const summary_mapping = {
  ...hematology_mapping,
  ...differential_count_mapping,
  ...urinalysis_mapping,
  ...fecalysis_mapping,
  'Pregnancy Test': {
    template_name: 'Other Tests Findings',
  },
  'Hepatitis B Result': {
    template_name: 'Hepatitis B Screening (HBsAg)',
  },
  'Hepatitis A Result': {
    template_name: 'Hepatitis A Screening (HAV IgM)',
  },
  // ...hematology_mapping_2
};
export const getAnswerUnifiedForm = (
  field: string,
  notes: PatientNoteModel[],
  defaultValue?: string,
  occurence?: number,
  prefix?: string,
  columns?: string[]
): string => {
  const defValue = defaultValue ?? '-';
  const defOccurence = occurence ?? 1;
  const source_mapping = summary_mapping as any;
  const fieldKey = prefix ? prefix + field : field;
  const mapping = source_mapping[fieldKey];
  if (mapping) {
    const note = notes.find(
      (note) =>
        note.header.toLowerCase().includes(mapping.template_name.toLowerCase()) ||
        note.template_id === mapping.template_id
    );

    if (note) {
      const body = note.template_body[0];
      const responses = note.responses;

      if (body) {
        if (mapping.source_type === 'table') {
          const tableField = body.fields.find(
            (field: any) =>
              (mapping.table_name && field.display_name === mapping.table_name) ||
              (!mapping.table_name && field.type === 'table')
          );

          if (tableField) {
            const questionColumn = tableField.columns.find(
              (column: any) => column.displayName === mapping.table_column_name
            );

            const rowResponses = responses[tableField.field_name];
            if (rowResponses) {
              const response = rowResponses.find((response: any) => {
                return response[questionColumn.fieldName] === field;
              });

              if (response) {
                if (columns && columns.length > 0) {
                  // Format columns into a string for easy display
                  return columns
                    .map((columnName) => {
                      const column = tableField.columns.find((col: any) => col.displayName === columnName);
                      return response[column?.fieldName] ? `${columnName}: ${response[column?.fieldName]}` : defValue;
                    })
                    .join(', ');
                } else {
                  // Default behavior: return only the main answer column
                  const answerColumn = tableField.columns.find(
                    (column: any) => column.displayName === mapping.table_column_name_answer
                  );

                  return response[answerColumn?.fieldName] || defValue;
                }
              }
            }
          }
        } else {
          let count = 0;
          const formField = body.fields.find((item: any) => {
            if (item.display_name?.trim() === field?.trim() && !item.section_header) {
              count++;
              return defOccurence === count;
            }
            return false;
          });

          return responses[formField?.field_name] || defValue;
        }
      }
    }
  }

  return defValue;
};
