import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ConfirmationDialog, CustomModal, RegularButton } from 'core/components';
import { deleteHMO, getHMO, getHMOs, updateHMO } from 'company/api/company-hmos';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { IconButton } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import { CareGoPage } from 'core/PageBuilder';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { HmoForm } from './components/HMOModel';
import InfiniteScrollContainer from 'core/components/dataView/InfiniteScrollContainer';
import { PatientBillingTable } from 'company/entities/tables';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';
import CustomLoadingIndicator from 'core/components/CustomLoadingIndicator';
import { UserContext } from 'core/context/user.context';

interface CorporateClientsProps {
  onClose?: () => void;
}

const CompanyHMO = (props: CorporateClientsProps) => {
  const { onClose } = props;

  const { user } = useContext(UserContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery('(max-width:768px)');

  const [selectedHMO, setSelectedHMO] = useState<number>(0);
  const [hmo, setHMO] = useState<any>();
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openFormModal, setOpenFormModal] = useState<boolean>(false);
  const [updatedHMO, setUpdatedHMO] = useState<any>();
  const [addedHMO, setAddedHMO] = useState<number>();
  const [deletedId, setDeletedId] = useState<number>();
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);

  const tableRef: any = useRef();
  const handleDelete = (data: any) => {
    return deleteHMO(data.id).then(() => {
      enqueueSnackbar('HMO successfully deleted!', { variant: 'success' });
      hmo.is_active = false;
      setDeletedId(data.id);
    });
  };

  // const handleUpdateHmo = (data: any) => {
  //   return updateHMO(data.id, data).then(()=> {
  //     setUpdatedHMO(data)
  //   })
  // }

  const displayHMO = useCallback(
    (hmo: any) => {
      return (
        <>
          <Box display="flex" flexDirection="column" gap="5px" width="100%">
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              color={selectedHMO === hmo.id ? (isMobile ? colors.text : 'white') : colors.text}
            >
              <Typography variant="h5" fontWeight="600">
                {hmo.hmo_name} {hmo.is_active ? '' : '(Inactive)'}
              </Typography>
            </Box>
          </Box>
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedHMO]
  );

  const getHMODetails = useCallback(() => {
    if (selectedHMO) {
      setIsLoading(true);
      setIsTableLoading(true);

      getHMO(selectedHMO)
        .then((res) => setHMO(res.data))
        .catch((error) => console.error(error))
        .finally(() => setIsLoading(false));
    }
  }, [selectedHMO]);

  const navigate = useNavigate();

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      navigate('/company/patient-management');
    }
  };

  useEffect(() => {
    if (selectedHMO) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      setHMO(undefined);
      getHMODetails();
    }
  }, [selectedHMO, getHMODetails]);

  useEffect(() => {
    if (hmo) {
      setIsTableLoading(false);
    }
  }, [hmo]);

  const renderTitle = () => {
    if (isLoading) {
      return (
        <Typography variant="h3" fontWeight={700} color={colors.primary}>
          Loading...
        </Typography>
      );
    }
    if (hmo) {
      return (
        <Typography variant="h3" fontWeight={700} color={colors.primary}>
          {hmo.hmo_name} {hmo.is_active ? '' : '(Inactive)'}
        </Typography>
      );
    }
    return null;
  };

  const renderPatientBillingTable = () => {
    if (isTableLoading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="200px">
          <CustomLoadingIndicator />
        </Box>
      );
    }
    if (selectedHMO >= 1 && hmo) {
      return <PatientBillingTable hmo={hmo} tableRef={tableRef} />;
    }
    return null;
  };

  return (
    <CareGoPage breadcrumb={[{ label: 'Patient Management', link: '/company/patient-management' }, { label: 'HMOs' }]}>
      {isMobile ? (
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} padding={'10px'}>
          <Typography variant="h3" color={colors.primary} fontWeight={'600'}>
            Company HMOs
          </Typography>
          <Box display={'flex'} alignItems={'center'}>
            <IconButton
              onClick={() => {
                setIsAdd(true);
                setOpenFormModal(true);
                // setModalHeader('Add Corporate Client');
              }}
            >
              <AddIcon sx={{ color: colors.primary }} />
            </IconButton>

            <IconButton onClick={handleClose}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      ) : (
        <></>
      )}

      {isMobile ? (
        <InfiniteScrollContainer
          title="HMO"
          getData={(query) => getHMOs(query)}
          getSingleData={(id) => getHMO(id)}
          selected={selectedHMO}
          setSelected={setSelectedHMO}
          renderItem={displayHMO}
          urlKeyId="id"
          addedNewItem={addedHMO}
          deleteId={deletedId}
          updatedItem={updatedHMO}
          titleComponents={
            <RegularButton
              startIcon={<AddIcon />}
              label="Add "
              onClick={() => {
                setIsAdd(true);
                setOpenFormModal(true);
              }}
              disabled={user.remaining_storage <= 0}
            />
          }
          displaySelectedTitle={renderTitle}
          // hideActions={hmo ? false : true}
          // actions={[
          //   {
          //     icon: <EditOutlinedIcon />,
          //     label: 'Update',
          //     action: (data) => {
          //       setIsAdd(false);
          //       setOpenFormModal(true);
          //     },
          //   },
          //   {
          //     icon: <DeleteOutlineIcon />,
          //     label: 'Delete',
          //     color: 'error',
          //     action: () => {
          //       setOpenDeleteModal(true);
          //     },
          //   },
          // ]}
        >
          {renderPatientBillingTable()}
        </InfiniteScrollContainer>
      ) : (
        <InfiniteScrollContainer
          title="HMO"
          getData={(query) => getHMOs(query)}
          getSingleData={(id) => getHMO(id)}
          selected={selectedHMO}
          setSelected={setSelectedHMO}
          renderItem={displayHMO}
          urlKeyId="id"
          addedNewItem={addedHMO}
          deleteId={deletedId}
          updatedItem={updatedHMO}
          titleComponents={
            <RegularButton
              startIcon={<AddIcon />}
              label="Add "
              onClick={() => {
                setIsAdd(true);
                setOpenFormModal(true);
              }}
              disabled={user.remaining_storage <= 0}
            />
          }
          displaySelectedTitle={() =>
            hmo && (
              <Typography variant="h3" fontWeight={700} color={colors.primary}>
                {hmo?.hmo_name} {hmo?.is_active ? '' : '(Inactive)'}
              </Typography>
            )
          }
          hideActions={hmo ? false : true}
          actions={[
            {
              icon: <EditOutlinedIcon />,
              label: 'Update',
              action: (data) => {
                setIsAdd(false);
                setOpenFormModal(true);
              },
            },
            {
              icon: <DeleteOutlineIcon />,
              label: 'Delete',
              color: 'error',
              action: () => {
                setOpenDeleteModal(true);
              },
            },
          ]}
        >
          {selectedHMO >= 1 && (
            <PatientBillingTable
              hmo={hmo}
              tableRef={tableRef}
              // headerComponent={
              //   <Typography sx={{ fontSize: '16px', color: colors.accent, fontWeight: '600' }}>Transactions</Typography>
              // }
            />
          )}
        </InfiniteScrollContainer>
      )}

      {selectedHMO && hmo && (
        <ConfirmationDialog
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          onConfirm={handleDelete}
          data={hmo}
          title={'Delete ' + hmo.hmo_name}
          content="Are you sure you want to delete this HMO?"
        />
      )}

      <CustomModal
        open={openFormModal}
        setOpen={setOpenFormModal}
        header={isAdd ? 'Create HMO' : `Update ${hmo?.hmo_name}`}
      >
        <HmoForm
          hmo={isAdd ? undefined : hmo}
          callbackAfterSubmit={(data) => {
            if (isAdd) {
              setAddedHMO(data.hmo.id);
            } else {
              setUpdatedHMO(data.hmo);
              // handleUpdateHmo(data.hmo)
            }
            setIsAdd(false);
            setOpenFormModal(false);
          }}
        />
      </CustomModal>
    </CareGoPage>
  );
};

export default CompanyHMO;
