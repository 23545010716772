import PageBuilder, { BLOCK_TYPE } from 'core/PageBuilder/PageBuilder';
import { PageBuilderDrawerType, PageBuilderPageType, PageBuilderTableType } from 'core/PageBuilder';
import { changeNullToBlank, formatDateTime } from 'core/utils';
import { getCompanyLogValues, getCompanyLogs } from 'company/api/company-logs';
import { useContext, useEffect, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import { ColoredTable } from 'core/components';
import { FacilityContext } from 'core/context/facility.context';
import { GridColDef } from '@mui/x-data-grid';
import { Typography } from '@mui/material';

type ActivityLogsProps = {};

const ActivityLogs: React.FC<ActivityLogsProps> = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { facility } = useContext(FacilityContext);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [activityLogData, setActivityLogData] = useState<any[]>([]);

  const activityLogsColumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', flex: 0.5 },
    ...(facility?.is_admin ? [{ field: 'facility_name', headerName: 'Facility' }] : []),
    { field: 'full_name', headerName: 'User', flex: 1 },
    {
      field: 'description',
      headerName: 'Action',
      flex: 3,
    },
    {
      field: 'created_at',
      headerName: 'Date and Time',
      flex: 0.7,
      renderCell: (params) => <Typography>{formatDateTime(params.row.created_at)}</Typography>,
    },
  ];

  const generateColumns = (row: any) => {
    const defaultColumns: GridColDef[] = [
      { field: 'field_name', headerName: 'Field Name', flex: 1 },
      { field: 'old_value', headerName: 'Old Value', flex: 1 },
      { field: 'new_value', headerName: 'New Value', flex: 1 },
    ];

    if (row) {
      const action = row.action.toLowerCase();
      if (action.includes('create') || action.includes('add') || action.includes('register')) {
        return defaultColumns.filter((col) => col.field !== 'old_value');
      } else if (action.includes('delete') || action.includes('remove')) {
        return defaultColumns.filter((col) => col.field !== 'new_value');
      }
    }

    return defaultColumns;
  };

  const selectedLogColumns = generateColumns(selectedRow);

  const getActivityLogs = (query: ApiQuery) => {
    return getCompanyLogs(facility.id, query);
  };

  const getLogValues = (selectedRow: any, query?: ApiQuery) => {
    if (selectedRow) {
      return getCompanyLogValues(facility.id, selectedRow.id, query).then((res) => {
        setActivityLogData(res.data.data);
      });
    }
  };

  const handleSelectRow = (selected: any) => {
    setSelectedRow(selected);
  };

  const mappedData = activityLogData.map((row, index) => ({
    ...row,
    id: `row_${index}`,
  }));

  const filteredData = mappedData.filter((row, index, array) => {
    const firstIndex = array.findIndex(
      (item) =>
        item.old_value === row.old_value && item.new_value === row.new_value && item.old_value !== item.new_value
    );
    return index === firstIndex;
  });

  const filteredDataFormatted = filteredData
    .map((row) => ({
      ...row,
    }))
    .filter((row) => {
      if (selectedRow) {
        const action = selectedRow.action.toLowerCase();
        if (
          (action.includes('create') || action.includes('add') || action.includes('register')) &&
          (!row.new_value || changeNullToBlank(row.old_value === ''))
        ) {
          return false;
        } else if (
          (action.includes('delete') || action.includes('remove')) &&
          (!row.old_value || changeNullToBlank(row.old_value === ''))
        ) {
          return false;
        } else if (
          (!row.old_value || changeNullToBlank(row.old_value === '')) &&
          (!row.new_value || changeNullToBlank(row.old_value === ''))
        ) {
          return false;
        } else if (row.field_name === 'updated_at' || row.field_name === 'created_at') {
          return false;
        }
        return true;
      }
      return true;
    });

  useEffect(() => {
    getLogValues(selectedRow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow]);

  useEffect(() => {
    setBreadcrumb([{ label: 'Company Logs' }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const content = {
    type: BLOCK_TYPE.PAGE,
    block: {
      type: BLOCK_TYPE.DRAWER,
      parentHeader: 'Company Logs',
      parentSubHeader: 'Click the row to see detailed logs',
      childHeader: selectedRow ? selectedRow.description : '',
      childSubHeader: selectedRow ? selectedRow.full_name + ' - ' + formatDateTime(selectedRow.created_at) : '',
      parentWidth: 800,
      handleClose: () => setSelectedRow(undefined),
      showChild: !!selectedRow,
      parentComponent: {
        type: BLOCK_TYPE.TABLE,
        entityName: 'SMS Template',
        useColoredTable: true,
        tableComponent: {
          columns: selectedRow
            ? [activityLogsColumns[0], activityLogsColumns[2], activityLogsColumns[3]]
            : activityLogsColumns,
          getData: getActivityLogs,
          rowId: 'id',
          initialOrder: 'desc',
          selectableRow: true,
          onSelectRow: handleSelectRow,
        },
      } as PageBuilderTableType,
      childComponent: {
        type: BLOCK_TYPE.CUSTOM,
        block: <ColoredTable dataRows={filteredDataFormatted} columns={selectedLogColumns} rowHeight={55} />,
      } as any,
    } as PageBuilderDrawerType,
  } as PageBuilderPageType;

  return <PageBuilder content={content} />;
};

export default ActivityLogs;
