import { useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

interface MobileMenuContainerProps {
  isOpen: boolean;
}

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
      if (window.innerWidth > 768) {
        setIsMobileMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const scrollToFeatures = () => {
    document.getElementById('features')?.scrollIntoView({ behavior: 'smooth' });
  };
  const scrollToHome = () => {
    document.getElementById('home')?.scrollIntoView({ behavior: 'smooth' });
  };
  const NavComponent = () => {
    return (
      <>
        <NavItems>
          {/* <img src="/assets/LandingMobile/LOGO.png" alt="logo" style={{ height: '35px' }} /> */}
          {!isSmallScreen && <NavButton onClick={() => navigate('/')}>Home</NavButton>}
          {!isSmallScreen && <NavButton>About</NavButton>}
          {!isSmallScreen && <NavButton onClick={scrollToFeatures}>Features</NavButton>}
          <NavButton onClick={() => navigate('/demo')}>Request a Demo</NavButton>
          <SpecialButton onClick={() => navigate('/portal')}>Login</SpecialButton>
          {/* <SpecialButton>Get Started</SpecialButton> */}
        </NavItems>
      </>
    );
  };

  return (
    <Nav>
      <NavbarContainer>
        <Logo
          src="/assets/landingassets/logoblacktext.png"
          onClick={
            isSmallScreen
              ? () => {
                  navigate('/');
                  scrollToHome();
                }
              : undefined
          }
        />
        <HamburgerIcon isOpen={isMobileMenuOpen} onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? <CloseIcon /> : <MenuIcon />}
        </HamburgerIcon>
        <MobileMenuContainer isOpen={isMobileMenuOpen}>
          <Logo src="./assets/landingassets/logoblacktext.png" />
          <NavComponent />
        </MobileMenuContainer>
        {!isSmallScreen && <NavComponent />}
      </NavbarContainer>
    </Nav>
  );
};

export default Navbar;
const Nav = styled.div`
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 768px) {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: white;
  }
`;
const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  width: 80%;
  height: 80px;
  @media (max-width: 768px) {
    justify-content: space-between;
    width: 100%;
    height: 60px;
  }
`;

const Logo = styled.img`
  height: 55px;
  text-align: center;
  padding-top: 10px;
  @media (max-width: 768px) {
    height: 40px;
  }
`;

const NavItems = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const NavButton = styled.button`
  background: none;
  font-family: Nunito;
  border: none;
  cursor: pointer;
  font-size: 18px;
  letter-spacing: 0.5px;
  &:hover {
    text-decoration: none;
    color: #239bd7;
  }
  @media (max-width: 768px) {
    font-size: 15px;
  }
`;

const SpecialButton = styled(NavButton)`
  background: linear-gradient(to right, #239bd7, #0463ae);
  color: white;
  padding: 10px 20px;
  border-radius: 30px;
  font-size: 15px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const MobileMenuContainer = styled.div<MobileMenuContainerProps>`
  position: fixed;
  top: 0;
  right: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
  width: 50%;
  height: 100%;
  background-color: white;
  z-index: 999;
  gap: 20px;
  padding: 2rem;
  display: flex;
  flex-direction: column;

  align-items: center;
  transition: right 0.3s ease-in-out;
  @media (min-width: 768px) {
    display: none; /* Hide for desktop */
  }
`;

const HamburgerIcon = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 1.6rem;
  right: ${({ isOpen }) => (isOpen ? 'calc(53% )' : '2rem')};
  cursor: pointer;
  transition: right 0.3s ease-in-out;
  @media (min-width: 768px) {
    display: none;
  }
`;
