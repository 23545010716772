/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, SxProps, Theme, Typography } from '@mui/material';
import {
  CustomCheckbox,
  CustomCheckboxGroup,
  CustomSelectField,
  Dropdown,
  FileUpload,
  RegularButton,
  SubmitButton,
} from 'core/components';
import CustomSearchDropdownField, { dropdownFields } from './CustomSearchDropdownField';
import { DISPLAY_ONLY_FIELDS, DROPDOWN_FIELD, FORM_MODE, FieldInput } from 'core/model/interface';
import FormField, { addNewField } from './FormField';
import { Formik, FormikHelpers } from 'formik';
import React, { useEffect, useState } from 'react';

import AbcOutlinedIcon from '@mui/icons-material/AbcOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import BorderAllOutlinedIcon from '@mui/icons-material/BorderAllOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { CompanyAppointmentModel } from 'company/entities/modules/ClinicManagement/Appointment/AppointmentModel';
import CustomAutoCompleteMultipleSelect from '../CustomAutoCompleteMultipleSelect';
import CustomDatePicker from '../CustomDatePicker';
import CustomDateTimePicker from '../CustomDatetimePicker';
import CustomImageUpload from '../CustomImageUpload';
import CustomMultiSelectCheckbox from '../CustomMultiSelectCheckbox';
import CustomPasswordField from '../CustomPasswordField';
import CustomPasswordGeneratorField from '../CustomPasswordGeneratorField';
import CustomRadioGroup from '../CustomRadioGroup';
import CustomSignature from '../CustomSignature';
import CustomTableForm from './CustomTableForm';
import CustomTextField from '../CustomTextField';
import CustomTimePicker from '../CustomTimePicker';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import DocumentRichText from 'company/screens/DocumentEditor/components/DocumentRichText';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FlakyOutlinedIcon from '@mui/icons-material/FlakyOutlined';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined';
import InsertInvitationOutlinedIcon from '@mui/icons-material/InsertInvitationOutlined';
import NotesIcon from '@mui/icons-material/Notes';
import { PatientModel } from 'company/entities/modules/ClinicManagement/Patient/PatientModel';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import ReCAPTCHA from 'react-google-recaptcha';
import { SIZES } from 'theme/constants';
import ShortTextIcon from '@mui/icons-material/ShortText';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import TitleOutlinedIcon from '@mui/icons-material/TitleOutlined';
import dayjs from 'dayjs';
import { getDoctor } from 'company/api/doctors';
import { isDevMode } from '../HideOrShowComponent';
import moment from 'moment';
import useMediaQuery from '@mui/material/useMediaQuery';

type CustomFormProps = {
  onSubmit?: (data: any, formikHelpers: FormikHelpers<any>) => void;
  onCancel?: () => void;
  children?: React.ReactNode;
  fields: FieldInput[];
  setFormFields?: React.Dispatch<React.SetStateAction<FieldInput[]>>;
  setResponses?: React.Dispatch<React.SetStateAction<any>>;
  schema?: any;
  initialValues: any;
  submitButtonText?: any;
  disabled?: boolean;
  readOnly?: boolean;
  loading?: boolean;
  buttonWidth?: string;
  span?: number;
  columnNumber?: number;
  submitButtonMarginTop?: number;
  footerComponent?: React.ReactNode;
  columnGap?: number | string;
  rowGap?: number | string;
  showSubmitAtTop?: boolean;
  useReCAPTCHA?: boolean;
  hideSubmitButton?: boolean;
  showCancelButton?: boolean;
  isSuccess?: boolean;
  submitButtonId?: string;
  buttonLabel?: string;
  buttonPosition?: 'start' | 'center' | 'end';

  buttonBackgroundColor?: string;
  color?: string;
  borderRadius?: string;
  colorHover?: string;
  heightButton?: string;
  marginButton?: string;
  formMode?: FORM_MODE;
  background?: string;

  appointment?: CompanyAppointmentModel;
  patient?: PatientModel;
  altSubmitComponent?: React.ReactNode;
  processInitialValues?: (setFieldData: React.Dispatch<any>, values: any) => void;

  submitButtonStyles?: SxProps<Theme>;
  cancelBtnText?: string;
};

const CustomForm: React.FC<CustomFormProps> = (props) => {
  const {
    onSubmit,
    onCancel,
    fields,
    setFormFields,
    setResponses,
    schema,
    initialValues,
    submitButtonText,
    showCancelButton,
    disabled,
    readOnly,
    loading,
    buttonWidth,
    span = 4,
    columnNumber = 2,
    submitButtonMarginTop,
    footerComponent,
    columnGap,
    rowGap,
    showSubmitAtTop,
    useReCAPTCHA,
    hideSubmitButton,
    isSuccess,
    submitButtonId,
    buttonPosition,
    formMode,
    appointment,
    patient,
    altSubmitComponent,
    processInitialValues,
    submitButtonStyles,
    cancelBtnText,
  } = props;
  const isNonMobile = useMediaQuery('(min-width:600px)');
  const gridColumn = 'repeat(' + span + ', minmax(0, 1fr))';
  const [reCAPTCHAValid, setReCAPTCHAValid] = useState(false);
  const [customDisplayName, setCustomDisplayName] = useState<any>({});
  const [fieldData, setFieldData] = useState<any>({});
  const [provider, setProvider] = useState<any>();
  const [userInputFields, setUserInputFields] = useState<FieldInput[]>([]);

  const handleFormSubmit = (values: any, formikHelpers: FormikHelpers<any>) => {
    if (useReCAPTCHA && !reCAPTCHAValid) {
      return;
    }

    const date_fields = fields.filter((field) => field.type === 'date' || field.type === 'datetime');
    for (let index = 0; index < date_fields.length; index++) {
      const field_name = date_fields[index].field_name;

      if (values[field_name]) {
        const field_type = date_fields[index].type;
        const date_value = new Date(values[field_name]);

        if (field_type === 'date') values[field_name] = moment(date_value).format('YYYY-MM-DD');
        else if (field_type === 'datetime') values[field_name] = moment(date_value).format('YYYY-MM-DD HH:mm:ss');
      }
    }

    onSubmit && onSubmit(values, formikHelpers);
    // formikHelpers.resetForm(values);
  };

  const onChangeReCAPTCHA = (data: any) => {
    if (data) {
      setReCAPTCHAValid(true);
    }
  };

  useEffect(() => {
    setUserInputFields(
      fields.filter((field) => !DISPLAY_ONLY_FIELDS.includes(field.type as string) || field.type === 'table')
    );
  }, [fields]);

  useEffect(() => {
    if (appointment?.provider_id) {
      getDoctor(appointment.provider_id).then((res) => setProvider(res.data));
    }
  }, [appointment]);

  useEffect(() => {
    if (initialValues && processInitialValues) {
      processInitialValues(setFieldData, initialValues);
    }
  }, [initialValues]);

  const getDropdown = () => {
    const index = 0;
    return (
      <Dropdown
        buttonLabel={'Add Field'}
        variant={'outlined'}
        icon={<AddIcon />}
        optionList={[
          {
            label: 'Short Text',
            icon: <ShortTextIcon />,
            action: () => addNewField(index, 'text', span, columnNumber, setFormFields),
          },
          {
            label: 'Long Text',
            icon: <NotesIcon />,
            action: () => addNewField(index, 'long_text', span, columnNumber, setFormFields),
          },
          {
            label: 'Rich Text',
            icon: <StickyNote2OutlinedIcon />,
            action: () => addNewField(index, 'rich_text', span, columnNumber, setFormFields),
          },
          {
            label: 'Yes/No',
            icon: <FlakyOutlinedIcon />,
            action: () => addNewField(index, 'yes_no', span, columnNumber, setFormFields),
          },
          {
            label: 'Radio',
            icon: <RadioButtonCheckedIcon />,
            action: () => addNewField(index, 'radio_group', span, columnNumber, setFormFields),
          },
          {
            label: 'Checkbox Group',
            icon: <CheckBoxOutlinedIcon />,
            action: () => addNewField(index, 'checkbox_group', span, columnNumber, setFormFields),
            hidden: !isDevMode(),
          },
          {
            label: 'Checkbox',
            icon: <CheckBoxOutlinedIcon />,
            action: () => addNewField(index, 'checkbox', span, columnNumber, setFormFields),
          },
          {
            label: 'Dropdown',
            icon: <ArrowDropDownCircleIcon />,
            action: () => addNewField(index, 'dropdown', span, columnNumber, setFormFields),
          },
          {
            label: 'Date',
            icon: <InsertInvitationOutlinedIcon />,
            action: () => addNewField(index, 'date', span, columnNumber, setFormFields),
          },
          {
            label: 'Time',
            icon: <AccessTimeIcon />,
            action: () => addNewField(index, 'time', span, columnNumber, setFormFields),
          },
          {
            label: 'DateTime',
            icon: <DateRangeOutlinedIcon />,
            action: () => addNewField(index, 'datetime', span, columnNumber, setFormFields),
          },
          {
            label: 'Divider',
            icon: <HorizontalRuleOutlinedIcon />,
            action: () => addNewField(index, 'divider', span, columnNumber, setFormFields),
          },
          {
            label: 'Section Header',
            icon: <TitleOutlinedIcon />,
            action: () => addNewField(index, 'section_header', span, columnNumber, setFormFields),
          },
          {
            label: 'Subsection Header',
            icon: <FormatSizeIcon />,
            action: () => addNewField(index, 'subsection_header', span, columnNumber, setFormFields),
          },
          {
            label: 'Body Text',
            icon: <AbcOutlinedIcon />,
            action: () => addNewField(index, 'body_text', span, columnNumber, setFormFields),
          },
          {
            label: 'Table',
            icon: <BorderAllOutlinedIcon />,
            action: () => addNewField(index, 'table', span, columnNumber, setFormFields),
          },
          {
            label: 'File Upload',
            icon: <FileUploadIcon />,
            action: () => addNewField(index, 'file_upload', span, columnNumber, setFormFields),
          },
        ]}
      />
    );
  };

  return (
    <>
      <Formik
        onSubmit={handleFormSubmit}
        // enableReinitialize={!autoSave}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
      >
        {(props) => {
          const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = props;
          return (
            <form onSubmit={handleSubmit}>
              {showSubmitAtTop && !hideSubmitButton && (
                <Box display="flex" justifyContent={'end'} mb={2} gap={SIZES.paddingS}>
                  {altSubmitComponent}
                  <SubmitButton
                    label={submitButtonText}
                    loading={loading}
                    disabled={disabled}
                    width={buttonWidth ? buttonWidth : 'auto'}
                    id={submitButtonId}
                  />
                </Box>
              )}
              <Box
                display="grid"
                columnGap={columnGap ?? '25px'}
                rowGap={rowGap ?? '25px'}
                gridTemplateColumns={gridColumn}
                alignItems="flex-start"
                sx={{
                  '& > div': { gridColumn: isNonMobile ? undefined : 'span ' + span },
                }}
              >
                {fields?.length === 0 && formMode === FORM_MODE.EDIT ? (
                  <Box width="100%" style={{ gridColumn: 'span ' + span }}>
                    {getDropdown()}
                  </Box>
                ) : (
                  fields?.map((field, index) => {
                    if (field.hidden || (field.hiddenBasedOnOtherField && field.hiddenBasedOnOtherField(values))) {
                      return false;
                    }
                    if (field.type === 'divider') {
                      return (
                        <FormField
                          key={index}
                          field={field}
                          index={index}
                          setFormFields={setFormFields}
                          formMode={formMode}
                          span={span}
                          columnNumber={columnNumber}
                          userInputFields={userInputFields}
                        >
                          <Divider />
                        </FormField>
                      );
                    }
                    if (field.type === 'subsection_header' || field.type === 'section_header') {
                      return (
                        <FormField
                          key={index}
                          field={field}
                          index={index}
                          setFormFields={setFormFields}
                          formMode={formMode}
                          span={span}
                          columnNumber={columnNumber}
                          userInputFields={userInputFields}
                        >
                          <Typography
                            variant={
                              field.type === 'subsection_header'
                                ? 'h5'
                                : field.type === 'section_header'
                                ? 'h4'
                                : undefined
                            }
                            fontWeight={'bold'}
                          >
                            {field.subsection_header ?? field.section_header ?? field.body_text}
                          </Typography>
                        </FormField>
                      );
                    }
                    if (field.type === 'group_header') {
                      return (
                        <FormField
                          key={field.field_name}
                          field={field}
                          index={index}
                          setFormFields={setFormFields}
                          formMode={formMode}
                          span={span}
                          columnNumber={columnNumber}
                          userInputFields={userInputFields}
                        >
                          <Typography variant="h4" fontWeight={'600'}>
                            {field.display_name ?? ''}
                          </Typography>
                        </FormField>
                      );
                    }
                    if (field.type === 'body_text') {
                      return (
                        <FormField
                          key={index}
                          field={field}
                          index={index}
                          setFormFields={setFormFields}
                          formMode={formMode}
                          span={span}
                          columnNumber={columnNumber}
                          userInputFields={userInputFields}
                        >
                          <DocumentRichText
                            isBodyText
                            component={{ id: '', type: 'rich_text', content: field.body_text }}
                            mode={formMode}
                            halfWidth={field.span === span / columnNumber}
                            handleChangeCallback={(value) => {
                              field.body_text = value;
                            }}
                            patient={patient}
                            appointment={appointment}
                            provider={provider}
                          />
                        </FormField>
                      );
                    }
                    if (field.type === 'component') {
                      return (
                        <Box width="100%" key={index} sx={{ gridColumn: 'span ' + (field.span ?? span) }}>
                          {field.displayComponentBasedOnFormFields
                            ? field.displayComponentBasedOnFormFields(values, setFieldValue)
                            : field.component}
                        </Box>
                      );
                    }

                    if (dropdownFields.includes(field.type as DROPDOWN_FIELD)) {
                      return (
                        <CustomSearchDropdownField
                          key={field.field_name}
                          field={field}
                          initialValue={values[field.field_name]}
                          error={errors[field.field_name] as string}
                          touched={touched[field.field_name] as boolean}
                          setFieldValue={setFieldValue}
                          span={field.span ?? span / columnNumber}
                          setCustomDisplayName={setCustomDisplayName}
                          data={fieldData[field.field_name]}
                          setFieldData={setFieldData}
                        />
                      );
                    }

                    if (field.type === 'table') {
                      return (
                        <FormField
                          key={field.field_name}
                          field={field}
                          index={index}
                          setFormFields={setFormFields}
                          formMode={formMode}
                          span={span}
                          columnNumber={columnNumber}
                          userInputFields={userInputFields}
                        >
                          <CustomTableForm
                            displayName={field.display_name}
                            columns={field.columns!}
                            formMode={formMode}
                            initialValues={JSON.stringify(values[field.field_name])}
                            handleChange={(rows) => {
                              setResponses && setResponses((prev: any) => ({ ...prev, [field.field_name]: rows }));
                            }}
                            handleFieldChange={() => {
                              setFormFields &&
                                setFormFields((prevFormFields) =>
                                  prevFormFields.map((fld) => (fld.field_name === field.field_name ? field : fld))
                                );
                            }}
                            field={field}
                          />
                        </FormField>
                      );
                    }
                    if (field.type === 'select') {
                      return (
                        <FormField
                          key={field.field_name}
                          field={field}
                          index={index}
                          setFormFields={setFormFields}
                          formMode={formMode}
                          span={span}
                          columnNumber={columnNumber}
                          options={field.options!}
                          updateOptions={(updateOptions) => (field.options = updateOptions)}
                          handleChange={handleChange}
                          userInputFields={userInputFields}
                        >
                          <CustomSelectField
                            id={field.id}
                            label={field.display_name!}
                            disabled={field.disabled}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            value={values[field.field_name]}
                            fieldName={field.field_name}
                            error={errors[field.field_name] as string}
                            touched={touched[field.field_name] as boolean}
                            span={field.span ?? span / columnNumber}
                            options={field.options!}
                            optional={field.optional}
                            handleChangeCallback={(value) => {
                              field.onChange && field.onChange(value, setFieldValue);
                              field.onTemplateUpdate && field.onTemplateUpdate(value, setFieldValue);
                            }}
                            size={field.size}
                            readOnly={readOnly}
                          />
                        </FormField>
                      );
                    } else if (field.type === 'datetime') {
                      return (
                        <FormField
                          key={field.field_name}
                          field={field}
                          index={index}
                          setFormFields={setFormFields}
                          formMode={formMode}
                          span={span}
                          columnNumber={columnNumber}
                          userInputFields={userInputFields}
                        >
                          <CustomDateTimePicker
                            id={field.id}
                            key={field.field_name}
                            fieldName={field.field_name}
                            label={field.display_name!}
                            disabled={field.disabled}
                            value={values[field.field_name] ? dayjs(values[field.field_name]) : null}
                            // handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            error={errors[field.field_name]}
                            touched={touched[field.field_name] as boolean}
                            span={field.span ?? span / columnNumber}
                            disablePast={field.disablePast}
                            disableFuture={field.disableFuture}
                            size={field.size}
                            minutesStep={field.minutesStep}
                            readOnly={readOnly}
                            // handleChangeCallback={(value) => field.onChange && field.onChange(value, setFieldValue)}
                            optional={field.optional}
                          />
                        </FormField>
                      );
                    } else if (field.type === 'date') {
                      return (
                        <FormField
                          key={field.field_name}
                          field={field}
                          index={index}
                          setFormFields={setFormFields}
                          formMode={formMode}
                          span={span}
                          columnNumber={columnNumber}
                          userInputFields={userInputFields}
                        >
                          <CustomDatePicker
                            fieldName={field.field_name}
                            label={field.display_name!}
                            disabled={field.disabled}
                            value={values[field.field_name]}
                            handleChange={(value) => field.onChange && field.onChange(value, setFieldValue)}
                            setFieldValue={setFieldValue}
                            error={errors[field.field_name]}
                            touched={touched[field.field_name] as boolean}
                            span={field.span ?? span / columnNumber}
                            disablePast={field.disablePast}
                            disableFuture={field.disableFuture}
                            size={field.size}
                            readOnly={readOnly}
                            optional={field.optional}
                          />
                        </FormField>
                      );
                    } else if (field.type === 'time') {
                      return (
                        <FormField
                          key={field.field_name}
                          field={field}
                          index={index}
                          setFormFields={setFormFields}
                          formMode={formMode}
                          span={span}
                          columnNumber={columnNumber}
                          userInputFields={userInputFields}
                        >
                          <CustomTimePicker
                            key={field.field_name}
                            fieldName={field.field_name}
                            label={field.display_name!}
                            disabled={field.disabled}
                            value={values[field.field_name]}
                            setFieldValue={setFieldValue}
                            error={errors[field.field_name]}
                            touched={touched[field.field_name] as boolean}
                            span={field.span ?? span / columnNumber}
                            size={field.size}
                            minutesStep={field.minutesStep}
                            maxTime={field.maxTime}
                            minTime={field.minTime}
                            format={field.format}
                            optional={field.optional}
                            readOnly={readOnly}
                          />
                        </FormField>
                      );
                    } else if (field.type === 'radiogroup') {
                      return (
                        <FormField
                          key={field.field_name}
                          field={field}
                          index={index}
                          setFormFields={setFormFields}
                          formMode={formMode}
                          span={span}
                          columnNumber={columnNumber}
                          options={field.options!}
                          updateOptions={(updateOptions) => (field.options = updateOptions)}
                          handleChange={handleChange}
                          userInputFields={userInputFields}
                        >
                          <CustomRadioGroup
                            label={field.display_name!}
                            handleChange={handleChange}
                            disabled={field.disabled}
                            fieldName={field.field_name}
                            value={values[field.field_name]}
                            span={field.span ?? span / columnNumber}
                            optionsColumn={field.optionsColumn ?? (span === field.span ? 3 : 2)}
                            handleChangeCallback={(value) => field.onChange && field.onChange(value, setFieldValue)}
                            options={field.options!}
                            row={field.row}
                            style={field.style}
                            readOnly={readOnly}
                            setFieldValue={setFieldValue}
                            optional={field.optional}
                          />
                        </FormField>
                      );
                    } else if (field.type === 'checkbox_group') {
                      return (
                        <FormField
                          key={field.field_name}
                          field={field}
                          index={index}
                          setFormFields={setFormFields}
                          formMode={formMode}
                          span={span}
                          columnNumber={columnNumber}
                          options={field.options!}
                          updateOptions={(updateOptions) => (field.options = updateOptions)}
                          handleChange={handleChange}
                          userInputFields={userInputFields}
                        >
                          <CustomCheckboxGroup
                            options={field.options!}
                            key={field.field_name}
                            label={field.display_name!}
                            disabled={field.disabled}
                            fieldName={field.field_name}
                            value={values[field.field_name]}
                            span={field.span ?? span / columnNumber}
                            optionsColumn={span === field.span ? 3 : 2}
                            handleChangeCallback={(value) => field.onChange && field.onChange(value, setFieldValue)}
                            margin={field.margin}
                            setFieldValue={setFieldValue}
                            readOnly={readOnly}
                            optional={field.optional}
                          />
                        </FormField>
                      );
                    } else if (field.type === 'checkbox') {
                      return (
                        <FormField
                          key={field.field_name}
                          field={field}
                          index={index}
                          setFormFields={setFormFields}
                          formMode={formMode}
                          span={span}
                          columnNumber={columnNumber}
                          userInputFields={userInputFields}
                        >
                          <CustomCheckbox
                            key={field.field_name}
                            label={customDisplayName[field.field_name] ?? field.display_name!}
                            handleChange={handleChange}
                            disabled={field.disabled}
                            fieldName={field.field_name}
                            value={values[field.field_name]}
                            span={field.span ?? span / columnNumber}
                            handleChangeCallback={(value) => field.onChange && field.onChange(value, setFieldValue)}
                            margin={field.margin}
                            setFieldValue={setFieldValue}
                            readOnly={readOnly}
                            optional={field.optional}
                          />
                        </FormField>
                      );
                    } else if (field.type === 'password') {
                      return (
                        <CustomPasswordField
                          key={field.field_name}
                          label={field.display_name!}
                          disabled={field.disabled}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          value={values[field.field_name]}
                          fieldName={field.field_name}
                          error={errors[field.field_name]}
                          touched={touched[field.field_name] as boolean}
                          span={field.span ?? span / columnNumber}
                          size={field.size}
                          optional={field.optional}
                        />
                      );
                    } else if (field.type === 'multiselect') {
                      return (
                        <CustomAutoCompleteMultipleSelect
                          key={field.field_name}
                          label={field.display_name!}
                          disabled={field.disabled}
                          setFieldValue={setFieldValue}
                          value={values[field.field_name]}
                          fieldName={field.field_name}
                          error={errors[field.field_name] as string}
                          touched={touched[field.field_name] as boolean}
                          span={field.span ?? span / columnNumber}
                          options={field.options!}
                          optional={field.optional}
                          size={field.size}
                          handleChange={handleChange}
                          handleChangeCallback={(value) => field.onChange && field.onChange(value, setFieldValue)}
                        />
                      );
                    } else if (field.type === 'password_generator') {
                      return (
                        <CustomPasswordGeneratorField
                          key={field.field_name}
                          label={field.display_name!}
                          disabled={field.disabled}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          value={values[field.field_name]}
                          fieldName={field.field_name}
                          error={errors[field.field_name]}
                          touched={touched[field.field_name] as boolean}
                          span={field.span ?? span / columnNumber}
                          size={field.size}
                          optional={field.optional}
                          handleChangeCallback={(value) => field.onChange && field.onChange(value, setFieldValue)}
                        />
                      );
                    } else if (field.type === 'image_upload') {
                      return (
                        <CustomImageUpload
                          key={field.field_name}
                          fieldName={field.field_name}
                          label={field.display_name!}
                          value={values[field.field_name]}
                          isBanner={field.isBanner}
                          setFieldValue={setFieldValue}
                          span={field.span ?? span}
                          optional={field.optional}
                        />
                      );
                    } else if (field.type === 'rich_text') {
                      return (
                        <FormField
                          key={field.field_name}
                          field={field}
                          index={index}
                          setFormFields={setFormFields}
                          formMode={formMode}
                          span={span}
                          columnNumber={columnNumber}
                          userInputFields={userInputFields}
                        >
                          <CustomTextField
                            {...field}
                            rows={5}
                            multiline
                            label={field.display_name!}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            value={values[field.field_name]}
                            fieldName={field.field_name}
                            error={errors[field.field_name]}
                            touched={touched[field.field_name] as boolean}
                            handleChangeCallback={(value) => field.onChange && field.onChange(value, setFieldValue)}
                            readOnly={readOnly}
                          />
                          {/* <CustomRichTextEditor
                            label={field.display_name!}
                            fieldName={field.field_name}
                            value={values[field.field_name]}
                            setFieldValue={setFieldValue}
                            span={field.span ?? span}
                            height={field.height}
                            optional={field.optional}
                            readOnly={readOnly}
                            editFormMode={formMode === FORM_MODE.EDIT}
                            
                          /> */}
                        </FormField>
                      );
                    } else if (field.type === 'multiselect_checkbox') {
                      return (
                        <CustomMultiSelectCheckbox
                          {...field}
                          key={field.field_name}
                          label={field.display_name!}
                          disabled={field.disabled}
                          value={values[field.field_name]}
                          fieldName={field.field_name}
                          span={field.span ?? span / columnNumber}
                          options={field.options!}
                          handleChange={handleChange}
                          handleChangeCallback={(value) => field.onChange && field.onChange(value, setFieldValue)}
                          setFieldValue={setFieldValue}
                        />
                      );
                    } else if (field.type === 'signature') {
                      if (!isDevMode()) return <></>;
                      return (
                        <CustomSignature
                          {...field}
                          key={field.field_name}
                          label={field.display_name!}
                          value={values[field.field_name]}
                          fieldName={field.field_name}
                          span={field.span ?? span}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          optional={field.optional}
                        />
                      );
                    } else if (field.type === 'file_upload') {
                      return (
                        <FormField
                          key={field.field_name}
                          field={field}
                          index={index}
                          setFormFields={setFormFields}
                          formMode={formMode}
                          span={span}
                          columnNumber={columnNumber}
                          userInputFields={userInputFields}
                        >
                          <FileUpload
                            source={field.source ?? 'forms'}
                            maxFilesonDrop={field.maxFilesonDrop}
                            maxFilesAllowed={field.maxFilesAllowed}
                            maxSize={field.maxSize}
                            fileTypes={field.fileTypes}
                            span={field.span ?? span}
                            formMode={formMode}
                            appointmentId={appointment?.id}
                            patientId={patient?.patient_id}
                            label={field.display_name!}
                            value={values[field.field_name]}
                            fieldName={field.field_name}
                            setFieldValue={setFieldValue}
                          />
                        </FormField>
                      );
                    }

                    return (
                      <FormField
                        key={field.field_name}
                        field={field}
                        index={index}
                        setFormFields={setFormFields}
                        formMode={formMode}
                        span={span}
                        columnNumber={columnNumber}
                        userInputFields={userInputFields}
                      >
                        <CustomTextField
                          {...field}
                          label={field.display_name!}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          value={values[field.field_name]}
                          fieldName={field.field_name}
                          error={errors[field.field_name]}
                          touched={touched[field.field_name] as boolean}
                          handleChangeCallback={(value) => field.onChange && field.onChange(value, setFieldValue)}
                          readOnly={readOnly}
                        />
                      </FormField>
                    );
                  })
                )}
                {useReCAPTCHA && (
                  <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!} onChange={onChangeReCAPTCHA} />
                )}
              </Box>
              {footerComponent}
              <Box
                display={'flex'}
                justifyContent={buttonPosition ? buttonPosition : 'end'}
                mt={submitButtonMarginTop ?? 4}
                sx={hideSubmitButton ? { display: 'none' } : undefined}
                gap={SIZES.paddingS}
              >
                {altSubmitComponent}
                {showCancelButton && onCancel && (
                  <RegularButton
                    variant="outlined"
                    label={cancelBtnText || 'Cancel'}
                    loading={loading}
                    onClick={onCancel}
                  />
                )}
                <SubmitButton
                  label={submitButtonText}
                  loading={loading}
                  disabled={disabled || (useReCAPTCHA && !reCAPTCHAValid)}
                  width={buttonWidth ? buttonWidth : 'auto'}
                  isSuccess={isSuccess}
                  id={submitButtonId}
                  submitButtonStyles={submitButtonStyles ? submitButtonStyles : {}}
                />
              </Box>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default CustomForm;
