import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';

import { cancelSelfRegistration, confirmSelfRegistration, getSelfRegistration } from 'company/api/self-registration';
import { formatDate } from 'core/utils';
import { SIZES } from 'theme/constants';
import { useSnackbar } from 'notistack';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { ApiQuery } from 'core/model/interface';
import { FacilityContext } from 'core/context/facility.context';
import { UserContext } from 'core/context/user.context';
import { CareGoPage } from 'core/PageBuilder';
import { WaitForFacility } from 'company/components';
import CustomLoadingIndicator from 'core/components/CustomLoadingIndicator';
import { ColoredTable, ConfirmationDialog, CustomGridCell, RegularButton } from 'core/components';

const SelfRegistrationConfirmation = () => {
  const { facility } = useContext(FacilityContext);
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const selRegTableRef = useRef<any>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [loadingRows, setLoadingRows] = useState<{ [key: number]: boolean }>({});
  const [selectedSelfRegistration, setSelectedSelfRegistration] = useState<any | null>(null);
  const [isConfirm, setIsConfirm] = useState(true);
  const [alertContent, setAlertContent] = useState('');
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  const refreshTable = () => {
    selRegTableRef.current?.refreshTable();
  };

  const getPendingSelfRegistration = (query: ApiQuery) => {
    return getSelfRegistration(facility.id, { status: 'pending', ...query });
  };

  const handleProcessSelfRegistration = async () => {
    setOpenConfirmationDialog(false);
    setLoadingRows((prev) => ({ ...prev, [selectedSelfRegistration.id]: true }));

    try {
      let res;
      if (isConfirm) {
        res = await confirmSelfRegistration(facility.id, selectedSelfRegistration.id);
      } else {
        res = await cancelSelfRegistration(facility.id, selectedSelfRegistration.id);
      }
      successSelfRegistrationUpdate(res.data.message);
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Error occurred! Please try again.', { variant: 'error' });
    } finally {
      resetLoadingState();
    }
  };

  const resetLoadingState = () => {
    setOpenConfirmationDialog(false);
    setSelectedSelfRegistration(null);
    if (selectedSelfRegistration) {
      setLoadingRows((prev) => ({ ...prev, [selectedSelfRegistration.id]: false }));
    }
  };

  const successSelfRegistrationUpdate = (message: string) => {
    enqueueSnackbar(message, { variant: 'success' });
    refreshTable();
  };

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    localStorage.setItem('previousPath', window.location.pathname + window.location.search);
  }, []);

  return (
    <CareGoPage
      loading={!user}
      breadcrumb={[
        { label: 'Patient Management', link: '/company/patient-management' },
        { label: 'Self Registration-Portal' },
      ]}
    >
      <WaitForFacility facility={facility}>
        <Box mb={SIZES.paddingL}>
          {isLoading ? (
            <CustomLoadingIndicator />
          ) : (
            <>
              <Typography variant="h4" fontWeight={600} textAlign="left">
                Registration List
              </Typography>
              <ColoredTable
                ref={selRegTableRef}
                stackHeaderComponents
                containerRowAlignment="flex-start"
                getData={getPendingSelfRegistration}
                columns={[
                  {
                    field: 'first_name',
                    headerName: 'Patient Name',
                    renderCell: (params) => (
                      <CustomGridCell>
                        <Typography>
                          {params.row.first_name} {params.row.last_name}
                        </Typography>
                      </CustomGridCell>
                    ),
                  },
                  { field: 'mobile_number', headerName: 'Mobile Number' },
                  {
                    field: 'birthday',
                    headerName: 'Birthday',
                    renderCell: ({ value }) => formatDate(value),
                  },
                  {
                    field: 'action',
                    sortable: false,
                    headerName: 'Actions',
                    headerAlign: 'center',
                    align: 'center',
                    flex: 1,
                    renderCell: (params) => (
                      <Box display="flex" gap="10px">
                        <RegularButton
                          variant="contained"
                          color="error"
                          label="Reject"
                          startIcon={<HighlightOffIcon color="error" sx={{ color: '#fff' }} />}
                          onClick={() => {
                            setSelectedSelfRegistration(params.row);
                            setOpenConfirmationDialog(true);
                            setIsConfirm(false);
                            setAlertContent(
                              'Would you like to reject ' + params.row.first_name + "'s self registration?"
                            );
                          }}
                          loading={loadingRows[params.row.id] || false}
                        />
                        <RegularButton
                          variant="contained"
                          color="success"
                          label="Confirm"
                          startIcon={<CheckCircleOutlineIcon sx={{ color: '#fff' }} />}
                          loading={loadingRows[params.row.id] || false}
                          onClick={() => {
                            setSelectedSelfRegistration(params.row);
                            setOpenConfirmationDialog(true);
                            setIsConfirm(true);
                            setAlertContent(
                              `Are you sure you want to register "${params.row.full_name}" as a new patient?`
                            );
                          }}
                        />
                      </Box>
                    ),
                  },
                ]}
              />
            </>
          )}
        </Box>

        <ConfirmationDialog
          open={openConfirmationDialog}
          setOpen={(open) => {
            setOpenConfirmationDialog(open);
            if (!open) resetLoadingState();
          }}
          content={alertContent}
          title="Confirmation"
          onConfirm={handleProcessSelfRegistration}
        />
      </WaitForFacility>
    </CareGoPage>
  );
};

export default SelfRegistrationConfirmation;
