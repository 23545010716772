/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Button, Chip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { CareGoPage, CareGoTable } from 'core/PageBuilder';
import {
  ConfirmationDialog,
  CustomGridCell,
  CustomModal,
  Dropdown,
  HideOrShowComponent,
  RegularButton,
} from 'core/components';
import { ContainerColumn, ContainerRow } from 'core/components/containers';
import { deleteCompanyTag, getCompanyTag, getCompanyTags } from 'company/api/company-tags';
import { forwardRef, useCallback, useContext, useEffect, useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';

import AddIcon from '@mui/icons-material/Add';
import { CompanyTagsModel } from 'company/entities/modules/ClinicManagement/Clinic/ClinicModel';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FacilityContext } from 'core/context/facility.context';
import { GridColDef } from '@mui/x-data-grid';
import InfiniteScrollContainer from 'core/components/dataView/InfiniteScrollContainer';
import { Link } from 'react-router-dom';
import { PatientGroupForm } from 'company/entities/forms';
import { PatientTable } from 'company/entities/tables';
import { SIZES } from 'theme/constants';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { WaitForFacility } from 'company/components';
import { isDevMode } from 'core/components/HideOrShowComponent';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';
import { Drawer, IconButton } from '@mui/material';
import CustomBottomPopover from 'core/layout/components/CustomBottomPopover';
import { useNavigate } from 'react-router-dom';
import CustomLoadingIndicator from 'core/components/CustomLoadingIndicator';
import { UserContext } from 'core/context/user.context';

interface PatientGroupsProps {
  onClose?: () => void;
}

const PatientGroups = forwardRef((props: PatientGroupsProps, ref) => {
  const { onClose } = props;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { enqueueSnackbar } = useSnackbar();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [modalHeader, setModalHeader] = useState<string>();
  const { facility } = useContext(FacilityContext);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openActions, setOpenActions] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { user } = useContext(UserContext);

  const [selectedPatientGroup, setSelectedPatientGroup] = useState<number>(0);
  const [deleteId, setDeleteId] = useState<number>(0);
  const [group, setGroup] = useState<any>();
  const [forceRefresh, setForceRefresh] = useState<number>(0);
  const [addedPatientGroup, setAddedPatientGroup] = useState<number>();
  const [updatedPatientGroup, setUpdatedPatientGroup] = useState<CompanyTagsModel>();

  const isMobile = useMediaQuery('(max-width:768px)');
  const navigate = useNavigate();

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      navigate('/company/patient-management');
    }
  };

  const callbackAfterSubmit = () => {
    setForceRefresh(forceRefresh + 1);
  };

  const handleDelete = (data: any) => {
    if (facility) {
      const tags = {
        tags: JSON.stringify([data.id]),
      };
      return deleteCompanyTag(facility!.id, tags)
        .then(() => {
          enqueueSnackbar('Group successfully deleted!', { variant: 'success' });
          callbackAfterSubmit();
          setDeleteId(selectedPatientGroup);
        })
        .catch(() => {
          enqueueSnackbar('Cannot delete group with patients added.', { variant: 'error' });
        });
    }
  };

  const getPatientGroupDetails = useCallback(() => {
    if (facility && selectedPatientGroup && selectedPatientGroup !== deleteId) {
      setIsLoading(true);

      getCompanyTag(facility.id, selectedPatientGroup)
        .then((res) => setGroup(res.data))
        .catch((error) => console.error(error))
        .finally(() => setIsLoading(false));
    }
  }, [facility, selectedPatientGroup]);

  const mockData = {
    data: {
      data: [
        {
          id: 1,
          name: 'Free Eye Checkup',
          period: 'Apr 15, 2024 9:00 AM - Apr 16, 2024 9:00 AM',
          num_patients: 10,
          status: 'Active',
        },
        {
          id: 2,
          name: 'Free Maternity Care',
          period: 'Apr 15, 2024 9:00 AM - Apr 16, 2024 9:00 AM',
          num_patients: 15,
          status: 'Pending',
        },
      ],
      meta: {
        total: 5,
      },
    },
  };

  const getMockData = async (): Promise<any> => {
    return mockData;
  };

  const options = [
    {
      label: 'Campaigns',
    },
    {
      label: 'Patient Transactions',
    },
  ];

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => (
        <CustomGridCell>
          <Link to={''} className="emphasis">
            {params.row.name}
          </Link>
        </CustomGridCell>
      ),
    },
    { field: 'period', headerName: 'Period', flex: 2 },
    {
      field: 'num_patients',
      headerName: 'Selected Patients',
      flex: 1.5,
      renderCell: (params) => (
        <Box display={'flex-column'}>
          <Typography fontSize={'10px'}>{params.row.num_patients} out of 20</Typography>
          <Typography color={colors.blueAccent} fontSize={'10px'}>
            patients selected
          </Typography>
        </Box>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => (
        <Chip
          size="small"
          variant="filled"
          label={params.row.status}
          sx={{
            color: '#388E3C',
            backgroundColor: '##F3F3F3',

            fontSize: 9,
            paddingBlock: 0,
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    if (selectedPatientGroup) getPatientGroupDetails();
  }, [forceRefresh, selectedPatientGroup, facility, getPatientGroupDetails]);

  const displayItem = useCallback(
    (tag: any) => {
      const isSelected = selectedPatientGroup === tag.id;
      const textColor = isSelected && !isMobile ? 'white' : colors.text;
      return (
        <>
          {/* <Checkbox sx={{ padding: '3px', color: selectedPatientGroup === tag.id ? 'white' : colors.light }} /> */}
          <Box display="flex" flexDirection="column" gap="5px" width="100%">
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              color={selectedPatientGroup === tag.id ? 'colors.primary' : colors.text}
            >
              <Typography variant="h5" fontWeight="600">
                {tag.tag_name}
              </Typography>
            </Box>

            <Typography variant="h5" fontWeight="300" fontSize={'12px'} color={textColor}>
              {tag.patient_count} patients
            </Typography>
          </Box>
        </>
      );
    },
    [colors.light, selectedPatientGroup]
  );

  const renderPatientGroupTable = () => {
    if (isLoading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="200px">
          <CustomLoadingIndicator />
        </Box>
      );
    }
    if (selectedPatientGroup >= 1) {
      return (
        <PatientTable
          groupId={selectedPatientGroup}
          visibleColumns={['full_name', 'mobile_number', 'birthday', 'age', 'sex']}
          hideActions
          hideViews
        />
      );
    }
    return null;
  };

  return (
    <CareGoPage
      breadcrumb={[{ label: 'Patient Management', link: '/company/patient-management' }, { label: 'Patient Groups' }]}
    >
      <WaitForFacility facility={facility}>
        {isMobile ? (
          <Box color={colors.primary}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} padding={'10px'}>
              <Typography variant="h3" color={colors.primary} fontWeight={'600'}>
                Patient Groups
              </Typography>
              <Box display={'flex'} alignItems={'center'}>
                <IconButton
                  onClick={() => {
                    setIsAdd(true);
                    setOpenModal(true);
                    setModalHeader('Add Patient Group');
                  }}
                  disabled={user.remaining_storage <= 0}
                >
                  <AddIcon sx={{ color: colors.primary }} />
                </IconButton>
                <IconButton onClick={handleClose}>
                  <ClearIcon />
                </IconButton>
              </Box>
            </Box>

            <InfiniteScrollContainer
              title="Patient Group List"
              getData={(query) => getCompanyTags(facility.id, query)}
              getSingleData={(id) => getCompanyTag(facility.id, id)}
              renderItem={displayItem}
              selected={selectedPatientGroup}
              setSelected={setSelectedPatientGroup}
              addedNewItem={addedPatientGroup}
              updatedItem={updatedPatientGroup}
              deleteId={deleteId}
              urlKeyId="group_id"
              openActions={openActions}
              setOpenActions={setOpenActions}
              displaySelectedTitle={() => (
                <Typography variant="h3" fontWeight={700} color={colors.primary}>
                  {group?.tag_name}
                </Typography>
              )}
              hideActions={group ? false : true}
              hideContainer={group ? false : true}
              // actions={[
              //   {
              //     icon: <EditOutlinedIcon />,
              //     label: 'Update',
              //     action: (data) => {
              //       setOpenModal(true);
              //       setIsAdd(false);
              //       setModalHeader('Update ' + group?.tag_name);
              //     },
              //   },
              //   {
              //     icon: <DeleteOutlineIcon />,
              //     label: 'Delete',
              //     color: 'error',
              //     action: () => {
              //       setOpenDeleteModal(true);
              //     },
              //   },
              // ]}
            >
              {/* <Typography
                variant="h4"
                fontWeight="bold"
                width="100%"
                color={colors.primary}
                fontSize={'20px'}
                mb={'20px'}
              >
                {group?.tag_name}
              </Typography> */}
              {renderPatientGroupTable()}

              <CustomBottomPopover title="Actions" open={openActions} setOpen={setOpenActions}>
                <Box display={'flex'} alignItems={'center'} justifyContent={'center'} gap={'20px'} marginTop={'40px'}>
                  <RegularButton
                    label="Update"
                    startIcon={<EditOutlinedIcon />}
                    onClick={(data) => {
                      setOpenModal(true);
                      setIsAdd(false);
                      setModalHeader('Update ' + group?.tag_name);
                    }}
                    styles={{
                      width: '100px',
                      display: 'flex',
                      borderRadius: '8px',
                      textTransform: 'none',
                      justifyContent: 'center',
                      paddingInline: '15px',
                      backgroundColor: colors.light_blue_background,
                      color: colors.primary,
                      boxShadow: 'none',
                      '&:hover': {
                        backgroundColor: `${colors.light_blue_background} !important`,
                        color: `${colors.primary} !important`,
                        boxShadow: 'none !important',
                      },
                    }}
                  />
                  <RegularButton
                    label="Delete"
                    startIcon={<DeleteOutlineIcon />}
                    onClick={() => {
                      setOpenDeleteModal(true);
                    }}
                    styles={{
                      width: '100px',
                      display: 'flex',
                      borderRadius: '8px',
                      textTransform: 'none',
                      justifyContent: 'center',
                      paddingInline: '15px',
                      backgroundColor: colors.light_red_background,
                      color: colors.redAccent,
                      boxShadow: 'none',
                      '&:hover': {
                        backgroundColor: `${colors.light_red_background} !important`,
                        color: `${colors.redAccent} !important`,
                        boxShadow: 'none !important',
                      },
                    }}
                  />
                </Box>
              </CustomBottomPopover>
            </InfiniteScrollContainer>
          </Box>
        ) : (
          <InfiniteScrollContainer
            title="Patient Group List"
            getData={(query) => getCompanyTags(facility.id, query)}
            getSingleData={(id) => getCompanyTag(facility.id, id)}
            renderItem={displayItem}
            selected={selectedPatientGroup}
            setSelected={setSelectedPatientGroup}
            addedNewItem={addedPatientGroup}
            updatedItem={updatedPatientGroup}
            deleteId={deleteId}
            urlKeyId="group_id"
            titleComponents={
              <ContainerRow gap="5px" sx={{ justifyContent: 'flex-end' }}>
                <RegularButton
                  startIcon={<AddIcon />}
                  label="Add "
                  onClick={() => {
                    setIsAdd(true);
                    setOpenModal(true);
                    setModalHeader('Add Patient Group');
                  }}
                  styles={{ paddingInline: SIZES.padding }}
                  disabled={user.remaining_storage <= 0}
                />
              </ContainerRow>
            }
            headerComponents={
              <HideOrShowComponent hidden={!isDevMode()}>
                <RegularButton
                  startIcon={<AddIcon />}
                  label="Add Campaigns"
                  color="primary"
                  onClick={() => undefined}
                  styles={{ paddingInline: SIZES.padding }}
                  fullWidth
                  disabled={user.remaining_storage <= 0}
                />
              </HideOrShowComponent>
            }
            displaySelectedTitle={() => (
              <Typography variant="h3" fontWeight={700} color={colors.primary}>
                {group?.tag_name}
              </Typography>
            )}
            hideActions={group ? false : true}
            hideContainer={group ? false : true}
            actions={[
              {
                icon: <EditOutlinedIcon />,
                label: 'Update',
                action: (data) => {
                  setOpenModal(true);
                  setIsAdd(false);
                  setModalHeader('Update ' + group?.tag_name);
                },
              },
              {
                icon: <DeleteOutlineIcon />,
                label: 'Delete',
                color: 'error',
                action: () => {
                  setOpenDeleteModal(true);
                },
              },
            ]}
          >
            <ContainerColumn gap={SIZES.paddingL}>
              {selectedPatientGroup && group ? (
                <PatientTable
                  groupId={selectedPatientGroup}
                  visibleColumns={['full_name', 'mobile_number', 'birthday', 'age', 'sex']}
                  hideActions
                  hideViews
                />
              ) : (
                ''
              )}
              {selectedPatientGroup && group ? (
                <HideOrShowComponent hidden={!isDevMode()}>
                  <Box height="400px" width="100%" mt={'2rem'}>
                    <Box display={'flex'} justifyContent={'space-between'}>
                      <Typography variant="h5" fontWeight="bold">
                        List of Campaigns
                      </Typography>
                      <Dropdown
                        buttonLabel={'View Campaigns'}
                        icon={<VisibilityOutlinedIcon />}
                        optionList={options}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                      />
                    </Box>
                    <Box paddingBlock={SIZES.padding}>
                      <CareGoTable
                        useColoredTable
                        tableComponent={{
                          columns: columns,
                          getData: getMockData,
                          hideViews: true,
                          hideSearch: true,
                        }}
                        tableAction={{
                          includeAddButton: false,
                          includeDeleteAction: true,
                          includeUpdateAction: true,
                          doNotHideActionForInactiveCompany: true,
                        }}
                      />
                    </Box>
                  </Box>
                </HideOrShowComponent>
              ) : (
                ''
              )}
            </ContainerColumn>
          </InfiniteScrollContainer>
        )}
      </WaitForFacility>

      {facility && (isAdd ? true : group) && (
        <CustomModal width={500} open={openModal} setOpen={setOpenModal} header={modalHeader}>
          <PatientGroupForm
            facility={facility}
            pGroup={isAdd ? undefined : group}
            callbackAfterSubmit={(data) => {
              setOpenModal(false);
              callbackAfterSubmit();
              if (isAdd) {
                setAddedPatientGroup(data.tag.id);
              } else {
                setUpdatedPatientGroup(data.tag);
              }
            }}
          />
        </CustomModal>
      )}

      {group && (
        <ConfirmationDialog
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          onConfirm={handleDelete}
          data={group}
          title={'Delete ' + group.tag_name}
          content="Are you sure you want to delete this group?"
        />
      )}
    </CareGoPage>
  );
});

export default PatientGroups;
