import {
  GridAlignment,
  GridColDef,
  GridRenderCellParams,
  GridTreeNodeWithRender,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import { formatDate, formatDateTime, formatNumber } from 'core/utils';

import { CustomGridCell } from 'core/components';
import { FieldInput } from 'core/model/interface';
import { Link } from 'react-router-dom';
import { ReactNode } from 'react';
import { Typography } from '@mui/material';
import moment from 'moment';

export interface EntityFields extends FieldInput {
  fieldName: string;
  displayName?: string;
  flex?: number;
  span?: number;
  link?: string;
  generateLink?: (data: any) => string;
  cellContainer?: ReactNode;
  renderCell?: (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) => ReactNode;
  editable?: boolean | ((params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) => boolean);
  valueFormatter?: (params: GridValueFormatterParams<any>) => any;
  valueOptions?: string[];
  headerAlign?: GridAlignment;
  align?: GridAlignment;
  linkPreviousLocation?: string;
  sortable?: boolean;
}

export const processTableColumns = (columns: EntityFields[], layout: string[]): GridColDef[] => {
  return layout
    .map((item) => {
      const column = columns.find((column) => column.fieldName === item);
      if (!column) console.error('Column not found: ' + item);
      const {
        fieldName,
        displayName,
        link,
        generateLink,
        editable,
        sortable,
        type,
        align,
        flex = 1,
        renderCell,
        valueFormatter,
        linkPreviousLocation,
      } = column!;

      const renderCellV2 = (params: any) => {
        const { value } = params;
        let display = value;

        if (renderCell) return renderCell(params);

        if (type === 'date') display = formatDate(value);
        if (type === 'datetime') display = formatDateTime(value);
        if (type === 'currency')
          display = `₱ ${formatNumber(parseFloat(value)) ? formatNumber(parseFloat(value)) : '0'}`;

        if (link || generateLink) {
          if (params.stringOnly) return display;
          const _link = link ? link : generateLink && generateLink(params.row);
          return (
            <Link to={`${_link}`} className="emphasis" state={{ from: linkPreviousLocation }}>
              <Typography>{display}</Typography>
            </Link>
          );
        }

        return display;
      };

      const getValueFormatter = (params: any) => {
        if (type === 'date') return () => moment(params.value).format('DD/MM/YYYY');
        return valueFormatter;
      };

      return {
        ...column,
        field: fieldName,
        headerName: displayName,
        type: type === 'currency' ? 'number' : type,
        align: type === 'currency' || type === 'number' ? 'right' : align,
        flex,
        editable,
        sortable: sortable !== false,
        valueFormatter: (params: any) => getValueFormatter(params),
        renderCell: (params: any) =>
          params.stringOnly ? (
            renderCellV2(params)
          ) : (
            <CustomGridCell>
              {link || generateLink || renderCell ? (
                renderCellV2(params)
              ) : (
                <Typography>{renderCellV2(params)}</Typography>
              )}
            </CustomGridCell>
          ),
      };
    })
    .filter((column) => column !== null) as GridColDef[];
};

export const processFormField = (
  fields: EntityFields[],
  layout: string[],
  dropdownOptions?: any,
  sortable?: boolean
): FieldInput[] => {
  return layout.map((item) => {
    const field = fields.find((field) => field.fieldName === item);
    if (!field) console.error('Field not found: ' + item);
    const { fieldName, displayName, options } = field!;

    return {
      ...field,
      field_name: fieldName,
      display_name: displayName,
      options: dropdownOptions && dropdownOptions[fieldName] ? dropdownOptions[fieldName] : options,
      sortable: sortable !== false,
    };
  }) as FieldInput[];
};
