import { Box, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import { CustomForm, CustomModal } from 'core/components';
import { Fragment, useContext, useEffect, useState } from 'react';
import { deleteAppointment, rescheduleAppointments, scheduleAppointment } from 'company/api/appointments';
import { previewSms, updateLocalizedTemplates } from 'company/api/sms-template';
import { schedule_appointment_from_health_package, sms_template_schema } from 'company/model/schema';

import { DROPDOWN_FIELD } from 'core/model/interface';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FacilityContext } from 'core/context/facility.context';
import { FieldInput } from 'core/model/interface';
import { FormikHelpers } from 'formik';
import ReactMarkdown from 'react-markdown';
import { customReplaceCompanySmsTemplate } from 'core/utils';
import { getDashboardStat } from 'company/api/inventory-dashboard';
import { getServicesWithAppointments } from 'company/api/health-packages';
import { specialTokens } from 'core/data/list';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

type UserInput = {
  schedule: string;
  service_id: any[];
};

interface UserFieldInput extends FieldInput {
  field_name?: keyof UserInput;
}

const sendSMSCheckbox: FieldInput = {
  field_name: 'send_sms',
  display_name: 'Send SMS to patient',
  type: 'checkbox',
  onChange: (value) => console.log(value),
};

type ScheduleAppointmentProps = {
  selectedService?: any;
  stageID: number;
  patient: any;
  packagePatientID: number;
  setOpen: (open: boolean) => void;
  refreshTable?: () => void;
  action?: any;
};
export enum ACTION {
  APPOINTMENT = 'Schedule Appointment',
  RESCHEDULE = 'Reschedule',
  CANCEL = 'Cancel Appointment',
}
const ScheduleAppointment: React.FC<ScheduleAppointmentProps> = ({
  selectedService,
  stageID,
  patient,
  setOpen,
  refreshTable,
  packagePatientID,
  action,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { enqueueSnackbar } = useSnackbar();
  const { facility } = useContext(FacilityContext);
  const [loading, setLoading] = useState<boolean>(false);
  const program_code = 'appointments';
  const [smsTemplatePreview, setSmsTemplatePreview] = useState('');
  const [openTemplateModal, setOpenTemplateModal] = useState<boolean>(false);
  const [preview, setPreview] = useState('');
  const [templateBody, setTemplateBody] = useState('');
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<any>();
  const [lastSavedTemplateBody, setLastSavedTemplateBody] = useState('');
  const [services, setServices] = useState<any>();
  const [formData, setFormData] = useState<any>();
  const SMS_CREDIT_THRESHOLD = 100;

  const initialValues: UserInput = {
    schedule: patient.expected_next_stage,
    service_id:
      services && services.length > 0
        ? services.map((u: any) => {
            return { key: u.service_name, value: u.service_id };
          })
        : [],
  };

  const [scheduleValue, setScheduleValue] = useState(initialValues.schedule);

  const formFields: UserFieldInput[] = [
    {
      field_name: 'schedule',
      display_name: 'Schedule',
      type: 'datetime',
      disablePast: true,
      onChange: (value: any) => {
        console.log(value);
        setFormData(value);
        setScheduleValue(value);
      },
      span: 4,
    },
    { field_name: 'service_id', type: DROPDOWN_FIELD.SERVICE, span: 4, multiple: true },
  ];

  const smsFormFields: FieldInput[] = [
    {
      field_name: 'template_body',
      display_name: 'Template Body',
      type: 'text',
      multiline: true,
      rows: 7,
      span: 4,
      onChange: (value) => setTemplateBody(value),
    },
  ];

  const handleSubmit = async (data: any, formikHelpers: FormikHelpers<any>) => {
    data.patients = JSON.stringify([patient.patient_id]);
    data.services = data.service_id ? JSON.stringify(data.service_id.map((service: any) => service.value)) : '';
    data.stage_id = stageID;
    data.package_patient_id = packagePatientID;

    if (action === ACTION.RESCHEDULE && selectedService) {
      data.appointments = JSON.stringify([selectedService.appointment_id]);
    }

    setLoading(true);

    if (data) {
      if (action === ACTION.RESCHEDULE) {
        return rescheduleAppointments(facility.id, data).then((res) => {
          successFormSubmit(res, formikHelpers);
        });
      } else if (action === ACTION.APPOINTMENT) {
        return scheduleAppointment(facility.id, data).then((res) => {
          successFormSubmit(res, formikHelpers);
        });
      } else if (selectedService) {
        const data = { appointments: JSON.stringify([selectedService.appointment_id]) };

        return deleteAppointment(facility.id, program_code, data).then((res) => {
          successFormSubmit(res, formikHelpers);
        });
      }
    }
  };

  const successFormSubmit = (res: any, { setErrors }: FormikHelpers<any>) => {
    if (res.status === 200) {
      enqueueSnackbar(
        `Appointment successfully ${
          action === ACTION.RESCHEDULE ? 'rescheduled' : action === ACTION.CANCEL ? 'cancelled' : 'scheduled'
        }!`,
        { variant: 'success' }
      );
      setOpen(false);
    } else if (res.response.status === 400) {
    }
    setLoading(false);
    refreshTable && refreshTable();
  };

  const getSmsPreview = async (template_code: string, data: any, schedule: any) => {
    const preview = await previewSms(facility.id, null, template_code);
    setSelectedTemplate(preview.data);
    let template = preview.data.template_body;
    const contacts = [];
    facility.globe_mobile_number && contacts.push(facility.globe_mobile_number);
    facility.smart_mobile_number && contacts.push(facility.smart_mobile_number);

    return template
      .replace('[Facility_Name]', facility.facility_short_name)
      .replace('[Facility_Type]', facility.facility_type)
      .replace('[Facility_Contact]', contacts.join(' / '))
      .replace('[Name]', patient ? patient.full_name : data?.full_name ? data.full_name : '[Name]')
      .replace('[First_Name]', patient ? patient.first_name : data?.full_name ? data.full_name : '[First_Name]')
      .replace('[Health_Program]', 'appointment');
    // .replace('[Date]', schedule ? formatDate(schedule) : formatDate(service?.schedule))
    // .replace('[Time]', schedule ? formatDate(schedule) : formatTime(service?.schedule));
  };

  const getSmsTemplatePreview = async (template: string, data: any, schedule: any) => {
    setSmsTemplatePreview('');
    setSmsTemplatePreview(await getSmsPreview(template, data, schedule));
  };

  const handleSmsEdit = async (data: any) => {
    setButtonLoading(true);
    updateLocalizedTemplates(selectedTemplate.id, facility.id, data.template_body)
      .then(async () => {
        enqueueSnackbar(`Template successfully updated!`, { variant: 'success' });
        setButtonLoading(false);
        setOpenTemplateModal(false);
        setSmsTemplatePreview(customReplaceCompanySmsTemplate(facility, data.template_body, patient, services));
        setLastSavedTemplateBody(data.template_body);
      })
      .catch(() => {
        setButtonLoading(false);
      });

    refreshTable && refreshTable();
  };

  const getServices = async () => {
    return getServicesWithAppointments(stageID, patient.id, {}).then((res) => {
      setServices(res.data.data);
    });
  };

  useEffect(() => {
    if (action === ACTION.RESCHEDULE) {
      getSmsTemplatePreview('rescheduled_appointment', patient, scheduleValue);
    } else if (action === ACTION.CANCEL) {
      getSmsTemplatePreview('cancelled_appointment', patient, scheduleValue);
    }
    // eslint-disable-next-line
  }, [action, scheduleValue]);

  useEffect(() => {
    if (patient && action && selectedTemplate) {
      setLastSavedTemplateBody('');
      setPreview(customReplaceCompanySmsTemplate(facility, templateBody, patient, services));
    }
  }, [templateBody, action, selectedTemplate]);

  useEffect(() => {
    getServices();
  }, [stageID]);

  useEffect(() => {
    const checkSMSCredits = async (formData: any) => {
      if (formData?.send_sms) {
        try {
          const res = await getDashboardStat(facility.id);
          const credits = res.data.sms_credits;
          if (credits < SMS_CREDIT_THRESHOLD) {
            enqueueSnackbar(
              `Important Notice: Your SMS credit balance has fallen below ${SMS_CREDIT_THRESHOLD} credits. Current balance: ${credits} credits.`,
              {
                variant: 'warning',
                persist: true,
              }
            );
          }
        } catch (error) {
          console.error('Error fetching SMS credits:', error);
        }
      }
    };

    if (formData) {
      checkSMSCredits(formData);
    }
  }, [formData?.send_sms]);

  return (
    <>
      <Box display="grid" gridTemplateColumns="120px 1fr" marginBottom="20px" rowGap="2px">
        <Typography variant="body1" color={colors.text}>
          Patient
        </Typography>
        <Typography variant="body1" color={colors.text} fontWeight={'bold'}>
          {patient?.full_name}
        </Typography>
      </Box>

      {action === ACTION.CANCEL && (
        <Typography
          variant="body1"
          color={colors.text}
          fontStyle="italic"
          style={{ textIndent: '20px', marginBottom: '10px' }}
        >
          Are you sure you want to cancel this appointment? The appointment will be canceled after clicking the submit
          button.
        </Typography>
      )}

      <CustomForm
        onSubmit={handleSubmit}
        fields={
          action === ACTION.RESCHEDULE
            ? [
                ...formFields,
                {
                  ...sendSMSCheckbox,
                  display_name: 'Send Reschedule SMS to patientsss',
                  span: 4,
                },
              ]
            : action === ACTION.CANCEL
            ? [sendSMSCheckbox]
            : formFields
        }
        initialValues={
          action === ACTION.RESCHEDULE
            ? { ...services, send_sms: false }
            : action === ACTION.CANCEL
            ? { send_sms: false }
            : initialValues
        }
        schema={action !== ACTION.CANCEL ? schedule_appointment_from_health_package : undefined}
        loading={loading}
        span={2}
        footerComponent={
          <>
            {action && [ACTION.CANCEL, ACTION.RESCHEDULE].includes(action) && (
              <Box sx={{ mt: '20px', p: '20px', borderRadius: 2, backgroundColor: colors.secondary_background }}>
                <Box display="flex" flexDirection="row" gap="0.5rem" alignItems="center" paddingBottom="5px">
                  <Typography variant="body1" color={colors.text} fontWeight="bold">
                    SMS Preview
                  </Typography>
                  <Tooltip title="Edit Template">
                    <IconButton
                      sx={{
                        width: '15px',
                        height: '15px',
                        '&:hover': {
                          color: colors.accent, // Change the background color to green on hover
                        },
                      }}
                      onClick={() => setOpenTemplateModal(true)}
                    >
                      <EditOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Typography variant="body1" color={colors.text} style={{ textIndent: '20px', marginBlock: '10px' }}>
                  {smsTemplatePreview ? smsTemplatePreview : 'Loading ...'}
                </Typography>
              </Box>
            )}
          </>
        }
      />

      {selectedTemplate && (
        <CustomModal width={900} header="Edit Template" open={openTemplateModal} setOpen={setOpenTemplateModal}>
          <Box sx={{ p: '20px', borderRadius: 2, border: '1px solid ' + colors.primary }}>
            <Typography variant="h5" color={colors.text} fontWeight="bold">
              Special tokens
            </Typography>{' '}
            <br></br>
            <Box display="grid" gridTemplateColumns="150px 1fr 150px 1fr" gap="10px">
              {specialTokens.map((token, index) => (
                <Fragment key={index}>
                  <Typography
                    variant="body1"
                    color={colors.text}
                    style={{
                      fontWeight: 'bold',
                      padding: '5px',
                    }}
                  >
                    <span style={{ backgroundColor: colors.textBackground, borderRadius: '5px', padding: '6px' }}>
                      {' '}
                      {token.token}{' '}
                    </span>
                  </Typography>
                  <Typography variant="body1" color={colors.text}>
                    {token.description}
                  </Typography>
                </Fragment>
              ))}
            </Box>
          </Box>

          <Box sx={{ marginBlock: '20px', p: '20px', borderRadius: 2, border: '1px solid ' + colors.primary }}>
            <Typography variant="h5" color={colors.text} fontWeight="bold">
              Preview ({selectedTemplate?.template_body.length} characters)
            </Typography>
            <Box
              color={colors.text}
              sx={{
                marginBottom: '20px',
                whiteSpace: 'pre-line',
                '& code': {
                  color: 'white',
                  backgroundColor: colors.main,
                  padding: '2px 5px',
                  borderRadius: '5px',
                },
              }}
            >
              <ReactMarkdown children={preview ? preview : smsTemplatePreview} />
            </Box>
          </Box>

          <CustomForm
            initialValues={{
              template_body: lastSavedTemplateBody ? lastSavedTemplateBody : selectedTemplate?.template_body,
            }}
            onSubmit={handleSmsEdit}
            fields={smsFormFields}
            schema={sms_template_schema}
            loading={buttonLoading}
          />
        </CustomModal>
      )}
    </>
  );
};

export default ScheduleAppointment;
