import {
  CategoryModel,
  PRODUCT_ENTITY_NAME,
  ProductModel,
  addProductFormFields,
  addProductInitialValues,
  createProductModelSchema,
  productFields,
  updateProductFormFields,
  updateProductInitialValues,
} from './ProductModel';
import { createClinicProduct, updateClinicProduct } from 'company/api/products';
import { useContext, useEffect, useState } from 'react';

import { FacilityContext } from 'core/context/facility.context';
import TemplateForm from 'company/entities/Template/TemplateForm';
import { getProductCategories } from 'company/api/product-categories';

type Props = {
  product?: ProductModel;
  medicine?: boolean;
  readonly?: boolean;
  submitOnTop?: boolean;
  callbackAfterSubmit?: (data: any) => void;
  source?: string;
  readOnly?: boolean;
};

const ProductForm: React.FC<Props> = (props: Props) => {
  const { facility } = useContext(FacilityContext);
  const { callbackAfterSubmit, product, source, readOnly, medicine } = props;
  const [options, setOptions] = useState({});

  const handleFormSubmit = (data: any) => {
    return product && !medicine
      ? updateClinicProduct(product.id, data)
      : createClinicProduct({ ...data, source: source ?? '', facility_id: facility.id });
  };

  useEffect(() => {
    getProductCategories().then((response) => {
      const categories: CategoryModel[] = response.data.data;
      setOptions({ category_id: categories.map((category) => ({ key: category.category, value: category.id })) });
    });
  }, []);

  return (
    <TemplateForm
      readOnly={readOnly}
      submitButtonId={'create_product_submit_button'}
      entityName={PRODUCT_ENTITY_NAME}
      fields={productFields}
      entity={product}
      visibleFields={product && !medicine ? updateProductFormFields : addProductFormFields}
      initialValues={product && !medicine ? updateProductInitialValues : addProductInitialValues}
      dropdownOptions={options}
      schema={createProductModelSchema}
      formSubmitApiFunction={handleFormSubmit}
      callbackAfterSubmit={callbackAfterSubmit}
      fill={medicine}
    />
  );
};

export default ProductForm;
