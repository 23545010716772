import { Box, Typography, useTheme } from '@mui/material';

import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';
import Carousel from 'react-material-ui-carousel';
import ChatIcon from '@mui/icons-material/Chat';
import CustomDashboardWidget from 'core/components/CustomDashboardWidget';
import MedicationOutlinedIcon from '@mui/icons-material/MedicationOutlined';
import MoodOutlinedIcon from '@mui/icons-material/MoodOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import React from 'react';
import WheelchairPickupOutlinedIcon from '@mui/icons-material/WheelchairPickupOutlined';
import { formatCurrency } from 'core/utils';
import { tokens } from 'theme/theme';

interface CustomCarouselProps {
  stats?: any;
  isMobilePhone: boolean;
  textOptions?: boolean;
  texts?: string[];
}

const CustomCarousel: React.FC<CustomCarouselProps> = ({
  stats,
  isMobilePhone,
  textOptions = false,
  texts = ['credits?', 'storage?', 'accounts?', 'patients?'],
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  if (textOptions) {
    return (
      <Carousel
        animation="fade"
        indicators={false}
        interval={5000}
        autoPlay={true}
        duration={1000}
        navButtonsAlwaysInvisible={true}
        sx={{
          display: 'inline-block',
          minWidth: '80px',
        }}
      >
        {texts.map((text, index) => (
          <Typography
            key={index}
            sx={{
              display: 'inline-block',
              textAlign: 'left',
              color: colors.primary,
              fontWeight: 700,
            }}
          >
            {text}
          </Typography>
        ))}
      </Carousel>
    );
  }

  const widgetsPerSlide = isMobilePhone ? 2 : 4;

  const widgets = [
    {
      count: formatCurrency(stats?.total_sales_this_week),
      label: 'Total Sales this Week',
      icon: PaidOutlinedIcon,
    },
    {
      count: formatCurrency(stats?.total_sales_this_month),
      label: 'Total Sales this Month',
      icon: PaidOutlinedIcon,
    },
    {
      count: formatCurrency(stats?.total_sales_this_year),
      label: 'Total Sales this Year',
      icon: PaidOutlinedIcon,
    },
    {
      count: stats?.sms_credits.toLocaleString(),
      label: 'Available Credits',
      icon: ChatIcon,
    },
    {
      count: stats?.new_patients_this_month.toLocaleString(),
      label: 'New Patients this Month',
      icon: MoodOutlinedIcon,
    },
    {
      count: stats?.medicine_dispensed_this_month.toLocaleString(),
      label: 'Medicine Dispensed this Month',
      icon: MedicationOutlinedIcon,
    },
    {
      count: stats?.most_availed_service_this_month?.name || 'No service',
      label: 'Most Availed Service this Month',
      icon: WheelchairPickupOutlinedIcon,
    },
    {
      count: stats?.most_availed_product_this_month?.item_name || 'No product',
      label: 'Most Availed Product this Month',
      icon: AddShoppingCartOutlinedIcon,
    },
  ];

  const widgetGroups = [];
  for (let i = 0; i < widgets.length; i += widgetsPerSlide) {
    widgetGroups.push(widgets.slice(i, i + widgetsPerSlide));
  }

  return (
    <Carousel
      animation="slide"
      indicators={false}
      interval={8000}
      sx={{ mb: 3 }}
      autoPlay={true}
      duration={1000}
      navButtonsProps={{
        style: {
          backgroundColor: colors.primary,
          borderRadius: '50%',
        },
      }}
      // navButtonsAlwaysInvisible={true}
    >
      {widgetGroups.map((group, groupIndex) => (
        <Box
          key={groupIndex}
          display="grid"
          gridTemplateColumns={isMobilePhone ? 'repeat(2, 1fr)' : 'repeat(4, 1fr)'}
          gap={isMobilePhone ? '8px' : '20px'}
        >
          {group.map((widget, widgetIndex) => (
            <CustomDashboardWidget key={widgetIndex} count={widget.count} label={widget.label} icon={widget.icon} />
          ))}
        </Box>
      ))}
    </Carousel>
  );
};

export default CustomCarousel;
