import { deleteOOFEvent, getAllOutOfOfficeEvents } from 'company/api/calendar-events';
import TemplateTable from 'company/entities/Template/TemplateTable';
import { FacilityContext } from 'core/context/facility.context';
import { ApiQuery } from 'core/model/interface';
import { TableActionProps } from 'core/PageBuilder/CareGoTable';
import { forwardRef, useContext, useEffect, useRef, useState } from 'react';
import {
  OOO_ENTITY_NAME,
  outOfOfficeFields,
  outOfOfficeFormFields,
  OutOfOfficeModel,
  outOfOfficeTableFields,
} from './CalendarEventModel';
import CalendarEventForm from './CalendarEventForm';
import { ServiceProviderModel } from '../ServiceProvider/ServiceProviderModel';

type Props = {
  visibleColumns?: string[];
  tableRef?: any;
  tableAction?: TableActionProps;
  outOfOfficeEvent?: OutOfOfficeModel;
  callBackAfterSubmit?: () => void;
  serviceProvider?: ServiceProviderModel;
};

export interface OutOfOfficeApiQuery extends ApiQuery {
  serviceProviderId?: number;
  startDate?: Date;
  endDate?: Date;
}

const CalendarEventTableOOO = forwardRef((props: Props, ref) => {
  const { outOfOfficeEvent, tableRef, visibleColumns, tableAction, callBackAfterSubmit, serviceProvider } = props;
  const { facility } = useContext(FacilityContext);
  const [selectedRow, setSelectedRow] = useState<OutOfOfficeModel>();

  const backupRef: any = useRef();
  const mainRef: any = tableRef ?? backupRef;

  const refreshTable = () => {
    if (mainRef.current) {
      mainRef.current.refreshTable();
    } else {
      console.error('tableRef is not assigned correctly');
    }
  };

  const getData = (query: OutOfOfficeApiQuery) => {
    if (!serviceProvider) return getAllOutOfOfficeEvents(facility.id, query);
    return getAllOutOfOfficeEvents(facility.id, { ...query, serviceProviderId: serviceProvider.doctor_id });
  };

  function handleDeleteOOOEvent() {
    if (!selectedRow) return;
    return deleteOOFEvent(facility.id, selectedRow.id);
  }

  return (
    <TemplateTable
      ref={mainRef}
      getData={getData}
      selectedRow={selectedRow}
      handleDelete={handleDeleteOOOEvent}
      setSelectedRow={setSelectedRow}
      entityName={OOO_ENTITY_NAME}
      fields={outOfOfficeFields}
      visibleColumns={outOfOfficeTableFields}
      templateForm={
        <CalendarEventForm
          serviceProvider={serviceProvider}
          outOfOffice
          outOfOfficeEvent={selectedRow}
          updateOutOfOffice={!!selectedRow}
          callbackAfterSubmit={() => {
            callBackAfterSubmit && callBackAfterSubmit();
            refreshTable();
            mainRef.current?.closeFormModal();
          }}
        />
      }
    />
  );
});

export default CalendarEventTableOOO;
