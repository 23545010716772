import { EntityFields } from 'company/entities/utils';
import * as yup from 'yup';

export const TOPUP_ENTITY_NAME = 'TopUp';

export type ResourceType = 'storage' | 'users' | 'patients' | 'credits';

export interface TopUpModel {
  resource_type: string;
  amount: number;
  price_per_unit: number;
  total_price: number;
}

export interface TopUpInput {
  resource_type: string;
  amount: number;
}

export const topupInitialValues: TopUpInput = {
  resource_type: 'storage',
  amount: 1,
};

export const create_topup_schema = yup.object().shape({
  resource_type: yup
    .string()
    .oneOf(['storage', 'users', 'patients', 'credits'], 'Invalid resource type')
    .required('Resource type is required'),
  amount: yup.number().required('Amount is required').min(1, 'Must be at least 1'),
});

export const update_topup_schema = create_topup_schema;

export const topupFields: EntityFields[] = [
  {
    fieldName: 'resource_type',
    displayName: 'Resource Type',
    type: 'select',
    options: [
      { key: 'Storage Space', value: 'storage' },
      { key: 'User Accounts', value: 'users' },
      { key: 'Patient Slots', value: 'patients' },
      { key: 'SMS Credits', value: 'credits' },
    ],
    hidden: true,
  },
  {
    fieldName: 'amount',
    displayName: 'Amount',
    type: 'number',
    span: 4,
  },
];

export const topupFormFields: string[] = ['amount'];

export const updateTopupFormFields: string[] = ['resource_type', 'amount'];
