import { Box, Tooltip, Typography, useTheme } from '@mui/material';
import { ConfirmationDialog, CustomForm, CustomIconButton, CustomModal, RegularButton } from 'core/components';
import { ContainerColumn, ContainerRow } from 'core/components/containers';
import { DISCOUNT_TYPE, DROPDOWN_FIELD, FieldInput } from 'core/model/interface';
import { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { edit_price_schema, pos_payment_schema } from 'company/model/schema';
import {
  formatCurrency,
  formatNumber,
  formatNumberDecimal,
  getDiscountAmount,
  getLineItemDiscountedPrice,
  tranformFormErrors,
} from 'core/utils';

import AccessibleOutlinedIcon from '@mui/icons-material/AccessibleOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { CompanyAppointmentModel } from 'company/entities/modules/ClinicManagement/Appointment/AppointmentModel';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';
import ElderlyWomanOutlinedIcon from '@mui/icons-material/ElderlyWomanOutlined';
import { FacilityContext } from 'core/context/facility.context';
import { FormikHelpers } from 'formik';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import OrderDetails from 'core/components/OrderDetails';
import { OrderProps } from '..';
import POSPaymentSuccessModal from './POSPaymentSuccessModal';
import { POSProps } from '..';
import { PatientModel } from 'company/entities/modules/ClinicManagement/Patient/PatientModel';
import PatientSearchDropdown from 'company/components/dropdown/PatientSearchDropdown';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import { SIZES } from 'theme/constants';
import { UserContext } from 'core/context/user.context';
import ViewInvoiceClick from 'company/screens/RegistrationBoard/components/ViewInvoiceClick';
import { createPOS } from 'company/api/patient-billing';
import { getPatient } from 'company/api/patient';
import { isDevMode } from 'core/components/HideOrShowComponent';
import styled from 'styled-components';
import { tokens } from 'theme/theme';
import { updateInvoiceItems } from 'company/api/invoice-items';
import { useSnackbar } from 'notistack';

export type UserInput = {
  patient_id: number | string;
  payment: number | string;
  payment_method?: string;
  issue_date: Date;
  payment_difference?: string;
  total?: number;
  corporate_client_id?: string;
  hmo_id?: string;
  hmo_card_number?: string;
  approval_code?: string;
  approval_code_2?: string;
  approval_code_3?: string;
  approval_code_4?: string;
  approval_code_5?: string;
  approved_by?: string;
  approved_by_2?: string;
  approved_by_3?: string;
  approved_by_4?: string;
  approved_by_5?: string;

  diagnosis?: string;
  service_provider_id?: any;
  link_hmo?: boolean;
  link_corporate_client?: boolean;
  change_amount?: string;
};

interface UserFieldInput extends FieldInput {
  field_name?: keyof UserInput;
}

interface CartProps {
  orders: OrderProps[];
  setOrders: Dispatch<SetStateAction<OrderProps[]>>;
  afterPayment?: (orders: OrderProps[], invoiceId: number) => void;
  fromRunningInvoice?: boolean;
  fromCreateInvoice?: boolean;
  fromAppointmentCreateInvoice?: boolean;
  fromUpdateInvoiceItems?: boolean;
  removeFromCart?: (item: OrderProps) => void;
  appointment?: CompanyAppointmentModel;
  setOpenInvoiceItemModal?: any;
  initialOrders?: OrderProps[];
  refreshInvoiceDetails?: () => void;
}
type CombinedProps = CartProps & POSProps;

const ServicePackageDetails: React.FC<{ order: OrderProps }> = ({ order }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  if (order.item_type !== 'service-package' || !order.included_services) {
    return null;
  }

  const services =
    typeof order.included_services === 'string' ? JSON.parse(order.included_services) : order.included_services;

  return (
    <Box sx={{}}>
      {order.included_services.map((service) => (
        <Typography
          key={service.id}
          variant="caption"
          sx={{
            display: 'block',
            color: colors.text,
            fontSize: '11px',
            lineHeight: '16px',
            fontWeight: 600,
          }}
        >
          • {service.service_name}
        </Typography>
      ))}
    </Box>
  );
};

const Cart: React.FC<CombinedProps> = ({
  orders,
  setOrders,
  afterPayment,
  fromRunningInvoice,
  fromCreateInvoice,
  fromAppointmentCreateInvoice,
  removeFromCart,
  appointment,
  fromUpdateInvoiceItems = false,
  setOpenInvoiceItemModal,
  initialOrders,
  refreshInvoiceDetails,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { facility } = useContext(FacilityContext);
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedPatient, setSelectedPatient] = useState<number>(0);
  const [openCancelConfirmation, setOpenCancelConfirmation] = useState<boolean>(false);
  const [openPaymentModal, setOpenPaymentModal] = useState<boolean>(false);
  const [editPriceIndex, setEditPriceIndex] = useState<number>(0);
  const [openEditPriceModal, setOpenEditPriceModal] = useState<boolean>(false);
  const [editPriceModalHeader, setEditPriceModalHeader] = useState<string>('');
  const [openPayModal, setOpenPayModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [patient, setPatient] = useState<PatientModel>();
  const [paymentData, setPaymentData] = useState<any>();
  const [openViewInvoice, setOpenViewInvoice] = useState<boolean>(false);
  const [invoiceId, setInvoiceId] = useState<number>();
  const [editingPriceIndex, setEditingPriceIndex] = useState<number | null>(null);
  const [saveItemsLoading, setSaveItemsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (appointment) {
      setSelectedPatient(appointment.patient_id);
    } else {
      const savedPatient = localStorage.getItem('selectedPatient');
      if (savedPatient !== null) {
        setSelectedPatient(parseInt(savedPatient));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment]);

  useEffect(() => {
    if (!initialOrders) return;
    const patientID = initialOrders[0]?.patientId;
    if (patientID) {
      setSelectedPatient(patientID);
      localStorage.removeItem('selectedPatient');
      localStorage.setItem('selectedPatient', String(initialOrders[0].patientId));
    }
  }, [initialOrders, orders]);

  const discountFields: FieldInput[] = [
    {
      field_name: 'discount_type',
      display_name: 'Discount Type',
      type: 'radiogroup',
      span: 4,
      options: [
        { key: 'Discount Amount', value: DISCOUNT_TYPE.NEW_UNIT_PRICE },
        { key: 'Discount Percentage', value: DISCOUNT_TYPE.PERCENTAGE },
      ],
    },
    {
      field_name: 'discount_amount',
      display_name: 'Discount Amount',
      span: 4,
      hiddenBasedOnOtherField: (data) => data.discount_type !== DISCOUNT_TYPE.NEW_UNIT_PRICE,
    },
    {
      field_name: 'discount_percentage',
      display_name: 'Discount Percentage (%)',
      span: 4,
      hiddenBasedOnOtherField: (data) => data.discount_type !== DISCOUNT_TYPE.PERCENTAGE,
    },
  ];

  const paymentFields: UserFieldInput[] = [
    { field_name: 'issue_date', display_name: 'Issue Date', type: 'datetime' },
    {
      field_name: 'payment',
      display_name: 'Payment Amount',
      type: 'text',
      span: 2,
    },
    {
      field_name: 'payment_method',
      display_name: 'Payment Method',
      type: 'select',
      options: [
        { key: 'Cash', value: 'cash' },
        { key: 'HMO', value: 'hmo' },
        { key: 'Bank Transfer', value: 'bank' },
        { key: 'Gcash', value: 'gcash' },
        { key: 'Maya', value: 'maya' },
        { key: 'Debit', value: 'debit' },
        { key: 'Credit', value: 'credit' },
        { key: 'P.O.', value: 'po' },
      ],
      span: 2,
      onChange: (value: string, setFieldValue: any) => {
        if (value === 'hmo') {
          setFieldValue('payment', 0);
        } else {
          setFieldValue('payment', formatNumberDecimal(totalAmount));
        }
      },
    },
    {
      field_name: 'service_provider_id',
      display_name: 'Service Provider',
      type: DROPDOWN_FIELD.SERVICE_PROVIDER,
      optional: true,
      multiple: true,
    },
    // {
    //   component: <Box></Box>,
    //   type: 'component',
    //   span: 2,
    // },
    {
      field_name: 'link_corporate_client',
      display_name:
        'Link Transaction to Corporate Client' + (patient?.company_name ? ` (${patient?.company_name})` : ''),
      type: 'checkbox',
      span: 4,
    },
    {
      field_name: 'corporate_client_id',
      display_name: 'Corporate Client',
      type: DROPDOWN_FIELD.CORPORATE_CLIENT,
      hiddenBasedOnOtherField: (data) => !data?.link_corporate_client,
      span: 4,
    },
    // {
    //   field_name: 'link_hmo',
    //   display_name: 'Link Transaction to HMO' + (patient?.hmo_name ? ` (${patient?.hmo_name})` : ''),
    //   type: 'checkbox',
    //   span: 4,
    // },
    {
      field_name: 'hmo_id',
      display_name: 'HMO',
      type: DROPDOWN_FIELD.HMO,
      hiddenBasedOnOtherField: (data) => data?.payment_method !== 'hmo',
      disabled: !!patient?.hmo_id,
      optional: false,
    },
    {
      field_name: 'hmo_card_number',
      display_name: 'HMO Card Number',
      hiddenBasedOnOtherField: (data) => data?.payment_method !== 'hmo' || !data?.hmo_id,
      optional: true,
      disabled: !!patient?.hmo_card_number,
    },

    { type: 'divider', span: 4, hiddenBasedOnOtherField: (data) => data?.payment_method !== 'hmo' || !data?.hmo_id },
    {
      type: 'component',
      hiddenBasedOnOtherField: (data) => data?.payment_method !== 'hmo' || !data?.hmo_id,
      component: (
        <Typography sx={{ fontWeight: '600', color: colors.accent }}>
          You can add up to 5 LOA codes and approver
        </Typography>
      ),
    },
    {
      type: 'component',
      hiddenBasedOnOtherField: (data) => data?.payment_method !== 'hmo' || !data?.hmo_id,
      span: 2,
      component: <Typography sx={{ fontWeight: '600' }}>LOA Approval Code</Typography>,
    },
    {
      type: 'component',
      hiddenBasedOnOtherField: (data) => data?.payment_method !== 'hmo' || !data?.hmo_id,
      span: 2,
      component: <Typography sx={{ fontWeight: '600' }}>LOA Approved By</Typography>,
    },
    {
      field_name: 'approval_code',
      display_name: 'LOA Approval Code',
      hiddenBasedOnOtherField: (data) => data?.payment_method !== 'hmo' || !data?.hmo_id,
      optional: true,
      onChange: (value, setFieldValue) => {
        if (!value) {
          setFieldValue && setFieldValue('approval_code_2', '');
          setFieldValue && setFieldValue('approval_code_3', '');
          setFieldValue && setFieldValue('approval_code_4', '');
          setFieldValue && setFieldValue('approval_code_5', '');
        }
      },
    },

    {
      field_name: 'approved_by',
      display_name: 'LOA Approved By',
      hiddenBasedOnOtherField: (data) => data?.payment_method !== 'hmo' || !data?.hmo_id,
      optional: true,
      onChange: (value, setFieldValue) => {
        if (!value) {
          setFieldValue && setFieldValue('approved_by_2', '');
          setFieldValue && setFieldValue('approved_by_3', '');
          setFieldValue && setFieldValue('approved_by_4', '');
          setFieldValue && setFieldValue('approved_by_5', '');
        }
      },
    },
    {
      field_name: 'approval_code_2',
      display_name: 'LOA Approval Code 2',
      hiddenBasedOnOtherField: (data) => data?.payment_method !== 'hmo' || !data?.hmo_id || !data.approval_code,
      optional: true,
      onChange: (value, setFieldValue) => {
        if (!value) {
          setFieldValue && setFieldValue('approval_code_3', '');
          setFieldValue && setFieldValue('approval_code_4', '');
          setFieldValue && setFieldValue('approval_code_5', '');
        }
      },
    },
    {
      field_name: 'approved_by_2',
      display_name: 'LOA Approved By 2',
      hiddenBasedOnOtherField: (data) => data?.payment_method !== 'hmo' || !data?.hmo_id || !data.approval_code,
      optional: true,
      onChange: (value, setFieldValue) => {
        if (!value) {
          setFieldValue && setFieldValue('approved_by_3', '');
          setFieldValue && setFieldValue('approved_by_4', '');
          setFieldValue && setFieldValue('approved_by_5', '');
        }
      },
    },
    {
      field_name: 'approval_code_3',
      display_name: 'LOA Approval Code 3',
      hiddenBasedOnOtherField: (data) =>
        data?.payment_method !== 'hmo' || !data?.hmo_id || !data.approval_code || !data.approval_code_2,
      optional: true,
      onChange: (value, setFieldValue) => {
        if (!value) {
          setFieldValue && setFieldValue('approval_code_4', '');
          setFieldValue && setFieldValue('approval_code_5', '');
        }
      },
    },
    {
      field_name: 'approved_by_3',
      display_name: 'LOA Approved By 3',
      hiddenBasedOnOtherField: (data) =>
        data?.payment_method !== 'hmo' || !data?.hmo_id || !data.approval_code || !data.approval_code_2,
      optional: true,
      onChange: (value, setFieldValue) => {
        if (!value) {
          setFieldValue && setFieldValue('approved_by_4', '');
          setFieldValue && setFieldValue('approved_by_5', '');
        }
      },
    },
    {
      field_name: 'approval_code_4',
      display_name: 'LOA Approval Code 4',
      hiddenBasedOnOtherField: (data) =>
        data?.payment_method !== 'hmo' ||
        !data?.hmo_id ||
        !data.approval_code ||
        !data.approval_code_2 ||
        !data.approval_code_3,
      optional: true,
      onChange: (value, setFieldValue) => {
        if (!value) {
          setFieldValue && setFieldValue('approval_code_5', '');
        }
      },
    },
    {
      field_name: 'approved_by_4',
      display_name: 'LOA Approved By 4',
      hiddenBasedOnOtherField: (data) =>
        data?.payment_method !== 'hmo' ||
        !data?.hmo_id ||
        !data.approval_code ||
        !data.approval_code_2 ||
        !data.approval_code_3,
      optional: true,
      onChange: (value, setFieldValue) => {
        if (!value) {
          setFieldValue && setFieldValue('approved_by_5', '');
        }
      },
    },
    {
      field_name: 'approval_code_5',
      display_name: 'LOA Approval Code 5',
      hiddenBasedOnOtherField: (data) =>
        data?.payment_method !== 'hmo' ||
        !data?.hmo_id ||
        !data.approval_code ||
        !data.approval_code_2 ||
        !data.approval_code_3 ||
        !data.approval_code_4,
      optional: true,
    },
    {
      field_name: 'approved_by_5',
      display_name: 'LOA Approved By 5',
      hiddenBasedOnOtherField: (data) =>
        data?.payment_method !== 'hmo' ||
        !data?.hmo_id ||
        !data.approval_code ||
        !data.approval_code_2 ||
        !data.approval_code_3 ||
        !data.approval_code_4,
      optional: true,
    },
    { type: 'divider', span: 4, hiddenBasedOnOtherField: (data) => data?.payment_method !== 'hmo' || !data?.hmo_id },
    {
      field_name: 'diagnosis',
      display_name: 'Diagnosis',
      hiddenBasedOnOtherField: (data) => data?.payment_method !== 'hmo' || !data?.hmo_id,
      type: 'text',
      multiline: true,
      optional: true,
      span: 4,
      rows: 3,
    },
    {
      field_name: 'payment_difference',
      type: 'component',
      hiddenBasedOnOtherField: (data) => parseFloat(data.payment) >= parseFloat(data.total),
      displayComponentBasedOnFormFields: (data) => (
        <Typography>Payment Difference: {formatCurrency(parseFloat(data.total) - parseFloat(data.payment))}</Typography>
      ),
    },
    {
      field_name: 'change_amount',
      type: 'component',
      hiddenBasedOnOtherField: (data) => parseFloat(data.payment) <= parseFloat(data.total),
      displayComponentBasedOnFormFields: (data) => (
        <Typography>Change: {formatCurrency(parseFloat(data.payment) - parseFloat(data.total))}</Typography>
      ),
    },
  ];

  // const totalAmount = useMemo(() => {
  //   let totalAmount = orders
  //     .map((order) => {
  //       return Number(order.unit_price) * order.quantity;
  //     })
  //     .reduce((prev, curr) => prev + curr, 0);
  //   return parseFloat(totalAmount.toFixed(2));
  // }, [orders]);

  function handleUpdateInvoiceItems() {
    if (!initialOrders) return;
    const invoiceID = initialOrders[0].invoice_id;
    setSaveItemsLoading(true);
    invoiceID &&
      updateInvoiceItems(facility.id, invoiceID, orders)
        .then((res) => {
          setOpenInvoiceItemModal(false);
          refreshInvoiceDetails && refreshInvoiceDetails();
        })
        .finally(() => {
          setSaveItemsLoading(false);
        });
  }

  const totalAmount = useMemo(() => {
    return orders.reduce((total, order) => {
      return total + (getLineItemDiscountedPrice(order, true) as number);
    }, 0);
  }, [orders]);

  const paymentFormInitialValues: UserInput = useMemo(
    () => ({
      patient_id: selectedPatient ? selectedPatient : '',
      payment_method: 'cash',
      payment: formatNumberDecimal(totalAmount),
      issue_date: new Date(),
      total: totalAmount,
      corporate_client_id: patient?.corporate_client_id ? patient?.corporate_client_id + '' : '',
      hmo_id: patient?.hmo_id ? patient?.hmo_id + '' : '',
      hmo_card_number: patient?.hmo_id ? patient?.hmo_card_number : '', //change to card number
      approval_code: '',
      approved_by: '',
      diagnosis: '',
      service_provider_id: [],
    }),
    [selectedPatient, totalAmount, patient]
  );

  const fetchPatientData = (): void => {
    setPatient(undefined);
    getPatient(facility.id, selectedPatient)
      .then((response) => {
        const resPatient = response.data;
        if (resPatient) {
          setPatient(resPatient);
        } else {
          console.error('No patient data found.');
        }
      })
      .catch((error) => {
        console.error('Error fetching patient data:', error);
      });
  };

  useEffect(() => {
    if (facility && selectedPatient) fetchPatientData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPatient, facility]);

  const updateOrders = (order: any[]) => {
    setOrders(order);
    if (!initialOrders) {
      const ordersWithStock = order.map((item) => ({
        ...item,
        initial_quantity_in_stock: item.quantity_in_stock,
      }));
      localStorage.setItem('orders', JSON.stringify(ordersWithStock));
    }
  };

  useEffect(() => {
    if (!orders.length) return;

    const updatedOrders = [...orders];
    updatedOrders.forEach((order) => {
      if (order.item_type === 'product' && !order.made_to_order) {
        const stockLevel = order.initial_quantity_in_stock ?? order.quantity_in_stock;
        order.quantity_in_stock = stockLevel - order.quantity;

        const relatedOrders = updatedOrders.filter((o) => o.item_id === order.item_id && o.item_type === 'product');
        relatedOrders.forEach((relatedOrder) => {
          relatedOrder.quantity_in_stock = order.quantity_in_stock;
        });
      }
    });

    updateOrders(updatedOrders);
  }, [orders.length]);

  const handleDelete = (index: number) => {
    const updatedOrders = [...orders];
    const item = updatedOrders[index];
    updatedOrders.splice(index, 1);
    updateQuantityInStock(updatedOrders, item, item.quantity);
    updateOrders(updatedOrders);

    if (updatedOrders.length === 0) {
      setSelectedPatient(0);
      localStorage.removeItem('selectedPatient');
      removeFromCart && removeFromCart(item);
    }
  };

  const updateQuantityInStock = (updatedOrders: OrderProps[], item: OrderProps, value: number) => {
    if (item.item_type === 'product' && !item.made_to_order) {
      const existingOrders = updatedOrders.filter(
        (order) => order.item_id === item.item_id && order.item_type === 'product'
      );

      if (existingOrders.length) {
        const newQuantityInStock = existingOrders[0].quantity_in_stock;
        existingOrders.forEach((order) => (order.quantity_in_stock = newQuantityInStock + value));
      }
    }
  };

  const handleSubtract = (index: number) => {
    const updatedOrders = [...orders];
    const item = updatedOrders[index];
    if (item.quantity > 1) {
      item.quantity -= 1;
      updateQuantityInStock(updatedOrders, item, 1);
      updateOrders(updatedOrders);
    }
  };

  const handleAdd = (index: number) => {
    const updatedOrders = [...orders];
    const item = updatedOrders[index];

    if (
      item.item_type === 'service-package' ||
      item.item_type === 'service' ||
      item.made_to_order ||
      (item.item_type === 'product' && item.quantity_in_stock > 0)
    ) {
      item.quantity += 1;

      // Only update stock for regular products
      if (item.item_type === 'product' && !item.made_to_order) {
        updateQuantityInStock(updatedOrders, item, -1);
      }

      updateOrders(updatedOrders);
    }
  };

  const handleRemoveDiscount = (index: number) => {
    const updatedOrders = [...orders];
    updatedOrders[index].discount = undefined;
    updatedOrders[index].unit_price = parseFloat(updatedOrders[index].original_price);

    updateOrders(updatedOrders);
  };

  const handleApplyDiscountClick = (index: number) => {
    setEditPriceIndex(index);

    const name = orders[index].item_name;
    const header = 'Apply Discount - ' + name;
    setEditPriceModalHeader(header);
    setOpenEditPriceModal(true);
  };

  const handleEditPrice = async (data: any) => {
    const updatedOrders = [...orders];
    const originalPrice = Number(updatedOrders[editPriceIndex].original_price);

    if (data.discount_type === DISCOUNT_TYPE.NEW_UNIT_PRICE) {
      let discountAmount = Number(data.discount_amount);
      discountAmount = Math.min(originalPrice, discountAmount);

      if (discountAmount === 0) {
        handleRemoveDiscount(editPriceIndex);
        setOpenEditPriceModal(false);
        return;
      } else {
        updatedOrders[editPriceIndex].unit_price = originalPrice - discountAmount;
        updatedOrders[editPriceIndex].discount = { discount_type: DISCOUNT_TYPE.NEW_UNIT_PRICE };
      }
    } else if (data.discount_type === DISCOUNT_TYPE.PERCENTAGE) {
      const discountPercentage = Number(data.discount_percentage);
      updatedOrders[editPriceIndex].unit_price = originalPrice * (1 - discountPercentage / 100);
      updatedOrders[editPriceIndex].discount = {
        discount_type: DISCOUNT_TYPE.PERCENTAGE,
        percentage: discountPercentage,
      };
    }

    // Round the final unit_price to a reasonable number of decimal places
    updatedOrders[editPriceIndex].unit_price = Number(updatedOrders[editPriceIndex].unit_price.toFixed(4));

    updateOrders(updatedOrders);
    setOpenEditPriceModal(false);
  };

  const handleOrder = async (data: any, formikHelpers?: FormikHelpers<any>) => {
    const patientIds = [data.patient_id];
    data.patients = JSON.stringify(patientIds);

    const formattedTotal = formatNumberDecimal(totalAmount);
    const paymentAmount = Number(data.payment);

    if (paymentAmount > formattedTotal) {
      data.change = paymentAmount - formattedTotal;
      data.payment = formattedTotal;
    } else {
      data.change = 0;
    }

    data.orders = orders.map((order) => ({
      ...order,
      auto_added: order.auto_added ?? false,
    }));

    if (appointment) {
      data.appointment_id = appointment.id;
    }

    if (data?.service_provider_id) {
      const providerIds = data?.service_provider_id.map((obj: any) => obj.value);
      data.providerIDs = providerIds;
    }

    if (data?.payment_method !== 'hmo') {
      data.hmo_id = undefined;
      data.hmo_card_number = undefined;
      data.approval_code = undefined;
      data.approved_by = undefined;
      data.diagnosis = undefined;
    }

    if (!data?.link_corporate_client) {
      data.corporate_client_id = undefined;
    }

    setPaymentData(data);
    setLoading(true);
    createPOS(facility.id, data)
      .then((res: any) => {
        successFormSubmit(res, formikHelpers);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };

  const successFormSubmit = (res: any, formikHelpers?: FormikHelpers<any>) => {
    if (res.status === 200) {
      enqueueSnackbar('Order successfully created!', { variant: 'success' });
      setOpenPaymentModal(false);

      const newInvoiceId = res.data.invoice.id;
      setInvoiceId(newInvoiceId);
      afterPayment && afterPayment(orders, newInvoiceId);
      setLoading(false);
      updateOrders([]);
      setOpenPayModal(true);
      setSelectedPatient(0);
      localStorage.removeItem('orders');
      localStorage.removeItem('selectedPatient');
    } else if (res.response.status === 400 && formikHelpers) {
      formikHelpers.setErrors(tranformFormErrors(res.response.data));
    }
  };
  const handleSCPWDQuickDiscount = (index: number, apply?: boolean) => {
    orders[index].sc_pwd_discounted = apply;
    updateOrders([...orders]);
  };

  const togglePriceEdit = (index: number) => {
    setEditingPriceIndex(editingPriceIndex === index ? null : index);
  };

  // const handlePriceChange = (index: number, newPrice: string) => {
  //   const updatedOrders = [...orders];
  //   updatedOrders[index].original_price = newPrice;
  //   updatedOrders[index].unit_price = Number(newPrice); // Reset unit_price to match original_price
  //   updatedOrders[index].discount = undefined; // Remove any existing discount
  //   updateOrders(updatedOrders);
  // };

  const handlePriceChange = (index: number, newPrice: string) => {
    const updatedOrders = [...orders];
    const order = updatedOrders[index];
    const newPriceNum = Number(newPrice);

    order.original_price = newPrice;

    // if percent dont remove
    if (order.discount && order.discount.discount_type === DISCOUNT_TYPE.PERCENTAGE) {
      if (order.discount.percentage) {
        const discountPercentage = order.discount.percentage;
        order.unit_price = newPriceNum * (1 - discountPercentage / 100);
      }
    } else {
      // if fixed remove
      order.unit_price = newPriceNum;
      if (order.discount && order.discount.discount_type === DISCOUNT_TYPE.NEW_UNIT_PRICE) {
        order.discount = undefined;
      }
    }

    //
    // if (order.sc_pwd_discounted) {
    //   order.unit_price = newPriceNum * 0.8;
    // }

    updateOrders(updatedOrders);
  };

  return (
    <Box
      sx={{ backgroundColor: colors.light_blue_background, padding: SIZES.padding, borderRadius: SIZES.borderRadiusS }}
    >
      <Typography color={colors.accent} variant="body1" fontSize="25px" fontWeight="600">
        {appointment ? 'Orders' : 'Cart'}
      </Typography>
      {!orders.length ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <img src="/assets/cart.svg" alt="" />
        </Box>
      ) : (
        <>
          <Box
            marginTop="10px"
            sx={{ overflowY: 'auto', height: fromCreateInvoice ? 'calc(100vh - 510px)' : 'calc(100vh - 470px)' }}
          >
            <Box paddingRight="10px" display="flex" flexDirection="column" gap={SIZES.padding}>
              {orders.map((order, index) => (
                <Box>
                  <Box
                    key={index}
                    display="grid"
                    gridTemplateColumns={'2fr 1fr 1fr'}
                    width="100%"
                    gap={SIZES.paddingS}
                    alignItems="flex-start"
                  >
                    {/* Item Column */}
                    <Box display="flex" gap={SIZES.paddingS} width="100%" alignItems="flex-start">
                      <Box color={colors.redAccent} border={'1px solid'} borderRadius={'8px'}>
                        <CustomIconButton
                          color="error"
                          icon={<DeleteOutlinedIcon sx={{ fontSize: '18px' }} />}
                          onClick={() => handleDelete(index)}
                        />
                      </Box>
                      <Box>
                        <Tooltip
                          placement="top"
                          arrow
                          title={order.item_name}
                          // style={{ width: '100%' }}
                        >
                          <Typography
                            fontSize="15px"
                            fontWeight={600}
                            component="div"
                            sx={{
                              whiteSpace: 'nowrap',
                              width: '8vw',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {order.item_name}
                          </Typography>
                        </Tooltip>
                        {order.item_type === 'service-package' && <ServicePackageDetails order={order} />}
                        {order.item_type === 'product' && !order.made_to_order && (
                          <Typography fontSize="11px" fontWeight="500">
                            Available: {formatNumber(order.quantity_in_stock, 0)}
                          </Typography>
                        )}
                        {order.item_type === 'product' && !order.show_in_invoice && order.auto_added && (
                          <Typography fontSize="11px" fontWeight="400">
                            Hidden from invoice
                          </Typography>
                        )}

                        <Box display={'flex'} width="100%" flexDirection={'column'}>
                          <Box>
                            <Box display={'flex'} width="100%" alignItems={'center'}>
                              {editingPriceIndex === index ? (
                                <input
                                  type="number"
                                  value={order.original_price}
                                  onChange={(e) => handlePriceChange(index, e.target.value)}
                                  onBlur={() => togglePriceEdit(index)}
                                  autoFocus
                                  style={{
                                    width: '80px',
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    color: colors.primary,
                                    border: `1px solid ${colors.primary}`,
                                    borderRadius: '4px',
                                    padding: '4px 6px',
                                  }}
                                />
                              ) : (
                                <Typography color={colors.primary} fontWeight="600" fontSize="14px">
                                  {formatCurrency(order.original_price)}
                                </Typography>
                              )}
                              {editingPriceIndex !== index ? (
                                <CustomIconButton
                                  size="small"
                                  iconSize="16px"
                                  color="primary"
                                  onClick={() => togglePriceEdit(index)}
                                  tooltip="Edit Price"
                                  icon={<ModeEditOutlineOutlinedIcon />}
                                />
                              ) : (
                                <CustomIconButton
                                  size="small"
                                  iconSize="16px"
                                  color="primary"
                                  tooltip="Save Price"
                                  onClick={() => undefined}
                                  icon={<CheckOutlinedIcon />}
                                />
                              )}
                            </Box>

                            <Box display="flex" gap={SIZES.paddingS} alignItems="center">
                              <RegularButton
                                variant="outlined"
                                size="small"
                                color="primary"
                                label="Discount"
                                startIcon={<DiscountOutlinedIcon />}
                                onClick={() => handleApplyDiscountClick(index)}
                                disabled={parseFloat(order.original_price) <= 0 || editingPriceIndex === index}
                                styles={{ padding: '3px 7px' }}
                              />

                              <RegularButton
                                variant="outlined"
                                size="small"
                                color="primary"
                                label="SC/PWD"
                                startIcon={<ElderlyWomanOutlinedIcon />}
                                endIcon={<AccessibleOutlinedIcon />}
                                onClick={() => handleSCPWDQuickDiscount(index, true)}
                                disabled={
                                  parseFloat(order.original_price) <= 0 ||
                                  order.sc_pwd_discounted ||
                                  editingPriceIndex === index
                                }
                                styles={{ padding: '3px 7px' }}
                              />
                            </Box>
                          </Box>

                          {!!order.made_to_order && (
                            <Typography fontSize="11px" fontWeight="500">
                              Made to Order
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </Box>

                    {/* Qty Column */}
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <CustomIconButton
                        disabled={order.quantity === 1}
                        icon={<RemoveOutlinedIcon />}
                        onClick={() => handleSubtract(index)}
                      />

                      <input
                        type="text"
                        inputMode="numeric"
                        value={order.quantity}
                        disabled
                        onChange={(e) => {
                          const newQuantity = parseInt(e.target.value);
                          if (!isNaN(newQuantity)) {
                            if (newQuantity <= order.quantity_in_stock) {
                              // handleQuantityChange(index, newQuantity);
                            } else {
                              // handleQuantityChange(index, order.quantity_in_stock);
                            }
                          }
                        }}
                        style={{
                          textAlign: 'center',
                          color: colors.accent,
                          border: '1px solid ' + colors.accent,
                          fontSize: '15px',
                          fontWeight: 600,
                          width: '40px',
                          padding: '12px 3px',
                          outline: 'none',
                          background: 'transparent',
                          borderRadius: SIZES.borderRadiusS,
                          WebkitAppearance: 'none',
                          MozAppearance: 'textfield',
                        }}
                        onWheel={(e) => e.preventDefault()}
                        min="1"
                        max={order.quantity_in_stock}
                      />

                      <CustomIconButton
                        disabled={order.item_type === 'product' && order.quantity_in_stock === 0}
                        icon={<AddOutlinedIcon />}
                        onClick={() => handleAdd(index)}
                      />
                    </Box>

                    {/* Total Column */}
                    <Box width="100%">
                      <Typography textAlign="right" fontWeight={600} mt="20px">
                        {getLineItemDiscountedPrice(order)}
                      </Typography>
                    </Box>
                  </Box>
                  {!!order.discount || order.sc_pwd_discounted ? (
                    <Box display="flex" alignItems="center" gap={SIZES.paddingS} paddingLeft="45px" mt="10px">
                      <Typography
                        color="gray"
                        fontWeight="500"
                        fontSize="13px"
                        // sx={{ textDecoration: 'line-through' }}
                      >
                        {getDiscountAmount(order, true)}
                      </Typography>

                      {order.discount && (
                        <Box display="flex" alignItems="center">
                          <Typography
                            fontWeight="500"
                            fontSize="13px"
                            display="flex"
                            alignItems="center"
                            gap="5px"
                            sx={{
                              backgroundColor: colors.light_blue_background_2,
                              paddingInline: SIZES.paddingS,
                              marginLeft: SIZES.paddingS,
                              borderRadius: '10px',
                            }}
                          >
                            {order.discount.discount_type === DISCOUNT_TYPE.PERCENTAGE
                              ? `${order.discount.percentage}% OFF - `
                              : `${formatCurrency(parseFloat(order.original_price) - order.unit_price)} - `}
                            Clinic <DiscountOutlinedIcon sx={{ fontSize: '12px' }} />
                          </Typography>

                          <CustomIconButton
                            color="primary"
                            tooltip="Remove Discount"
                            icon={<DeleteOutlinedIcon sx={{ fontSize: '16px' }} />}
                            onClick={() => handleRemoveDiscount(index)}
                          />
                        </Box>
                      )}

                      {order.sc_pwd_discounted && (
                        <Box display="flex" alignItems="center">
                          <Typography
                            fontWeight="500"
                            fontSize="13px"
                            display="flex"
                            alignItems="center"
                            gap="5px"
                            sx={{
                              backgroundColor: colors.light_blue_background_2,
                              paddingInline: SIZES.paddingS,
                              borderRadius: '10px',
                            }}
                          >
                            20% OFF - SC/PWD <DiscountOutlinedIcon sx={{ fontSize: '12px' }} />
                          </Typography>
                          <CustomIconButton
                            color="primary"
                            tooltip="Remove Discount"
                            icon={<DeleteOutlinedIcon sx={{ fontSize: '16px' }} />}
                            onClick={() => handleSCPWDQuickDiscount(index, false)}
                          />
                        </Box>
                      )}
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>
              ))}
            </Box>
          </Box>
          <Box>
            {isDevMode() && (
              <>
                {fromAppointmentCreateInvoice && (
                  <>
                    <Typography
                      variant="h5"
                      fontWeight={400}
                      fontSize="10px"
                      color={theme.palette.mode === 'dark' ? 'white' : 'black'}
                      mt={'10px'}
                    >
                      • Products linked will not be visible in the invoice (check if the product’s “Show in Invoice”
                      property is checked in Linked Products) <br />• Products linked with higher quantity than the
                      stock will not be visible in the cart.
                    </Typography>
                  </>
                )}

                <hr style={{ border: 0, borderTop: '2px dashed #888888', margin: '20px 0' }} />
              </>
            )}

            <TotalAmountContainer
              style={{
                background: colors.light_blue_background,
              }}
            >
              <ContainerColumn gap={SIZES.paddingS}>
                <Box display="flex" justifyContent={'space-between'}>
                  <Typography
                    variant="h3"
                    fontWeight={700}
                    fontSize="20px"
                    color={theme.palette.mode === 'dark' ? 'white' : 'black'}
                  >
                    Total
                  </Typography>
                  <Typography variant="h3" fontWeight={700} fontSize="20px" color={colors.accent}>
                    Php {formatNumber(totalAmount)}
                  </Typography>
                </Box>

                <Box sx={{ pt: SIZES.paddingS }}>
                  <PatientSearchDropdown
                    disabled={!!initialOrders}
                    handleChangeCallback={(value) => {
                      const patientId = value ? value.value : 0;
                      setSelectedPatient(patientId);
                      localStorage.setItem('selectedPatient', patientId.toString());
                    }}
                    initialValue={selectedPatient}
                  />
                </Box>

                {!fromUpdateInvoiceItems && (
                  <ContainerRow sx={{ mt: '10px' }}>
                    <RegularButton
                      variant="outlined"
                      onClick={() => setOpenCancelConfirmation(true)}
                      label="Cancel"
                      fullWidth
                    />
                    <RegularButton
                      onClick={() => setOpenPaymentModal(true)}
                      label={fromRunningInvoice || appointment ? 'Proceed to Billing' : 'Pay'}
                      fullWidth
                      disabled={!selectedPatient || user.remaining_storage <= 0}
                    />
                  </ContainerRow>
                )}

                {fromUpdateInvoiceItems && (
                  <ContainerRow sx={{ mt: '10px' }}>
                    <RegularButton
                      variant="outlined"
                      onClick={() => setOpenInvoiceItemModal(false)}
                      label="Cancel"
                      fullWidth
                    />
                    <RegularButton
                      onClick={() => handleUpdateInvoiceItems()}
                      label={'Save Items'}
                      fullWidth
                      loading={saveItemsLoading}
                    />
                  </ContainerRow>
                )}
                {fromCreateInvoice && (
                  <RegularButton
                    onClick={() => setOpenPaymentModal(true)}
                    label="Save as Draft"
                    fullWidth
                    disabled={!selectedPatient}
                    color="warning"
                  />
                )}
              </ContainerColumn>
            </TotalAmountContainer>
          </Box>
        </>
      )}

      <CustomModal header={editPriceModalHeader} open={openEditPriceModal} setOpen={setOpenEditPriceModal} width={500}>
        <Typography variant="h5" textAlign={'center'} padding={SIZES.padding}>
          This discount applies only to this item. If it's a fixed amount, the discount will be multiplied by the
          quantity to get the total discount. If it's a percentage discount, it will be applied to the item's price.
        </Typography>
        <CustomForm
          onSubmit={handleEditPrice}
          fields={discountFields}
          initialValues={{
            discount_type: orders?.[editPriceIndex]?.discount?.discount_type ?? DISCOUNT_TYPE.NEW_UNIT_PRICE,
            discount_amount: (() => {
              const originalPrice = Number(orders?.[editPriceIndex]?.original_price);
              const unitPrice = Number(orders?.[editPriceIndex]?.unit_price);
              const difference = originalPrice - unitPrice;
              return difference > 0 ? difference : '';
            })(),
            discount_percentage: orders?.[editPriceIndex]?.discount?.percentage ?? '',
          }}
          loading={loading}
          schema={edit_price_schema(Number(orders?.[editPriceIndex]?.original_price))}
          buttonWidth={'150px'}
          buttonPosition="center"
        />
      </CustomModal>

      <ConfirmationDialog
        title={'Do you wish to cancel?'}
        open={openCancelConfirmation}
        setOpen={setOpenCancelConfirmation}
        onConfirm={() => {
          updateOrders([]);
          localStorage.removeItem('orders');
          setSelectedPatient(0);
          localStorage.removeItem('selectedPatient');
        }}
        content={'Are you sure you want to cancel the order? This action cannot be undone.'}
        cancelButtonLabel={'No'}
      />

      <CustomModal header={`Payment - ${patient?.full_name}`} open={openPaymentModal} setOpen={setOpenPaymentModal}>
        <OrderDetails orders={orders} totalAmount={totalAmount} />

        {user?.user_group?.group_name !== 'COMPANY_DOCTOR' ? (
          <CustomForm
            onSubmit={handleOrder}
            fields={paymentFields}
            initialValues={paymentFormInitialValues}
            loading={loading}
            schema={pos_payment_schema(formatNumberDecimal(totalAmount))}
            buttonWidth={'157px'}
          />
        ) : (
          <Box display="flex" justifyContent="flex-end">
            <RegularButton
              label="Submit"
              onClick={() => handleOrder({ patient_id: appointment?.patient_id, issue_date: new Date() })}
            />
          </Box>
        )}
      </CustomModal>

      <POSPaymentSuccessModal
        data={paymentData}
        openModal={openPayModal}
        setOpenModal={setOpenPayModal}
        setViewDetailsClick={() => {
          setOpenPayModal(false);
          setOpenViewInvoice(true);
        }}
      />

      <ViewInvoiceClick fromPOS invoiceId={invoiceId} openModal={openViewInvoice} setOpenModal={setOpenViewInvoice} />
    </Box>
  );
};

export default Cart;

const TotalAmountContainer = styled.div`
  width: 100%;
  background: '#e4f3fa';
  border: 2px solid #239bd7;
  border-radius: 10px;
  padding: 20px;
`;
