import { Box, Divider, Typography, useMediaQuery } from '@mui/material';
import { FORM_MODE, FieldInput } from 'core/model/interface';
import {
  Fragment,
  ReactNode,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { debounce, evaluateDynamicExpression } from 'core/utils';

import { CompanyAppointmentModel } from 'company/entities/modules/ClinicManagement/Appointment/AppointmentModel';
import CustomCheckbox from '../CustomCheckbox';
import CustomCheckboxGroup from '../CustomCheckboxGroup';
import CustomDatePicker from '../CustomDatePicker';
import CustomDateTimePicker from '../CustomDatetimePicker';
import CustomRadioGroup from '../CustomRadioGroup';
import CustomSelectField from '../CustomSelectField';
import CustomTableForm from './CustomTableForm';
import CustomTextField from '../CustomTextField';
import CustomTimePicker from '../CustomTimePicker';
import DocumentRichText from 'company/screens/DocumentEditor/components/DocumentRichText';
import { FacilityContext } from 'core/context/facility.context';
import FileUpload from './FileUpload';
import { PatientModel } from 'company/entities/modules/ClinicManagement/Patient/PatientModel';
import SavingUpdates from './SavingUpdates';
import { WaitForFacility } from 'company/components';
import { autoSavePatientNote } from 'company/api/patient-notes';
import { getDoctor } from 'company/api/doctors';

type Props = {
  noteId?: number;
  appointment?: CompanyAppointmentModel;
  patient: PatientModel;
  fields: FieldInput[];
  responses: any;
};

type FieldContainerProps = {
  field: FieldInput;
  children: ReactNode;
};

const PatientRecordForm: React.FC<Props> = ({ noteId, appointment, patient, fields, responses }) => {
  const span = 12;
  const isNonMobile = useMediaQuery('(min-width:600px)');
  const gridColumn = `repeat(${span}, minmax(0, 1fr))`;
  const { facility } = useContext(FacilityContext);

  const [formFields, setFormFields] = useState<FieldInput[]>([]);
  const [provider, setProvider] = useState<any>();
  const componentRef: any = useRef();
  const inputRefs = useRef<any[]>([]);

  useEffect(() => {
    setFormFields(fields);
    inputRefs.current = inputRefs.current.slice(0, fields.length);
  }, [fields]);

  useEffect(() => {
    if (appointment?.provider_id) {
      getDoctor(appointment.provider_id).then((res) => setProvider(res.data));
    }
  }, [appointment]);

  const getDefaultSpan = useCallback(
    (type: string) =>
      [
        'subsection_header',
        'section_header',
        'group_header',
        'body_text',
        'divider',
        'component',
        'rich_text',
      ].includes(type)
        ? span
        : span / 2,
    []
  );

  const indexWithFormula = useMemo(() => {
    return fields.map((item, index) => (!item.formula ? -1 : index)).filter((idx) => idx >= 1);
  }, [fields]);

  const updateField = (field: FieldInput, value: any) => {
    if (Array.isArray(responses) || !responses) {
      responses = {};
    }
    responses[field.field_name] = value;
    indexWithFormula.forEach((idx) => {
      inputRefs.current[idx]?.refreshResponse();
    });
    autoUpdate();
  };

  const autoUpdate = debounce(async () => {
    try {
      componentRef.current?.setIsSaving(true);
      if (noteId) await autoSavePatientNote(facility.id, noteId, JSON.stringify(responses));
    } finally {
      setTimeout(() => {
        componentRef.current?.setIsSaving(false);
      }, 1000);
    }
  }, 1000);

  const FormFieldComponents = useMemo(() => {
    const FieldContainer: React.FC<FieldContainerProps> = ({ field, children }) => {
      return <Box sx={{ gridColumn: 'span ' + (field.span ?? getDefaultSpan(field.type as string)) }}>{children}</Box>;
    };
    return (
      <Fragment>
        {formFields?.map((field, index) => {
          //not yet used
          if (field.hidden || (field.hiddenBasedOnOtherField && field.hiddenBasedOnOtherField(responses))) {
            return false;
          }

          switch (field.type) {
            case 'divider':
              return (
                <FieldContainer field={field} key={index}>
                  <Divider />
                </FieldContainer>
              );

            case 'subsection_header':
            case 'section_header':
              return (
                <FieldContainer field={field} key={index}>
                  <Typography
                    variant={
                      field.type === 'subsection_header' ? 'h5' : field.type === 'section_header' ? 'h4' : undefined
                    }
                    fontWeight={'bold'}
                  >
                    {field.subsection_header ?? field.section_header}
                  </Typography>
                </FieldContainer>
              );

            case 'body_text':
              return (
                <FieldContainer field={field} key={index}>
                  <DocumentRichText
                    isBodyText
                    component={{ id: '', type: 'rich_text', content: field.body_text }}
                    mode={FORM_MODE.RESPOND}
                    halfWidth={field.span === span / 2}
                    patient={patient}
                    appointment={appointment}
                    provider={provider}
                  />
                </FieldContainer>
              );

            case 'select':
              return (
                <FieldContainer field={field} key={field.field_name}>
                  <TextField
                    field={field}
                    responses={responses}
                    ref={(el) => (inputRefs.current[index] = el)}
                    handleChangeCallback={(value) => updateField(field, value)}
                  />
                  {/* <CustomSelectField
                    label={field.display_name!}
                    value={responses[field.field_name]}
                    fieldName={field.field_name}
                    options={field.options!}
                    handleChange={(e) => updateField(field, e.target.value)}
                  /> */}
                </FieldContainer>
              );

            case 'date':
              return (
                <FieldContainer field={field} key={field.field_name}>
                  <CustomDatePicker
                    label={field.display_name!}
                    value={responses[field.field_name]}
                    fieldName={field.field_name}
                    handleChange={(value) => updateField(field, value)}
                  />
                </FieldContainer>
              );

            case 'time':
              return (
                <FieldContainer field={field} key={field.field_name}>
                  <CustomTimePicker
                    label={field.display_name!}
                    value={responses[field.field_name]}
                    fieldName={field.field_name}
                    handleChange={(value) => updateField(field, value)}
                  />
                </FieldContainer>
              );

            case 'datetime':
              return (
                <FieldContainer field={field} key={field.field_name}>
                  <CustomDateTimePicker
                    label={field.display_name!}
                    value={responses[field.field_name]}
                    fieldName={field.field_name}
                    handleChange={(value) => updateField(field, value)}
                  />
                </FieldContainer>
              );

            case 'radiogroup':
              return (
                <FieldContainer field={field} key={field.field_name}>
                  <CustomRadioGroup
                    label={field.display_name!}
                    value={responses[field.field_name]}
                    fieldName={field.field_name}
                    options={field.options!}
                    handleChange={(value) => updateField(field, value)}
                    optionsColumn={span === field.span ? 3 : 2}
                  />
                </FieldContainer>
              );

            case 'checkbox_group':
              return (
                <FieldContainer field={field} key={field.field_name}>
                  <CustomCheckboxGroup
                    label={field.display_name!}
                    value={responses[field.field_name]}
                    fieldName={field.field_name}
                    options={field.options!}
                    handleChangeCallback={(value) => updateField(field, value)}
                    optionsColumn={span === field.span ? 3 : 2}
                  />
                </FieldContainer>
              );

            case 'checkbox':
              return (
                <FieldContainer field={field} key={field.field_name}>
                  <CustomCheckbox
                    label={field.display_name!}
                    value={responses[field.field_name]}
                    fieldName={field.field_name}
                    handleChangeCallback={(value) => updateField(field, value)}
                  />
                </FieldContainer>
              );

            case 'rich_text':
              return (
                <FieldContainer field={field} key={field.field_name}>
                  <TextField
                    field={{ ...field, multiline: true, rows: 5 }}
                    responses={responses}
                    ref={(el) => (inputRefs.current[index] = el)}
                    handleChangeCallback={(value) => updateField(field, value)}
                  />
                  {/* <CustomRichTextEditor
          editFormMode
          label={field.display_name!}
          value={responses[field.field_name]}
          fieldName={field.field_name}
          height={field.height}
          handleChangeCallback={(value) => updateField(field, value)}
        /> */}
                </FieldContainer>
              );

            case 'file_upload':
              return (
                <FieldContainer field={field} key={field.field_name}>
                  <FileUpload
                    source={'forms'}
                    maxFilesonDrop={field.maxFilesonDrop}
                    maxFilesAllowed={field.maxFilesAllowed}
                    maxSize={field.maxSize}
                    fileTypes={field.fileTypes}
                    span={field.span ?? span}
                    formMode={FORM_MODE.RESPOND}
                    appointmentId={appointment?.id}
                    patientId={patient?.patient_id}
                    label={field.display_name!}
                    value={responses[field.field_name]}
                    fieldName={field.field_name}
                    handleChange={(values) => updateField(field, values)}
                    patientNoteId={noteId}
                  />
                </FieldContainer>
              );

            case 'table':
              field.columns?.forEach((col) => {
                if (col.dependents) {
                  col.onCellChange = (value, row, handleRowEdit) => {
                    if (col.dependents[row.id]) {
                      Object.keys(col.dependents[row.id]).map((key) => {
                        let expression = col.dependents[row.id][key] as string;
                        expression = expression.replaceAll('{value}', value);
                        expression = expression.replaceAll('{patient.sex}', patient.sex);
                        return handleRowEdit(row, key, !value ? '' : evaluateDynamicExpression(expression));
                      });
                    }
                  };
                }
              });
              return (
                <FieldContainer field={field} key={field.field_name}>
                  <CustomTableForm
                    displayName={field.display_name}
                    columns={field.columns!}
                    formMode={FORM_MODE.RESPOND}
                    initialValues={JSON.stringify(responses[field.field_name])}
                    handleChange={(rows) => updateField(field, rows)}
                    fixedRows
                  />
                </FieldContainer>
              );
            default:
              return (
                <FieldContainer field={field} key={field.field_name}>
                  <TextField
                    field={field}
                    responses={responses}
                    ref={(el) => (inputRefs.current[index] = el)}
                    handleChangeCallback={(value) => updateField(field, value)}
                  />
                </FieldContainer>
              );
          }
        })}
      </Fragment>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment, formFields, noteId, patient, provider, responses]);

  return (
    <WaitForFacility facility={facility}>
      <Box
        display="grid"
        columnGap={'25px'}
        rowGap={'25px'}
        gridTemplateColumns={gridColumn}
        alignItems="flex-end"
        sx={{
          '& > div': { gridColumn: isNonMobile ? undefined : `span ${span}` },
        }}
      >
        {FormFieldComponents}
      </Box>
      <SavingUpdates ref={componentRef} />
    </WaitForFacility>
  );
};

type TextFieldProps = {
  field: FieldInput;
  responses: any;
  handleChangeCallback?: (value: string) => void;
};

const TextField = forwardRef((props: TextFieldProps, ref) => {
  const { field, responses, handleChangeCallback } = props;
  const [, setCount] = useState(0);

  useImperativeHandle(ref, () => ({ refreshResponse }));

  const refreshResponse = () => {
    setCount((prev) => prev + 1);
  };

  const getValue = useCallback(
    (field: FieldInput) => {
      if (field.formula) {
        responses[field.field_name] = field.formula(responses);
      }
      return responses[field.field_name];
    },
    [responses]
  );

  if (field.type === 'select') {
    return (
      <CustomSelectField
        label={field.display_name!}
        value={getValue(field)}
        fieldName={field.field_name}
        options={field.options!}
        handleChangeCallback={handleChangeCallback}
        readOnly={!!field.formula}
      />
    );
  }

  return (
    <CustomTextField
      {...field}
      label={field.display_name!}
      value={getValue(field)}
      fieldName={field.field_name}
      handleChangeCallback={handleChangeCallback}
      readOnly={!!field.formula}
    />
  );
});
export default PatientRecordForm;
