import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { CustomCheckbox, CustomModal, RegularButton } from 'core/components';
import { forwardRef, useContext, useEffect, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Avatar from '@mui/material/Avatar';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CalendarEventTableOOO from 'company/entities/modules/ClinicManagement/CalendarEvent/CalendarEventTableOOO';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { ContainerRow } from 'core/components/containers';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import { FacilityContext } from 'core/context/facility.context';
import PatientField from 'company/screens/PatientManagement/components/PatientField';
import { SIZES } from 'theme/constants';
import ScheduleTable from './ScheduleTable';
import { ServiceProviderModel } from 'company/entities/modules/ClinicManagement/ServiceProvider/ServiceProviderModel';
import TodayIcon from '@mui/icons-material/Today';
import { UserContext } from 'core/context/user.context';
import WeeklySchedule from 'company/screens/ClinicManagement/components/WeeklySchedule';
import { getAppointments } from 'company/api/appointments';
import { tokens } from 'theme/theme';

// import { FacilityContext } from 'core/context/facility.context';

type Props = {
  providerId: number;
  providerDetails: ServiceProviderModel;
  setOpenServiceProviderDetails: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenServiceProviders: React.Dispatch<React.SetStateAction<boolean>>;
  callbackAfterSubmit?: () => void;
};

const ServiceProviderDetails = forwardRef(
  ({ setOpenServiceProviderDetails, setOpenServiceProviders, providerDetails, callbackAfterSubmit }: Props, ref) => {
    const { user } = useContext(UserContext);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { facility } = useContext(FacilityContext);
    const [openScheduleModal, setOpenScheduleModal] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [openOutOfOfficeModal, setOpenOutOfOfficeModal] = useState<boolean>(false);
    const isMobile = useMediaQuery('(max-width:768px)');

    const setCustomSchedule = async (data: any) => {
      setOpenScheduleModal(true);
    };

    const getProviderAppointments = (query: ApiQuery = {}) => {
      query.provider_id = providerDetails.id;

      return getAppointments(facility.id, query).then((response) => {
        return response;
      });
    };

    useEffect(() => {
      getProviderAppointments();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      setDisabled(!providerDetails.schedule);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [providerDetails]);

    const handleBackToServiceProviders = () => {
      setOpenServiceProviderDetails(false);
      setOpenServiceProviders(true);
    };

    // if (!providerDetails) {
    //   return <Typography>No provider details found.</Typography>;
    // }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: isMobile ? 'center' : 'start',
          justifyContent: 'space-between',
          columnGap: '12px',
          rowGap: '24px',
          padding: isMobile ? 0 : '10px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'start',
            columnGap: '12px',
            marginTop: '-56px',
          }}
        >
          <Typography
            fontSize={'14px'}
            fontWeight={500}
            color={colors.primary}
            onClick={handleBackToServiceProviders}
            sx={{
              display: 'flex',
              alignItems: 'center',
              columnGap: '8px',
              ':hover': {
                cursor: 'pointer',
              },
            }}
          >
            <ArrowBackIcon sx={{ color: colors.primary }} />
            Back to Service Providers
          </Typography>
        </Box>

        {isMobile ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '20px',
              rowGap: '10px',
            }}
          >
            <Typography fontWeight={700} fontSize={'20px'} color={colors.primary}>
              {providerDetails.full_name}
            </Typography>

            <Avatar alt={providerDetails.full_name} src={providerDetails.picture} sx={{ width: 56, height: 56 }} />

            <Typography fontSize={'12px'} fontWeight={500} color={colors.grey_text} mt={0}>
              {providerDetails.title ?? 'Health Staff'} at {facility.facility_name}
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              columnGap: '16px',
              // marginTop: '20px',
            }}
          >
            <Avatar alt={providerDetails.full_name} src={providerDetails.picture} sx={{ width: 56, height: 56 }} />

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                rowGap: '2px',
                marginRight: '20px',
              }}
            >
              <Typography fontWeight={600} fontSize={'28px'} color={colors.primary}>
                {providerDetails.full_name}
              </Typography>
              <Typography fontSize={'12px'} fontWeight={500} color={colors.grey_text} mt={0}>
                {providerDetails.title ?? 'Health Staff'} at {facility.facility_name}
              </Typography>
            </Box>
          </Box>
        )}

        {/* <CustomDashboardWidget
              count={'8'}
              label="No. of Total Served"
              iconBackgroundColor="#6999B3"
              icon={SentimentVerySatisfiedIcon}
              sx={{ width: '300px', backgroundColor: '#337497' }}
            /> */}

        <Box width="100%">
          <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '10px', mb: SIZES.padding }}>
            <BusinessCenterIcon color="primary" />
            <Typography variant="h3" fontWeight="600">
              Provider Information
            </Typography>
          </Box>
          <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap="20px" width="100%">
            <PatientField label="First Name" value={providerDetails.first_name} />
            <PatientField label="Middle Name" value={providerDetails.middle_name} />
            <PatientField label="Last Name" value={providerDetails.last_name} />
            <PatientField label="Designation" value={providerDetails.title} />
            <PatientField label="License No." value={providerDetails.license_number} />
            <PatientField label="Contact No." value={providerDetails.mobile_number_1} />
          </Box>
        </Box>

        <Box width="100%">
          <ContainerRow
            sx={isMobile ? { flexDirection: 'column', gap: '5px', mb: SIZES.padding } : { mb: SIZES.padding }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}>
              <CalendarMonthIcon color="primary" />
              <Typography variant="h3" fontWeight="600">
                Schedule
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: '10px' }}>
              <RegularButton
                variant="outlined"
                label="Update Schedule"
                size="small"
                startIcon={<TodayIcon sx={{ fontSize: '12px' }} />}
                fullWidth={isMobile ? true : false}
                onClick={() => setCustomSchedule(true)}
              />

              <RegularButton
                variant="outlined"
                label="Add Out of Office"
                size="small"
                startIcon={<EventBusyOutlinedIcon sx={{ fontSize: '12px' }} />}
                fullWidth={isMobile ? true : false}
                onClick={() => setOpenOutOfOfficeModal(true)}
                disabled={user.remaining_storage <= 0}
              />
            </Box>
          </ContainerRow>
          <ScheduleTable schedule={providerDetails.schedule || []}></ScheduleTable>
        </Box>

        <CustomModal
          header={'Update Schedule'}
          subHeader={
            providerDetails?.full_name_with_title ? providerDetails?.full_name_with_title : providerDetails?.full_name
          }
          open={openScheduleModal}
          setOpen={setOpenScheduleModal}
          width={800}
        >
          <Box>
            {providerDetails && (
              <Box display={'flex'} gap="2px" alignItems={'center'}>
                <CustomCheckbox
                  label={'Unavailable'}
                  value={disabled}
                  handleChange={() => {
                    setDisabled((prev: boolean) => !prev);
                  }}
                  fieldName={''}
                />
              </Box>
            )}
          </Box>
          {providerDetails && (
            <WeeklySchedule
              schedule={providerDetails.schedule}
              id={providerDetails.assignment_id}
              type="doctor"
              disabled={disabled}
              setSchedule={(schedule) => {
                providerDetails.schedule = JSON.parse(schedule);
                setOpenScheduleModal(false);
              }}
              clinic_id={providerDetails.facility_id}
            />
          )}
        </CustomModal>

        <CustomModal
          width={'65%'}
          setOpen={setOpenOutOfOfficeModal}
          open={openOutOfOfficeModal}
          header="Create Calendar Event"
        >
          {/* <CalendarEventForm
            outOfOffice
            callbackAfterSubmit={() => {
              setOpenOutOfOfficeModal(false);
              refreshTable();
            }}
          /> */}
          <CalendarEventTableOOO serviceProvider={providerDetails} callBackAfterSubmit={callbackAfterSubmit} />
        </CustomModal>
      </Box>
    );
  }
);

export default ServiceProviderDetails;
