import { Box, Skeleton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { chunkWords, formatNumber, rotateArray } from 'core/utils';
import { useEffect, useMemo, useState } from 'react';

import { BarChart } from '@mui/x-charts/BarChart';
import { chart_colors } from 'company/screens/PatientRecords/components/ChartEditor';
import { tokens } from 'theme/theme';

interface Props {
  processData: (setChartDataKeys: (key: string[]) => void, setChartDataValues: (values: number[]) => void) => void;

  title: string;
  xAxisLabel: string;
  yAxisLabel: string;
  colSpan?: number;
}

const DashboardStackedBarChart: React.FC<Props> = ({ processData, title, xAxisLabel, yAxisLabel, colSpan = 6 }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [chartDataKeys, setChartDataKeys] = useState<string[]>([]);
  const [chartDataValues, setChartDataValues] = useState<any[]>([]);
  const isMobilePhone = useMediaQuery('(max-width:768px)');
  const chartColors = useMemo(() => rotateArray(chart_colors), []);

  useEffect(() => {
    processData(setChartDataKeys, setChartDataValues);
  }, [processData]);

  const total = useMemo(
    () =>
      chartDataValues?.length > 0
        ? chartDataValues.reduce(
            (sum, value: any) => sum + value.data.reduce((_sum: number, _value: any) => _sum + _value, 0),
            0
          )
        : 0,
    [chartDataValues]
  );

  if (!chartDataValues) {
    return (
      <Skeleton
        variant="rounded"
        width={'100%'}
        height={'400px'}
        sx={{ gridColumn: isMobilePhone ? 'span 12' : 'span ' + colSpan, borderRadius: '8px' }}
        animation="wave"
      />
    );
  }

  return (
    <Box
      sx={{
        gridColumn: isMobilePhone ? 'span 12' : 'span ' + colSpan,
        border: '1px solid ' + colors.border,
        borderRadius: '8px',
        minHeight: '400px',
        padding: '12px 24px',
        overflowX: 'auto',
        '& .MuiChartsAxis-directionY .MuiChartsAxis-label': {
          transform: 'translateX(-40px) !important',
        },
        '& .MuiChartsAxis-directionX .MuiChartsAxis-label': {
          transform: 'translateY(25px) !important',
        },
      }}
    >
      <Box>
        <Box
          sx={{
            border: 'none',
            borderBottom: '1px solid #CCCCCC',
            padding: '12px',
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '30px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              columnGap: '12px',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Typography variant="h3" fontWeight="700">
              {title}
            </Typography>
            <Typography variant="h4" fontWeight="600">
              Total: {formatNumber(total, 0)}
            </Typography>
          </Box>
        </Box>

        <BarChart
          colors={chartColors}
          grid={{ horizontal: true }}
          series={chartDataValues.map((series: any) => ({
            ...series,
            // stack: 'total',
            highlightScope: { faded: 'global', highlighted: 'series' },
          }))}
          sx={{
            padding: '5px',
            '& .MuiBarLabel-root': {
              paddingBottom: '20px !important',
            },
          }}
          height={290}
          borderRadius={15}
          barLabel={(item) => formatNumber(item.value + '', 0)}
          xAxis={[
            {
              label: xAxisLabel,
              labelStyle: { fontWeight: 'bold', fontSize: '16px' },
              data: chartDataKeys,
              // data: ['Dyslipidemia', 'Tc DM', '3', '4', '5'],
              scaleType: 'band',
              tickPlacement: 'middle',
              tickLabelPlacement: 'middle',
              tickSize: 10,
              tickLabelStyle: {
                fontFamily: 'Poppins',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              },
              valueFormatter: (value) => {
                return chunkWords(value);
              },
            },
          ]}
          yAxis={[
            {
              label: yAxisLabel,
              labelStyle: { fontWeight: 'bold', fontSize: '15px' },
              tickMinStep: 1,
            },
          ]}
          margin={{ top: 35, bottom: 75, left: 80, right: 20 }}
          slotProps={{
            legend: { direction: 'row', padding: -5 },
          }}
        />
      </Box>
    </Box>
  );
};

export default DashboardStackedBarChart;
