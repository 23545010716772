/* eslint-disable react-hooks/exhaustive-deps */
import {
  ConfirmationDialog,
  CustomContainer,
  CustomGridCell,
  CustomModal,
  CustomTable,
  PrimaryButton,
  RegularButton,
  RowAction,
} from 'core/components';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { calculateAge, changeNullToBlank, formatDate } from 'core/utils';
import { getEnrolledPatients, unenrollPatient } from 'lgu/api/tb-acf';
import { useContext, useEffect, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import { Box } from '@mui/material';
import CustomStepperNonLinear from 'core/components/CustomStepperNonLinear';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EnrollToProgramReminder from '../components/common/EnrollToProgramReminder';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import TbAcfBatchModal from '../components/TB/TbAcfBatchModal';
import { TbAcfPatientModel } from 'lgu/model/Entities';
import UpdatePatientModal from '../components/TBACF/UpdatePatientModal';
import { UserContext } from 'core/context/user.context';
import { exportPatientsEnrolledInTbAcf } from 'lgu/api/patient';
import { steps_filter } from '../data/list';
import { tb_acf_stages_filter } from 'lgu/data/select-options';
import { useSnackbar } from 'notistack';

type TbAcfProps = {
  refresh: number;
  setRefresh: (refreshTable: any) => void;
};

const TbAcf: React.FC<TbAcfProps> = ({ refresh, setRefresh }) => {
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<string>('');

  const [selectedPatientIDs, setSelectedPatientIDs] = useState<any[]>([]);
  const [selectedPatientID, setSelectedPatientID] = useState<number>(0);
  const [enoughPatients, setEnoughPatients] = useState<boolean>(false);
  const [selectedStage, setSelectedStage] = useState<number>(0);

  //enroll
  const [openEnrollModal, setOpenEnrollModal] = useState<boolean>(false);

  //unenroll
  const [openPatientUnenrollDialog, setOpenPatientUnenrollDialog] = useState<boolean>(false);

  //update
  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false);
  const [patient, setPatient] = useState<TbAcfPatientModel>();

  //batch update
  const [openBatchUpdateModal, setOpenBatchUpdateModal] = useState<boolean>(false);

  const updatePatient = (data: any) => {
    setPatient(changeNullToBlank(data));
    setOpenUpdateModal(true);
  };

  const showUnenrollPatient = (data: any) => {
    setSelectedPatientID(data.id);
    showUnenrollPatientDialog('Are you sure you want to unenroll ' + data.full_name + '?');
  };

  const actions = [
    { label: 'Update Patient', action: updatePatient, icon: <EditOutlinedIcon /> },
    { label: 'Unenroll Patient', action: showUnenrollPatient, icon: <PersonRemoveIcon /> },
  ];

  const columns: GridColDef[] = [
    { field: 'full_name', headerName: 'Patient Name', flex: 1.5 },
    { field: 'mobile_number', headerName: 'Mobile Number', flex: 1, sortable: false },
    {
      field: 'birthday',
      headerName: 'Birthday',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{formatDate(params.row.birthday)}</CustomGridCell>,
    },
    {
      field: 'age',
      headerName: 'Age',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      flex: 0.5,
      sortable: false,
      renderCell: (params) => <CustomGridCell>{calculateAge(params.row.birthday)}</CustomGridCell>,
    },
    { field: 'barangay_name', headerName: 'Barangay', flex: 1 },
    { field: 'stage', headerName: 'Stage', flex: 1.25 },
    {
      field: 'patient_status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) =>
        params.row.enrolled_tb_dots ? <CustomGridCell> Enrolled </CustomGridCell> : params.row.patient_status,
    },
    {
      field: 'action',
      flex: 0.75,
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => <RowAction actions={actions} data={params.row} />,
    },
  ];

  const showUnenrollPatientDialog = (content: string) => {
    setOpenPatientUnenrollDialog(true);
    setAlertContent(content);
  };

  const getPatientsEnrolled = (query: ApiQuery) => {
    setLoading(true);
    query.stage = tb_acf_stages_filter[selectedStage];
    getEnrolledPatients(query)
      .then((res) => {
        setRows(res.data.data);
        setRowCount(res.data.meta.total);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const showEnrollmentModal = () => {
    setOpenEnrollModal(true);
  };

  const handlePatientUnenroll = () => {
    const patientList = JSON.stringify(selectedPatientID ? [selectedPatientID] : selectedPatientIDs);
    const data = { patients: patientList };
    unenrollPatient(data)
      .then((res) => {
        enqueueSnackbar('Patient successfully unenrolled!', { variant: 'success' });
        refreshTable();
        setSelectedPatientID(0);
      })
      .catch((error) => console.error(error));
  };

  const handleSelectPatients = (selected: GridRowSelectionModel) => {
    if (selected.length > 0) {
      setEnoughPatients(true);
      setSelectedPatientIDs(selected);
    } else {
      if (selected.length === 0) {
        setEnoughPatients(false);
      }
    }
  };

  const showBatchUpdateModal = () => {
    if (enoughPatients) {
      setOpenBatchUpdateModal(true);
    } else {
      enqueueSnackbar('Invalid action. Select patients first!', { variant: 'error' });
    }
  };

  const exportRecords = async (query: ApiQuery) => {
    query.stage = tb_acf_stages_filter[selectedStage];
    exportPatientsEnrolledInTbAcf(query, user.lgu?.lgu_name);
  };

  // const refreshTable = () => {
  //   setRefreshEnrolled((prev: number) => prev + 1);
  // };

  const refreshTable = () => {
    setRefresh((prev: number) => prev + 1);
  };

  useEffect(() => {
    if (!openUpdateModal) {
      setPatient(undefined);
    }
  }, [openUpdateModal]);

  useEffect(() => {
    refreshTable();
  }, [selectedStage]);

  return (
    <>
      <CustomContainer>
        <Box m={2} width="80%" alignSelf="center">
          <CustomStepperNonLinear steps={steps_filter} activeStep={selectedStage} setActiveStep={setSelectedStage} />
        </Box>
        <Box display="flex" width="100%" justifyContent="space-between">
          <PrimaryButton
            onClick={() => showEnrollmentModal()}
            label="Enroll Patients"
            disabled={user.remaining_storage <= 0}
          />
        </Box>
        <CustomTable
          columns={columns}
          rows={rows}
          rowCount={rowCount}
          loading={loading}
          rowId={'id'}
          getData={getPatientsEnrolled}
          checkboxSelection={selectedStage !== 0 && selectedStage !== 4}
          selectableRow={selectedStage !== 0 && selectedStage !== 4}
          handleSelectRow={handleSelectPatients}
          disableSelectionOnClick
          keepNonExistentRowsSelected={false}
          forceRefresh={refresh}
          exportRecords={exportRecords}
          showRefreshButton
          headerComponent={
            <>
              {selectedStage !== 0 && selectedStage !== 4 ? (
                <Box display="flex" alignItems="end" gap="10px">
                  <RegularButton
                    onClick={() => showBatchUpdateModal()}
                    label="Batch Update"
                    disabled={!enoughPatients}
                  />
                </Box>
              ) : (
                <></>
              )}
            </>
          }
        />
      </CustomContainer>

      <EnrollToProgramReminder
        programCode={'tb'}
        refreshEnrolledTable={refreshTable}
        open={openEnrollModal}
        setOpen={setOpenEnrollModal}
      />

      <ConfirmationDialog
        open={openPatientUnenrollDialog}
        setOpen={setOpenPatientUnenrollDialog}
        content={alertContent}
        onConfirm={handlePatientUnenroll}
      />

      {patient && (
        <UpdatePatientModal
          patient={patient}
          open={openUpdateModal}
          setOpen={setOpenUpdateModal}
          refreshTable={refreshTable}
        />
      )}

      {selectedPatientIDs.length > 0 && (
        <CustomModal
          header={
            selectedStage === 1
              ? 'Stage 1: Chest X-ray'
              : selectedStage === 2
              ? 'Stage 2: Sputum Test'
              : 'Stage 3: Doctor Appointment'
          }
          open={openBatchUpdateModal}
          setOpen={setOpenBatchUpdateModal}
        >
          <TbAcfBatchModal
            patients={JSON.stringify(selectedPatientIDs)}
            selectedStage={selectedStage}
            openModal={setOpenBatchUpdateModal}
            refreshTable={refreshTable}
          />
        </CustomModal>
      )}
    </>
  );
};

export default TbAcf;
