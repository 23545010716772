import * as Yup from 'yup';

export const loginInitialValues = {
  username: '',
  password: '',
  accountType: 'company',
};

export const loginSchema = Yup.object().shape({
  username: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required'),
  accountType: Yup.string().required('Account type is required'),
});

export const loginFields = [
  {
    type: 'section_header',
    section_header: 'Account Information',
    span: 4,
  },
  {
    type: 'radiogroup',
    field_name: 'accountType',
    display_name: 'Account Type',
    span: 4,
    options: [
      { key: 'LGU', value: 'lgu' },
      { key: 'Company', value: 'company' },
    ],
  },

  {
    field_name: 'username',
    display_name: 'Username',
    type: 'text',
    span: 4,
  },
  {
    field_name: 'password',
    display_name: 'Password',
    type: 'password',
    span: 4,
  },
];
