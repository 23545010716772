import { Box, Divider, Grid, Typography, useTheme } from '@mui/material';
import React, { Ref, forwardRef, useContext, useEffect, useState } from 'react';
import { calculateAge, formatArray, formatDate, formatDateTime } from 'core/utils';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { FacilityContext } from 'core/context/facility.context';
import { FieldInput } from 'core/model/interface';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { PatientEmergencyContactModel } from 'company/entities/modules/ClinicManagement/PatientEmergencyContact/PatientEmergencyContactModel';
import PatientField from 'company/screens/PatientManagement/components/PatientField';
import PatientHeader from './PatientHeader';
import { PatientModel } from 'company/entities/modules/ClinicManagement/Patient/PatientModel';
import PersonPinOutlinedIcon from '@mui/icons-material/PersonPinOutlined';
import { SIZES } from 'theme/constants';
import { UserContext } from 'core/context/user.context';
import { getBasicHealthInformationByPatient } from 'company/api/patient-basic-health-info';
import { getBasicHealthInformationFields } from 'company/api/patient-basic-health-info-field';
import { getContactByPatient } from 'company/api/patient-emergency-contact';
import styled from 'styled-components';
import { tokens } from 'theme/theme';

type Props = {
  patient: PatientModel;
  fontSizes?: {
    header: string;
    body: string;
  };
};

const GlobalStyle = styled.div`
  @media print {
    html,
    body {
      height: 100%;
      margin: 0;
      padding: 0;
    }
  }
`;

const PageWrapper = styled(Box)`
  display: block;
  min-height: 100%;
  position: relative;
  padding-bottom: 60px; /* Height of the footer */
`;

const Footer = styled(Box)`
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
`;

const PrintablePatientProfile = forwardRef(({ patient, fontSizes }: Props, ref: Ref<HTMLDivElement>) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { facility } = useContext(FacilityContext);
  const { user } = useContext(UserContext);
  const [patientData, setPatientData] = useState<PatientEmergencyContactModel[]>();
  const [fields, setFields] = useState<FieldInput[]>([]);
  const [initialValues, setInitialValues] = useState<any[]>([]);

  const PageBreaker = styled('div')`
    page-break-after: always;
  `;

  const processFields = (fields: any[]) => {
    return fields.map(
      (field: {
        field_type: any;
        id: any;
        field_name: string;
        display_name: string;
        field_options?: string[];
        field_unit?: string;
      }) =>
        ({
          field_name: field.id.toString(),
          display_name: (field.display_name ?? field.field_name) + (field.field_unit ? ` (${field.field_unit})` : ''),
          type: field.field_type === 'yes_no' ? 'radiogroup' : field.field_type,
          span: ['long_text', 'group_header'].includes(field.field_type) ? 4 : 2,
          multiline: field.field_type === 'long_text',
          rows: field.field_type === 'long_text' ? 3 : undefined,
          options:
            field.field_type === 'yes_no' || (field.field_options && field.field_options.length)
              ? field.field_type === 'yes_no'
                ? [
                    { key: 'Yes', value: 'yes' },
                    { key: 'No', value: 'no' },
                  ]
                : (Array.isArray(field!.field_options)
                    ? field!.field_options
                    : JSON.parse(field!.field_options + '')
                  )?.map((option: string) => ({ key: option, value: option }))
              : undefined,
        } as FieldInput)
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (patient) {
          const response = await getContactByPatient(facility.id, patient.patient_id, { length: 100 });
          setPatientData(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching patient data:', error);
      }
    };
    fetchData();
  }, [patient, facility.id]);

  useEffect(() => {
    if (patient && facility) {
      getBasicHealthInformationByPatient(facility.id, patient.patient_id).then((fieldData) => {
        const initialData = {} as any;
        fieldData.data.data.forEach((data: any) => {
          initialData[data.field_id as string] = data.value;
        });
        setInitialValues(initialData);
      });

      getBasicHealthInformationFields({ order_by: 'order' }).then((result) => {
        setFields(processFields(result.data.data.filter((item: any) => !item.hidden)));
      });
    }
  }, [patient, facility]);

  return (
    <>
      <GlobalStyle />
      <PageWrapper ref={ref}>
        <Box display={'flex'} justifyContent={'space-between'}>
          <PatientHeader patient={patient} showProfile />
          <Box display={'flex'} flexDirection={'column'} rowGap={'5px'} alignItems={'end'}>
            {user.company?.company_logo && (
              <img alt="clinic-logo" width="90px" height="90px" id="logo" src={user.company?.company_logo} />
            )}
            <Typography
              variant="h3"
              textAlign="right"
              color={colors.primary}
              fontWeight={'bold'}
              sx={{ fontSize: fontSizes?.header }}
            >
              {facility.facility_name}
            </Typography>
            <Typography variant="h5" textAlign="right" sx={{ fontSize: fontSizes?.body }}>
              {formatArray([facility.address, facility.municipality_name, facility.province_name])}
            </Typography>
            <Typography variant="h5" textAlign="right" sx={{ fontSize: fontSizes?.body }}>
              {formatArray([facility.globe_mobile_number, facility.smart_mobile_number], ' | ')}
            </Typography>
          </Box>
        </Box>

        <Box display={'flex'} flexDirection={'column'} gap={SIZES.paddingL}>
          <Box display={'flex'} flexDirection={'column'} gap={SIZES.padding}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <PersonPinOutlinedIcon color="primary" sx={{ fontSize: fontSizes?.header }} />
              <Typography
                variant="h6"
                sx={{ fontWeight: '600', color: colors.dark_grey_text, fontSize: fontSizes?.header }}
              >
                Patient Information
              </Typography>
            </Box>
            {patient && (
              <Box width="100%">
                <Box display="grid" gridTemplateColumns="repeat(4, 1fr)" gap="10px">
                  <PatientField
                    label="Patient ID"
                    value={patient.id}
                    fontSizes={fontSizes}
                    fieldFontSize={fontSizes?.body}
                  />
                  <PatientField
                    label="First Name"
                    value={patient.first_name}
                    fontSizes={fontSizes}
                    fieldFontSize={fontSizes?.body}
                  />
                  <PatientField
                    label="Middle Name"
                    value={patient.middle_name}
                    fontSizes={fontSizes}
                    fieldFontSize={fontSizes?.body}
                  />
                  <PatientField
                    label="Last Name"
                    value={patient.last_name}
                    fontSizes={fontSizes}
                    fieldFontSize={fontSizes?.body}
                  />
                  <PatientField
                    label="Date Registered"
                    value={formatDate(patient.created_at)}
                    fieldFontSize={fontSizes?.body}
                  />
                  <PatientField
                    label="Sex Assigned at Birth"
                    value={patient.sex}
                    fontSizes={fontSizes}
                    fieldFontSize={fontSizes?.body}
                  />
                  <PatientField
                    label="Birthday"
                    value={formatDate(patient.birthday)}
                    fontSizes={fontSizes}
                    fieldFontSize={fontSizes?.body}
                  />
                  <PatientField
                    label="Age"
                    value={patient.birthday ? calculateAge(patient.birthday) : patient.age ? patient.age : '-'}
                    fontSizes={fontSizes}
                    fieldFontSize={fontSizes?.body}
                  />
                  <PatientField
                    label="Occupation"
                    value={patient.occupation}
                    fontSizes={fontSizes}
                    fieldFontSize={fontSizes?.body}
                  />
                  <PatientField
                    label="Department"
                    value={patient.department_name}
                    fontSizes={fontSizes}
                    fieldFontSize={fontSizes?.body}
                  />
                  <PatientField
                    label="Section"
                    value={patient.section_name}
                    fontSizes={fontSizes}
                    fieldFontSize={fontSizes?.body}
                  />
                  <PatientField
                    label="Case No."
                    value={patient.case_number}
                    fontSizes={fontSizes}
                    fieldFontSize={fontSizes?.body}
                  />
                  <PatientField
                    label="Civil Status"
                    value={patient.civil_status}
                    fontSizes={fontSizes}
                    fieldFontSize={fontSizes?.body}
                  />
                  <PatientField
                    label="PWD ID"
                    value={patient.pwd_id}
                    fontSizes={fontSizes}
                    fieldFontSize={fontSizes?.body}
                  />
                  <PatientField
                    label="Senior Citizen ID"
                    value={patient.senior_citizen_id}
                    fieldFontSize={fontSizes?.body}
                  />
                  <PatientField
                    label="Mobile # (Primary)"
                    value={patient.mobile_number}
                    fieldFontSize={fontSizes?.body}
                  />
                  <PatientField
                    label="Mobile # (Secondary)"
                    value={patient.mobile_number_2}
                    fieldFontSize={fontSizes?.body}
                  />
                  <PatientField
                    label="Landline Number"
                    value={patient.landline_number}
                    fontSizes={fontSizes}
                    fieldFontSize={fontSizes?.body}
                  />
                  <PatientField
                    label="Email Address"
                    value={patient.email}
                    fontSizes={fontSizes}
                    fieldFontSize={fontSizes?.body}
                  />
                  <PatientField
                    label="Diagnosis"
                    value={patient.diagnosis}
                    sx={{ gridColumn: 'span 3' }}
                    fontSizes={fontSizes}
                    fieldFontSize={fontSizes?.body}
                  />
                  <PatientField
                    label="Address"
                    value={formatArray([patient.address, patient.province_city], ', ')}
                    sx={{ gridColumn: 'span 3' }}
                    fontSizes={fontSizes}
                    fieldFontSize={fontSizes?.body}
                  />
                  <Divider sx={{ gridColumn: 'span 4' }} />
                  {patient.company_name && (
                    <>
                      <PatientField
                        label="Company"
                        value={patient.company_name}
                        fontSizes={fontSizes}
                        fieldFontSize={fontSizes?.body}
                      />
                      <PatientField
                        label="Employee ID"
                        value={patient.employee_id}
                        fontSizes={fontSizes}
                        fieldFontSize={fontSizes?.body}
                      />
                      <PatientField
                        label="Patient Group"
                        value={patient.tags.length ? patient.tags.map((tag) => tag.tag_name).join(', ') : '-'}
                        fontSizes={fontSizes}
                        fieldFontSize={fontSizes?.body}
                      />
                    </>
                  )}
                  {patient.hmo_name && (
                    <>
                      <PatientField label="HMO" value={patient.hmo_name} fieldFontSize={fontSizes?.body} />
                      <PatientField
                        label="HMO Number"
                        value={patient.hmo_account_number}
                        fontSizes={fontSizes}
                        fieldFontSize={fontSizes?.body}
                      />
                      <PatientField
                        label="HMO Card Number"
                        value={patient.hmo_card_number}
                        fontSizes={fontSizes}
                        fieldFontSize={fontSizes?.body}
                      />
                      <PatientField
                        label="HMO Account Name"
                        value={patient.hmo_account_name}
                        fontSizes={fontSizes}
                        fieldFontSize={fontSizes?.body}
                      />
                    </>
                  )}
                </Box>
              </Box>
            )}
          </Box>

          <Box display={'flex'} flexDirection={'column'} gap={SIZES.padding}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <AssignmentIndIcon color="primary" sx={{ fontSize: fontSizes?.header }} />
              <Typography
                variant="h6"
                sx={{ fontWeight: '600', color: colors.dark_grey_text, fontSize: fontSizes?.header }}
              >
                Health Information
              </Typography>
            </Box>
            <Box style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '10px' }}>
              {fields.map((field, index) =>
                field.type === 'group_header' ? (
                  <Typography
                    key={index}
                    variant="h5"
                    fontWeight={'600'}
                    style={{ gridColumn: 'span 4' }}
                    sx={{ fontSize: fontSizes?.header }}
                  >
                    {field.display_name}
                  </Typography>
                ) : (
                  <PatientField
                    key={index}
                    label={field.display_name!}
                    value={initialValues[field.field_name] ?? '-'}
                    sx={{ gridColumn: field.type === 'long_text' ? 'span 2' : undefined }}
                    fontSizes={fontSizes}
                    fieldFontSize={fontSizes?.body}
                  />
                )
              )}
            </Box>
          </Box>

          <Box display={'flex'} flexDirection={'column'} gap={SIZES.padding} className="PageBreaker">
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <LocalPhoneIcon color="primary" sx={{ fontSize: fontSizes?.header }} />
              <Typography
                variant="h6"
                sx={{ fontWeight: '600', color: colors.dark_grey_text, fontSize: fontSizes?.header }}
              >
                Emergency Contacts
              </Typography>
            </Box>
            <Box sx={{ '& p': { padding: '0px !important' } }}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Box display="grid" gridTemplateColumns="repeat(4, 1fr)" gap="5px" paddingBottom={SIZES.paddingS}>
                    <PatientField label="Full Name" value="" fontSizes={fontSizes} fieldFontSize={fontSizes?.body} />
                    <PatientField label="Relationship" value="" fontSizes={fontSizes} fieldFontSize={fontSizes?.body} />
                    <PatientField label="Contact #" value="" fontSizes={fontSizes} fieldFontSize={fontSizes?.body} />
                    <PatientField label="Email" value="" fontSizes={fontSizes} fieldFontSize={fontSizes?.body} />
                  </Box>
                </Grid>
                {patientData?.map((data, index) => (
                  <Grid item xs={12} key={index}>
                    <Box display="grid" gridTemplateColumns="repeat(4, 1fr)" gap="4px" paddingBottom={SIZES.paddingS}>
                      <Typography
                        sx={{ fontSize: fontSizes?.body }}
                      >{`${data.first_name} ${data.last_name}`}</Typography>
                      <Typography sx={{ fontSize: fontSizes?.body }}>{data.relationship}</Typography>
                      <Typography sx={{ fontSize: fontSizes?.body }}>{data.mobile_number}</Typography>
                      <Typography sx={{ fontSize: fontSizes?.body }}>{data.email ? data.email : '-'}</Typography>
                    </Box>
                    {index === 2 && (
                      <Grid item xs={12}>
                        <Box sx={{ pageBreakAfter: 'always' }}></Box>
                      </Grid>
                    )}
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Box>
        <Footer>
          <Box
            width="100%"
            position="fixed"
            bottom="0"
            sx={{
              '@media print': {
                position: 'absolute',
                '@page': {
                  marginBottom: '60px',
                },
              },
            }}
          >
            <hr
              style={{
                border: 'none',
                borderTop: '0.5px solid #0192D5',
                width: '100%',
                transform: 'scaleY(0.5)',
              }}
            />
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                <img
                  alt="cphi-logo"
                  width="35px"
                  height="auto"
                  id="logo"
                  src={process.env.PUBLIC_URL + `/assets/carego_health_suite_logo.png`}
                />
                <Box sx={{ textAlign: 'left', gap: '4px' }}>
                  <Typography sx={{ fontSize: '8px', color: colors.accent }}>
                    Document generated using CareGo Health Suite on {formatDateTime(new Date())}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Footer>
      </PageWrapper>
    </>
  );
});

export default PrintablePatientProfile;
