/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button } from '@mui/material';
import { CustomModal, CustomTable, PrimaryButton } from 'core/components';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { useContext, useEffect, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import { CompanyModel } from 'company/model/Entities';
import LocalizedSms from './templates/LocalizedSms';
import { SMSTemplatesModel } from 'lgu/model/Entities';
import TemplateForm from './components/TemplateForm';
import { getCompanySMSTemplates } from 'carego-admin/api/sms-templates';
import { UserContext } from 'core/context/user.context';

type SmsTemplatesProps = {
  company: CompanyModel;
};

const SmsTemplates: React.FC<SmsTemplatesProps> = ({ company }) => {
  const { user } = useContext(UserContext);

  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);

  const [selectedRow, setSelectedRow] = useState<SMSTemplatesModel>();
  const [clearSelected, setClearSelected] = useState(0);

  const [openTemplateModal, setOpenTemplateModal] = useState<boolean>(false);
  const [openTemplateForm, setOpenTemplateForm] = useState<boolean>(false);

  const [toEdit, setToEdit] = useState<boolean>(false);
  const [template, setTemplate] = useState<SMSTemplatesModel>();
  const [refreshTable, setRefreshTable] = useState(0);

  // Table Columns
  const columns: GridColDef[] = [
    { field: 'template_name', headerName: 'Template Name', flex: 1 },
    { field: 'template_code', headerName: 'Template Code', flex: 1 },
    { field: 'languages', headerName: 'Languages', flex: 0.5 },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      flex: 0.5,
      renderCell: (params) => (
        <Button
          variant="outlined"
          size="small"
          color="success"
          onClick={(event) => {
            setOpenTemplateForm(true);
            setToEdit(true);
            setTemplate(params.row);
            event.stopPropagation();
          }}
        >
          Edit
        </Button>
      ),
    },
  ];

  // Functions
  const getCompanyTemplates = (query: ApiQuery) => {
    setLoading(true);
    getCompanySMSTemplates(company.id, query)
      .then((res) => {
        setRows(res.data.data);
        setRowCount(res.data.meta.total);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const refreshCompanyTemplates = () => {
    setRefreshTable && setRefreshTable((prev: number) => prev + 1);
  };

  const handleSelectRow = (selected: GridRowSelectionModel) => {
    if (selected.length && selectedRow?.id !== selected[0]) {
      const selectedTemplate = rows.find((template) => template.id === selected[0]);
      setOpenTemplateModal(true);
      setSelectedRow(selectedTemplate);
    } else {
      setSelectedRow(undefined);
    }
  };

  useEffect(() => {
    if (company.id) {
      refreshCompanyTemplates();
    }
  }, [company]);

  return (
    <Box>
      <CustomTable
        columns={columns}
        rows={rows}
        rowCount={rowCount}
        loading={loading}
        rowId={'id'}
        getData={getCompanyTemplates}
        forceRefresh={refreshTable}
        selectableRow
        handleSelectRow={handleSelectRow}
        clearSelected={clearSelected}
        rowHeight={45}
        headerComponent={
          <Box display="flex" gap="10px">
            <PrimaryButton
              onClick={() => {
                setOpenTemplateForm(true);
                setToEdit(false);
                setTemplate(undefined);
              }}
              label="Add Template"
              disabled={user.remaining_storage <= 0}
            />
          </Box>
        }
      />

      {selectedRow && (
        <CustomModal
          open={openTemplateModal}
          setOpen={setOpenTemplateModal}
          header={selectedRow.template_name}
          width={900}
        >
          <LocalizedSms template={selectedRow} company={company} setRefreshMainTable={setRefreshTable!} />
        </CustomModal>
      )}

      <CustomModal
        open={openTemplateForm}
        setOpen={setOpenTemplateForm}
        header={toEdit ? 'Edit Template' : 'Add Template'}
        onClose={() => {
          setSelectedRow(undefined);
          setClearSelected((prev: number) => prev + 1);
        }}
      >
        <TemplateForm
          companyID={company.id}
          template={template}
          setRefreshTable={setRefreshTable!}
          setOpen={setOpenTemplateForm}
        />
      </CustomModal>
    </Box>
  );
};

export default SmsTemplates;
