import { Box, Typography } from '@mui/material';
import SignatureSelector, { SIGNATURE_TYPES } from '../../Summarized/SignatureSelector';
import { calculateAge, formatArray, formatDate } from 'core/utils';
import { getAnswerUnifiedForm, hematology_tests_1, hematology_tests_2 } from './source_field';

import React from 'react';

const underlineStyle = { borderBottom: '1px solid #878787', paddingInline: '5px', width: '100%' };

interface UnifiedLabFormProps {
  assessment: any;
}
const bold = { fontWeight: 600 };

export default function UnifiedLabForm({ assessment }: UnifiedLabFormProps) {
  const getAPEAnswer = (
    field: string,
    defaultValue?: string,
    occurence?: number,
    prefix?: string,
    columns?: string[]
  ): string => {
    return getAnswerUnifiedForm(field, assessment.notes ?? [], defaultValue, occurence, prefix, columns);
  };
  return (
    <Box>
      {/* PATIENT DETAILS SECTION */}
      <Box>
        <Box className="body" display="grid" gridTemplateColumns="1fr 1fr 1fr" columnGap="10px">
          <Box display="grid" gridTemplateColumns="0.5fr 1.5fr">
            <Typography fontWeight={600}>Date:</Typography>
            <Typography sx={underlineStyle}>{formatDate(assessment.created_at)}</Typography>
          </Box>

          <Box></Box>
          <Box></Box>

          <Box display="grid" gridTemplateColumns="0.5fr 1.5fr">
            <Typography fontWeight={600}>Patient Code:</Typography>
            <Typography sx={underlineStyle}>{assessment.case_number}</Typography>
          </Box>

          <Box display="grid" gridTemplateColumns="0.5fr 1.5fr">
            <Typography fontWeight={600}>Age:</Typography>
            <Typography sx={underlineStyle}>{calculateAge(assessment.birthday)}</Typography>
          </Box>

          <Box display="grid" gridTemplateColumns="0.5fr 1.5fr">
            <Typography fontWeight={600}>Gender:</Typography>
            <Typography sx={underlineStyle}>{assessment.sex}</Typography>
          </Box>

          <Box display="grid" gridTemplateColumns="0.3fr 1.7fr" gridColumn="span 2">
            <Typography fontWeight={600}>Patient Name:</Typography>
            <Typography sx={underlineStyle}>
              {formatArray([assessment.first_name, assessment.middle_name, assessment.last_name], ' ')}
            </Typography>
          </Box>

          <Box display="grid" gridTemplateColumns="0.5fr 1.5fr">
            <Typography fontWeight={600}>Company:</Typography>
            <Typography sx={underlineStyle}>{assessment.company_name}</Typography>
          </Box>
        </Box>
      </Box>
      {/* HEMATOLOGY RESULT */}
      <Box>
        <Box className="header" sx={{ backgroundColor: '#CEF0C9' }}>
          <Typography textAlign="center" fontWeight="bold">
            HEMATOLOGY RESULT
          </Typography>
        </Box>

        <Box display="grid" gridTemplateColumns={'1fr 1fr'} columnGap={'20px'} className="body">
          <Box>
            <Box display="grid" gridTemplateColumns={'repeat(3, 1fr)'} columnGap={'10px'}>
              <Typography sx={bold}>TEST</Typography>
              <Typography sx={bold}>RESULT</Typography>
              <Typography sx={bold}>Reference Result</Typography>
            </Box>
            {hematology_tests_1.map((test) => {
              return (
                <Box display="grid" gridTemplateColumns={'repeat(3, 1fr)'} columnGap={'10px'}>
                  <Typography>{test.test}</Typography>
                  <Typography sx={underlineStyle}>{getAPEAnswer(test.test)}</Typography>
                  <Typography sx={underlineStyle}>{test.ref_value}</Typography>
                </Box>
              );
            })}
          </Box>

          <Box>
            <Box display="grid" gridTemplateColumns={'repeat(3, 1fr)'} columnGap={'10px'}>
              <Typography sx={bold}>TEST</Typography>
              <Typography sx={bold}>RESULT</Typography>
              <Typography sx={bold}>Reference Result</Typography>
            </Box>
            {hematology_tests_2.map((test) => {
              return (
                <Box display="grid" gridTemplateColumns={'repeat(3, 1fr)'} columnGap={'10px'}>
                  <Typography>{test.test}</Typography>
                  <Typography sx={underlineStyle}>{getAPEAnswer(test.test, '', 1, 'DC_')} </Typography>
                  <Typography sx={underlineStyle}>{test.ref_value}</Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>

      {/* URINALYSIS RESULT */}
      <Box>
        <Box className="header" sx={{ backgroundColor: '#CEF0C9' }}>
          <Typography textAlign="center" fontWeight="bold">
            URINALYSIS RESULT
          </Typography>
        </Box>
        <Box display="grid" gridTemplateColumns={'1fr 1fr'} className="body">
          <Box>
            <Typography fontWeight={600} ml="10%">
              Physical
            </Typography>
            <Box display="grid" gridTemplateColumns={'1.5fr 3fr 3fr'}>
              <Typography>Color</Typography>
              <Typography sx={underlineStyle}>{getAPEAnswer('Color', '', 1, 'U_')}</Typography>
              <Box></Box>

              <Typography>Transparency</Typography>
              <Typography sx={underlineStyle}>{getAPEAnswer('Transparency', '', 1, 'U_')}</Typography>
              <Box></Box>

              <Typography>Specific Gravity</Typography>
              <Typography sx={underlineStyle}>{getAPEAnswer('Specific Gravity', '', 1, 'U_')}</Typography>
              <Box></Box>

              <Typography>pH</Typography>
              <Typography sx={underlineStyle}>{getAPEAnswer('pH', '', 1, 'U_')}</Typography>
              <Box></Box>
            </Box>

            <Typography fontWeight={600} ml="10%">
              Chemical Reaction
            </Typography>

            <Box display="grid" gridTemplateColumns={'1.5fr 3fr 3fr'}>
              <Typography>Protein</Typography>
              <Typography sx={underlineStyle}>{getAPEAnswer('Protein', '', 1, 'U_')}</Typography>
              <Box></Box>

              <Typography>Sugar</Typography>
              <Typography sx={underlineStyle}>{getAPEAnswer('Sugar', '', 1, 'U_')}</Typography>
              <Box></Box>
            </Box>
          </Box>
          <Box>
            <Box>
              <Typography fontWeight={600} ml="10%">
                Microscopy
              </Typography>
              <Box display="grid" gridTemplateColumns={'1.5fr 3fr 3fr'}>
                <Typography>WBC</Typography>
                <Typography sx={underlineStyle}>{getAPEAnswer('WBC', '', 1, 'U_')}/HPF</Typography>
                <Box></Box>

                <Typography>RBC</Typography>
                <Typography sx={underlineStyle}>{getAPEAnswer('RBC', '', 1, 'U_')}/HPF</Typography>
                <Box></Box>

                <Typography>Epithelial Cells</Typography>
                <Typography sx={underlineStyle}>{getAPEAnswer('Epithelial Cells', '', 1, 'U_')}</Typography>
                <Box></Box>

                <Typography>Mucus Threads</Typography>
                <Typography sx={underlineStyle}>{getAPEAnswer('Mucus Threads', '', 1, 'U_')}</Typography>
                <Box></Box>

                <Typography>Bacteria</Typography>
                <Typography sx={underlineStyle}>{getAPEAnswer('Bacteria', '', 1, 'U_')}</Typography>
                <Box></Box>

                <Typography>Am Urates</Typography>
                <Typography sx={underlineStyle}>{getAPEAnswer('AM Urates', '', 1, 'U_')}</Typography>
                <Box></Box>

                <Typography>Am Phosphates</Typography>
                <Typography sx={underlineStyle}>{getAPEAnswer('AM Phosphates', '', 1, 'U_')}</Typography>
                <Box></Box>

                <Typography>CaOx</Typography>
                <Typography sx={underlineStyle}>{getAPEAnswer('CaOx', '', 1, 'U_')}</Typography>
                <Box></Box>

                <Typography fontWeight={600}>NOTE:</Typography>
                <Typography sx={underlineStyle}>
                  {getAPEAnswer('Remarks (Significant Findings based on Result)')}
                </Typography>
                <Box></Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* FECALYSIS RESULT */}
      <Box>
        <Box className="header" sx={{ backgroundColor: '#CEF0C9' }}>
          <Typography textAlign="center" fontWeight="bold">
            FECALYSIS RESULT
          </Typography>
        </Box>

        <Box display="grid" gridTemplateColumns={'1fr 1fr'} className="body">
          <Box>
            <Box display="grid" gridTemplateColumns={'1.5fr 3fr 3fr'}>
              <Typography>Color</Typography>
              <Typography sx={underlineStyle}>{getAPEAnswer('Color', '-', 1, 'F_')}</Typography>
              <Box></Box>

              <Typography>Consistency</Typography>
              <Typography sx={underlineStyle}>{getAPEAnswer('Consistency', '-', 1, 'F_')}</Typography>
              <Box></Box>

              <Typography>Bacteria</Typography>
              <Typography sx={underlineStyle}>{getAPEAnswer('Bacteria', '-', 1, 'F_')}</Typography>
              <Box></Box>

              <Typography>WBC</Typography>
              <Typography sx={underlineStyle}>{getAPEAnswer('WBC', '-', 1, 'F_')}</Typography>
              <Box></Box>

              <Typography>RBC</Typography>
              <Typography sx={underlineStyle}>{getAPEAnswer('RBC', '-', 1, 'F_')}</Typography>
              <Box></Box>
            </Box>

            <Box display="grid" gridTemplateColumns={'1fr 4fr 1fr'}>
              <Typography fontWeight={600}>REMARKS:</Typography>
              <Typography sx={underlineStyle}>{getAPEAnswer('Remarks')}</Typography>
              <Box></Box>
            </Box>
          </Box>

          <Box>
            <Box display="grid" gridTemplateColumns={'1.5fr 3fr 3fr'}>
              <Typography>Occult Blood</Typography>
              <Typography sx={underlineStyle}>{getAPEAnswer('Occult Blood', '-', 1, 'F_')}</Typography>
              <Box></Box>

              <Typography>Fat Globules</Typography>
              <Typography sx={underlineStyle}>{getAPEAnswer('Fat Globules', '-', 1, 'F_')}</Typography>
              <Box></Box>

              <Typography>Yeast Cells</Typography>
              <Typography sx={underlineStyle}>{getAPEAnswer('Yeast Cells', '-', 1, 'F_')}</Typography>
              <Box></Box>

              <Typography>Entamoeba hystolitica</Typography>
              <Typography></Typography>
              <Box></Box>

              <Typography ml="10px">Cyst:</Typography>
              <Typography sx={underlineStyle}>{getAPEAnswer('Cyst', '-', 1, 'F_')}</Typography>
              <Box></Box>

              <Typography ml="10px">Tropozoites:</Typography>
              <Typography sx={underlineStyle}>{getAPEAnswer('Tropozoites', '-', 1, 'F_')}</Typography>
              <Box></Box>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* SPECIAL LAB EXAM RESULT */}
      <Box>
        <Box className="header" sx={{ backgroundColor: '#CEF0C9 !important' }}>
          <Typography textAlign="center" fontWeight="bold">
            SPECIAL LAB EXAM RESULT
          </Typography>
        </Box>

        <Box display="grid" className="body" gridTemplateColumns={'1.5fr 3fr 2fr'}>
          <Typography>EXAMINATION</Typography>
          <Typography>RESULT</Typography>
          <Box></Box>

          <Typography fontWeight={600}>HBsAg Screening</Typography>
          <Typography sx={underlineStyle}>{getAPEAnswer('Hepatitis B Result')}</Typography>
          <Box></Box>

          <Typography fontWeight={600}>Pregnancy Test</Typography>
          <Typography sx={underlineStyle}>{getAPEAnswer('Pregnancy Test')}</Typography>
          <Box></Box>

          <Typography fontWeight={600}>Hepatitis A Screening (HAV IgM)</Typography>
          <Typography sx={underlineStyle}>{getAPEAnswer('Hepatitis A Result')}</Typography>
          <Box></Box>
        </Box>
      </Box>

      <Box
        display="grid"
        gridTemplateColumns="1fr 1fr"
        className="body"
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <SignatureSelector
            type={SIGNATURE_TYPES.MEDTECH}
            // isPrinting={printMode}
            defaultStaff={{
              name: 'Jade Ella B. Vergara, RMT',
              title: 'Medical Technologist',
              licenseNumber: 'LN 0114334',
              signature: '/elsewhere_signatory/Jade Ella Vergara.png',
            }}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <SignatureSelector
            type={SIGNATURE_TYPES.MEDTECH}
            // isPrinting={printMode}
            defaultStaff={{
              name: 'Jade Ella B. Vergara, RMT',
              title: 'Medical Technologist',
              licenseNumber: 'LN 0114334',
              signature: '/elsewhere_signatory/Jade Ella Vergara.png',
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
