import { Box } from '@mui/material';
import { createDispenseRecord } from 'company/api/prescription-dispense';
import TemplateForm from 'company/entities/Template/TemplateForm';
import {
  DispenseLineItemModel,
  DispenseLineItemFields,
  DispenseLineItemVisibleFields,
  dispenseLineInitialValues,
  createDispenseLineSchema,
} from './DispenseLineItemModel';

type Props = {
  lineItem: any;
  facility_id: number;
  callbackAfterSubmit?: () => void;
};

const DispenseMedicineForm: React.FC<Props> = ({ lineItem, facility_id, callbackAfterSubmit }) => {
  const remainingQuantity = lineItem.quantity - (lineItem.dispensed_quantity || 0);

  const handleSubmit = (data: DispenseLineItemModel) => {
    return createDispenseRecord(facility_id, {
      prescription_line_item_id: lineItem.id,
      medicine_id: lineItem.medicine_id,
      facility_id,
      dispensed_quantity: data.dispensed_quantity,
      remarks: data.remarks,
    });
  };

  return (
    <Box>
      <TemplateForm
        submitButtonId="dispense_medicine_submit_button"
        entity={dispenseLineInitialValues}
        entityName="Medicine Dispense"
        fields={DispenseLineItemFields}
        visibleFields={DispenseLineItemVisibleFields}
        initialValues={dispenseLineInitialValues}
        formSubmitApiFunction={handleSubmit}
        callbackAfterSubmit={callbackAfterSubmit}
        schema={createDispenseLineSchema(remainingQuantity)}
      />
    </Box>
  );
};

export default DispenseMedicineForm;
