import { CompanyProtectedComponent, ConfirmationDialog, CustomModal, RegularButton } from 'core/components';
import { ReactNode, useContext, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FacilityContext } from 'core/context/facility.context';
import ImageUpload from 'company/screens/ClinicManagement/components/ImageUpload';
import { PatientForm } from 'company/entities/forms';
import { PatientModel } from 'company/entities/modules/ClinicManagement/Patient/PatientModel';
import { SIZES } from 'theme/constants';
import { deletePatient } from 'company/api/patient';
import { useSnackbar } from 'notistack';

type Props = {
  patient: PatientModel;
  extraAction?: ReactNode;
  onUpdate?: (id: number, data: PatientModel) => void;
  onDelete?: (id: number) => void;
  showProfile?: boolean;
};

const PatientHeader: React.FC<Props> = ({ patient, showProfile, extraAction, onUpdate, onDelete }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { facility } = useContext(FacilityContext);
  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const [picture, setPicture] = useState<any>(patient.profile_photo);

  const handleDelete = async () => {
    try {
      await deletePatient(facility.id, patient.patient_id);
      enqueueSnackbar('Patient successfully deleted!', { variant: 'success' });
      onDelete && onDelete(patient.patient_id);
    } catch (error) {
      enqueueSnackbar('Error occured! Unable to delete patient.', { variant: 'error' });
    } finally {
      setOpenDeleteDialog(false);
    }
  };

  const updateData = useMemo(
    () => ({
      ...patient,
      tags: patient?.tags.map((tag: any) => {
        return { key: tag.tag_name, value: tag.id };
      }),
    }),
    [patient]
  );

  useEffect(() => {
    setPicture(patient.profile_photo);
  }, [patient]);

  return (
    <Box display="flex" gap="20px" alignItems="flex-start" justifyContent="space-between" mb={SIZES.padding}>
      <Box display="flex" gap="20px" alignItems="center" flexDirection={!onUpdate ? 'column' : 'row'}>
        {showProfile && (
          <ImageUpload
            picture={picture}
            setPicture={(url) => {
              patient.profile_photo = url;
              setPicture(url);
            }}
            facility_id={facility.id}
            patient_id={patient.patient_id}
          />
        )}
      </Box>

      {onUpdate && onDelete && (
        <Box display="flex" gap={SIZES.paddingS} alignItems="center">
          {extraAction}

          {!extraAction && (
            <>
              <CompanyProtectedComponent requiredAuth={['UPDATE_PATIENT']}>
                <RegularButton
                  size="small"
                  variant="outlined"
                  label="Update"
                  onClick={() => setOpenUpdateModal(true)}
                  startIcon={<EditOutlinedIcon />}
                />
              </CompanyProtectedComponent>
              <CompanyProtectedComponent requiredAuth={['DELETE_PATIENT']}>
                <RegularButton
                  size="small"
                  variant="outlined"
                  color="error"
                  label="Delete"
                  onClick={() => setOpenDeleteDialog(true)}
                  startIcon={<DeleteOutlinedIcon />}
                />
              </CompanyProtectedComponent>
            </>
          )}
        </Box>
      )}

      <CustomModal header="Update Patient" open={openUpdateModal} setOpen={setOpenUpdateModal}>
        <PatientForm
          facility={facility}
          patient={updateData}
          callbackAfterSubmit={(data) => {
            setOpenUpdateModal(false);
            onUpdate && onUpdate(patient.patient_id, data);
          }}
        />
      </CustomModal>

      <ConfirmationDialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        title="Delete Patient"
        content={`Are you sure you want to delete patient "${patient ? patient.full_name : ''}"?`}
        onConfirm={handleDelete}
      />
    </Box>
  );
};

export default PatientHeader;
