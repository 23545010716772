import TemplateDropdown, { TemplateDropdownProps } from './TemplateDropdown';
import { getAllServiceCategories, getServiceCategory } from 'company/api/service-categories';

import { ApiQuery } from 'core/model/interface';
import { FacilityContext } from 'core/context/facility.context';
import { useContext } from 'react';

const ServiceCategorySearchDropdown: React.FC<TemplateDropdownProps> = (props: TemplateDropdownProps) => {
  const { facility } = useContext(FacilityContext);

  function getData(query: ApiQuery) {
    return getAllServiceCategories(facility.id, query);
  }

  function getSingleData(id: number) {
    return getServiceCategory(facility.id, id);
  }
  return (
    <TemplateDropdown
      {...props}
      entity={'Service Category'}
      getData={(query: ApiQuery) => getData({ ...query, order_by: undefined })}
      getById={(id: number) => getSingleData(id)}
      // processOption={(record) => ({ key: record.service_category_name, value: record.id, data: { ...record } })}
      processOption={(record) => ({ key: record.service_category_name, value: record.id })}
      processNewRecord={(record) => record.data}
    />
  );
};

export default ServiceCategorySearchDropdown;
