import { Header, HeaderMobile } from './Styles';
import Navbar from './Navbar';
import styled from 'styled-components';

import { useNavigate } from 'react-router-dom';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { tokens } from 'theme/theme';
import { useEffect, useRef } from 'react';

const Scroll1 = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:768px)');
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const tryFunc = () => {
    navigate('/demo');
  };
  const scroll1Ref: any = useRef(null);

  return (
    <>
      {isMobile ? (
        // Mobile layout
        <Box id={'home'}>
          <MobileContainer>
            <MobileContent>
              <Box
                sx={{
                  width: '400px',
                  position: 'relative',
                  left: '5%',
                  top: '15%',
                }}
              >
                <ImagesMobile src="./assets//LandingMobile/Banner-Text 1.png" alt="Logo" style={{ width: '78%' }} />
              </Box>
              <Box
                sx={{
                  width: '100%',
                  height: 'auto',
                  backgroundColor: colors.light_blue_background_2,
                  borderRadius: '12px',
                  padding: '10px',
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    height: '260px',
                    backgroundColor: colors.primary,
                    borderRadius: '8px',
                  }}
                >
                  <img
                    src="./assets/LandingMobile/carego_demo_gif.gif"
                    style={{ width: '100%', height: '100%', borderRadius: '8px' }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  width: '400px',
                  position: 'relative',
                  right: '10%',
                  bottom: '20px',
                }}
              >
                <ImagesMobile src="./assets//LandingMobile/Banner-Text 2.png" alt="Logo" style={{ width: '70%' }} />
              </Box>
              <HeaderMobile>
                On-demand automated clinic monitoring with quick, secure, and efficient data management or reporting
                from all your clinics.
              </HeaderMobile>
              <ParagraphMobile>Clinic management app for Clinic owners.</ParagraphMobile>
              <ButtonMobile onClick={tryFunc}>Start free 14-day trial</ButtonMobile>
            </MobileContent>
          </MobileContainer>
        </Box>
      ) : (
        // Desktop layout
        <>
          <Navbar />
          <MainContainer>
            <Div>
              <Header>Optimize clinic performance through customized patient care with CareGo Health Suite</Header>
              <Paragraph>
                We help health providers deliver more personalized care and services through automated reminders and
                management of patient data.
              </Paragraph>
              <Button onClick={tryFunc}>Get started</Button>
            </Div>
            <Images src="./assets//Landing2/p1/banner.png" alt="Logo" />
          </MainContainer>
        </>
      )}
    </>
  );
};

export default Scroll1;
const Images = styled.img`
  height: auto;
  position: absolute;
  right: 0;

  max-width: 100%;
  @media (max-width: 1700px) {
    max-width: 55%;
  }
  @media (max-width: 768px) {
    max-width: 88%;
    right: 0;
  }
`;

const ImagesMobile = styled.img``;

const MainContainer = styled.div`
  width: 100vw;
  height: 95%;
  display: flex;
  justify-content: center;
  background-image: url('./assets//Landing2/p1/tryy.png');
  background-size: cover;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow-x: hidden;
  @media (max-width: 768px) {
    justify-content: none;
    height: 92%;
    background-size: 100% 100%;
    background-image: url('./assets//LandingMobile/background1.png');
  }
`;

const MainContainerMobile = styled.div`
  width: 100vw;
  height: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow-x: hidden;
  @media (max-width: 768px) {
    justify-content: none;
    height: 92%;
    background-size: 100% 100%;
    background-image: url('./assets//LandingMobile/background1.png');
  }
`;

const Div = styled.div`
  position: absolute;
  display: flex;
  left: 15%;
  top: 30%;
  flex-direction: column;

  max-width: 550px;

  @media (max-width: 1700px) {
    max-width: 480px;
    top: 25%;
    left: 12%;
  }
  @media (max-width: 768px) {
    top: 56%;
    left: 12%;
  }
`;

const DivMobile = styled.div`
  position: absolute;
  display: flex;
  left: 15%;
  top: 30%;
  flex-direction: column;
  align-items: center;
  max-width: 550px;

  @media (max-width: 1700px) {
    max-width: 480px;
    top: 25%;
    left: 12%;
  }
  @media (max-width: 768px) {
    top: 56%;
    left: 0%;
  }
`;

const Paragraph = styled.p`
  font-size: 20px;
  word-spacing: 2px;
  font-family: Nunito;
  padding-right: 40px;
  color: white;
  @media (max-width: 1500px) {
    font-size: 15px;
  }
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const Button = styled.button`
  width: 120px;
  font-family: Nunito;
  padding: 15px;
  border-radius: 25px;
  background-color: transparent;
  border: 1px solid white;
  font-weight: bold;
  text-align: center;
  color: white;
  cursor: pointer;
  z-index: 4;
  @media (max-width: 768px) {
    width: 100px;
    padding: 12px;
    font-size: 10px;
  }
`;

const MobileContainer = styled.div`
  width: 100%;
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: url('./assets//LandingMobile/background1.png');
  // background-size: cover;
  // background-position: center;
  // background-repeat: no-repeat;
  padding-bottom: 50px;
`;

const MobileContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  text-align: center;
`;

const ParagraphMobile = styled(Paragraph)`
  padding-right: 0;
  margin: 20px 0;
`;

const ButtonMobile = styled(Button)`
  width: auto;
  padding: 16px 24px;
  border-radius: 12px;
  margin-top: 20px;
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 0.4),
    rgba(173, 216, 230, 0.3)
  ); // Faint white to light blue gradient
  font-weight: 700; // Heavier font weight
  border: none; // Remove the border to let the gradient show
  color: white; // Ensure text is white for contrast
  font-size: 14px; // Slightly larger font size for better readability
  transition: all 0.3s ease; // Smooth transition for hover effects

  &:hover {
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.3),
      rgba(173, 216, 230, 0.4)
    ); // Slightly more opaque on hover
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); // Add a subtle shadow on hover
  }
`;
