import { APE_FIELD, getFindings, getNoteResponses } from 'company/screens/HealthAssessments/Summarized/source_fields';
import CustomDateRangeFilter, { DateRangeValue } from 'core/components/CustomDateRangeFilter';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import DashboardBarChart from 'company/screens/InventorySystem/Dashboard/components/DashboardBarChart';
import DashboardPieChart from 'company/screens/InventorySystem/Dashboard/components/DashboardPieChart';
import DashboardStackedBarChart from 'company/screens/InventorySystem/Dashboard/components/DashboardStackedBarChart';
import { ServiceModel } from 'company/entities/modules/ClinicManagement/Service/ServiceModel';
import { calculateAge } from 'core/utils';
import { getCompanyReport } from 'company/api/corporate-clients';

type Props = {
  companyId: number;
};

const CompanyReport: React.FC<Props> = ({ companyId }) => {
  const [filterDateRange, setFilterDateRange] = useState<DateRangeValue>([undefined, undefined]);
  const [records, setRecords] = useState<any[]>([]);
  const testResult = useMemo(() => ({ Normal: [0], 'With Findings': [0, {}], 'No Data': [0] }), []);

  const getPieData = (data: any, processValue?: (value: any) => number) => {
    return Object.keys(data)
      .filter((key) => key !== 'No Data' || data[key] > 0)
      .map((key: string) => ({
        value: processValue ? processValue(data[key]) : data[key],
        label: key,
      }));
  };

  const getRawData = async () => {
    const start_date = filterDateRange[0];
    const end_date = filterDateRange[1];
    setRecords([]);
    for (let page = 1; page <= 10; page++) {
      getCompanyReport(companyId, { start_date, end_date, page: page }).then((res) => {
        const data: any[] = res.data;
        if (data.length) setRecords((prev) => prev.concat(data));
      });
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getRawData();
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [companyId, filterDateRange]);

  const sexData = useMemo(() => {
    return records.reduce((res, item) => {
      const age = item.birthday ? calculateAge(item.birthday, true) : item.age ?? 0;
      if (!res[item.sex]) {
        res[item.sex] = { total: 1, age: [age] };
      } else {
        res[item.sex].total = res[item.sex].total + 1;
        res[item.sex].age.push(age);
      }
      return res;
    }, {});
  }, [records]);

  const findings = useMemo(() => {
    return records
      .filter((record) => !!record.notes)
      .map((record) => {
        const fields = getNoteResponses(record.notes, { sex: record.sex } as any);
        const initial = Object.values(APE_FIELD).reduce((res: any, field) => {
          res[field] = 'No Data';
          return res;
        }, {});

        initial[APE_FIELD.PREGNANT] = record.findings?.toLowerCase().includes('pregnant') ? 'Pregnant' : 'No Data';
        initial[APE_FIELD.CLASS] = record.class || 'No Data';
        initial[APE_FIELD.AGE] = record.birthday ? calculateAge(record.birthday, true) : record.age ?? 0;
        initial[APE_FIELD.SEX] = record.sex;

        return fields.reduce((result: any, field) => {
          const [findings] = getFindings(field, true);
          if (!findings) return result;

          if (findings) result[field.field] = findings;
          else result[field.field] = 'Normal';
          // else if (noData) result[field.field] = 'No Data';

          return result;
        }, initial);
      });
  }, [records]);
  // console.log(findings);

  const getAgeCategory = (age: number) => {
    if (age === 0) return 'No Data';
    else if (age < 35) return 'Under 35';
    else return '35 and Above';
  };

  const getAgeData = useCallback(
    (sex: string) => {
      if (sexData[sex]) {
        const ages: number[] = sexData[sex].age;
        const final: any = ages.reduce(
          (res, item) => {
            res[getAgeCategory(item)] += 1;
            return res;
          },
          { 'Under 35': 0, '35 and Above': 0, 'No Data': 0 }
        );

        return getPieData(final);
      }

      return [];
    },
    [sexData]
  );

  const addFindings = (
    result: any,
    findings: string[],
    age: number,
    sex: string,
    addNormalInWithFindings?: boolean
  ) => {
    const allIsNoData = findings.length && findings.every((finding) => finding === 'No Data');
    const allIsNormal =
      findings.length &&
      findings.every((finding) => finding === 'Normal' || finding === 'No Data' || finding === 'Normal ECG');

    if (!addNormalInWithFindings && allIsNoData) {
      result['No Data'][0] += 1;
    } else if (!addNormalInWithFindings && allIsNormal) {
      result['Normal'][0] += 1;
    } else if (findings.length) {
      if (addNormalInWithFindings) {
        if (allIsNormal) {
          result['Normal'][0] += 1;
        } else {
          result['With Findings'][0] += 1;
        }
      } else {
        result['With Findings'][0] += 1;
      }
      const findingsRes = result['With Findings'][1];
      findings
        .filter((finding) => addNormalInWithFindings || (finding !== 'No Data' && finding !== 'Normal'))
        .forEach((finding) => {
          const key = sex.charAt(0) + ' - ' + getAgeCategory(age);
          if (!findingsRes[finding]) {
            const breakdown: any = {
              'M - Under 35': 0,
              'M - 35 and Above': 0,
              'F - Under 35': 0,
              'F - 35 and Above': 0,
            };
            breakdown[key] = 1;
            findingsRes[finding] = { total: 1, breakdown };
          } else {
            findingsRes[finding].total += 1;
            if (!findingsRes[finding].breakdown[key]) {
              findingsRes[finding].breakdown[key] = 0;
            }
            findingsRes[finding].breakdown[key] += 1;
          }
        });
    }
  };

  const getFindingsChartData = useCallback(
    (fields: APE_FIELD[], addNormalInWithFindings?: boolean) => {
      const final: any = findings.reduce((res: any, finding: any) => {
        let results = fields.map((field) => finding[field]).filter((field) => !!field);

        results = results.filter((item, index) => results.indexOf(item) === index); //remove duplicates
        addFindings(res, results, finding[APE_FIELD.AGE], finding[APE_FIELD.SEX], addNormalInWithFindings);
        return res;
      }, JSON.parse(JSON.stringify(testResult)));
      return [
        getPieData(final, (data) => data[0]),
        getPieData(final['With Findings'][1], (data) => data.total),
        final['With Findings'][1],
      ];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [findings, testResult]
  );

  const processFindingsBreakdown = useCallback(
    (
      chartData: any,
      setChartDataKeys: (key: string[]) => void,
      setChartDataValues: (values: number[]) => void,
      breakdownFilter?: (data: any) => any[]
    ) => {
      const findings = Object.keys(chartData);
      if (findings.length) {
        const breakdownKeys: any[] = Object.keys(chartData[findings[0]].breakdown).map((key) => ({
          label: key,
          data: [],
        }));
        findings.forEach((key) => {
          const breakdown: any = chartData[key].breakdown;
          const _breakdownKeys = Object.keys(breakdown);
          _breakdownKeys.forEach((_breakdownKey) => {
            const found = breakdownKeys.find((_key) => _key.label === _breakdownKey);
            if (found) {
              const count = breakdown[_breakdownKey] ?? 0;
              found.data.push(count);
            }
          });
        });
        setChartDataKeys(findings);
        setChartDataValues(breakdownFilter ? breakdownFilter(breakdownKeys) : breakdownKeys);
        console.log(findings, breakdownKeys);
      }
    },
    []
  );

  const sexChartData = useMemo(() => {
    const all = getPieData(sexData, (data) => data.total);
    const female = getAgeData('Female');
    const male = getAgeData('Male');

    return [all, female, male];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAgeData]);

  const cbcChartData = useMemo(() => {
    return getFindingsChartData([APE_FIELD.CBC]);
  }, [getFindingsChartData]);

  const urinalysisChartData = useMemo(() => {
    return getFindingsChartData([
      APE_FIELD.URINALYSIS_PROTEIN,
      APE_FIELD.URINALYSIS_RBC,
      APE_FIELD.URINALYSIS_SUGAR,
      APE_FIELD.URINALYSIS_WBC,
      APE_FIELD.URINALYSIS_PROTEIN_RBC,
    ]);
  }, [getFindingsChartData]);

  const bloodChemistryChartData = useMemo(() => {
    return getFindingsChartData([
      APE_FIELD.CC_BUA,
      APE_FIELD.CC_CREATININE,
      APE_FIELD.CC_SGOT,
      APE_FIELD.CC_SGPT,
      APE_FIELD.CC_CHOLESTEROL,
      APE_FIELD.CC_TRIGLYCERIDE,
      APE_FIELD.CC_LDL,
      APE_FIELD.CC_FBS,
    ]);
  }, [getFindingsChartData]);

  // const peChartData = useMemo(() => {
  //   return getFindingsChartData([
  //     APE_FIELD.PE_HEAD,
  //     APE_FIELD.PE_EYES,
  //     APE_FIELD.PE_EARS,
  //     APE_FIELD.PE_NOSE,
  //     APE_FIELD.PE_MOUTH,
  //     APE_FIELD.PE_NECK,
  //     APE_FIELD.PE_BREAST,
  //     APE_FIELD.PE_LUNGS,
  //     APE_FIELD.PE_HEART,
  //     APE_FIELD.PE_ABDOMEN,
  //     APE_FIELD.PE_MUSCOLOSKELETAL,
  //     APE_FIELD.PE_ANUS,
  //     APE_FIELD.PE_GENITALS,
  //     APE_FIELD.PE_EXTREMITIES,
  //     APE_FIELD.PE_SKIN,
  //   ]);
  // }, [getFindingsChartData]);

  const bmiChartData = useMemo(() => {
    return getFindingsChartData([APE_FIELD.BMI], true);
  }, [getFindingsChartData]);

  const classChartData = useMemo(() => {
    return getFindingsChartData([APE_FIELD.CLASS], true);
  }, [getFindingsChartData]);

  const skinChartData = useMemo(() => {
    return getFindingsChartData([APE_FIELD.PE_SKIN]);
  }, [getFindingsChartData]);

  const anusChartData = useMemo(() => {
    return getFindingsChartData([APE_FIELD.PE_ANUS]);
  }, [getFindingsChartData]);

  const dentalChartData = useMemo(() => {
    return getFindingsChartData([
      APE_FIELD.DENTAL_CARIES,
      APE_FIELD.DENTAL_DENTURES,
      APE_FIELD.DENTAL_EXTRACTION,
      APE_FIELD.DENTAL_NON_RESTORABLE_TOOTH,
      APE_FIELD.DENTAL_ORAL_PROPHYLAXIS,
      APE_FIELD.DENTAL_RESTORATION,
      APE_FIELD.DENTAL_SEVERE_TOOTH_DECAY,
    ]);
  }, [getFindingsChartData]);

  const heartAndBPChartData = useMemo(() => {
    return getFindingsChartData([APE_FIELD.PE_HEART, APE_FIELD.BP_CLASS]);
  }, [getFindingsChartData]);

  const reproductiveChartData = useMemo(() => {
    return getFindingsChartData([APE_FIELD.PE_BREAST, APE_FIELD.PREGNANT]);
  }, [getFindingsChartData]);

  const eentChartData = useMemo(() => {
    return getFindingsChartData([APE_FIELD.PE_EYES, APE_FIELD.PE_EARS, APE_FIELD.PE_NOSE, APE_FIELD.PE_MOUTH]);
  }, [getFindingsChartData]);

  const musculoskeletalChartData = useMemo(() => {
    return getFindingsChartData([APE_FIELD.PE_MUSCOLOSKELETAL]);
  }, [getFindingsChartData]);

  const respiratoryChartData = useMemo(() => {
    return getFindingsChartData([APE_FIELD.PE_LUNGS, APE_FIELD.SMOKING_HISTORY]);
  }, [getFindingsChartData]);

  const genitalsChartData = useMemo(() => {
    return getFindingsChartData([APE_FIELD.PE_GENITALS]);
  }, [getFindingsChartData]);

  const xRayChartData = useMemo(() => {
    return getFindingsChartData([APE_FIELD.CHEST_XRAY]);
  }, [getFindingsChartData]);

  const ecgChartData = useMemo(() => {
    return getFindingsChartData([APE_FIELD.ECG]);
  }, [getFindingsChartData]);

  const eyeTestChartData = useMemo(() => {
    return getFindingsChartData([APE_FIELD.VISUAL_ACUITY, APE_FIELD.ISHIHARA]);
  }, [getFindingsChartData]);

  const processFindings = useCallback(
    (chartData: any, setChartDataKeys: (key: string[]) => void, setChartDataValues: (values: number[]) => void) => {
      let data: any[] = chartData[1];
      data = data.sort((a, b) => b.value - a.value);

      if (data) {
        const values = data.map((item: any) => parseInt(item.value));
        const names = data.map((item: any) => item.label);
        setChartDataKeys(names);
        setChartDataValues(values);
      }
    },
    []
  );

  const processTestsDone = useCallback(
    (setChartDataKeys: (key: string[]) => void, setChartDataValues: (values: number[]) => void) => {
      const data: any[] = records.reduce((result: any, record) => {
        const services: ServiceModel[] = record.services;
        return services.reduce((res: any, service) => {
          if (res[service.service_name]) {
            res[service.service_name] += 1;
          } else {
            res[service.service_name] = 1;
          }
          return res;
        }, result);
      }, {});
      if (data) {
        // const values = data.map((item) => parseInt(item.value));
        // const names = data.map((item) => item.label);
        setChartDataKeys(Object.keys(data));
        setChartDataValues(Object.values(data));
      }
    },
    [records]
  );

  const processPEFindings = useCallback(
    (setChartDataKeys: (key: string[]) => void, setChartDataValues: (values: number[]) => void) => {
      const dataNames: string[] = [];
      const dataValues: number[] = [];

      const getWithFindingsCount = (chartData: any[], label: string) => {
        const _chartData: any[] = chartData[1];
        const value = _chartData
          .filter((data: any) => data.label !== 'Normal' && data.label !== 'No Data')
          .reduce((result, data) => result + data.value, 0);
        dataValues.push(value);
        dataNames.push(label);
      };

      getWithFindingsCount(bmiChartData, 'BMI');
      getWithFindingsCount(dentalChartData, 'Dental');
      getWithFindingsCount(skinChartData, 'Skin');
      getWithFindingsCount(heartAndBPChartData, 'Heart and Blood Vessels');
      getWithFindingsCount(respiratoryChartData, 'Respiratory');
      getWithFindingsCount(eentChartData, 'EENT');
      getWithFindingsCount(anusChartData, 'Gastrointestinal');
      getWithFindingsCount(reproductiveChartData, 'Reproductive');
      getWithFindingsCount(musculoskeletalChartData, 'Musculoskeletal');
      getWithFindingsCount(genitalsChartData, 'Genito-Urinary');

      // data = data.sort((a, b) => b.value - a.value);

      // if (data) {
      //   const values = data.map((item: any) => parseInt(item.value));
      //   const names = data.map((item: any) => item.label);
      setChartDataKeys(dataNames);
      setChartDataValues(dataValues);
      // }
    },
    [
      bmiChartData,
      dentalChartData,
      skinChartData,
      heartAndBPChartData,
      respiratoryChartData,
      eentChartData,
      anusChartData,
      reproductiveChartData,
      musculoskeletalChartData,
      genitalsChartData,
    ]
  );

  return (
    <Box>
      <CustomDateRangeFilter setFilterDateRange={setFilterDateRange} />
      {/* <DashboardPieChart /> */}
      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap="20px" mt="20px">
        <DashboardPieChart title="Male / Female Report" chartData={sexChartData[0]} decimalPlaces={0} />
        <DashboardPieChart title="Male - Age Group" chartData={sexChartData[2]} decimalPlaces={0} />
        <DashboardPieChart title="Female - Age Group" chartData={sexChartData[1]} decimalPlaces={0} />
        <DashboardPieChart title="BMI" chartData={bmiChartData[1]} decimalPlaces={0} />
        <DashboardBarChart
          title="Procedures Done"
          xAxisLabel="Procedures"
          yAxisLabel="Total"
          processData={processTestsDone}
          colSpan={12}
        />
        <DashboardPieChart title="CBC" chartData={cbcChartData[0]} decimalPlaces={0} />
        <DashboardBarChart
          title="CBC Findings"
          xAxisLabel="Findings"
          yAxisLabel="Total"
          processData={(setChartDataKeys, setChartDataValues) =>
            processFindings(cbcChartData, setChartDataKeys, setChartDataValues)
          }
        />

        <DashboardPieChart title="Urinalysis" chartData={urinalysisChartData[0]} decimalPlaces={0} />
        <DashboardBarChart
          title="Urinalysis Findings"
          xAxisLabel="Findings"
          yAxisLabel="Total"
          processData={(setChartDataKeys, setChartDataValues) =>
            processFindings(urinalysisChartData, setChartDataKeys, setChartDataValues)
          }
        />

        <DashboardPieChart title="Blood Chemistry" chartData={bloodChemistryChartData[0]} decimalPlaces={0} />
        <DashboardBarChart
          title="Blood Chemistry Findings"
          xAxisLabel="Findings"
          yAxisLabel="Total"
          processData={(setChartDataKeys, setChartDataValues) =>
            processFindings(bloodChemistryChartData, setChartDataKeys, setChartDataValues)
          }
        />
        <DashboardStackedBarChart
          title="Blood Chemistry Findings"
          xAxisLabel="Findings"
          yAxisLabel="Total"
          processData={(setChartDataKeys, setChartDataValues) =>
            processFindingsBreakdown(bloodChemistryChartData[2], setChartDataKeys, setChartDataValues)
          }
          colSpan={12}
        />

        <DashboardPieChart title="X-Ray" chartData={xRayChartData[0]} decimalPlaces={0} />
        <DashboardBarChart
          title="X-Ray Findings"
          xAxisLabel="Findings"
          yAxisLabel="Total"
          processData={(setChartDataKeys, setChartDataValues) =>
            processFindings(xRayChartData, setChartDataKeys, setChartDataValues)
          }
        />

        <DashboardPieChart title="ECG" chartData={ecgChartData[0]} decimalPlaces={0} />
        <DashboardBarChart
          title="ECG Findings"
          xAxisLabel="Findings"
          yAxisLabel="Total"
          processData={(setChartDataKeys, setChartDataValues) =>
            processFindings(ecgChartData, setChartDataKeys, setChartDataValues)
          }
        />
        <DashboardStackedBarChart
          title="ECG Findings"
          xAxisLabel="Findings"
          yAxisLabel="Total"
          processData={(setChartDataKeys, setChartDataValues) =>
            processFindingsBreakdown(ecgChartData[2], setChartDataKeys, setChartDataValues, (breakdown) =>
              breakdown.filter((item: any) => item.label.includes('35 and Above'))
            )
          }
          colSpan={12}
        />

        <DashboardPieChart title="Eye Test" chartData={eyeTestChartData[0]} decimalPlaces={0} />
        <DashboardBarChart
          title="Eye Test Findings"
          xAxisLabel="Findings"
          yAxisLabel="Total"
          processData={(setChartDataKeys, setChartDataValues) =>
            processFindings(eyeTestChartData, setChartDataKeys, setChartDataValues)
          }
        />

        <DashboardBarChart
          title="Abnormal Physical Examination Report"
          xAxisLabel="Findings"
          yAxisLabel="Total"
          processData={processPEFindings}
          colSpan={12}
        />

        <DashboardPieChart title="Dental Report" chartData={dentalChartData[0]} decimalPlaces={0} />
        <DashboardBarChart
          title="Dental Findings"
          xAxisLabel="Findings"
          yAxisLabel="Total"
          processData={(setChartDataKeys, setChartDataValues) =>
            processFindings(dentalChartData, setChartDataKeys, setChartDataValues)
          }
        />
        <DashboardBarChart
          title="Skin Findings"
          xAxisLabel="Findings"
          yAxisLabel="Total"
          processData={(setChartDataKeys, setChartDataValues) =>
            processFindings(skinChartData, setChartDataKeys, setChartDataValues)
          }
        />

        <DashboardBarChart
          title="Gastrointestinal Findings"
          xAxisLabel="Findings"
          yAxisLabel="Total"
          processData={(setChartDataKeys, setChartDataValues) =>
            processFindings(anusChartData, setChartDataKeys, setChartDataValues)
          }
        />

        <DashboardBarChart
          title="Heart and Blood Vessel Findings"
          xAxisLabel="Findings"
          yAxisLabel="Total"
          processData={(setChartDataKeys, setChartDataValues) =>
            processFindings(heartAndBPChartData, setChartDataKeys, setChartDataValues)
          }
        />

        <DashboardBarChart
          title="Reproductive/Breast Findings"
          xAxisLabel="Findings"
          yAxisLabel="Total"
          processData={(setChartDataKeys, setChartDataValues) =>
            processFindings(reproductiveChartData, setChartDataKeys, setChartDataValues)
          }
        />

        <DashboardBarChart
          title="EENT Findings"
          xAxisLabel="Findings"
          yAxisLabel="Total"
          processData={(setChartDataKeys, setChartDataValues) =>
            processFindings(eentChartData, setChartDataKeys, setChartDataValues)
          }
        />

        <DashboardBarChart
          title="Musculoskeletal Findings"
          xAxisLabel="Findings"
          yAxisLabel="Total"
          processData={(setChartDataKeys, setChartDataValues) =>
            processFindings(musculoskeletalChartData, setChartDataKeys, setChartDataValues)
          }
        />

        <DashboardBarChart
          title="Respiratory Findings"
          xAxisLabel="Findings"
          yAxisLabel="Total"
          processData={(setChartDataKeys, setChartDataValues) =>
            processFindings(respiratoryChartData, setChartDataKeys, setChartDataValues)
          }
        />

        <DashboardBarChart
          title="Genito-Urinary Findings"
          xAxisLabel="Findings"
          yAxisLabel="Total"
          processData={(setChartDataKeys, setChartDataValues) =>
            processFindings(genitalsChartData, setChartDataKeys, setChartDataValues)
          }
        />

        <DashboardPieChart title="Classifications" chartData={classChartData[1]} decimalPlaces={0} />
      </Box>
    </Box>
  );
};

export default CompanyReport;
