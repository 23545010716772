import * as yup from 'yup';

import { DROPDOWN_FIELD } from 'core/model/interface';

export const SERVICE_CATEGORY_ENTITY_NAME = 'Service Category';

export interface ServiceCategoryModel {
  id: number;
  company_id: number;
  service_category_name: string;
  parent_id?: number;
  children?: ServiceCategoryModel[];
}

export const serviceCategorySchema = (selectedCategory?: number) =>
  yup.object().shape({
    service_category_name: yup.string().required('Service Category Name is required'),
    parent_id: yup
      .number()
      .nullable()
      .notOneOf([selectedCategory ?? 0], 'Category cannot be a parent of itself.'),
  });

export const serviceCategoryFields = [
  {
    fieldName: 'service_category_name',
    displayName: 'Service Category Name',
    type: 'text',
  },
  {
    fieldName: 'parent_id',
    displayName: 'Parent Category',
    type: DROPDOWN_FIELD.SERVICE_PARENT_CATEGORY,
    optional: true,
  },
];

export const serviceCategoryFormFields = ['service_category_name', 'parent_id'];
export const serviceCategoryUpdateFormFields = ['service_category_name', 'parent_id'];

export const serviceCategoryInitialValues = {
  service_category_name: '',
  parent_id: '',
};
