import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'health-assessments-service-status';

export const getAllHealthAssessmentServices = (facility_id: number, assessment_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/${assessment_id}`, { params: query });
};

export const getAssessmentServiceNotAdded = (facility_id: number, assessment_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/get-services-not-added/${assessment_id}`, { params: query });
};

export const addAssessmentServices = (facility_id: number, data: any) => {
  return axios.post(`${baseURL}/${path}/${facility_id}`, data);
};

export const removeAssessmentService = (facility_id: number, id: number) => {
  return axios.delete(`${baseURL}/${path}/${facility_id}/${id}`);
};

export const updateAssessmentServiceStatus = (facility_id: number, id: number, status: string) => {
  return axios.patch(`${baseURL}/${path}/${facility_id}/${id}/${status}`);
};
