/* eslint-disable no-useless-escape */
import { Box, Tooltip, Typography } from '@mui/material';

import Decimal from 'decimal.js';
import { FacilityModel } from 'core/model/Entities';
import { FieldInput } from 'core/model/interface';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import { PatientNoteModel } from 'company/model/Entities';
import axios from 'core/utils/custom_axios';
import { isTooMany } from 'company/screens/HealthAssessments/Summarized/source_fields';
import moment from 'moment';

export const formatDate = (date?: string | Date, defaultValue?: string) => {
  return date ? moment(date).format('ll') : defaultValue ?? '';
};

export const formatDateWithCompleteMonth = (date?: string | Date) => {
  return date ? moment(date).format('MMMM D, YYYY') : '';
};

export const formatDateTime = (date?: string | Date, defaultValue?: string) => {
  return date ? moment(date).format('lll') : defaultValue ?? '';
};

export const formatTime = (date?: string | Date) => {
  return date ? moment(date).format('LT') : '';
};

export const formatTimeWithoutAMPM = (value: string) => {
  const date = new Date(value);
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');

  hours = hours % 12 || 12;

  return `${hours}:${minutes}`;
};

export const format24HourTime = (time?: string | Date) => {
  return time ? moment(time, ['HH:mm']).format('LT') : '';
};

export const formatDateTimeWithDay = (date?: string | Date) => {
  return date ? moment(date).format('llll') : '';
};

export const formatCalendar = (date?: string | Date) => {
  return date ? moment(date).calendar() : '';
};

export const formatGenLedgerDate = (startDate: Date, endDate: Date) => {
  const startDateStr = formatDate(startDate).split(' ');
  const endDateStr = formatDate(endDate).split(' ');

  startDateStr[1] = startDateStr[1].replace(',', '');
  endDateStr[1] = endDateStr[1].replace(',', '');

  if (startDateStr[0] === endDateStr[0] && startDateStr[1] === endDateStr[1] && startDateStr[2] === endDateStr[2]) {
    return [startDateStr[0], startDateStr[1], startDateStr[2]].join(' ');
  }

  // same month && year checking
  if (startDateStr[0] === endDateStr[0] && startDateStr[2] === endDateStr[2]) {
    return [startDateStr[0], `${startDateStr[1]} - ${endDateStr[1]}`, startDateStr[2]].join(' ');
  }

  // same year checking
  if (startDateStr[2] === endDateStr[2]) {
    return [startDateStr[0], startDateStr[1], '-', endDateStr[0], endDateStr[1], startDateStr[2]].join(' ');
  }

  return [startDateStr[0], startDateStr[1], startDateStr[2], '-', endDateStr[0], endDateStr[1], endDateStr[2]].join(
    ' '
  );
};

export const calculateAge = (birthday?: string | Date, yearsOnly?: boolean) => {
  // if (!birthday) return 0;

  if (yearsOnly && birthday) {
    var diff_ms = Date.now() - new Date(birthday).getTime();
    var age_dt = new Date(diff_ms);

    return Math.abs(age_dt.getUTCFullYear() - 1970);
  }

  if (!birthday) return 0;
  const now = new Date();
  const midnight = new Date(now.setHours(0, 0, 0, 0)); // Set time to 12:00 AM

  // Calculate the difference in time
  const diffInMs = midnight.getTime() - new Date(birthday).getTime();

  if (diffInMs < 0) {
    return 'Invalid birthdate'; // Future date check
  }

  // Calculate the difference in weeks, months, and years
  const diffInWeeks = Math.floor(diffInMs / (7 * 24 * 60 * 60 * 1000));
  const diffInMonths = Math.floor(diffInMs / (30.44 * 24 * 60 * 60 * 1000));

  if (diffInWeeks <= 2 * 4) {
    return diffInWeeks + (diffInWeeks === 1 ? ' wk' : ' wks');
  } else if (diffInMonths < 12) {
    return diffInMonths + (diffInMonths === 1 ? ' mo' : ' mos');
  } else {
    const age_dt = new Date(diffInMs);
    const age = Math.abs(age_dt.getUTCFullYear() - 1970);
    return age + (age === 1 ? ' yr' : ' yrs');
  }
};

export const tranformFormErrors = (errors: any) => {
  for (var key in errors) {
    errors[key] = errors[key].join(' ');
  }

  return errors;
};

export const changeNullToBlank: any = (obj: any) => {
  for (var key in obj) {
    if (obj[key] === null) {
      obj[key] = '';
    }
  }

  return obj;
};

export const formatDatetimeJS = (datetime: string) => {
  return moment(datetime).format('YYYY-MM-DD HH:mm:ss');
};

export const capitalizeWord = (string: string) => {
  return string ? string.toLowerCase().replace(/(?:^|\s|[-"'([{])+\S/g, (c) => c.toUpperCase()) : '';
};

export const formatNumber = (number: number | string, decimal: number = 2) => {
  let num = parseFloat(number as string);
  if (isNaN(num)) num = 0;
  return num.toLocaleString('en-US', { maximumFractionDigits: decimal, minimumFractionDigits: decimal });
};

export const formatNumberDecimal = (number: number | string, decimal: number = 2) => {
  let num = parseFloat(number as string);
  if (isNaN(num)) num = 0;
  return parseFloat(
    num.toLocaleString('en-US', { maximumFractionDigits: decimal, minimumFractionDigits: decimal }).replaceAll(',', '')
  );
};

export const preciseformatNumber = (number: number | string, decimal: number = 2): string => {
  let numStr = typeof number === 'number' ? number.toString() : number;
  numStr = numStr.replace(/,/g, '');
  let [intPart, fracPart = ''] = numStr.split('.');
  fracPart = fracPart.padEnd(decimal, '0').slice(0, decimal);
  intPart = intPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return decimal > 0 ? `${intPart}.${fracPart}` : intPart;
};

export const formatCurrency = (number: number | string = 0, decimal: number = 2) => {
  return '₱ ' + formatNumber(number, decimal);
};

export const getLineItemDiscountedPrice = (order: any, numberOnly?: boolean) => {
  let vatAmount = 0;
  let lineItemPrice = order.unit_price;

  if (order.sc_pwd_discounted) {
    if (order.tax === 'vat') {
      vatAmount = lineItemPrice * (3 / 28);
      lineItemPrice -= vatAmount;
    }
    lineItemPrice = lineItemPrice * 0.8;
  }

  return numberOnly ? lineItemPrice * order.quantity : formatCurrency(lineItemPrice * order.quantity);
};

export const getDiscountAmount = (order: any, fromPOS?: boolean) => {
  if (fromPOS) {
    return (
      '-' +
      formatCurrency(
        parseFloat(order.original_price) * order.quantity - (getLineItemDiscountedPrice(order, true) as number)
      )
    );
  }

  return '-' + formatCurrency(parseFloat(order.original_price) * order.quantity - parseFloat(order.total_price));
};

export const debounce = <T extends unknown[]>(func: (...args: T) => void, delay: number) => {
  let timeoutId: NodeJS.Timeout;

  return (...args: T) => {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const downloadFromAxios = async (filename: string, downloadLink: string) => {
  try {
    const response = await axios({
      url: downloadLink,
      method: 'GET',
      responseType: 'blob',
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', filename);

    document.body.appendChild(link);

    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading the file:', error);
  }
};

export const replaceCompanySmsTemplate = (template: string, facility: FacilityModel) => {
  if (!template) return '';
  let updated = template
    .replaceAll('[Name]', '`Cheska Dela Cruz`')
    .replaceAll('[First_Name]', '`Cheska`')
    .replaceAll('[Date]', '`' + formatDate(new Date()) + '`')
    .replaceAll('[Time]', '`9:30 AM`')
    .replaceAll('[Health_Program]', '`appointment`')
    .replaceAll('[Day]', '`4`')
    .replaceAll('[Hour]', '`12`');

  if (facility) {
    const contacts = [];
    facility.globe_mobile_number && contacts.push(facility.globe_mobile_number);
    facility.smart_mobile_number && contacts.push(facility.smart_mobile_number);
    updated = updated
      .replaceAll('[Name]', '`Cheska Dela Cruz`')
      .replaceAll('[First_Name]', '`Cheska`')
      .replaceAll('[Date]', '`' + formatDate(new Date()) + '`')
      .replaceAll('[Time]', '`9:30 AM`')
      .replaceAll('[Health_Program]', '`appointment`')
      .replaceAll('[Day]', '`4`')
      .replaceAll('[Hour]', '`12`')
      .replaceAll('[Facility_Type]', '`' + facility?.facility_type + '`')
      .replaceAll('[Facility_Contact]', '`' + contacts.join(' / ') + '`')
      .replaceAll('[Facility_Name]', '`' + facility?.facility_short_name + '`');
  }

  return updated;
};

export const filterArrayByKey = (array: any[], _key: any, key_value: any, value: any) => {
  const filteredItems = array.filter((item) => item[_key] === key_value);
  return filteredItems[0][value];
};

export const filterArrayByValue = (array: any[], _value: any, value_value: any, key: any) => {
  const filteredItems = array.filter((item) => item[_value] === value_value);
  return filteredItems[0][key];
};

export const customReplaceCompanySmsTemplate = (
  facility: FacilityModel,
  template: string,
  patient: any,
  appointment: any
) => {
  let updated = template
    .replaceAll('[Name]', patient?.full_name)
    .replaceAll('[First_Name]', patient?.first_name)
    .replaceAll('[Date]', formatDate(appointment?.schedule))
    .replaceAll('[Time]', formatTime(appointment?.schedule))
    .replaceAll('[Health_Program]', 'appointment')
    .replaceAll('[Day]', '4')
    .replaceAll('[Hour]', '12');

  if (facility) {
    const contacts = [];
    facility.globe_mobile_number && contacts.push(facility.globe_mobile_number);
    facility.smart_mobile_number && contacts.push(facility.smart_mobile_number);
    updated = updated
      .replaceAll('[Name]', patient?.full_name)
      .replaceAll('[First_Name]', patient?.first_name)
      .replaceAll('[Date]', appointment ? formatDate(appointment?.schedule) : formatDate(new Date()))
      .replaceAll('[Time]', appointment ? formatTime(appointment?.schedule) : '9:30 AM')
      .replaceAll('[Health_Program]', 'appointment')
      .replaceAll('[Day]', '4')
      .replaceAll('[Hour]', '12')
      .replaceAll('[Facility_Type]', '' + facility?.facility_type + '')
      .replaceAll('[Facility_Contact]', '' + contacts.join(' / ') + '')
      .replaceAll('[Facility_Name]', '' + facility?.facility_short_name + '');
  }

  return updated;
};

export const formatArray = (data: (string | undefined)[], separator?: string): string => {
  separator = separator ?? ', ';
  return data.filter((item) => !!item).join(separator);
};

export const formatNumericalDate = (date: Date) => {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${date.getFullYear()}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
};

export const generateInstruction = (data: any): string => {
  const displayFrequency = () => {
    if (data.frequency === 'weekly') {
      const days = Array.isArray(data.days_of_week)
        ? data.days_of_week.map((day: any) => day.key).join(', ')
        : data.days_of_week
        ? data.days_of_week
            .split(',')
            .map((day: string) => day.charAt(0).toUpperCase() + day.slice(1))
            .join(', ')
        : '';

      return 'every ' + data.days_of_week ? days : '[day]';
    }
    return data.frequency;
  };

  const displayInterval = () => {
    return data.hours_interval && data.frequency_per_day > 1
      ? `one dose every ${data.hours_interval ?? 0} hour${data.hours_interval > 1 ? 's' : ''}`
      : '';
  };

  let start_date = '';
  if (data.start_date) start_date = moment(data.start_date).format('YYYY-MM-DD');

  if (!data.frequency) return data.instruction ?? '-';

  return (
    `Take ${displayFrequency()}` +
    `${data.frequency_per_day > 1 ? `, ${data.frequency_per_day} times a day, ` : ''}` +
    `${displayInterval()}` +
    `${data.duration ? ` for ${data.duration} day${data.duration > 1 ? 's' : ''}` : ''}` +
    `${
      data.time_to_take || start_date
        ? ` starting at ${
            start_date && !data.time_to_take
              ? formatDate(start_date)
              : start_date
              ? formatDateTime(start_date + ' ' + data.time_to_take)
              : formatTime('2024-01-01 ' + data.time_to_take)
          }`
        : ''
    }. ${data.instruction ?? ''}`
  );
};

export const convertJsonToCsv = (json: object[]): string => {
  if (json.length === 0) return '';

  const headers = Object.keys(json[0]);
  const csvRows: string[] = [];

  // Add the headers row
  csvRows.push(headers.join(','));

  // Add the data rows
  for (const row of json) {
    const values = headers.map((header) => {
      const escaped = ('' + (row as any)[header]).replace(/"/g, '""');
      return `"${escaped}"`;
    });
    csvRows.push(values.join(','));
  }

  return csvRows.join('\n');
};

export const shuffle = (array: any[]) => {
  array = [...array];
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const rotateArray = (array: any[]) => {
  array = [...array];
  const startIndex = Math.floor(Math.random() * array.length);
  return array.slice(startIndex).concat(array.slice(0, startIndex));
};

export const nameToHex = (name: string) => {
  // Convert name to a numerical value by summing ASCII values of characters
  let numericalValue = 0;
  for (let i = 0; i < name.length; i++) {
    numericalValue += name.charCodeAt(i);
  }

  // Use the numerical value to generate RGB values
  const r = (numericalValue * 1234567) % 256;
  const g = (numericalValue * 2345671) % 256;
  const b = (numericalValue * 3456781) % 256;

  // Convert RGB values to a hex string
  const hexColor = `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b
    .toString(16)
    .padStart(2, '0')}`;
  return hexColor;
};

export const formatVariantName = (
  data: any,
  useDefaultFormat?: boolean,
  format?: (value: string) => string
): string => {
  if (!data.variant_name || data.variant_name === data.product_name) return ''; // Check if attributes exist

  try {
    const formattedAttributes = data.variant_name;
    if (useDefaultFormat) return formattedAttributes ? ` (${formattedAttributes})` : '';
    return format ? format(formattedAttributes) : formattedAttributes;
  } catch (error) {
    console.error('Error parsing attributes:', error);
    return '';
  }
};

export const displayAttribute = (data: any) => {
  const combined = formatVariantName(data);
  return (
    <Box display="flex" gap="3px" alignItems="center">
      <LocalMallOutlinedIcon color="primary" sx={{ fontSize: '18px' }} />
      <Tooltip title={combined} placement="left">
        <Typography
          variant="subtitle1"
          fontWeight={500}
          color="#888"
          sx={{
            whiteSpace: 'nowrap',
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: '14px',
          }}
        >
          {combined}
        </Typography>
      </Tooltip>
    </Box>
  );
};

export const formatPaymentMethodLabel = (key: string) => {
  if (key === 'debitcredit') {
    return 'Debit/Credit';
  }
  if (key === 'po') {
    return 'Prosthesis and Orthosis';
  }
  if (key === 'bank') {
    return 'Bank Transfer';
  }
  if (key === 'hmo') {
    return 'HMO';
  }
  return key.charAt(0).toUpperCase() + key.slice(1);
};

export const formatNumberNoRounding = (number: Decimal | number | string, decimals: number = 2): string => {
  const decimalNumber = new Decimal(number);
  return decimalNumber.toFixed(decimals);
};

export const buildAddress = (facility: FacilityModel) => {
  if (facility && facility.address) {
    if (facility.municipality_name) {
      if (facility.province_name) {
        return facility.address + ', ' + facility.municipality_name + ', ' + facility.province_name;
      }
      return facility.address + ', ' + facility.municipality_name;
    }
    return facility.address;
  }
};

export const simpleQueryParser = (path: string) => {
  const queries: any = {};
  const splitPath = path.split('?');
  const rawpath = splitPath[0];

  if (splitPath.length <= 1) return [rawpath, queries];
  splitPath.shift();

  // manual parsing
  for (const queryable of splitPath) {
    const pairs = queryable.split('&');
    for (const pairedkeyvalue of pairs) {
      const keyvalue = pairedkeyvalue.split('=');
      if (keyvalue.length >= 2) queries[keyvalue[0]] = keyvalue[1];
    }
  }

  return [rawpath, queries];
};
// Define token types for operators and numbers
enum TokenType {
  Number,
  Operator,
  LeftParen,
  RightParen,
}

// Define a Token interface
interface Token {
  type: TokenType;
  value: string;
}

// Tokenizer function to split the input string into tokens
function tokenize(expression: string): Token[] {
  const tokens: Token[] = [];
  let current = '';

  const operators = '+-*/()';

  for (let i = 0; i < expression.length; i++) {
    const char = expression[i];

    if (operators.includes(char)) {
      if (current) {
        tokens.push({ type: TokenType.Number, value: current });
        current = '';
      }

      if (char === '(') {
        tokens.push({ type: TokenType.LeftParen, value: char });
      } else if (char === ')') {
        tokens.push({ type: TokenType.RightParen, value: char });
      } else {
        tokens.push({ type: TokenType.Operator, value: char });
      }
    } else if (char.trim()) {
      current += char; // Accumulate number characters
    }
  }

  if (current) {
    tokens.push({ type: TokenType.Number, value: current });
  }

  return tokens;
}

// Helper function to evaluate basic operations
function applyOperator(operator: string, b: number, a: number): number {
  switch (operator) {
    case '+':
      return a + b;
    case '-':
      return a - b;
    case '*':
      return a * b;
    case '/':
      return a / b;
    default:
      throw new Error(`Unknown operator: ${operator}`);
  }
}

// Function to handle operator precedence
function precedence(operator: string): number {
  if (operator === '+' || operator === '-') return 1;
  if (operator === '*' || operator === '/') return 2;
  return 0;
}

// Function to evaluate the expression using two stacks (Shunting Yard Algorithm)
export const evaluateExpression = (expression: string, data?: any): string => {
  if (expression.startsWith('string:')) {
    expression = expression.replace(/[0-9a-fA-F\-]{36}/g, '');
    return expression.replace('string:', '');
  }

  if (expression.startsWith('special_hematology_formula')) {
    const regex = /\(([^)]+)\)/;
    const match = expression.match(regex);

    if (match) {
      const parameters = match[1].split(',');

      const table1: any[] = data[parameters[0]] ?? [];
      let final = table1.reduce((result, item) => {
        const level = item[parameters[1]];
        const test = item[parameters[2]];
        const value = item[parameters[3]];
        if (level && level !== 'Normal') {
          result.push(`${test}: ${level} (${value})`);
        }
        return result;
      }, []);

      const table2: any[] = data[parameters[4]] ?? [];
      let encoded = false;
      final = table2.reduce((result, item) => {
        const level = item[parameters[5]];
        const test = item[parameters[6]];
        const value = item[parameters[7]];
        if (level) encoded = true;
        if (level && level !== 'Normal') {
          result.push(`${test}: ${level} (${value})`);
        }
        return result;
      }, final);

      final.push(final.length ? 'The rest are normal' : encoded ? 'All normal' : 'N/A');
      return final.join('\n');
    }
    return '';
  }

  if (expression.startsWith('special_urinalysis_formula')) {
    const regex = /\(([^)]+)\)/;
    const match = expression.match(regex);

    if (match) {
      const parameters = match[1].split(',');
      const protein = data[parameters[0]];
      const sugar = data[parameters[1]];
      const wbc: string = data[parameters[2]];
      const rbc = data[parameters[3]];
      const result: string[] = [];

      let encoded = !!(protein || sugar || wbc || rbc);
      if (protein && protein !== 'Negative') result.push('Protein: ' + protein);
      if (sugar && sugar !== 'Negative') result.push('Sugar: ' + sugar);
      if (wbc) {
        if (isTooMany(wbc)) {
          result.push('WBC: ' + wbc);
        } else {
          const match = wbc.trim().match(/(\d+)\s*-\s*(\d+)/);
          if (match) {
            const num1 = parseInt(match[1]);
            const num2 = parseInt(match[2]);
            if (num1 >= 12 || num2 >= 12) {
              result.push('WBC: ' + wbc);
            }
          }
        }
      }
      if (rbc) {
        if (isTooMany(rbc)) {
          result.push('RBC: ' + rbc);
        } else {
          const match = rbc.trim().match(/(\d+)\s*-\s*(\d+)/);
          if (match) {
            const num1 = parseInt(match[1]);
            if (num1 > 0 && data.sex === 'Male') {
              result.push('RBC: ' + rbc);
            } else if (num1 >= 5 && data.sex === 'Female') {
              result.push('RBC: ' + rbc);
            }
          }
        }
      }

      return result.length ? result.join('\n') : encoded ? 'All normal' : 'N/A';
    }
    return '';
  }

  if (expression.startsWith('special_eye_test_formula')) {
    const regex = /\(([^)]+)\)/;
    const match = expression.match(regex);

    if (match) {
      const parameters = match[1].split(',');
      const uncorrectedOD = parseInt((data[parameters[0]] ?? '0').replace('20/', ''));
      const uncorrectedOS = parseInt((data[parameters[1]] ?? '0').replace('20/', ''));

      const correctedOD = parseInt((data[parameters[2]] ?? '0').replace('20/', ''));
      const correctedOS = parseInt((data[parameters[3]] ?? '0').replace('20/', ''));

      const uncorrectedNV = parseInt((data[parameters[4]] ?? '0').replace('J', ''));
      const correctedNV = parseInt((data[parameters[5]] ?? '0').replace('J', ''));
      const ishihara = data[parameters[6]] ?? '';

      let final: string[] = [];
      const checkResult = (od: number, os: number) => {
        if (od > 20 && os > 20) {
          if (od === os) {
            final.push('OU 20/' + od);
          } else {
            final.push('OD 20/' + od);
            final.push('OS 20/' + os);
          }
        } else if (od > 20 || os > 20) {
          final.push('OD 20/' + od);
          final.push('OS 20/' + os);
        } else {
          final.push('Normal');
        }
      };

      if (correctedOD && correctedOS) {
        checkResult(correctedOD, correctedOS);
      } else if (uncorrectedOD && uncorrectedOS) {
        checkResult(uncorrectedOD, uncorrectedOS);
      } else {
        const uncorrectedOD = data[parameters[0]];
        const uncorrectedOS = data[parameters[1]];
        const correctedOD = data[parameters[2]];
        const correctedOS = data[parameters[3]];

        if (correctedOD && correctedOS) {
          final.push('OD ' + correctedOD);
          final.push('OS ' + correctedOS);
        } else if (uncorrectedOD && uncorrectedOS) {
          final.push('OD ' + uncorrectedOD);
          final.push('OS ' + uncorrectedOS);
        }
      }

      if (correctedNV && correctedNV >= 5) {
        final.push('Near Vision: J' + correctedNV);
      } else if (!correctedNV && uncorrectedNV && uncorrectedNV >= 5) {
        final.push('Near Vision: J' + uncorrectedNV);
      }

      if (ishihara === 'Inadequate') {
        final.push('Ishihara: Inadequate');
      }

      if (final.length > 1 && final[0] === 'Normal') {
        final[0] = 'Far Vision: Normal';
      }

      return final.join('\n');
    }
    return '';
  }

  if (expression.startsWith('special_clinical_chemistry_formula')) {
    const regex = /\(([^)]+)\)/;
    const match = expression.match(regex);

    if (match) {
      const parameters = match[1].split(',');

      const table1: any[] = data[parameters[0]] ?? [];
      let encoded = false;
      let final: string[] = table1.reduce((result, item) => {
        const level = item[parameters[1]];
        const test = item[parameters[2]];
        const value = item[parameters[3]];
        if (level) encoded = true;
        if (level && level !== 'Normal') {
          result.push(`${test}: ${level} (${value})`);
        }
        return result;
      }, []);

      final.push(final.length ? 'The rest are normal' : encoded ? 'All normal' : 'N/A');
      return final.join('\n');
    }
    return '';
  }

  if (expression.startsWith('special_fecalysis_formula')) {
    const regex = /\(([^)]+)\)/;
    const match = expression.match(regex);

    if (match) {
      const parameters = match[1].split(',');
      const color = data[parameters[0]];
      const consistency = data[parameters[1]];
      const remarks = data[parameters[2]];
      if (color && consistency && (!remarks || remarks === 'N/A')) {
        return 'Normal';
      }
      return remarks;
    }
    return 'N/A';
  }

  if (expression.startsWith('special_physical_exam_formula')) {
    const regex = /\(([^)]+)\)/;
    const match = expression.match(regex);
    if (!match) return '';

    const physical_exam_table = data[match[1]];

    const result = physical_exam_table
      .reduce((acc: any, item: any) => {
        const key = Object.keys(item)[2];
        const organ = Object.values(item)[1];
        if (item[key] !== 'Normal' && item[key] !== '') {
          acc.push(`${organ} : ${item[key]}`);
        }

        return acc;
      }, [])
      .join('\n');

    if (!result) return 'All normal';
    return result;
  }

  if (expression.startsWith('special_height_formula')) {
    const fields = expression.replace('special_height_formula:', '').replace(/[0-9a-fA-F\-]{36}/g, '0');
    let [feet, inches]: any = fields.split('##');
    feet = feet ? feet.trim() : '0';
    inches = inches ? inches.trim() : '0';
    if (!feet || isNaN(Number(feet))) feet = 0;
    if (!inches || isNaN(Number(inches))) inches = 0;

    feet = Number(feet) + Math.floor(inches / 12);
    inches = inches % 12;

    return `${feet}'${inches}"`;
  }

  if (expression.startsWith('special_bp_class_formula')) {
    const fields = expression.replace('special_bp_class_formula:', '');
    const match = fields.replace(/\s+/g, '').match(/^(\d+)\/(\d+)$/);

    if (match) {
      // Extract systolic and diastolic values
      const systolic = parseInt(match[1]);
      const diastolic = parseInt(match[2]);
      return new BloodPressureClassifier(systolic, diastolic).classify();
    }
    return '';
  }

  if (expression.startsWith('special_audiometry_formula')) {
    const regex = /\(([^)]+)\)/;
    const match = expression.match(regex);

    if (match) {
      const parameters = match[1].split(',');
      const table: any[] = data[parameters[0]] ?? [];
      if (table.length) {
        const result = table[0];
        return (
          Object.values(result).reduce((res: number, val) => res + (!isNaN(Number(val)) ? Number(val) : 0), 0) + ''
        );
      }

      return '';
    }
  }

  if (expression.startsWith('special_audiometry_findings_formula')) {
    const regex = /\(([^)]+)\)/;
    const match = expression.match(regex);

    if (match) {
      const parameters = match[1].split(',');
      const rightEar = Number(data[parameters[0]]);
      const leftEar = Number(data[parameters[1]]);

      const getFindings = (value: number) => {
        if (value < 25) return 'Normal hearing threshold';
        if (value < 40) return 'Mild hearing loss';
        if (value < 60) return 'Moderate hearing loss';
        if (value < 80) return 'Moderate to severe hearing loss';
        else return 'Severe hearing loss';
      };

      const getRecommendations = (findings: string) => {
        if (findings === 'Normal hearing threshold') return '';
        if (findings === 'Mild hearing loss' || findings === '- Moderate hearing loss')
          return '- To wear a hearing protection while working in a noisy environment';
        else
          return '- Advise to use a hearing aid or amplifier. To wear hearing protection while working in a noisy environment. Suggest EENT consult';
      };

      const rightEarFindings = getFindings(rightEar);
      const leftEarFindings = getFindings(leftEar);
      if (rightEarFindings === leftEarFindings)
        return rightEarFindings + ', both ears ' + getRecommendations(rightEarFindings);
      else {
        if (rightEar > leftEar)
          return `${rightEarFindings}, right ear; ${leftEarFindings}, left ear ${getRecommendations(rightEarFindings)}`;
        else
          return `${leftEarFindings}, left ear; ${rightEarFindings}, right ear ${getRecommendations(leftEarFindings)}`;
      }
    }
    return '';
  }

  if (expression.startsWith('range')) {
    return parseRangeInput(expression);
  }

  const tokens = tokenize(expression);

  const values: number[] = []; // Stack for numbers
  const operators: string[] = []; // Stack for operators

  for (const token of tokens) {
    if (token.type === TokenType.Number) {
      values.push(parseFloat(token.value)); // Push number to values stack
    } else if (token.type === TokenType.LeftParen) {
      operators.push(token.value); // Push '(' to operators stack
    } else if (token.type === TokenType.RightParen) {
      // Process until matching '('
      while (operators.length && operators[operators.length - 1] !== '(') {
        const operator = operators.pop()!;
        const b = values.pop()!;
        const a = values.pop()!;
        values.push(applyOperator(operator, b, a));
      }
      operators.pop(); // Pop '('
    } else if (token.type === TokenType.Operator) {
      while (operators.length && precedence(operators[operators.length - 1]) >= precedence(token.value)) {
        const operator = operators.pop()!;
        const b = values.pop()!;
        const a = values.pop()!;
        values.push(applyOperator(operator, b, a));
      }
      operators.push(token.value);
    }
  }

  // Apply remaining operators
  while (operators.length) {
    const operator = operators.pop()!;
    const b = values.pop()!;
    const a = values.pop()!;
    values.push(applyOperator(operator, b, a));
  }

  return formatNumber(values.pop()!, 2);
};

const parseRangeInput = (input: string): string => {
  // Match the general structure of the string
  const regex = /range\(([\d.]+),\s*\[([^\]]+)\],\s*\[([^\]]+)\]\)/;
  const match = input.match(regex);

  if (!match) {
    return '';
  }

  const value = parseFloat(match[1]); // First captured group: value
  const ranges = match[2] // Second captured group: ranges
    .split(',')
    .map((v) => parseFloat(v.trim()));
  const labels = match[3] // Third captured group: labels
    .split(',')
    .map((v) => v.trim().replace(/['"]+/g, '')); // Remove any quotes

  return getValueFromRange(value, ranges, labels);
};

const getValueFromRange = (value: number, ranges: number[], labels: string[]): string => {
  // Validate the input
  if (ranges.length !== labels.length) {
    return '';
  }
  // Iterate through the ranges to find the correct label
  for (let i = 0; i < ranges.length; i++) {
    if (value < ranges[i]) {
      return i === 0 ? '' : labels[i - 1];
    }
  }

  // If the value is greater than or equal to the last range, return the last label
  return labels[labels.length - 1];
};

export const indexToColumn = (index: number) => {
  // 1: A, 2: B, 3: C, .... 27: AA
  let columnName = '';
  let dividend = index;

  while (dividend > 0) {
    let modulo = (dividend - 1) % 26;
    columnName = String.fromCharCode(65 + modulo) + columnName;
    dividend = Math.floor((dividend - 1) / 26);
  }

  return columnName;
};

export const evaluateDynamicExpression = (expression: string): string => {
  // Remove any unnecessary spaces and normalize the expression
  expression = expression.trim();

  // Check if the expression matches the ternary pattern
  const regex = expression.includes('(')
    ? /([^\?]+)\s*\?\s*\((.*?)\)\s*:\s*\((.*?)\)/
    : /^(.+?)\s*\?\s*(.+?)\s*:\s*(.+)$/;

  // If the expression matches, proceed to evaluate
  const match = expression.match(regex);

  if (match) {
    const condition = match[1]?.trim();
    const trueValue = match[2]?.trim();
    const falseValue = match[3]?.trim();

    // Evaluate the condition
    if (evaluateCondition(condition)) {
      // If the condition is true, recursively evaluate the trueValue
      return evaluateDynamicExpression(trueValue);
    } else {
      // Otherwise, recursively evaluate the falseValue
      return evaluateDynamicExpression(falseValue);
    }
  }

  // If no ternary pattern was found, return the value (base case)
  return expression;
};

const evaluateCondition = (condition: string): boolean => {
  // Function to evaluate basic conditions in the form of "100 < 95", "100 > 120", or "apple === 'apple'"
  const conditionRegex = /^(.+?)\s*([<>=!]+|===|!==)\s*(.+)$/;
  const match = condition.match(conditionRegex);

  if (match) {
    const leftOperand = match[1].trim();
    const operator = match[2].trim();
    const rightOperand = match[3].trim();

    // Handle numeric comparison
    const leftIsNumber = !isNaN(Number(leftOperand));
    const rightIsNumber = !isNaN(Number(rightOperand));

    if (leftIsNumber && rightIsNumber) {
      const leftNum = parseFloat(leftOperand);
      const rightNum = parseFloat(rightOperand);

      switch (operator) {
        case '<':
          return leftNum < rightNum;
        case '>':
          return leftNum > rightNum;
        case '==':
          // eslint-disable-next-line eqeqeq
          return leftNum == rightNum;
        case '<=':
          return leftNum <= rightNum;
        case '>=':
          return leftNum >= rightNum;
        case '!=':
          // eslint-disable-next-line eqeqeq
          return leftNum != rightNum;
        case '!==':
          return leftNum !== rightNum;
        case '===':
          return leftNum === rightNum;
        default:
          throw new Error(`Unsupported operator: ${operator}`);
      }
    } else {
      // Handle string comparison
      switch (operator) {
        case '<':
          return leftOperand < rightOperand;
        case '>':
          return leftOperand > rightOperand;
        case '==':
          // eslint-disable-next-line eqeqeq
          return leftOperand == rightOperand;
        case '===':
          return leftOperand === rightOperand;
        case '!=':
          // eslint-disable-next-line eqeqeq
          return leftOperand != rightOperand;
        case '!==':
          return leftOperand !== rightOperand;
        default:
          throw new Error(`Unsupported operator: ${operator}`);
      }
    }
  }

  throw new Error(`Invalid condition: ${condition}`);
};

export const getFieldsAndResponses = (note: PatientNoteModel): [FieldInput[], any] => {
  if (note.template_body) {
    return [note.template_body[0].fields, note.responses];
  }

  if (note.body?.length) {
    const responses = note.responses ?? note.body[0].responses ?? {};
    return [note.body[0].fields, responses];
  }
  return [[], {}];
};

class BloodPressureClassifier {
  systolic: number;
  diastolic: number;

  // Constructor to initialize systolic and diastolic values
  constructor(systolic: number, diastolic: number) {
    this.systolic = systolic;
    this.diastolic = diastolic;
  }

  // Method to classify blood pressure
  classify() {
    if (this.systolic <= 80 || this.diastolic <= 60) {
      return 'Hypotension';
    } else if (this.systolic <= 120 && this.diastolic < 90) {
      return 'Normal';
    } else if (this.systolic < 140 && this.diastolic <= 90) {
      return 'Pre-HPN';
    } else if (this.systolic >= 140 && this.systolic < 150 && this.diastolic >= 80 && this.diastolic <= 90) {
      return 'HPN I';
    } else if (this.systolic >= 120 || this.diastolic >= 100) {
      return 'HPN II';
    } else {
      return '';
    }
  }
}

//
export const buildTree = (data: any[]) => {
  // Create a lookup object to store items by their id
  const lookup: any = {};
  const tree: any[] = [];

  // Initialize lookup with all data items
  data.forEach((item) => {
    lookup[item.id] = { ...item, children: [] };
  });

  // Build the tree structure
  data.forEach((item) => {
    if (item.parent_id !== null) {
      // If the item has a parent, add it to the parent's children
      lookup[item.parent_id].children.push(lookup[item.id]);
    } else {
      // If the item has no parent, it's a root node
      tree.push(lookup[item.id]);
    }
  });

  return tree;
};

export const chunkWords = (sentence: string) => {
  const words = sentence.split(' '); // Split the sentence into words
  const lines: string[] = [];
  let index = 0;

  words.forEach((word) => {
    const lengthWord = word.length;
    if (!lines[index]) {
      lines[index] = word;
    } else if (lines[index].length + lengthWord <= 14) {
      lines[index] = lines[index] + ' ' + word;
    } else {
      index++;
      lines[index] = word;
    }
  });

  return lines.join('\n'); // Join lines with '\n'
};
