import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ConfirmationDialog, CustomModal, HideOrShowComponent, RegularButton } from 'core/components';
import { deleteClient, getClient, getClients, getPatientsUnderCorporateClient } from 'company/api/corporate-clients';
import { useCallback, useContext, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import BusinessIcon from '@mui/icons-material/Business';
import CampaignIcon from '@mui/icons-material/Campaign';
import { CareGoPage } from 'core/PageBuilder';
import CompanyReport from './components/CompanyReport';
import { ContainerColumn } from 'core/components/containers';
import CorporateClientDepartmentTable from './components/Departments/CorporateClientDepartmentTable';
import { CorporateClientForm } from 'company/entities/forms';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FacilityContext } from 'core/context/facility.context';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import InfiniteScrollContainer from 'core/components/dataView/InfiniteScrollContainer';
import MockContent from './components/MockContent';
import PatientField from '../PatientManagement/components/PatientField';
import { PatientTable } from 'company/entities/tables';
import { SIZES } from 'theme/constants';
import SectionDropdown from 'core/components/SectionDropdown';
import { TabProps } from 'core/components/CustomTabs';
import { UserContext } from 'core/context/user.context';
import { isDevMode } from 'core/components/HideOrShowComponent';
import { tokens } from 'theme/theme';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

interface CorporateClientsProps {
  onClose?: () => void;
}

const CorporateClients = (props: CorporateClientsProps) => {
  const { onClose } = props;
  const { user } = useContext(UserContext);

  const { facility } = useContext(FacilityContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { enqueueSnackbar } = useSnackbar();

  const [selectedCorporateClient, setSelectedCorporateClient] = useState<number>(0);
  const [client, setClient] = useState<any>();
  const [addedClient, setAddedClient] = useState<number>();
  const [updatedClient, setUpdatedClient] = useState<any>();
  const [deletedClient, setDeletedClient] = useState<any>();

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [modalHeader, setModalHeader] = useState<string>();
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [forceRefresh, setForceRefresh] = useState<number>(0);
  const [patientNumber, setPatientNumber] = useState<number>(0);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [openActions, setOpenActions] = useState<boolean>(false);

  const callbackAfterSubmit = () => {
    setForceRefresh((prev: number) => prev + 1);
    navigate(`/company/patient-management/corporate-clients?client_id=0`);
    setSelectedCorporateClient(0);
  };

  // const handleDelete = (data: any) => {
  //   if (facility) {
  //     return deleteClient(data.id).then(() => {
  //       enqueueSnackbar('Corporate Client set to Inactive!', { variant: 'success' });
  //       client.is_active = false;
  //       setUpdatedClient(client);
  //       callbackAfterSubmit();
  //       setSelectedCorporateClient(0);
  //     });
  //   }
  // };

  const handleDelete = async () => {
    if (facility) {
      try {
        await deleteClient(client.id);
        setDeletedClient(client.id);
        callbackAfterSubmit();
        enqueueSnackbar('Client successfully deleted!', { variant: 'success' });
      } catch (error) {
        console.error('Error in handleDelete:', error);
        enqueueSnackbar('Failed to delete client');
      }
    } else {
    }
  };

  const isMobile = useMediaQuery('(max-width:768px)');
  const navigate = useNavigate();

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      navigate('/company/patient-management');
    }
  };

  const displayItem = useCallback(
    (client: any) => {
      const isSelected = selectedCorporateClient === client.id;
      const textColor = isSelected ? (isMobile ? colors.text : 'white') : colors.text;
      return (
        <Box display="flex" flexDirection="column" gap="5px" width="100%">
          <Box display="flex" justifyContent="space-between" width="100%" color={textColor}>
            <Typography variant="h5" fontWeight="600">
              {client.company_name} {client.is_active ? '' : '(Inactive)'}
            </Typography>
          </Box>
          <Typography variant="h5" fontWeight="300" fontSize={'12px'} color={textColor}>
            {client.contact_person} | {client.assigned_facilities}
          </Typography>
        </Box>
      );
    },
    [colors.text, selectedCorporateClient]
  );
  const getClientDetails = useCallback(() => {
    if (facility && selectedCorporateClient) {
      getClient(selectedCorporateClient)
        .then((res) => setClient(res.data))
        .catch((error) => console.error(error));

      getPatientsUnderCorporateClient(selectedCorporateClient)
        .then((res) => setPatientNumber(res.data.meta.total))
        .catch((error) => console.error(error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facility, selectedCorporateClient]);

  useEffect(() => {
    if (selectedCorporateClient) getClientDetails();
  }, [forceRefresh, selectedCorporateClient, facility, getClientDetails]);

  useEffect(() => {
    getClientDetails();
    getClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedClient, updatedClient, deletedClient]);

  const sections: TabProps[] = [
    {
      label: 'Report',
      content: selectedCorporateClient ? <CompanyReport companyId={selectedCorporateClient} /> : <></>,
      icon: <AssessmentOutlinedIcon />,
    },
    {
      label: 'Patients',
      content: (
        <PatientTable
          corporateClientId={selectedCorporateClient}
          visibleColumns={['full_name', 'mobile_number', 'birthday', 'age', 'sex', 'address', 'created_at']}
          hideActions
        />
      ),
      icon: <GroupsOutlinedIcon />,
    },
    {
      label: 'Departments',
      content: (
        <CorporateClientDepartmentTable
          companyId={client ? client.company_id : undefined}
          corporateClientId={client ? client.id : undefined}
        />
      ),
      icon: <ApartmentIcon />,
      hidden: true,
    },
    {
      label: 'Transactions',
      content: <MockContent />,
      icon: <BusinessIcon />,
      hidden: !isDevMode(),
    },
    {
      label: 'Campaigns',
      icon: <CampaignIcon />,
      hidden: true,
    },
  ];

  return (
    <CareGoPage
      breadcrumb={[
        { label: 'Patient Management', link: '/company/patient-management' },
        { label: 'Corporate Clients' },
      ]}
    >
      <InfiniteScrollContainer
        title="Corporate Clients"
        getData={(query) => getClients(query)}
        getSingleData={(id) => getClient(id)}
        renderItem={displayItem}
        selected={selectedCorporateClient}
        setSelected={setSelectedCorporateClient}
        addedNewItem={addedClient}
        updatedItem={updatedClient}
        deleteId={deletedClient}
        urlKeyId="client_id"
        titleComponents={
          <RegularButton
            startIcon={<AddIcon />}
            label="Add "
            onClick={() => {
              setIsAdd(true);
              setOpenModal(true);
              setModalHeader('Add Corporate Client');
            }}
            disabled={user.remaining_storage <= 0}
          />
        }
        headerComponents={
          <HideOrShowComponent hidden={!isDevMode()}>
            <RegularButton
              startIcon={<AddIcon />}
              label="Add Campaigns"
              color="primary"
              onClick={() => undefined}
              styles={{ paddingInline: SIZES.padding }}
              fullWidth
              disabled={user.remaining_storage <= 0}
            />
          </HideOrShowComponent>
        }
        displaySelectedTitle={() =>
          client && (
            <Typography variant="h3" fontWeight={700} color={colors.primary}>
              {client?.company_name} {client?.is_active ? '' : '(Inactive)'}
            </Typography>
          )
        }
        hideActions={client ? false : true}
        hideContainer={client ? false : true}
        actions={[
          {
            icon: <EditOutlinedIcon />,
            label: 'Update',
            action: (data) => {
              setOpenModal(true);
              setIsAdd(false);
              setModalHeader('Update ' + client.company_name);
            },
          },
          {
            icon: <DeleteOutlineIcon />,
            label: 'Delete',
            color: 'error',
            hidden: !client?.is_active,
            action: () => {
              setOpenDeleteModal(true);
            },
          },
        ]}
      >
        <ContainerColumn gap={SIZES.paddingL} sx={{ marginTop: '40px' }}>
          {client && (
            <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap="20px">
              <PatientField label="Contact Person" value={client.contact_person} />
              <PatientField label="Contact Number" value={client.contact_number} />
              <PatientField label="Email" value={client.email} />
              <PatientField label="No. of Patients" value={patientNumber} />
              <PatientField label="Address" value={client.address} sx={{ gridColumn: 'span 2' }} />
              <PatientField label="Clinic" value={client.assigned_facilities} sx={{ gridColumn: 'span 3' }} />
            </Box>
          )}
          {client && (
            <Box>
              <Box display={'flex'} justifyContent={'space-between'}>
                <SectionDropdown optionList={sections} urlKeyId="id" />
              </Box>
              {/* <MockContent /> */}
            </Box>
          )}
        </ContainerColumn>
      </InfiniteScrollContainer>

      {facility && (isAdd ? true : client) && (
        <CustomModal open={openModal} setOpen={setOpenModal} header={modalHeader}>
          <CorporateClientForm
            corporateClient={isAdd ? undefined : client}
            callbackAfterSubmit={(data) => {
              setOpenModal(false);
              callbackAfterSubmit();
              if (isAdd) {
                setAddedClient(data.client.id);
              } else {
                setUpdatedClient(data.client);
              }
            }}
          />
        </CustomModal>
      )}

      {client && (
        <ConfirmationDialog
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          onConfirm={handleDelete}
          data={client}
          title={'Delete ' + client.company_name}
          content="Are you sure you want to delete this corporate client?"
        />
      )}

      {/* <CorporateClientDrawer
        ref={drawerRef}
        setDrawerSelectedRow={setSelectedRow}
        childComponent={<CustomTabs tabs={tabs} selected={selected} setSelected={setSelected} isSubTab />}
      /> */}
    </CareGoPage>
  );
};

export default CorporateClients;
