import * as yup from 'yup';

import { EntityFields } from '../../../utils';
import { FieldInput } from 'core/model/interface';

export const PC_ENTITY_NAME = 'Category';

export const product_category_schema = yup.object().shape({
  category: yup.string().max(100, 'Category should not exceed 100 characters.').required('Category is required.'),
  description: yup
    .string()
    .max(200, 'Category description should not exceed 200 characters.')
    .required('Description is required.'),
});

export type ProductCategoryInput = {
  category: string;
  description: string;
};

export interface ProductCategoryModel {
  id: number;
  category: string;
  description: string;
  company_id: number;
  is_active: boolean;
  created_by: number;
  created_at: string;
  updated_at: string;
}

export interface ProductCategoryFieldInput extends FieldInput {
  field_name?: keyof ProductCategoryFieldInput;
}

export const productCategoryinitialValues: ProductCategoryInput = {
  category: '',
  description: '',
};

export const productCategoryFields: EntityFields[] = [
  { fieldName: 'category', displayName: 'Category', span: 4 },
  { fieldName: 'description', displayName: 'Description', multiline: true, rows: 3, span: 4 },
];

export const productCategoryTableColumns: string[] = ['category', 'description'];

export const productCategoryFormFields: string[] = ['category', 'description'];
