/* eslint-disable react-hooks/exhaustive-deps */

import { ConfirmationDialog, CustomGridCell, CustomModal, PrimaryButton, RegularButton } from 'core/components';
import PageBuilder, { BLOCK_TYPE } from 'core/PageBuilder/PageBuilder';
import { getUsersAssigned, removeUsersFromClinic } from 'company/api/users';
import { useContext, useEffect, useState } from 'react';

import AddUser from '../AddUser';
import { ApiQuery } from 'core/model/interface';
import { Box, useMediaQuery } from '@mui/material';
import { CompanyFacilityModel } from 'company/model/Entities';
import { GridColDef } from '@mui/x-data-grid';
import { PageBuilderTableType } from 'core/PageBuilder';
import RemoveIcon from '@mui/icons-material/Remove';
import { formatDate } from 'core/utils';
import { useSnackbar } from 'notistack';
import { userGroups } from 'core/data/list';
import { UserContext } from 'core/context/user.context';

type ClinicUserAccessProps = {
  clinic: CompanyFacilityModel;
};

const ClinicUserAccess: React.FC<ClinicUserAccessProps> = ({ clinic }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);

  const [forceRefresh, setForceRefresh] = useState<number>(0);
  const [clearSelected, setClearSelected] = useState<number>(0);
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [alertContent, setAlertContent] = useState<string>('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const isMobilePhone = useMediaQuery('(max-width:768px)');

  const columns: GridColDef[] = [
    { field: 'username', headerName: 'Username', flex: 1 },
    { field: 'full_name', headerName: 'Full Name', flex: 1 },
    {
      field: 'user_group_id',
      headerName: 'User Type',
      flex: 1,
      renderCell: (params) => (
        <CustomGridCell>
          {userGroups?.map((u: any) => {
            if (u.id === params.row.user_group_id) return u.group_name;
            return '';
          })}
        </CustomGridCell>
      ),
    },
    { field: 'mobile_number', headerName: 'Mobile Number', flex: 1 },
    {
      field: 'created_at',
      headerName: 'Date Registered',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{formatDate(params.row.created_at)}</CustomGridCell>,
    },
    {
      field: 'is_active',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{params.row.is_active === 1 ? 'Active' : 'Deactivated'}</CustomGridCell>,
    },
    {
      field: 'assigned_facilities',
      headerName: 'Facilities',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{params.row.assigned_facilities}</CustomGridCell>,
    },
  ];

  const handleServiceDelete = (data: any) => {
    const userList = JSON.stringify(data ? [data] : selectedRows);
    const users = { users: userList };

    return removeUsersFromClinic(clinic.id, users).then(() => {
      setForceRefresh((prev: number) => prev + 1);
      setClearSelected((prev) => prev + 1);
      if (selectedRows.length > 1 && !data) {
        enqueueSnackbar('Users successfully removed!', { variant: 'success' });
      }
    });
  };

  const handleSelectRows = (selected: any) => {
    setSelectedRows(Array.from(selected));
  };

  const refreshTable = () => {
    setForceRefresh((prev: number) => prev + 1);
  };

  const getUsers = (query: ApiQuery) => {
    return getUsersAssigned(clinic.id, query);
  };

  useEffect(() => {
    refreshTable();
  }, [clinic]);

  const content = {
    type: BLOCK_TYPE.TABLE,
    entityName: 'User',
    useColoredTable: true,
    tableComponent: {
      columns: columns,
      getData: getUsers,
      forceRefresh: forceRefresh,
      checkboxSelection: true,
      hideAddSelectedButton: true,
      clearSelected: clearSelected,
      selectableRow: true,
      onSelectRow: handleSelectRows,
      headerComponent: (
        <Box display="flex" gap="10px">
          {!isMobilePhone && (
            <PrimaryButton
              onClick={() => {
                setOpenAddModal(true);
              }}
              label="Add Users"
              disabled={user.remaining_storage <= 0}
            />
          )}
          {!isMobilePhone && (
            <RegularButton
              onClick={() => {
                if (selectedRows.length) {
                  setOpenDeleteDialog(true);
                  setAlertContent(
                    'Are you sure you want to remove ' +
                      selectedRows.length +
                      ' ' +
                      (selectedRows.length === 1 ? 'user' : 'users') +
                      ' from ' +
                      clinic?.facility_name +
                      '?'
                  );
                } else {
                  enqueueSnackbar('Invalid action. Select users first!', { variant: 'error' });
                }
              }}
              label="Remove Users"
              disabled={!selectedRows.length}
              startIcon={<RemoveIcon />}
              color="error"
            />
          )}
        </Box>
      ),
    },
    tableAction: !isMobilePhone
      ? {
          includeDeleteAction: true,
          deleteButtonLabel: 'Remove',
          deleteApiFunction: handleServiceDelete,
          alertCustomObjectColumn: 'full_name',
          customDeleteSnackBarMessageOnSuccess: 'removed',
        }
      : {},
  } as PageBuilderTableType;

  return (
    <Box width="100%">
      <PageBuilder content={content} />

      <CustomModal width={900} header={'Add Users'} open={openAddModal} setOpen={setOpenAddModal}>
        <AddUser entity={'Users'} clinic={clinic} setRefreshMainTable={setForceRefresh} />
      </CustomModal>

      <ConfirmationDialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        content={alertContent}
        onConfirm={handleServiceDelete}
        confirmButtonColor={false}
      />
    </Box>
  );
};

export default ClinicUserAccess;
