import { Box, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { AccountType } from 'core/model/Entities';
import AuthCard from './AuthCard';
import Cookies from 'js-cookie';
import { CustomModal } from 'core/components';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';
import { QRCode } from 'react-qrcode-logo';
import { login } from 'core/api/user';
import { motion } from 'motion/react';
import { onboardNewClientInSignUp } from 'core/api/public';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';
import useZoomLevel from './ZoomLevel';

const CareGoPortal = () => {
  const navigate = useNavigate();
  const zoomLevel = useZoomLevel();

  const isMobilePhone = useMediaQuery('(max-width:840px)');
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { enqueueSnackbar } = useSnackbar();

  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const loginUser = (username: string, password: string) => {
    login({ username, password }, AccountType.COMPANY).then((response) => {
      if (response.data.token) {
        Cookies.set('access_token', response.data.token);
        navigate('/company');
        localStorage.removeItem('orders');
        localStorage.removeItem('selectedPatient');
      }
    });
  };

  const handleSubmit = async (data: any) => {
    setIsLoading(true);
    try {
      const response = await onboardNewClientInSignUp(data);

      if (response.data?.company) {
        await loginUser(data.username, data.password);
        setIsSuccess(true);
        enqueueSnackbar('Account successfully created!', { variant: 'success' });
      } else {
        setIsSuccess(false);
        enqueueSnackbar(response.data?.message || 'Registration failed', { variant: 'error' });
      }
    } catch (error: any) {
      setIsSuccess(false);

      const errorType = error.response?.data?.error_type;

      if (errorType === 'duplicate_email') {
        enqueueSnackbar('This email address is already registered. Please use a different email.', {
          variant: 'error',
        });
      } else if (errorType === 'duplicate_username') {
        enqueueSnackbar('This username is already taken. Please choose another username.', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(error.response?.data?.message || 'Registration failed', {
          variant: 'error',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const TypewriterText = () => {
    const phrases = [
      'supervise remotely.',
      'manage patients.',
      'send reminders.',
      'track revenue.',
      'create notes.',
      'print prescriptions.',
      'inspect inventory.',
      'automate reports.',
      'generate APE.',
    ];

    const [displayText, setDisplayText] = useState('');
    const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
      const currentPhrase = phrases[currentPhraseIndex];
      const typeSpeed = isDeleting ? 50 : 120;
      const pauseDuration = 2300;

      let timeoutId: NodeJS.Timeout;
      const interval = setInterval(() => {
        if (!isDeleting && displayText === currentPhrase) {
          timeoutId = setTimeout(() => setIsDeleting(true), pauseDuration);
          return;
        }

        if (isDeleting && displayText === '') {
          setIsDeleting(false);
          setCurrentPhraseIndex((prev) => (prev + 1) % phrases.length);
          return;
        }

        const nextText = isDeleting
          ? currentPhrase.substring(0, displayText.length - 1)
          : currentPhrase.substring(0, displayText.length + 1);

        setDisplayText(nextText);
      }, typeSpeed);

      return () => {
        clearInterval(interval);
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayText, currentPhraseIndex, isDeleting]);

    return (
      <Box position="relative" display="inline-flex" alignItems="center">
        <Typography
          variant="h1"
          color={colors.primary}
          fontWeight={800}
          fontSize={zoomLevel !== 100 ? '1.4rem' : '2rem'}
        >
          can&nbsp;
        </Typography>
        <Box position="relative" display="inline-block">
          <Typography
            variant="h1"
            color={colors.primary}
            fontWeight={800}
            fontSize={zoomLevel !== 100 ? '1.4rem' : '2rem'}
          >
            {displayText}
            <motion.div
              initial={{ scaleX: 0 }}
              animate={{
                scaleX: [0, 1, 1, 0],
                transition: {
                  duration: 2,
                  repeat: Infinity,
                  ease: 'linear',
                  times: [0, 0.4, 0.5, 0.6],
                },
              }}
              style={{
                position: 'absolute',
                right: '-10px',
                top: '0%',
                transform: 'translateY(-10%)',
                height: '100%',
                width: '4px',
                background: colors.primary,
                originY: 0,
              }}
            />
          </Typography>
        </Box>
      </Box>
    );
  };

  if (isMobilePhone) {
    return (
      <Box
        component="main"
        sx={{
          width: '100%',
          minHeight: '100vh',
          background: colors.primary,
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          position: 'fixed',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
        }}
      >
        {/* Header Section */}
        <Box
          sx={{
            width: '100%',
            p: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Link to="/">
            <HouseRoundedIcon sx={{ color: 'white', fontSize: '1.5rem' }} />
          </Link>

          <Box
            width="20%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgcolor={'white'}
            py={1}
            px={2}
            borderRadius={2}
          >
            <img
              src={process.env.PUBLIC_URL + '/assets/landingassets/NewLogo.png'}
              alt="Logo"
              style={{
                width: '100%',
                height: '100%',
              }}
            />
          </Box>
        </Box>

        {/* Hero Section */}
        <Box sx={{ p: 3, pt: 1, color: 'white' }}>
          <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
            <Typography
              variant="h1"
              fontWeight={700}
              sx={{
                fontSize: '2.3rem',
                lineHeight: 1.2,
                mb: 1,
              }}
            >
              Say hello to CareGo!
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: '1rem',
                mb: 1,
              }}
            >
              CareGo is a user-friendly clinic platform that connects patients and healthcare providers.
            </Typography>

            {/* Social Links */}
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} width={'45%'}>
              <Box width={'7vw'} sx={{ cursor: 'pointer' }}>
                <motion.div whileHover={{ scale: 1.1 }}>
                  <a href="https://www.facebook.com/CareGoHealthSuite" target="_blank" rel="noreferrer">
                    <img
                      src="./assets//landingassets/fb_white.png"
                      alt="facebook"
                      width={'100%'}
                      style={{ display: 'flex', alignItems: 'center' }}
                    />
                  </a>
                </motion.div>
              </Box>
              <Box width={'7vw'} sx={{ cursor: 'pointer' }}>
                <motion.div whileHover={{ scale: 1.1 }}>
                  <a href="https://www.linkedin.com/company/carego-health-suite" target="_blank" rel="noreferrer">
                    <img
                      src="./assets//landingassets/linkedin_white.png"
                      alt="linkedin"
                      width={'100%'}
                      style={{ display: 'flex', alignItems: 'center' }}
                    />
                  </a>
                </motion.div>
              </Box>
              <Box width={'7vw'} sx={{ cursor: 'pointer' }}>
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  onClick={() => {
                    navigator.clipboard.writeText('partnerships@cphealthinnovations.com');
                    enqueueSnackbar('Email copied to clipboard!', { variant: 'success' });
                  }}
                >
                  <img
                    src="./assets//landingassets/mail_white.png"
                    alt="email"
                    width={'100%'}
                    style={{ display: 'flex', alignItems: 'center' }}
                  />
                </motion.div>
              </Box>
              <Box width={'7vw'} sx={{ cursor: 'pointer' }}>
                <motion.div whileHover={{ scale: 1.2 }}>
                  <img
                    src="./assets//landingassets/location_white.png"
                    alt="location"
                    width={'100%'}
                    onClick={() => {
                      setOpenLocationModal(true);
                    }}
                  />
                </motion.div>
              </Box>
            </Box>
          </motion.div>
        </Box>

        {/* Form Section */}
        <Box
          sx={{
            backgroundColor: 'white',
            borderRadius: '2rem 2rem 0 0',
            p: 3,
            width: '100%',
          }}
        >
          <Box sx={{ width: '100%' }}>
            <AuthCard
              handleSubmit={handleSubmit}
              isSuccess={isSuccess}
              isLoading={isLoading}
              isMobile={isMobilePhone}
            />
          </Box>
        </Box>

        {/* Location Modal */}
        <CustomModal open={openLocationModal} setOpen={setOpenLocationModal} header="Head Office" width={500}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              p: 2,
            }}
          >
            <Box
              sx={{
                width: '100%',
                maxWidth: '400px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h5" fontWeight={500}>
                  214-215, Commercenter, Filinvest Ave, <br /> Muntinlupa, Metro Manila
                </Typography>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 1,
                  color: colors.primary,
                }}
              >
                <KeyboardDoubleArrowDownOutlinedIcon />
                <Typography variant="h3" fontWeight={700} color={colors.primary}>
                  Google Maps
                </Typography>
                <KeyboardDoubleArrowDownOutlinedIcon />
              </Box>

              <Box sx={{ width: '200px', height: 'auto' }}>
                <QRCode
                  logoImage={process.env.PUBLIC_URL + '/assets/carego_health_suite_logo_white.png'}
                  removeQrCodeBehindLogo
                  eyeRadius={[10, 10, 10]}
                  fgColor={colors.primary}
                  value={'https://maps.app.goo.gl/DBa4CCWYongBLHu76'}
                  size={200}
                  style={{ width: '100%', height: 'auto' }}
                />
              </Box>

              <Typography variant="h6" fontWeight={500} color={colors.primary}>
                https://maps.app.goo.gl/VRzKFuUo1HY1KA8P6
              </Typography>
            </Box>
          </Box>
        </CustomModal>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        minHeight: '100vh',
        width: '100%',
        maxWidth: '100vw',
        maxHeight: '100vh',
        background: 'linear-gradient(118deg, rgba(1, 146, 213, 1) 0%, rgba(1, 146, 213, 0.7) 50%, #fafafa 50%)',
        pl: 0,
        pr: 0,
        gap: 0,
      }}
    >
      {/* Left Section */}
      <Box
        sx={{
          flex: 1,
          color: 'white',
          p: 5,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* Top Left */}
        <Box sx={{ color: 'white', mb: zoomLevel > 100 ? 2 : 6 }}>
          <motion.div initial={{ opacity: 0, x: -20 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 0.8 }}>
            <Typography
              fontWeight={700}
              sx={{
                fontSize: zoomLevel !== 100 ? '4rem' : '5rem',
                lineHeight: 1.2,
                mb: 2,
              }}
            >
              Say hello to CareGo!
            </Typography>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.3 }}
          >
            <Typography
              variant="h4"
              fontWeight={500}
              sx={{
                mt: 2,
                fontSize: zoomLevel <= 100 ? '1rem' : '0.9rem',
              }}
            >
              CareGo is a user-friendly clinic platform that connects patients and healthcare providers.
            </Typography>
            <Typography
              variant="h4"
              fontWeight={500}
              sx={{
                mt: 1,
                fontSize: zoomLevel <= 100 ? '1rem' : '0.9rem',
              }}
            >
              Sign up now to experience the future of seamless healthcare.
            </Typography>
          </motion.div>
        </Box>

        {/* Preview Box */}
        <Box
          bgcolor={'white'}
          pt={2}
          px={4}
          borderRadius={6}
          sx={{
            position: 'fixed',
            bottom: '5vh',
            height: '68vh',
            width: '38vw',
            zIndex: 0,
          }}
        >
          <Box display="flex" alignItems={'center'} justifyContent={'start'} gap={2} flexDirection="row">
            <img
              src={process.env.PUBLIC_URL + '/assets/landingassets/NewLogo.png'}
              alt="Logo"
              style={{
                maxWidth: '8vw',
                width: '100%',
                height: 'auto',
              }}
            />
            <TypewriterText />
          </Box>
        </Box>

        {/* Preview GIF */}
        <Box
          bgcolor={'white'}
          px={4}
          mt={1}
          borderRadius={8}
          sx={{
            width: 'fit-content',
            display: 'block',
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
            minHeight: 'fit-content',
            mb: 0,
            zIndex: 2,
            position: 'fixed',
            bottom: '5vh',
          }}
        >
          <img
            alt="Gif"
            src="./assets/LandingMobile/preview1.gif"
            style={{
              // width: '100%',
              maxWidth: '50vw',
              height: 'auto',
              maxHeight: '60vh',
              borderRadius: '12px',
              zIndex: 3,
            }}
          />
        </Box>
      </Box>

      {/* Right Section - Sign Up Form */}
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <AuthCard handleSubmit={handleSubmit} isSuccess={isSuccess} isLoading={isLoading} isMobile={isMobilePhone} />
        {/* </Box> */}

        {/* {zoomLevel !== 100 && ( */}
      </Box>

      <Box
        height={'35%'}
        width={'4%'}
        sx={{
          backgroundColor: colors.primary,
          borderRadius: '20px 0 0 20px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          pl: 1,
          py: 3,
          position: 'fixed',
          right: 0,
          top: '35%',
        }}
      >
        <Tooltip title="Facebook" placement="left">
          <Box width={'1.8vw'} sx={{ cursor: 'pointer' }}>
            <motion.div whileHover={{ scale: 1.2 }}>
              <a href="https://www.facebook.com/CareGoHealthSuite" target="_blank">
                <img src="./assets//landingassets/fb_white.png" alt="facebook" width={'100%'} />
              </a>
            </motion.div>
          </Box>
        </Tooltip>

        <Tooltip title="Linkedin" placement="left">
          <Box width={'1.8vw'} sx={{ cursor: 'pointer' }}>
            <motion.div whileHover={{ scale: 1.2 }}>
              <a href="https://www.linkedin.com/company/carego-health-suite" target="_blank">
                <img src="./assets//landingassets/linkedin_white.png" alt="linkedin" width={'100%'} />
              </a>
            </motion.div>
          </Box>
        </Tooltip>

        <Tooltip title="Email" placement="left">
          <Box width={'1.8vw'} sx={{ cursor: 'pointer' }}>
            <motion.div whileHover={{ scale: 1.2 }}>
              <img
                src="./assets//landingassets/mail_white.png"
                alt="email"
                width={'100%'}
                onClick={() => {
                  navigator.clipboard.writeText('partnerships@cphealthinnovations.com');
                  enqueueSnackbar('Email copied to clipboard!', { variant: 'success' });
                }}
              />
            </motion.div>
          </Box>
        </Tooltip>

        <Tooltip title="Location" placement="left">
          <Box width={'1.8vw'} sx={{ cursor: 'pointer' }}>
            <motion.div whileHover={{ scale: 1.2 }}>
              <img
                src="./assets//landingassets/location_white.png"
                alt="location"
                width={'100%'}
                onClick={() => {
                  setOpenLocationModal(true);
                }}
              />
            </motion.div>
          </Box>
        </Tooltip>
      </Box>
      {/* Decorative Image */}
      <Box
        sx={{
          position: 'absolute',
          top: 30,
          left: zoomLevel <= 100 ? 820 : 685,
          zIndex: 1,
          display: 'block',
          maxWidth: '20vw',
        }}
      >
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8, delay: 0.6 }}
          whileHover={{ scale: 1.1 }}
        >
          <img
            src={process.env.PUBLIC_URL + '/assets/landingassets/capsules.png'}
            alt="Decorative"
            style={{
              width: '100%',
            }}
          />
        </motion.div>
      </Box>

      {/* Back to Home */}
      <Box
        height={'5vh'}
        width={'2.5vw'}
        sx={{
          backgroundColor: 'white',
          borderRadius: '0 0 10px 0',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          left: 0,
          top: 0,
          zIndex: 3,
          padding: 1,
          pt: '0.8%',
        }}
      >
        <Tooltip title="Back to Home" placement="right">
          <Box sx={{ cursor: 'pointer' }}>
            <motion.div whileHover={{ scale: 1.1 }}>
              <Link to={`/`}>
                <HouseRoundedIcon sx={{ color: colors.primary, fontSize: '1.4rem' }} />
              </Link>
            </motion.div>
          </Box>
        </Tooltip>
      </Box>

      <CustomModal open={openLocationModal} setOpen={setOpenLocationModal} header="Head Office " width={500}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            p: 2,
          }}
        >
          <Box
            sx={{
              width: '100%',
              maxWidth: '400px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h5" fontWeight={500}>
                214-215, Commercenter, Filinvest Ave, <br /> Muntinlupa, Metro Manila
              </Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1,
                color: colors.primary,
              }}
            >
              <KeyboardDoubleArrowDownOutlinedIcon />
              <Typography variant="h3" fontWeight={700} color={colors.primary}>
                Google Maps
              </Typography>
              <KeyboardDoubleArrowDownOutlinedIcon />
            </Box>

            <Box sx={{ width: '200px', height: 'auto' }}>
              <QRCode
                logoImage={process.env.PUBLIC_URL + '/assets/carego_health_suite_logo_white.png'}
                removeQrCodeBehindLogo
                eyeRadius={[10, 10, 10]}
                fgColor={colors.primary}
                value={'https://maps.app.goo.gl/DBa4CCWYongBLHu76'}
                size={200}
                style={{ width: '100%', height: 'auto' }}
              />
            </Box>

            <Typography variant="h6" fontWeight={500} color={colors.primary}>
              https://maps.app.goo.gl/VRzKFuUo1HY1KA8P6
            </Typography>
          </Box>
        </Box>
      </CustomModal>
    </Box>
  );
};

export default CareGoPortal;
