/* eslint-disable react-hooks/exhaustive-deps */

import 'core/styles/RichTextEditor.css';

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { CompanyProtectedComponent, ConfirmationDialog, CustomModal, RegularButton } from 'core/components';
import { PatientNoteTemplatesTable, PatientServiceProductTable } from 'company/entities/tables';
import {
  deleteClinicService,
  exportServices,
  getServiceById,
  getServices,
  getServicesAssigned,
} from 'company/api/services';
import { deleteServiceProduct, getProductsByServiceId } from 'company/api/service-products';
import { useContext, useEffect, useRef, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { ApiQuery } from 'core/model/interface';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import ClinicsTable from '../ClinicsTable';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DownloadIcon from '@mui/icons-material/Download';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FacilityContext } from 'core/context/facility.context';
import HealthAndSafetyOutlinedIcon from '@mui/icons-material/HealthAndSafetyOutlined';
import ImportServiceModal from './components/ImportServiceModal';
import InfiniteScrollContainer from 'core/components/dataView/InfiniteScrollContainer';
import ServiceCategories from './components/ServiceCategories';
import ServiceDetails from './components/ServiceDetails';
import { ServiceForm } from 'company/entities/forms';
import ServiceGroups from './components/ServiceGroups';
import ServiceNoteModal from './ServiceNoteModal';
import ServiceProductModal from './ServiceProductModal';
import { UserContext } from 'core/context/user.context';
import { deleteServiceNote } from 'company/api/service-notes';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

export interface ServiceResponse {
  assigned_facilities: string;
  assigned_facilities_ids: string;
  banner_image: string;
  buffer_time_value: 5;
  clinic_price: string;
  company_id: number;
  tax: string;
  description: null | string;
  duration: number;
  full_name: string;
  full_name_with_title: string;
  id: number;
  lead_time_value: number;
  price: string;
  provider_id: number;
  service_name: string;
  slots: number;
  sms_name: string;
  service_category_id?: any[];
}

const Services: React.FC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user } = useContext(UserContext);

  const serviceNotesTableRef: any = useRef();
  const serviceProductsTableRef: any = useRef();
  const [selectedService, setSelectedService] = useState<number>();
  const [rawService, setRawService] = useState<ServiceResponse>();
  const [toUpdateService, setToUpdateService] = useState<ServiceResponse>();
  const [importOpen, setImportOpen] = useState<boolean>(false);

  const [openAddService, setOpenAddService] = useState(false);
  const [openDeleteService, setOpenDeleteService] = useState(false);
  const [openDeleteNote, setOpenDeleteNote] = useState(false);
  const [openDeleteProduct, setOpenDeleteProduct] = useState(false);

  const [openLinkedNotesModal, setOpenLinkedNotesModal] = useState(false);
  const [openLinkedProductsModal, setOpenLinkedProductsModal] = useState(false);

  const [addedService, setAddedService] = useState<number>();
  const [updatedService, setUpdatedService] = useState<ServiceResponse>();
  const [deletedService, setDeletedService] = useState<number>();
  const [noteToDelete, setNoteToDelete] = useState<any>();
  const [productToDelete, setProductToDelete] = useState<any>();
  const { facility } = useContext(FacilityContext);
  const { enqueueSnackbar } = useSnackbar();

  const [openServiceGroups, setOpenServiceGroups] = useState<boolean>(false);
  const [openProductModal, setOpenProductModal] = useState(false);
  const [productToUpdate, setProductToUpdate] = useState<any>(null);
  const [openServiceCategory, setOpenServiceCategory] = useState<boolean>(false);
  const [serviceItems, setServiceItems] = useState<any[]>([]);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  const isMobilePhone = useMediaQuery('(max-width:768px)');

  const getData = async (query: ApiQuery) => {
    if (facility) {
      const response = await getServicesAssigned(facility.id, query);
      setServiceItems(response.data.data);
      return response;
    }

    return getServices(query); //check
  };

  const handleDelete = () => {
    if (selectedService) {
      deleteClinicService(selectedService).then((response: any) => {
        const currentOrders = JSON.parse(localStorage.getItem('orders') || '[]');
        const updatedOrders = currentOrders.filter((order: any) => order.item_id !== selectedService);
        localStorage.setItem('orders', JSON.stringify(updatedOrders));

        // localStorage.removeItem('orders');

        setDeletedService(parseInt(response.data.service));
        setOpenDeleteService(false);
      });
    }
  };

  const handleRefreshExternalDetails = (value: number | null) => {
    if (value === null) {
      const currentIndex = serviceItems.findIndex((item) => item.id === selectedService);
      if (currentIndex >= 0) {
        const nextItem = serviceItems[currentIndex + 1] || serviceItems[currentIndex - 1];
        if (nextItem) {
          setSelectedService(nextItem.id);
          setRawService(nextItem);
        } else {
          setSelectedService(undefined);
          setRawService(undefined);
        }
      }

      setRefreshTrigger((prev) => prev + 1);
    } else {
      refreshServiceDetails();
    }
  };

  const refreshServiceDetails = () => {
    if (selectedService) {
      getServiceById(selectedService).then((response: any) => {
        setRawService(response.data);
      });
    }
  };

  const handleDeleteNote = () => {
    noteToDelete && removeServiceNote(noteToDelete.company_service_notes_id);
  };

  const handleDeleteProduct = () => {
    productToDelete && removeServiceProduct(productToDelete.id);
  };

  const removeServiceNote = async (note: number) => {
    try {
      await deleteServiceNote(note);
      enqueueSnackbar(`Note successfully removed!`, { variant: 'success' });

      serviceNotesTableRef.current.refreshTable();
    } catch {
      enqueueSnackbar(`Error occured! Unable to remove note.`, { variant: 'error' });
    }
  };

  const removeServiceProduct = async (data: number) => {
    try {
      await deleteServiceProduct(data);
      enqueueSnackbar(`Product successfully removed!`, { variant: 'success' });

      serviceProductsTableRef.current.refreshTable();
    } catch {
      enqueueSnackbar(`Error occured! Unable to remove product.`, { variant: 'error' });
    }
  };

  const displayItem = (item: ServiceResponse) => {
    return (
      <Box display="flex" flexDirection="column" gap="5px" width="100%">
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          color={item.id === selectedService && !isMobilePhone ? 'white' : colors.text}
        >
          <Typography variant="h5" fontWeight="600">
            {item.service_name}
          </Typography>
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    if (!selectedService) return;
    getServiceById(selectedService).then((response) => {
      // setAddedService(response.data.id);
      setRawService(response.data);
    });
  }, [selectedService]);

  if (openServiceGroups) return <ServiceGroups setOpenServiceGroups={setOpenServiceGroups} />;
  if (openServiceCategory) return <ServiceCategories setOpenServiceCategories={setOpenServiceCategory} />;
  return (
    <>
      <InfiniteScrollContainer
        key={refreshTrigger}
        urlKeyId="sid"
        title="List of Services"
        getData={getData}
        exportData={exportServices}
        getSingleData={getServiceById}
        renderItem={displayItem}
        selected={selectedService}
        setSelected={setSelectedService}
        addedNewItem={addedService}
        updatedItem={updatedService}
        deleteId={deletedService}
        containerHeight="calc(100vh - 170px)"
        titleComponents={
          <CompanyProtectedComponent requiredAuth={['MANAGE_MEDICINES']}>
            {!isMobilePhone && (
              <RegularButton
                startIcon={<AddIcon />}
                label="Add"
                onClick={() => setOpenAddService(true)}
                size="small"
                disabled={user.remaining_storage <= 0}
              />
            )}
          </CompanyProtectedComponent>
        }
        headerComponents={
          <Box sx={{ display: 'flex', gap: '10px', width: '100%' }}>
            <RegularButton
              startIcon={<CategoryOutlinedIcon />}
              variant="outlined"
              label="Categories"
              onClick={() => setOpenServiceCategory(true)}
              fullWidth
            />

            <RegularButton
              startIcon={<HealthAndSafetyOutlinedIcon />}
              variant="outlined"
              label="Packages"
              onClick={() => setOpenServiceGroups(true)}
              fullWidth
            />
          </Box>
        }
        displaySelectedTitle={() => (
          <Typography variant="h3" fontWeight={700} color={colors.primary}>
            {!isMobilePhone ? rawService?.service_name : 'Service'}
          </Typography>
        )}
        actions={
          !isMobilePhone
            ? [
                {
                  icon: <DriveFileRenameOutlineIcon />,
                  label: 'Update',
                  action: (data) => {
                    setOpenAddService(true);
                    setToUpdateService(rawService);
                  },
                },
                {
                  icon: <DeleteOutlineIcon />,
                  label: 'Delete',
                  color: 'error',
                  action: () => {
                    setOpenDeleteService(true);
                  },
                },
              ]
            : undefined
        }
        underSearchComponents={
          <CompanyProtectedComponent requiredAuth={['IMPORT_SERVICES']}>
            <RegularButton
              startIcon={<DownloadIcon style={{ transform: 'rotate(180deg)' }} />}
              label="Import"
              variant="outlined"
              size="small"
              onClick={() => setImportOpen(true)}
              disabled={user.remaining_storage <= 0}
            />
          </CompanyProtectedComponent>
        }
      >
        {!!selectedService && (
          <Box>
            <ServiceDetails services={rawService} />
            <Box>
              <ClinicsTable
                source="Services"
                selectedService={rawService}
                setRefreshExternalDetails={handleRefreshExternalDetails}
              />
              <PatientNoteTemplatesTable
                tableRef={serviceNotesTableRef}
                serviceId={selectedService}
                hideAddUpdateDeleteAction
                tableComponent={{
                  mobileViewConfig: (data) => ({
                    title_fields: ['header'],
                    // subtitle_fields: ['sex', 'age'],
                  }),
                  headerComponent: (
                    <Typography fontWeight={600} variant="h4">
                      Linked Notes
                    </Typography>
                  ),
                  filterComponent: !isMobilePhone ? (
                    <Typography>
                      Appointments with this service will automatically generate notes from the linked notes.
                    </Typography>
                  ) : (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
                      <Typography>
                        Appointments with this service will automatically generate notes from the linked notes.
                      </Typography>
                      <RegularButton
                        size="medium"
                        startIcon={<AddIcon />}
                        label="Add Note"
                        onClick={() => {
                          setOpenLinkedNotesModal(true);
                        }}
                        fullWidth
                      />
                    </Box>
                  ),
                  afterSearchbarComponent: !isMobilePhone ? (
                    <RegularButton
                      styles={{ width: '150px' }}
                      size="medium"
                      startIcon={<AddIcon />}
                      label="Add Note"
                      onClick={() => {
                        setOpenLinkedNotesModal(true);
                      }}
                    />
                  ) : undefined,
                  rowHeight: 45,
                }}
                tableAction={{
                  rowActions: [
                    {
                      style: { flex: 2 },
                      label: 'Remove',
                      action: (data) => {
                        setNoteToDelete(data);
                        setOpenDeleteNote(true);
                      },
                      icon: <DeleteOutlineIcon />,
                      color: 'error',
                    },
                  ],
                }}
              />
              <PatientServiceProductTable
                tableRef={serviceProductsTableRef}
                serviceId={selectedService}
                hideAddUpdateDeleteAction
                overrideGetData={(query) => getProductsByServiceId(Number(selectedService), query)}
                tableComponent={{
                  mobileViewConfig: (data) => ({
                    title_fields: ['header'],
                    // subtitle_fields: ['sex', 'age'],
                  }),
                  headerComponent: (
                    <Typography fontWeight={600} variant="h4">
                      Linked Products
                    </Typography>
                  ),
                  filterComponent: !isMobilePhone ? (
                    <Typography>List of products linked to the service.</Typography>
                  ) : (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
                      <Typography>List of products linked to the service.</Typography>
                      <RegularButton
                        size="medium"
                        startIcon={<AddIcon />}
                        label="Add Product"
                        onClick={() => {
                          setProductToUpdate(null);
                          setOpenProductModal(true);
                        }}
                        fullWidth
                      />
                    </Box>
                  ),
                  afterSearchbarComponent: !isMobilePhone ? (
                    <RegularButton
                      styles={{ width: '150px' }}
                      size="medium"
                      startIcon={<AddIcon />}
                      label="Add Product"
                      onClick={() => {
                        setProductToUpdate(null);
                        setOpenProductModal(true);
                      }}
                    />
                  ) : undefined,
                  rowHeight: 45,
                }}
                tableAction={{
                  rowActions: [
                    {
                      style: { flex: 2 },
                      label: 'Update',
                      action: (data) => {
                        setProductToUpdate(data);
                        setOpenProductModal(true);
                      },
                      icon: <EditOutlinedIcon />,
                    },
                    {
                      style: { flex: 2 },
                      label: 'Remove',
                      action: (data) => {
                        setProductToDelete(data);
                        setOpenDeleteProduct(true);
                      },
                      icon: <DeleteOutlineIcon />,
                      color: 'error',
                    },
                  ],
                }}
              />

              <ServiceNoteModal
                drawerRefModal={serviceNotesTableRef}
                open={openLinkedNotesModal}
                setOpen={setOpenLinkedNotesModal}
                facility={facility}
                serviceId={selectedService as number}
              />
              <ServiceProductModal
                drawerRefModal={serviceProductsTableRef}
                open={openLinkedProductsModal}
                setOpen={setOpenLinkedProductsModal}
                facility={facility}
                serviceId={selectedService as number}
              />
            </Box>
          </Box>
        )}
      </InfiniteScrollContainer>

      <CustomModal
        header={toUpdateService ? 'Update Service' : 'Add Service'}
        open={openAddService}
        setOpen={setOpenAddService}
        onClose={() => setToUpdateService(undefined)}
      >
        <ServiceForm
          service={toUpdateService}
          callbackAfterSubmit={(data: any) => {
            setOpenAddService(false);
            setToUpdateService(undefined);
            localStorage.removeItem('orders');

            if (toUpdateService) {
              setUpdatedService(data.service);
              setRawService(data.service);
            } else {
              setAddedService(data.service.id);
            }
          }}
        />
      </CustomModal>

      {rawService && (
        <ConfirmationDialog
          content={'Are you sure you want to delete ' + rawService.service_name + '?'}
          open={openDeleteService}
          setOpen={setOpenDeleteService}
          onConfirm={handleDelete}
          bottomDrawerDialog={isMobilePhone}
        />
      )}
      {noteToDelete && (
        <ConfirmationDialog
          bottomDrawerDialog={isMobilePhone}
          content={'Are you sure you want to delete ' + noteToDelete.header + '?'}
          open={openDeleteNote}
          setOpen={setOpenDeleteNote}
          onConfirm={handleDeleteNote}
        />
      )}
      {productToDelete && (
        <ConfirmationDialog
          bottomDrawerDialog={isMobilePhone}
          content={'Are you sure you want to delete ' + productToDelete.variant_name + '?'}
          open={openDeleteProduct}
          setOpen={setOpenDeleteProduct}
          onConfirm={handleDeleteProduct}
        />
      )}
      <ServiceProductModal
        drawerRefModal={serviceProductsTableRef}
        open={openProductModal}
        setOpen={setOpenProductModal}
        facility={facility}
        serviceId={selectedService as number}
        productToUpdate={productToUpdate}
      />

      <ImportServiceModal open={importOpen} setOpen={setImportOpen} callbackAfterImport={() => undefined} />
    </>
  );
};

export default Services;
