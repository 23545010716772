import React, { useContext, useEffect, useState } from 'react';
import { CustomModal } from 'core/components';
import { Box, Card, CardContent, Typography, Button, LinearProgress, useTheme } from '@mui/material';
import styled from 'styled-components';
import { FacilityContext } from 'core/context/facility.context';
import { UserContext } from 'core/context/user.context';
import { getPatients } from 'company/api/patient';
import { getTotalStorage } from 'company/api/file-upload';
import { getUsersAssigned } from 'company/api/users';
import { getDashboardStat } from 'company/api/inventory-dashboard';

import CustomAccordionForm from 'core/components/CustomAccordion';
import CustomAccordion from 'core/components/CustomAccordion';
import TopUpForm from './TopUpForm';

type TopUpModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const TopUpModal: React.FC<TopUpModalProps> = ({ open, setOpen }) => {
  const theme = useTheme();
  const { facility } = useContext(FacilityContext);
  const { user } = useContext(UserContext);
  const primaryColor = theme.palette.primary.main;

  const [userCount, setUserCount] = useState(0);
  const [userLimit, setUserLimit] = useState(0);
  const [patientCount, setPatientCount] = useState(0);
  const [patientLimit, setPatientLimit] = useState(0);
  const [storageCount, setStorageCount] = useState({ value: 0, unit: 'KB' });
  const [storageLimit, setStorageLimit] = useState(0);
  const [smsCount, setSmsCount] = useState(0);
  const userProgress = (userCount / userLimit) * 100;
  const patientProgress = (patientCount / patientLimit) * 100;

  const storageProgress = () => {
    let storageInGB;
    if (storageCount.unit === 'GB') {
      storageInGB = storageCount.value;
    } else if (storageCount.unit === 'MB') {
      storageInGB = storageCount.value / 1024;
    } else {
      storageInGB = storageCount.value / (1024 * 1024);
    }
    return (storageInGB / storageLimit) * 100;
  };

  useEffect(() => {
    if (facility) {
      getUserCount();
      getPatientCount();
      getStorageCount();
      getSmsCount();
    }
  }, []);

  useEffect(() => {
    if (user) {
      setUserLimit(user.company?.user_limit ?? 0);
      setPatientLimit(user.company?.patient_limit ?? 0);
      setStorageLimit(user.company?.storage_limit ?? 0);
    }
  }, [user]);

  const getUserCount = () => {
    getUsersAssigned(facility.id).then((users) => {
      setUserCount(users.data.data.length);
    });
  };

  const getSmsCount = () => {
    getDashboardStat(facility.id).then((smsCredits) => {
      setSmsCount(smsCredits.data.sms_credits);
    });
  };

  const getPatientCount = () => {
    getPatients(facility.id).then((patients) => {
      setPatientCount(patients.data.meta.total);
    });
  };

  const getStorageCount = () => {
    getTotalStorage(facility.id).then((storage) => {
      const storageInBytes = storage.data;
      let storageToDisplay;
      let unit;

      const storageInGB = storageInBytes / (1024 * 1024 * 1024);

      storageToDisplay = parseFloat(storageInGB.toFixed(2));
      unit = 'GB';

      setStorageCount({ value: storageToDisplay, unit: unit });
    });
  };

  const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    marginTop: '20px',
    marginBottom: '20px',
    '& .MuiLinearProgress-bar': {
      borderRadius: 5,
    },
  }));

  const StyledCard = styled(Card)({
    height: 420,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '20px',
    transition: 'transform 0.2s, box-shadow 0.2s',
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: '0 10px 20px rgba(0, 0, 0, 0.12)',
    },
    borderRadius: '15px',
    backgroundColor: 'white',
  });

  const getSmsStatus = (count: number) => {
    if (count < 100) return { status: 'critical', progress: 33 };
    if (count < 200) return { status: 'moderate', progress: 66 };
    return { status: 'safe', progress: 100 };
  };

  const resources = [
    {
      title: 'Storage Space',
      type: 'storage',
      current: storageCount,
      limit: storageLimit,
      description: 'Additional storage space for your files and documents.',
      unit: 'GB',
      price: '₱100/5GB (Monthly)',
      progress: storageProgress(),
    },
    {
      title: 'User Accounts',
      type: 'users',
      current: userCount,
      limit: userLimit,
      description: 'Additional user accounts for your facility.',
      unit: 'users',
      price: '₱500/account (Monthly)',
      progress: userProgress,
    },
    {
      title: 'Module Access',
      type: 'modules',

      // current: patientCount,
      // limit: patientLimit,
      description: 'Request an upgrade for module access to [POS or Patient Transaction]. ',
      unit: 'modules',
      price: 'Price Varies',
      progress: patientProgress,
    },
    {
      title: 'SMS Credits',
      type: 'credits',
      current: smsCount,
      limit: null,
      description: 'Additional SMS credits for your facility.',
      unit: 'credits',
      price: '₱2000/2500 SMS',
      progress: getSmsStatus(smsCount).progress,
    },
  ];

  const handleTopUp = (values: any) => {
    console.log(`Topping up ${values.resource_type} by ${values.amount}`);
  };

  return (
    <CustomModal header="Resource Top Up" open={open} setOpen={setOpen}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
          gap: 3,
          p: 2,
        }}
      >
        {resources.map((resource, index) => (
          <StyledCard key={index}>
            <CardContent>
              <Box sx={{ textAlign: 'center', mb: 2 }}>
                <Typography variant="h6" gutterBottom>
                  {resource.title}
                </Typography>
                <Typography variant="h2" color="primary" fontWeight={700}>
                  {resource.price}
                </Typography>
              </Box>

              <Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  {resource.type !== 'modules' ? (
                    <>
                      <Typography variant="body2">Usage</Typography>
                      <Typography variant="body2">
                        {resource.type === 'credits'
                          ? `${resource.current} ${resource.unit} (${
                              Number(resource.current) < 100
                                ? 'Critical'
                                : Number(resource.current) < 200
                                ? 'Moderate'
                                : 'Safe'
                            })`
                          : typeof resource.current === 'object'
                          ? `${resource.current.value} ${resource.current.unit} / ${resource.limit} ${resource.unit}`
                          : `${resource.current} / ${resource.limit} ${resource.unit}`}
                      </Typography>
                    </>
                  ) : (
                    <Typography textAlign={'center'}>Modules are new features for your clinic</Typography>
                  )}
                </Box>

                {resource.type !== 'modules' && (
                  <StyledLinearProgress variant="determinate" value={resource.progress} />
                )}
              </Box>

              <Box sx={{ p: 2, pt: 0 }}>
                <Box mb={5}>
                  <CustomAccordion title="Top Up">
                    <TopUpForm resourceType={resource.type} callbackAfterSubmit={handleTopUp} />
                  </CustomAccordion>
                </Box>

                <Typography variant="body2" align="center" color="textSecondary">
                  {resource.description}
                </Typography>
              </Box>
            </CardContent>
          </StyledCard>
        ))}
      </Box>
    </CustomModal>
  );
};

export default TopUpModal;
