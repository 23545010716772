import { Box, useMediaQuery } from '@mui/material';
import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';

type Scroll2Props = {
  id: string;
};
const Scroll2: React.FC<Scroll2Props> = ({ id }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const totalSlides = window.innerWidth >= 768 ? 10 : 8;
  const isMobile = useMediaQuery('(max-width:768px)');

  const handleSlideToLeft = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 2 + totalSlides) % totalSlides);
  };

  const handleSlideToRight = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 2) % totalSlides);
  };

  const slideContents = [
    {
      title: 'Patient Records',
      text: 'Customize clinic forms and automate patient management with health sessions.',
      imageSrc: './assets/Landing2/p2/icon1.svg',
    },
    {
      title: 'Appointments',
      text: 'Track schedules and automatically remind your patients to ensure worry-free scheduling of next appointments.',
      imageSrc: './assets/Landing2/p2/icon2.svg',
    },
    {
      title: 'Health Campaigns',
      text: 'Reach your audience effectively with semi-automated targeted SMS text blasts.',
      imageSrc: './assets/Landing2/p2/icon3.svg',
    },

    {
      title: 'Booking Platform',
      text: 'Be accessible from your patients with our booking platform and schedule with ease through our automated blocking schedules.',
      imageSrc: './assets/Landing2/p2/icon4.svg',
    },
    {
      title: 'Inventory',
      text: 'Streamline inventory of medicines and products with each patient visit.',
      imageSrc: './assets/Landing2/p2/icon11.svg',
    },
    {
      title: 'Invoicing and Daily Transactions',
      text: 'Monitor clinic finances with our Invoicing and Daily Transactions feature.',
      imageSrc: './assets/Landing2/p2/icon12.svg',
    },
    {
      title: 'E-prescription',
      text: 'Prescribe medicines conveniently for each Patient and send clinic-branded health and medication reminders.',
      imageSrc: './assets/Landing2/p2/icon13.svg',
    },
    {
      title: 'Digital Medical Certificate',
      text: 'Generate digital medical certificates for your patients seamlessly',
      imageSrc: './assets/Landing2/p2/icon14.svg',
    },
  ];
  const halfLength = Math.ceil(slideContents.length / 2);
  const firstHalf = slideContents.slice(0, halfLength);
  const secondHalf = slideContents.slice(halfLength);
  return isMobile ? (
    <MainContainer id={id}>
      <Container>
        <Con1>
          {/* <Test> */}
          {/* <span
              style={{
                background: 'linear-gradient(to right, #239bd7, #0463ae)',
                WebkitBackgroundClip: 'text',
                color: 'transparent',
                display: 'inline-block',
              }}
            > */}
          {/* </span>{' '} */}
          {/* </Test> */}
          Key Features for efficient <br /> delivery of personalized care <br />
          to more patients
        </Con1>
        <Image
          src="./assets//LandingMobile/device.png"
          alt="Logo"
          style={{ height: '250px', marginBottom: '20px', maxWidth: '100%' }}
        />
        <Con2>
          {slideContents.map((content, index) => (
            <ImageContainer key={index} style={{ transform: `translateX(${100 * (index - currentSlide)}%)` }}>
              <Image src={content.imageSrc} alt="Logo" />
              <Contain>
                <TextContainer>
                  <Title>{content.title}</Title>
                  <ImageText>{content.text}</ImageText>
                </TextContainer>
              </Contain>
            </ImageContainer>
          ))}
        </Con2>
        <Box>
          <Con4>
            {firstHalf.map((content, index) => (
              <ImageContainer key={index} style={{ transform: `translateX(${100 * (index - currentSlide)}%)` }}>
                <Image src={content.imageSrc} alt="Logo" />
                <Contain>
                  <TextContainer>
                    <Title>{content.title}</Title>
                    <ImageText>{content.text}</ImageText>
                  </TextContainer>
                </Contain>
              </ImageContainer>
            ))}
          </Con4>

          <Con4>
            {secondHalf.map((content, index) => (
              <ImageContainer
                key={index + halfLength}
                style={{ transform: `translateX(${100 * (index - currentSlide)}%)` }}
              >
                <Image src={content.imageSrc} alt="Logo" />
                <Contain>
                  <TextContainer>
                    <Title>{content.title}</Title>
                    <ImageText>{content.text}</ImageText>
                  </TextContainer>
                </Contain>
              </ImageContainer>
            ))}
          </Con4>
        </Box>

        <Con3>
          <ButtonCon>
            <ButtonImage onClick={handleSlideToLeft} src="./assets/Landing2/p2/Previous.png" alt="Logo" />
            <ButtonImage onClick={handleSlideToRight} src="./assets/Landing2/p2/Next.png" alt="Logo" />
          </ButtonCon>
        </Con3>
      </Container>
    </MainContainer>
  ) : (
    <MainContainer id={id}>
      <Container>
        <Con1>
          <Images className="logo" src="./assets/Landing2/p2/Waves.png" alt="Logo" />
          <Test>
            <span
              style={{
                background: 'linear-gradient(to right, #239bd7, #0463ae)',
                WebkitBackgroundClip: 'text',
                color: 'transparent',
                display: 'inline-block',
              }}
            >
              Key Features
            </span>{' '}
            for efficient delivery of <br />
          </Test>
          personalized care to more patients
        </Con1>
        <Con2>
          {slideContents.map((content, index) => (
            <ImageContainer key={index} style={{ transform: `translateX(${100 * (index - currentSlide)}%)` }}>
              <Image src={content.imageSrc} alt="Logo" />
              <Contain>
                <TextContainer>
                  <Title>{content.title}</Title>
                  <ImageText>{content.text}</ImageText>
                </TextContainer>
              </Contain>
            </ImageContainer>
          ))}
        </Con2>
        <Box>
          <Con4>
            {firstHalf.map((content, index) => (
              <ImageContainer key={index} style={{ transform: `translateX(${100 * (index - currentSlide)}%)` }}>
                <Image src={content.imageSrc} alt="Logo" />
                <Contain>
                  <TextContainer>
                    <Title>{content.title}</Title>
                    <ImageText>{content.text}</ImageText>
                  </TextContainer>
                </Contain>
              </ImageContainer>
            ))}
          </Con4>

          <Con4>
            {secondHalf.map((content, index) => (
              <ImageContainer
                key={index + halfLength}
                style={{ transform: `translateX(${100 * (index - currentSlide)}%)` }}
              >
                <Image src={content.imageSrc} alt="Logo" />
                <Contain>
                  <TextContainer>
                    <Title>{content.title}</Title>
                    <ImageText>{content.text}</ImageText>
                  </TextContainer>
                </Contain>
              </ImageContainer>
            ))}
          </Con4>
        </Box>

        <Con3>
          <ButtonCon>
            <ButtonImage onClick={handleSlideToLeft} src="./assets/Landing2/p2/Previous.png" alt="Logo" />
            <ButtonImage onClick={handleSlideToRight} src="./assets/Landing2/p2/Next.png" alt="Logo" />
          </ButtonCon>
        </Con3>
      </Container>
    </MainContainer>
  );
};

export default Scroll2;
const ImageContainer = styled.div`
  height: 95%;
  display: flex;
  align-items: center;
  flex-direction: column;
  transition: transform 0.5s ease;
  will-change: transform;
  padding: 0 60px;

  @media (max-width: 1900px) {
    height: 97%;
    padding: 0 42px;
  }
  @media (max-width: 768px) {
    padding: 0 42px;

    height: 85%;
  }
`;
const Contain = styled.div`
  height: 330px;
  width: 330px;
  z-index: -1;

  border-radius: 20px;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  align-items: center;

  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  @media (max-width: 1900px) {
    width: 250px;
    height: 250px;
  }
  @media (max-width: 768px) {
    width: 230px;
    height: 150px;
  }
`;
const TextContainer = styled.div`
  text-align: center;
  padding: 20px;
  display: flex;
  gap: 30px;
  height: 60%;
  flex-direction: column;

  @media (max-width: 1900px) {
    width: 250px;
    height: 150px;
    bottom: 40px;
    gap: 20px;
  }
  @media (max-width: 768px) {
    gap: 12px;
  }
`;

const Test = styled.div`
  display: flex;
  gap: 10px;
  @media (max-width: 1900px) {
    gap: 8px;
  }
  @media (max-width: 768px) {
    gap: 7px;
    font-size: 18px;
  }
`;
const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: 15px;
  }
`;
const ImageText = styled.p`
  margin: 0;
  font-size: 18px;
  color: #000;
  @media (max-width: 1900px) {
    font-size: 14px;
  }
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const MainContainer = styled.div<{ height?: string }>`
  width: 100vw;
  height: 100%;
  display: flex;

  justify-content: center;
  position: relative;
  background-image: url('./assets/Landing2/p2/des.png');
  background-size: cover;
  background-position: center;
  background-size: 100% 95%;
  background-repeat: no-repeat;
  @media (max-width: 768px) {
    // height: 100vh;
    background-size: 100% 100%;
    background-image: none;
    // margin-top: 100px;
  }
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;

  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 768px) {
    justify-content: center;
    gap: 10px;
  }
`;

const ButtonImage = styled.img``;
const ButtonCon = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: 768px) {
    height: 40px;
  }
`;
const Con1 = styled.div`
  font-size: 35px;
  font-weight: bold;
  text-align: center;
  @media (max-width: 1900px) {
    font-size: 30px;
  }
  @media (max-width: 768px) {
    font-size: 22px;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  High {
    display: inline;
  }
`;
const Con2 = styled.div`
  height: 380px;

  position: relative;
  display: flex;

  justify-content: center;

  @media (max-width: 1900px) {
    width: 100%;
    height: 280px;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;
const Con4 = styled.div`
  position: relative;
  display: flex;

  justify-content: center;

  @media (max-width: 1900px) {
    width: 100%;
    height: 200px;
    margin-left: 150px;
  }
  @media (min-width: 769px) {
    display: none;
  }
`;
const Con3 = styled.div``;
const Image = styled.img`
  height: auto;

  @media (max-width: 1900px) {
    height: 50px;
  }
  @media (max-width: 768px) {
    height: 40px;
  }
`;
const Images = styled.img`
  height: auto;
  width: 80px;
  height: 10px;
  @media (max-width: 1900px) {
    width: 70px;
    height: 10px;
  }
`;
