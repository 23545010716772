import { Box, Grid, Typography } from '@mui/material';
import React, { Fragment } from 'react';
import SignatureSelector, { SIGNATURE_TYPES } from '../Summarized/SignatureSelector';
import { calculateAge, formatArray, formatDate } from 'core/utils';
import {
  getAnswerMalvarForm,
  medical_history_index_malvar,
  medical_history_malvar,
  medical_history_malvar_2,
  physical_exam_malvar,
} from '../Summarized/malvar_source_field';

import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { SIZES } from 'theme/constants';

interface MalvarFormProps {
  assessment: any;
  printMode: boolean;
}
const underlineStyle = { borderBottom: '1px solid #878787', paddingInline: '5px', width: '100%' };

export default function MalvarAssessmentForm({ assessment, printMode }: MalvarFormProps) {
  const getAPEAnswer = (
    field: string,
    defaultValue?: string,
    occurence?: number,
    prefix?: string,
    columns?: string[]
  ): string => {
    return getAnswerMalvarForm(field, assessment.notes ?? [], defaultValue, occurence, prefix, columns);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const showTickBox = (className: string) => {
    return assessment.class === className ? (
      <CheckBoxOutlinedIcon sx={{ fontSize: '16px', gridRow: 'span 2' }} />
    ) : (
      <CheckBoxOutlineBlankOutlinedIcon sx={{ fontSize: '16px', gridRow: 'span 2' }} />
    );
  };

  function getClassificationAnswer(classification: 'A' | 'B' | 'C' | 'PENDING') {
    if (assessment.class === classification) return ' x ';
    return '   ';
  }

  function determineCBCAnswer(type: 'Normal' | 'With Findings' | 'Result') {
    const hemoglobinResult = getAPEAnswer('Hemoglobin');
    const hematocritResult = getAPEAnswer('Hematocrit');
    let result = '';
    if (hemoglobinResult !== 'Normal') {
      result += `${hemoglobinResult} Hemoglobin Level\n`;
    }
    if (hematocritResult !== 'Normal') {
      result += `${hematocritResult} Hematorcrit Level\n`;
    }

    if (type === 'Normal' && hemoglobinResult === 'Normal' && hematocritResult === 'Normal') return ' x ';
    if (type === 'With Findings' && (hematocritResult !== 'Normal' || hemoglobinResult !== 'Normal')) return ' x ';

    if (type === 'Result' && result !== '') {
      return result;
    }

    return '   ';
    // if (ans === 'N/A' && type === 'Normal') return ' x ';
    // if (ans === 'N/A' && type === 'With Findings') return '   ';

    // if (ans === '' && type === 'Normal') return ' x ';
    // if (ans === '' && type === 'With Findings') return '   ';

    // if (ans !== 'N/A' && ans !== '' && type === 'Result') return ans;

    // if (ans !== 'N/A' && ans !== '' && type === 'With Findings') return ' x ';
    // if (ans !== 'N/A' && ans !== '' && type === 'Normal') return '   ';
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      {/* FIRST ROW */}
      <Box>
        <Box className="header" sx={{ backgroundColor: '#CEF0C9' }}>
          <Typography textAlign="center" fontWeight="bold">
            MEDICAL EXAMINATION
          </Typography>
        </Box>
        <Box className="body" display="grid" gridTemplateColumns="0.3fr 2.2fr 0.2fr 1.3fr 0.4fr 1.1fr" columnGap="1%">
          <Typography fontWeight={600}>Name:</Typography>
          <Typography sx={underlineStyle}>
            {formatArray([assessment.first_name, assessment.middle_name, assessment.last_name], ' ')}
          </Typography>
          <Typography fontWeight={600}>Age:</Typography>
          <Typography sx={underlineStyle}>
            {assessment.birthday ? calculateAge(assessment.birthday) : assessment.age}
          </Typography>
          <Typography fontWeight={600}>Company:</Typography>
          <Typography sx={underlineStyle}>{assessment.company_name}</Typography>
        </Box>

        <Box
          className="body"
          display="grid"
          gridTemplateColumns="0.4fr 1.6fr 0.5fr 1.2fr 0.5fr 1.2fr 0.3fr 1.5fr"
          columnGap="5px"
        >
          <Typography fontWeight={600}>Address:</Typography>
          <Typography sx={underlineStyle}>{assessment.address ?? '-'}</Typography>
          <Typography fontWeight={600}>Contact No:</Typography>
          <Typography sx={underlineStyle}>{assessment.mobile_number ?? '-'}</Typography>
          <Typography fontWeight={600}>Gender:</Typography>
          <Typography sx={underlineStyle}>{assessment.sex ?? '-'}</Typography>

          <Typography fontWeight={600}>Date:</Typography>
          <Typography sx={underlineStyle}>{!!assessment.schedule ? formatDate(assessment.schedule) : '-'}</Typography>
        </Box>
      </Box>

      {/* SECOND ROW */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
        <Box className="header" sx={{ backgroundColor: '#CEF0C9' }}>
          <Typography fontWeight={'500'}>I. MEDICAL HISTORY</Typography>
        </Box>

        <Box className="body">
          <Grid container>
            <Grid item xs={12} display="grid" gridTemplateColumns={'0.5fr 1.5fr 2fr'}>
              <Typography>A. Present Symptoms: </Typography>
              <Typography sx={underlineStyle}>{getAPEAnswer(medical_history_malvar_2[0])}</Typography>
              <Typography></Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography>
              B. Past Medical History: Has applicant suffered from, been told he had any of the following conditions
            </Typography>
          </Grid>

          <Grid item xs={12} paddingLeft={SIZES.padding}>
            <Box display="grid" gridTemplateColumns="1fr 40px 1fr 40px 1fr 40px" columnGap="10px">
              {medical_history_malvar.map((history, index) => {
                const moreInfo = getAPEAnswer(history, '', 1, '', ['More Info']);
                return (
                  <Fragment key={index}>
                    {medical_history_index_malvar[index] === 9 && <Box></Box>}
                    {medical_history_index_malvar[index] === 9 && <Box></Box>}

                    <Box display="flex" flexDirection="column">
                      <Box display="flex" alignItems="center">
                        <Box width={120}>
                          <Typography>
                            {medical_history_index_malvar[index]}. {history}:
                          </Typography>
                        </Box>
                        {medical_history_index_malvar[index] !== 25 && (
                          <Box sx={{ border: '1px solid #878787', textAlign: 'center', px: 1 }}>
                            <Box width={20}>
                              <Typography>{getAPEAnswer(history, 'No')}</Typography>
                            </Box>
                          </Box>
                        )}
                        {medical_history_index_malvar[index] === 25 && (
                          <Typography sx={underlineStyle}>{getAPEAnswer('Other Past Medical History')}</Typography>
                        )}
                      </Box>

                      {moreInfo && <Typography sx={underlineStyle}>{moreInfo}</Typography>}
                    </Box>
                    <Box></Box>
                  </Fragment>
                );
              })}
              {/* <Box sx={{ gridColumn: 'span 2' }}>
              <Box display="flex" flexDirection="row" alignItems="flex-start" gap="5px" sx={{ flexWrap: 'nowrap' }}>
                <Typography sx={{ whiteSpace: 'nowrap' }}>15. Others:</Typography>
                <Typography
                  sx={{
                    ...underlineStyle,
                    wordWrap: 'break-word',
                    overflow: 'visible',
                    flex: '1',
                  }}
                >
                  {getAPEAnswer(medical_history2[1], '-')}
                </Typography>
              </Box>
            </Box> */}
            </Box>
          </Grid>

          <Grid item xs={12} display="grid" gridTemplateColumns={'1fr 4fr'}>
            <Typography>C. Family Medical History</Typography>
            <Typography sx={underlineStyle}>{getAPEAnswer(medical_history_malvar_2[2])}</Typography>
            <Typography>D. Operations and Accidents</Typography>
            <Typography sx={underlineStyle}>{getAPEAnswer(medical_history_malvar_2[3])}</Typography>
            <Typography>E. Allergies</Typography>
            <Typography sx={underlineStyle}>{getAPEAnswer(medical_history_malvar_2[4])}</Typography>
            <Typography>G. Personal / Social History</Typography>
            <Typography sx={underlineStyle}>
              {(() => {
                const smokingHistory = getAPEAnswer(medical_history_malvar_2[6]);
                const smokingHistoryDuration = getAPEAnswer(medical_history_malvar_2[7]);
                const drinkingHistory = getAPEAnswer(medical_history_malvar_2[8]);

                if (smokingHistory === '-' && smokingHistoryDuration === '-' && drinkingHistory === '-') {
                  return 'N/A';
                }
                return (
                  formatArray(
                    [
                      smokingHistory +
                        (smokingHistory && smokingHistory !== 'Non-smoker' && smokingHistoryDuration
                          ? ` (${smokingHistoryDuration})`
                          : ''),
                      drinkingHistory,
                    ],
                    ', '
                  ) || '-'
                );
              })()}
            </Typography>
          </Grid>
        </Box>
      </Box>

      {/* THIRD ROW */}
      <Box>
        <Box className="header" sx={{ backgroundColor: '#CEF0C9' }}>
          <Typography fontWeight={'500'}>II. PHYSICAL EXAMINATION</Typography>
        </Box>

        <Box className="body" display="grid" gridTemplateColumns={'1fr 1fr'} columnGap={'20px'}>
          <Box>
            <Box display="grid" gridTemplateColumns={'3fr 1.5fr'} columnGap={'5px'}>
              <Box display="grid" gridTemplateColumns={'1.5fr 0.5fr'}>
                <Typography>A. Height</Typography>
                <Typography sx={underlineStyle}>
                  <strong>{getAPEAnswer('Height (m)')}</strong> m
                </Typography>

                <Typography>B. Weight</Typography>
                <Typography sx={underlineStyle}>
                  <strong>{getAPEAnswer('Weight (kg)')}</strong> kg
                </Typography>

                <Typography>C. Blood Pressure</Typography>
                <Typography sx={underlineStyle}>
                  <strong>{getAPEAnswer('Blood Pressure (mmHg)')}</strong> mmHg
                </Typography>

                <Typography>D. Pulse</Typography>
                <Typography sx={underlineStyle}>
                  <strong>{getAPEAnswer('Pulse Rate (per min)')}</strong> beats/min
                </Typography>

                <Typography>E. Temperature</Typography>
                <Typography sx={underlineStyle}>
                  <strong>{getAPEAnswer('Temperature (°C)')}</strong> °C
                </Typography>

                <Typography>F. Hearing</Typography>
                <Typography sx={underlineStyle}>Normal</Typography>
              </Box>

              <Box display="grid" gridTemplateColumns={'1fr 1fr'}>
                <Typography>I. BMI</Typography>
                <Typography sx={{ textDecoration: 'underline', textUnderlineOffset: '4px' }}>
                  <strong>{getAPEAnswer('BMI')}</strong> kg/m3
                </Typography>
              </Box>
            </Box>

            <Box display="grid" gridTemplateColumns={'1fr 1fr'} columnGap={'5px'} mt="10px">
              <Box>
                <Typography>G. Visual Acuity</Typography>
                <Box display="grid" gridTemplateColumns={'1fr 1fr'} paddingLeft="10px">
                  <Typography>1. Far Vision</Typography>
                  <Typography sx={underlineStyle}>OD</Typography>

                  <Typography></Typography>
                  <Typography sx={underlineStyle}>OS</Typography>

                  <Typography>2. Near Vision</Typography>
                  <Typography sx={underlineStyle}>OU</Typography>

                  {/* <Typography></Typography>
                  <Typography sx={underlineStyle}>OS</Typography> */}

                  <Typography>{`3. Color Vision (Ishihara)`}</Typography>
                </Box>
              </Box>

              <Box display="grid" gridTemplateColumns={'1fr 1fr'} columnGap={'5px'}>
                <Typography fontWeight={600}>Uncorrected</Typography>
                <Typography fontWeight={600}>Corrected</Typography>
                <Typography sx={underlineStyle}>{getAPEAnswer('OD')}</Typography>
                <Typography sx={underlineStyle}>{getAPEAnswer('OD', '-', 2)}</Typography>
                <Typography sx={underlineStyle}>{getAPEAnswer('OS')}</Typography>
                <Typography sx={underlineStyle}>{getAPEAnswer('OS', '-', 2)}</Typography>
                <Typography sx={underlineStyle}>{getAPEAnswer('OU')}</Typography>
                <Typography sx={underlineStyle}>{getAPEAnswer('OU', '-', 2)}</Typography>
                <Typography sx={underlineStyle} mt="11px">
                  {getAPEAnswer('Color Vision (Ishihara)')}
                </Typography>
                {/* <Typography sx={underlineStyle}></Typography> */}
              </Box>
            </Box>

            <Box>
              <Typography>H. Dental</Typography>

              <Box display="grid" gridTemplateColumns={'0.5fr 1.5fr'} pl="5px">
                <>
                  <Typography>Upper</Typography>
                  <Box display="grid" gridTemplateColumns={'1fr 1fr'}>
                    <Typography>R 8-7-6-5-4-3-2-1</Typography>
                    <Typography>L 1-2-3-4-5-6-7-8</Typography>
                  </Box>
                </>

                <>
                  <Typography>Lower</Typography>
                  <Box display="grid" gridTemplateColumns={'1fr 1fr'}>
                    <Typography>R 8-7-6-5-4-3-2-1</Typography>
                    <Typography>L 1-2-3-4-5-6-7-8</Typography>
                  </Box>
                </>
              </Box>

              <Box display="grid" gridTemplateColumns={'repeat(4, 1fr)'}>
                <Typography>M - Missing</Typography>
                <Typography>C - Caries</Typography>
                <Typography>J - Jacket</Typography>
                <Typography>D - Dentures</Typography>
                <Typography>X - Indicated for Extraction</Typography>
                <Typography>U - Unerupted</Typography>
                <Typography>R - Root Fragments</Typography>
              </Box>
            </Box>
          </Box>

          <Box
            display="grid"
            gridTemplateColumns={'1fr 0.5fr 1.5fr'}
            borderLeft={'1px solid #ccc'}
            padding="5px"
            pl="10px"
            justifyContent={'center'}
            alignItems={'center'}
            textAlign={'center'}
          >
            <Typography></Typography>
            <Typography fontWeight={600}>Normal</Typography>
            <Typography fontWeight={600}>Findings</Typography>

            {physical_exam_malvar.map((exam, index) => {
              return [
                <Typography key={index}>{exam}</Typography>,
                <Typography key={index}>
                  {getAPEAnswer(exam, 'Normal') === '-' || getAPEAnswer(exam, 'Normal') === 'Normal' ? 'X' : ''}
                </Typography>,
                <Typography key={index} sx={underlineStyle} alignSelf={'flex-end'}>
                  {getAPEAnswer(exam) !== '-' && getAPEAnswer(exam) !== 'Normal' ? getAPEAnswer(exam, 'Normal') : ''}
                </Typography>,
              ];
            })}
          </Box>
        </Box>
      </Box>

      {/* FOURTH ROW */}
      <Box display="grid" gridTemplateColumns={'1fr 1fr'} columnGap={'10px'}>
        <Box>
          <Box className="header" sx={{ backgroundColor: '#CEF0C9', padding: 0 }}>
            <Typography fontWeight={500}>III. XRAY AND LABORATORY REPORT</Typography>
          </Box>
          <Box className="body">
            <Box display="grid" gridTemplateColumns={'0.5fr 1.5fr'}>
              <Typography>A. CHEST X-RAY</Typography>

              <Box display="grid" gridTemplateColumns={'0.3fr 0.7fr 2.1fr'}>
                <Typography fontWeight={500}>{`(${
                  getAPEAnswer('SIGNIFICANT FINDINGS') === 'Normal' ? ' x ' : '   '
                })`}</Typography>

                <Typography>Normal</Typography>
                <Box> </Box>

                <Typography fontWeight={500}>{`(${
                  getAPEAnswer('SIGNIFICANT FINDINGS') !== 'Normal' ? ' x ' : '   '
                })`}</Typography>

                <Typography>With Findings</Typography>
                <Typography sx={underlineStyle}>
                  {getAPEAnswer('SIGNIFICANT FINDINGS') !== 'Normal' ? getAPEAnswer('SIGNIFICANT FINDINGS') : ''}
                </Typography>
              </Box>
            </Box>

            <Box display="grid" gridTemplateColumns={'0.5fr 1.5fr'}>
              <Typography>B. CBC</Typography>

              <Box display="grid" gridTemplateColumns={'0.3fr 0.7fr 2.1fr'}>
                <Typography fontWeight={500}>{`(${determineCBCAnswer('Normal')})`}</Typography>

                <Typography>Normal</Typography>
                <Box> </Box>

                <Typography fontWeight={500}>{`(${determineCBCAnswer('With Findings')})`}</Typography>

                <Typography>With Findings</Typography>
                <Typography sx={underlineStyle}>{determineCBCAnswer('Result')}</Typography>
              </Box>
            </Box>

            <Box display="grid" gridTemplateColumns={'0.5fr 1.5fr'}>
              <Typography>C. Urinalysis</Typography>

              <Box display="grid" gridTemplateColumns={'0.3fr 0.7fr 2.1fr'}>
                <Typography fontWeight={500}>{`(${
                  getAPEAnswer('Remarks (Significant Findings based on Result)') === 'N/A' ? ' x ' : '   '
                })`}</Typography>

                <Typography>Normal</Typography>
                <Box> </Box>

                <Typography fontWeight={500}>{`(${
                  getAPEAnswer('Remarks (Significant Findings based on Result)') !== 'N/A' ? ' x ' : '   '
                })`}</Typography>

                <Typography>With Findings</Typography>
                <Typography sx={underlineStyle}>
                  {getAPEAnswer('Remarks (Significant Findings based on Result)') !== 'N/A'
                    ? getAPEAnswer('Remarks (Significant Findings based on Result)')
                    : ''}
                </Typography>
              </Box>
            </Box>

            <Box display="grid" gridTemplateColumns={'0.5fr 1.5fr'}>
              <Typography>D. Stool Exam</Typography>
              <Box display="grid" gridTemplateColumns={'0.3fr 0.7fr 2.1fr'}>
                <Typography fontWeight={500}>{`(${
                  getAPEAnswer('Summary Remarks') === '-' || getAPEAnswer('Summary Remarks') === 'Normal'
                    ? ' x '
                    : '   '
                })`}</Typography>

                <Typography>Normal</Typography>
                <Box> </Box>

                <Typography fontWeight={500}>{`(${
                  getAPEAnswer('Summary Remarks') !== '-' && getAPEAnswer('Summary Remarks') !== 'Normal'
                    ? ' x '
                    : '   '
                })`}</Typography>

                <Typography>With Findings</Typography>
                <Typography sx={underlineStyle}>
                  {getAPEAnswer('Summary Remarks') !== 'Normal' ? getAPEAnswer('Summary Remarks') : ''}
                </Typography>
              </Box>
            </Box>

            <Box display="grid" gridTemplateColumns={'1fr 0.5fr 1fr 0.5fr 1fr'}>
              <Typography>E. HBsAg Screening</Typography>
              <Typography fontWeight={500}>{`(${
                getAPEAnswer('Hepatitis B Result') === 'Non-Reactive' ? ' x ' : '   '
              })`}</Typography>

              <Typography>Non Reactive</Typography>

              <Typography fontWeight={500}>{`(${
                getAPEAnswer('Hepatitis B Result') === 'Reactive' ? ' x ' : '   '
              })`}</Typography>

              <Typography>Reactive</Typography>
            </Box>

            <Box display="grid" gridTemplateColumns={'1fr 0.5fr 1fr 0.5fr 1fr'}>
              <Typography>F. DRUG TEST</Typography>

              <Typography fontWeight={500}>{`(${
                getAPEAnswer('Drug Test') === 'Negative' ? ' x ' : '   '
              })`}</Typography>

              <Typography>Negative</Typography>

              <Typography fontWeight={500}>{`(${
                getAPEAnswer('Drug Test') === 'For Confirmatory' || getAPEAnswer('Drug Test') === 'Positive'
                  ? ' x '
                  : '   '
              })`}</Typography>

              <Typography>For Confirmatory</Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box className="header" sx={{ backgroundColor: '#CEF0C9' }}>
            <Typography fontWeight={500}>IV. CLASSIFICATION</Typography>
          </Box>

          <Box display="grid" gridTemplateColumns={'0.2fr 2.8fr'} rowGap="10px" className="body">
            <Typography sx={{ textAlign: 'center', fontSize: '0.625rem !important' }}>{`(${getClassificationAnswer(
              'A'
            )})`}</Typography>
            <Box>
              <Typography sx={{ fontSize: '0.625rem !important' }}>
                <strong>CLASS A:</strong> Physically fit for all types of work. No physical defect noted
              </Typography>
            </Box>

            <Typography sx={{ textAlign: 'center', fontSize: '0.625rem !important' }}>{`(${getClassificationAnswer(
              'B'
            )})`}</Typography>
            <Box>
              <Typography sx={{ fontSize: '0.625rem !important' }}>
                <strong>CLASS B:</strong> Physically fit for all types of work. Has minor ailment/defect. Easily curable
                or offers no handicap.
              </Typography>
            </Box>

            <Typography sx={{ textAlign: 'center', fontSize: '0.625rem !important' }}>{`(${getClassificationAnswer(
              'C'
            )})`}</Typography>
            <Box>
              <Typography sx={{ fontSize: '0.625rem !important' }}>
                <strong>CLASS C:</strong> Employment at risk and discretion of management consider.
              </Typography>
            </Box>

            <Typography sx={{ textAlign: 'center', fontSize: '0.625rem !important' }}>{`(${getClassificationAnswer(
              'PENDING'
            )})`}</Typography>
            <Box>
              <Typography sx={{ fontSize: '0.625rem !important' }}>
                <strong>PENDING:</strong> for futher evaluation of
              </Typography>
            </Box>

            <Box sx={{ mt: '10px' }}></Box>
            <Typography sx={{ ...underlineStyle, mt: '10px' }}></Typography>
          </Box>
        </Box>
      </Box>

      <Box display="grid" gridTemplateColumns={'1fr 1fr'} columnGap={'10px'}>
        <Box display="flex" justifyContent={'flex-end'} alignItems="end">
          <SignatureSelector
            type={SIGNATURE_TYPES.PHYSICIAN}
            isPrinting={printMode}
            defaultStaff={{
              name: 'Francis Terukazu Sunagawa, MD',
              title: SIGNATURE_TYPES.PHYSICIAN,
              licenseNumber: 'LN 96317',
              signature: '/elsewhere_signatory/Francis Sunagawa.png',
            }}
          />
        </Box>
        <Box>
          <Box className="header" sx={{ backgroundColor: '#CEF0C9', padding: 0 }}>
            <Typography fontWeight={500}>V. REMARKS & Recommendation</Typography>
          </Box>

          <Box display="grid" gridTemplateColumns={'1fr'} rowGap={'5px'} className="body">
            {(() => {
              const af = assessment.automated_findings?.split('\n') ?? [];
              const mf = assessment.findings?.split('\n') ?? [];
              const f_combined = [...af, ...mf];

              const ar = assessment.automated_recommendations?.split('\n') ?? [];
              const mr = assessment.recommendations?.split('\n') ?? [];
              const r_combined = [...ar, ...mr];

              if (f_combined.length === 0 && r_combined.length === 0) {
                // Fallback when there is no findings or recommendations
                return <Typography sx={{ whiteSpace: 'pre-wrap', ...underlineStyle }}> - </Typography>;
              }

              return f_combined.map((findings, index) => (
                <Typography sx={underlineStyle} key={index}>
                  {findings || r_combined[index]
                    ? `${findings || ''}${r_combined[index] ? ' - ' + r_combined[index] : ''}`
                    : '\u00a0'}
                </Typography>
              ));
            })()}
            {/* {Array.from({ length: 5 }, (_, i) => (
              <Typography sx={underlineStyle} mt={i === 0 ? '25px' : undefined}></Typography>
            ))} */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
