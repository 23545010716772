import { Box, Tooltip, Typography, useTheme } from '@mui/material';
import {
  ColoredTable,
  CompanyProtectedComponent,
  ConfirmationDialog,
  CustomForm,
  CustomGridCell,
  CustomModal,
  RegularButton,
} from 'core/components';
import { ContainerColumn, ContainerRow } from 'core/components/containers';
import { MouseEvent, useContext, useEffect, useRef, useState } from 'react';
import { changeNullToBlank, formatDateTime, formatDateTimeWithDay } from 'core/utils';
import {
  deleteCampaign,
  duplicateCampaign,
  getCampaign,
  getCampaigns,
  getPatientsForCampaign,
} from 'company/api/campaigns';
import { getOutreach, updateOutreachStatus } from 'company/api/outreaches';

import AddIcon from '@mui/icons-material/Add';
import { CompanyCampaignModel } from 'company/model/Entities';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { DropdownOptionProps } from 'core/components/Dropdown';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { FacilityContext } from 'core/context/facility.context';
import { FieldInput } from 'core/model/interface';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import InfiniteScrollContainer from 'core/components/dataView/InfiniteScrollContainer';
import { Link } from 'react-router-dom';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import OutreachModal from './components/OutreachModal';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PublishOutlinedIcon from '@mui/icons-material/PublishOutlined';
import { SIZES } from 'theme/constants';
import { UserContext } from 'core/context/user.context';
import { duplicate_outreach_schema } from 'company/model/schema';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

type UserInput = {
  name: string;
  description: string;
  event_start: string;
  event_end: string;
  id?: number;
  copy_clients: boolean;
  copy_sms_schedule: boolean;
};

interface UserFieldInput extends FieldInput {
  field_name?: keyof UserInput;
}

const _initialValues: UserInput = {
  name: '',
  description: '',
  event_start: '',
  event_end: '',
  copy_clients: false,
  copy_sms_schedule: false,
};

const Campaigns = () => {
  const { facility } = useContext(FacilityContext);
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [openDuplicateModal, setOpenDuplicateModal] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<UserInput>(_initialValues);
  const [loading, setLoading] = useState<any>();
  const [openPublishDialog, setOpenPublishDialog] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openOutreachModal, setOpenOutreachModal] = useState<boolean>(false);
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [selectedCampaign, setSelectedCampaign] = useState<number>(0);

  const [campaignDetails, setCampaignDetails] = useState<CompanyCampaignModel>();
  const [outreachDetails, setOutreachDetails] = useState<any>();
  const [addedCampaign, setAddedCampaign] = useState<number>();
  const [updatedCampaign, setUpdatedCampaign] = useState<CompanyCampaignModel>();
  const [deletedCampaign, setDeletedCampaign] = useState<number>();

  const [initialSelectedStage, setInitialSelectedStage] = useState<number>(0);

  const ref: any = useRef();

  const refreshColoredTable = () => {
    ref.current.refreshTable();
    if (outreachDetails)
      getOutreach(facility.id, outreachDetails.id).then((res) => {
        setOutreachDetails(res.data);
      });
  };

  // Form Fields
  const fields: UserFieldInput[] = [
    { field_name: 'name', display_name: 'Event Name', type: 'text', span: 4 },
    { field_name: 'event_start', display_name: 'Event Start', type: 'datetime', minutesStep: 5 },
    { field_name: 'event_end', display_name: 'Event End', type: 'datetime', minutesStep: 5 },
    { field_name: 'description', display_name: 'Description', type: 'text', multiline: true, span: 4, rows: 3 },
    {
      field_name: 'copy_clients',
      type: 'checkbox',
      display_name: 'Copy Enrolled Patients',
    },
    {
      field_name: 'copy_sms_schedule',
      type: 'checkbox',
      display_name: 'Copy SMS Schedules',
    },
  ];

  const handleDelete = async () => {
    if (selectedCampaign) {
      try {
        await deleteCampaign(facility.id, selectedCampaign);
        setDeletedCampaign(selectedCampaign);
        enqueueSnackbar('Outreach successfully deleted!', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar('Error occured! Unable to delete outreach.', { variant: 'error' });
      }
    }
  };

  const handleDuplicate = async (data: any) => {
    const to_update = changeNullToBlank(data);
    setInitialValues({
      ...to_update,
      name: data.name + ' - Copy',
      copy_clients: false,
      copy_sms_schedule: false,
      enrollment_type: 'manual',
    });
    setOpenDuplicateModal(true);
  };

  const confirmDuplicate = async (data: any) => {
    if (!campaignDetails) return;
    try {
      setLoading(true);

      const res = await duplicateCampaign(facility.id, campaignDetails.id, data);
      setAddedCampaign(res.data.campaign.id);
      enqueueSnackbar('Outreach successfully duplicated!', { variant: 'success' });
      setOpenDuplicateModal(false);
      ((fromEdit?: boolean) => {
        getOutreachDetails(fromEdit);
      })();
    } finally {
      setLoading(false);
      ((fromEdit?: boolean) => {
        getOutreachDetails(fromEdit);
      })();
    }
  };

  const handlePublish = (data: any) => {
    if (!campaignDetails) return;
    return updateOutreachStatus(facility.id, campaignDetails.outreach_id, { status: 'published' }).then((res) => {
      enqueueSnackbar(`Outreach successfully published!`, { variant: 'success' });
      ((fromEdit?: boolean) => {
        getOutreachDetails(fromEdit);
      })(true);
    });
  };

  const handleUpdate = (initialStage: number) => {
    setOpenOutreachModal(true);
    setIsAdd(false);
    setInitialSelectedStage(initialStage);
  };

  //actions
  const actions: DropdownOptionProps[] = [
    {
      label: 'Publish',
      action: () => setOpenPublishDialog(true),
      icon: <PublishOutlinedIcon />,
      hidden: campaignDetails && campaignDetails.outreach_status === 'published' ? true : false,
      requiredAuth: campaignDetails && campaignDetails.outreach_status === 'published' ? [''] : ['PUBLISH_CAMPAIGNS'],
    },
    {
      label: 'Update Outreach',
      action: () => handleUpdate(0),
      icon: <EditOutlinedIcon />,
      hidden: campaignDetails && campaignDetails.outreach_status === 'published' ? true : false,
    },
    {
      label: 'Duplicate',
      action: handleDuplicate,
      icon: <FileCopyOutlinedIcon />,
    },
    { label: 'divider' },
    {
      label: 'Delete',
      action: () => setOpenDeleteDialog(true),
      icon: <DeleteOutlinedIcon style={{ color: 'red' }} />,
      color: 'error',
    },
  ];

  const getOutreachDetails = (fromEdit?: boolean) => {
    if (facility.id && selectedCampaign) {
      getCampaign(facility.id, selectedCampaign)
        .then((res) => {
          setCampaignDetails(res.data);
          if (fromEdit) {
            setUpdatedCampaign(res.data);
          }
          getOutreach(facility.id, res.data.outreach_id)
            .then((res) => {
              setOutreachDetails(res.data);
            })
            .catch((error) => {
              console.error('Error fetching outreach details:', error);
            });
        })
        .catch((error) => {
          // Handle errors if necessary
          console.error('Error fetching outreach details:', error);
        });
    }
  };

  useEffect(() => {
    if (selectedCampaign) getOutreachDetails();
    ref.current?.refreshTable();
    setIsAdd(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCampaign]);

  useEffect(() => {
    if (user) {
      if (!user.company?.with_campaigns) {
        // navigate('/forbidden');
      }
    }
  }, [user]);

  const displayItem = (item: any) => {
    const isSelected = selectedCampaign === item.id;
    return (
      <ContainerRow>
        <Typography variant="h5" color={isSelected ? 'white' : colors.text} fontWeight="600">
          {item.name}
        </Typography>
        <>
          {item.outreach_status === 'published' && (
            <LockOutlinedIcon sx={{ color: isSelected ? 'white' : colors.text, fontSize: '17px' }} />
          )}
        </>
      </ContainerRow>
    );
  };

  // Define columns for the table
  const patientList = [
    {
      field: 'full_name',
      headerName: 'Name',
      renderCell: (params: any) => (
        <CustomGridCell>
          <Link to={`/company/patient-management?patient_id=${params.row.patient_id}`} className="emphasis">
            {params.value}
          </Link>
        </CustomGridCell>
      ),
    },
    {
      field: 'assigned_groups',
      headerName: 'Group/Company',
      renderCell: (params: any) => (
        <CustomGridCell>
          {`${params.row.assigned_groups || ''}`}
          {`${params.row.assigned_groups !== null && params.row.company_name !== null ? ', ' : ''}`}
          {`${params.row.company_name || ''}`}
        </CustomGridCell>
      ),
    },
  ];

  return (
    <>
      <OutreachModal
        outreach={isAdd ? undefined : campaignDetails}
        open={openOutreachModal}
        setOpen={setOpenOutreachModal}
        refreshMainTable={(fromEdit) =>
          ((fromEdit?: boolean) => {
            getOutreachDetails(fromEdit);
          })(fromEdit)
        }
        setAddedCampaign={setAddedCampaign}
        onUpdatePatientList={refreshColoredTable}
        initialSelectedStage={isAdd ? 0 : initialSelectedStage}
      />

      <ConfirmationDialog
        title={'Confirmation'}
        open={openPublishDialog}
        setOpen={setOpenPublishDialog}
        content={'You are about to publish the outreach. Are you sure you want to proceed?'}
        onConfirm={handlePublish}
      />

      <ConfirmationDialog
        title={'Confirmation'}
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        content={'Are you sure you want to delete this campaign?'}
        onConfirm={handleDelete}
      />

      <CustomModal
        open={openDuplicateModal}
        setOpen={setOpenDuplicateModal}
        width={700}
        header={'Duplicate ' + campaignDetails?.name}
      >
        <CustomForm
          fields={fields}
          initialValues={initialValues}
          loading={loading}
          onSubmit={confirmDuplicate}
          schema={duplicate_outreach_schema}
        />
      </CustomModal>

      <InfiniteScrollContainer
        urlKeyId="outreach"
        title="Campaigns List"
        getSingleData={(id) => getCampaign(facility.id, id)}
        getData={(query) => getCampaigns(facility.id, query)}
        selected={selectedCampaign}
        setSelected={setSelectedCampaign}
        renderItem={displayItem}
        addedNewItem={addedCampaign}
        updatedItem={updatedCampaign}
        deleteId={deletedCampaign}
        containerHeight="calc(100vh - 170px)"
        headerComponents={
          <CompanyProtectedComponent requiredAuth={['MANAGE_PATIENTS']}>
            <Box width="100%">
              <RegularButton
                variant="outlined"
                startIcon={<AddIcon />}
                label="Add"
                onClick={() => {
                  setOpenOutreachModal(true);
                  setIsAdd(true);
                }}
                styles={{ width: '100%' }}
                disabled={user.remaining_storage <= 0}
              />
            </Box>
          </CompanyProtectedComponent>
        }
        displaySelectedTitle={() => (
          <ContainerRow sx={{ justifyContent: 'flex-start' }}>
            <Typography variant="h3" fontWeight={700} color={colors.primary}>
              {campaignDetails?.name}
            </Typography>
            {campaignDetails?.outreach_status === 'published' && (
              <Tooltip title="Published" arrow placement="right">
                <LockOutlinedIcon sx={{ color: colors.primary, fontSize: '24px', fontWeight: 'bold' }} />
              </Tooltip>
            )}
          </ContainerRow>
        )}
        actions={actions}
      >
        <ContainerColumn gap={SIZES.paddingL}>
          {campaignDetails && (
            <Box>
              <Typography variant="h5" fontWeight={'bold'} mb={SIZES.paddingS}>
                Campaign Details
              </Typography>
              <Box display={'flex'} gap="50px">
                <Box display="flex" gap="10px">
                  <Typography color={colors.accent}>Event Start</Typography>
                  <Typography>{formatDateTime(campaignDetails?.event_start, '-')}</Typography>
                </Box>
                <Box display="flex" gap="10px">
                  <Typography color={colors.accent}>Event End</Typography>
                  <Typography>{formatDateTime(campaignDetails.event_end, '-')}</Typography>
                </Box>
              </Box>
            </Box>
          )}
          <Box>
            <Typography variant="h5" fontWeight={'bold'} mb={SIZES.paddingS}>
              SMS Template
            </Typography>
            <Typography>{campaignDetails?.sms_template}</Typography>
          </Box>

          <Box>
            <Typography variant="h5" fontWeight={'bold'} mb={SIZES.paddingS}>
              Outreach Summary
            </Typography>
            <Box
              width="100%"
              display="grid"
              gridTemplateColumns="1fr 1fr"
              columnGap={SIZES.padding}
              rowGap={SIZES.paddingS}
            >
              <ContainerRow gap={SIZES.paddingS} sx={{ justifyContent: 'flex-start' }}>
                <PeopleAltOutlinedIcon sx={{ color: colors.accent }} />
                <Typography color={colors.accent}>Total Patients Selected</Typography>
                <Typography>{outreachDetails?.total_patients ?? '0'}</Typography>
              </ContainerRow>

              <ContainerRow gap={SIZES.paddingS} sx={{ justifyContent: 'flex-start' }}>
                <PendingActionsOutlinedIcon sx={{ color: colors.accent }} />
                <Typography color={colors.accent}>SMS Delivery Schedule</Typography>
                <Box display="grid" gridTemplateColumns="1fr">
                  {!outreachDetails || outreachDetails.schedules === 0 ? (
                    <Typography>No schedules found.</Typography>
                  ) : (
                    JSON.parse(outreachDetails.schedules)?.map((smsSchedule: any, index: number) => (
                      <Typography key={index}>{formatDateTimeWithDay(smsSchedule)}</Typography>
                    ))
                  )}
                </Box>
              </ContainerRow>

              <ContainerRow gap={SIZES.paddingS} sx={{ justifyContent: 'flex-start' }}>
                <GroupsOutlinedIcon sx={{ color: colors.accent }} />
                <Typography color={colors.accent}>Groups Selected</Typography>
                <Typography>{outreachDetails?.assigned_groups ?? '-'}</Typography>
              </ContainerRow>

              <ContainerRow gap={SIZES.paddingS} sx={{ justifyContent: 'flex-start' }}>
                <EmailOutlinedIcon sx={{ color: colors.accent }} />
                <Typography color={colors.accent}>SMS to be sent</Typography>
                {outreachDetails && (
                  <Typography>
                    {Math.ceil((outreachDetails.sms_template.length ?? 0) / 160) *
                      outreachDetails.total_patients *
                      outreachDetails.total_schedules}
                  </Typography>
                )}
              </ContainerRow>
            </Box>
          </Box>

          <Box>
            <Typography variant="h5" fontWeight={'bold'} mb={SIZES.paddingS}>
              Patient List
            </Typography>
            {selectedCampaign > 0 && (
              <ColoredTable
                rowId="patient_id"
                ref={ref}
                columns={patientList}
                entityId={selectedCampaign}
                getData={(query) => getPatientsForCampaign(facility.id, selectedCampaign, query)}
                headerComponent={
                  <RegularButton
                    label="Update Patient List"
                    onClick={() => handleUpdate(1)}
                    styles={{ visibility: campaignDetails?.outreach_status !== 'published' ? 'visible' : 'hidden' }}
                  />
                }
              />
            )}
          </Box>
        </ContainerColumn>
      </InfiniteScrollContainer>
    </>
  );
};

export default Campaigns;
