import { Box, Grid, Typography, useTheme } from '@mui/material';
import CustomFilter, { CheckboxOptionProps } from 'core/components/CustomFilter';
import { ReactNode, forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { formatCurrency, formatDate, formatNumber, formatVariantName } from 'core/utils';

import { AttributeValueModel } from 'company/entities/modules/InventorySystem/AttributeValue/AttributeValueModel';
import Chip from '@mui/material/Chip';
import { ContainerRow } from 'core/components/containers';
import { Dropdown } from 'core/components';
import { DropdownOptionProps } from 'core/components/Dropdown';
import { FacilityContext } from 'core/context/facility.context';
import { Link } from 'react-router-dom';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Product } from '../ClinicInventory';
import { SIZES } from 'theme/constants';
import SearchBar from '../../POS/components/SearchBar';
import { getInventories } from 'company/api/inventories';
import { getVariantAttributeValues } from 'company/api/variant-attributes';
import { tokens } from 'theme/theme';

interface Props {
  extraButtons?: ReactNode | ReactNode[];
  actions: DropdownOptionProps[];
  refreshInventory?: number;
  medicineAction?: boolean;
}

const ClinicInventoryScroller = forwardRef((props: Props, ref) => {
  const { extraButtons, actions, refreshInventory, medicineAction } = props;

  useImperativeHandle(ref, () => ({
    getAllProducts,
    getFilters: () => ({
      search: productSearchQuery,
      status: productFilters.length === 0 || productFilters.length === 3 ? undefined : productFilters,
      attribute_values: attributeFilters,
    }),
  }));

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { facility } = useContext(FacilityContext);
  const parentRef = useRef();

  const [productFilters, setProductFilters] = useState<string[]>([]);
  const [attributeFilters, setAttributeFilters] = useState<string[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [products, setProducts] = useState<Product[]>([]);
  const [productSearchQuery, setProductSearchQuery] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const itemsPerPage = 10;

  const [attributes, setAttributes] = useState<AttributeValueModel[]>([]);

  const filteredActions = (product: any) => {
    const filteredActions = actions.filter((action) =>
      ['Update Price', 'Update Details'].includes(action.label as string)
    );
    return filteredActions;
  };

  const handleScroll = () => {
    const parentElement = parentRef.current as any;
    if (parentElement && parentElement.scrollHeight - parentElement.scrollTop - parentElement.clientHeight < 1) {
      setTimeout(() => {
        if (hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      }, 1000);
    }
  };

  const getAllProducts = async () => {
    if (hasMore && facility) {
      setIsLoading(true);
      try {
        const response = await getInventories(facility.id, {
          page: page,
          length: itemsPerPage,
          search: productSearchQuery,
          order_by: 'updated_at',
          order: 'desc',
          status: productFilters.length === 0 || productFilters.length === 3 ? undefined : productFilters,
          attribute_values: attributeFilters,
        });
        const newProducts: Product[] = response.data.data;

        if (page === 1) {
          setProducts(newProducts);
        } else {
          const existing = products.map((product) => product.variant_id);
          const toBeAdded = newProducts.filter((product) => !existing.includes(product.variant_id));
          setProducts((prevProducts) => [...prevProducts, ...toBeAdded]);
        }

        setHasMore(newProducts.length === itemsPerPage);
      } finally {
        setIsLoading(false);
        const parentElement = parentRef.current as any;
        if (parentElement && parentElement.scrollHeight === parentElement.clientHeight && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      }
    }
  };

  const refreshList = () => {
    setProducts([]);
    if (page !== 1) {
      setHasMore(true);
      setPage(1);
    } else {
      getAllProducts();
    }
  };

  const handleSearch = (searchKey: string) => {
    setProductSearchQuery(searchKey);
  };

  useEffect(() => {
    getVariantAttributeValues().then((res) => {
      setAttributes(res.data.data);
    });
  }, []);

  useEffect(() => {
    getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMore, page, facility?.id]);

  useEffect(() => {
    refreshList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productFilters, attributeFilters, productSearchQuery, refreshInventory]);

  const optionList: CheckboxOptionProps[] = useMemo(() => {
    let currentAttributeId = 0;

    return attributes.flatMap((attr) => {
      const value: CheckboxOptionProps[] = [];
      if (currentAttributeId !== attr.attribute_id) {
        currentAttributeId = attr.attribute_id;
        value.push({ label: attr.attribute_name, id: attr.attribute_name, isHeader: true });
      }
      value.push({ label: attr.value, id: attr.id + '' });
      return value;
    });
  }, [attributes]);

  return (
    <Box>
      <ContainerRow sx={{ paddingBlock: SIZES.padding, alignItems: 'center' }}>
        <Typography variant="h5" fontWeight={'bold'} width="200px">
          Variants List
        </Typography>
        <ContainerRow gap="5px" sx={{ justifyContent: 'flex-end' }}>
          <SearchBar handleSearch={(searchKey) => handleSearch(searchKey)} />
          {/* <SearchBar handleSearch={(searchKey) => setProductSearchQuery(searchKey)} /> */}
          <CustomFilter
            optionList={[
              { label: 'Available', id: 'available' },
              { label: 'Low Stock', id: 'lowStock' },
              { label: 'Unavailable', id: 'unavailable' },
            ]}
            buttonLabel="Status"
            selected={productFilters}
            setSelected={setProductFilters}
            selectAllOption
          />
          <CustomFilter
            buttonLabel={'Attributes'}
            optionList={optionList}
            selected={attributeFilters}
            setSelected={setAttributeFilters}
            span={2}
            noOptionContent={
              <Box p="0.825rem" display="flex" gap="5px">
                <Typography>No attribute filters available.</Typography>
                <Link to="/company/inventory/settings?inventory_settings=0&tab=1" style={{ textDecoration: 'none' }}>
                  <Typography sx={{ color: colors.primary, ':hover': { textDecoration: 'underline' } }}>
                    Add Attributes
                  </Typography>
                </Link>
              </Box>
            }
          />

          {extraButtons}
        </ContainerRow>
      </ContainerRow>
      <Box
        ref={parentRef}
        height="calc(100vh - 230px)"
        sx={{ overflowY: 'auto' }}
        display="flex"
        flexDirection="column"
        gap={'20px'}
        padding={SIZES.paddingS}
        paddingRight={0}
        onScroll={handleScroll}
      >
        {products.map((product, index) => (
          <Grid
            key={product.variant_id}
            container
            spacing={1}
            sx={{
              backgroundColor: colors.background,
              borderRadius: '10px',
              padding: '20px 15px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              '&:hover': {
                backgroundColor: colors.hoverTableRow,
              },
            }}
          >
            <Grid item xs={12} sm={6} md={6} lg={2.75}>
              <Link
                to={`/company/inventory/products/variant?id=${product.id}`}
                style={{ textDecoration: 'none' }}
                state={{ from: '/company/inventory/pos' }}
              >
                <Typography
                  variant="h5"
                  color={colors.accent}
                  mb="5px"
                  fontWeight="bold"
                  sx={{ '&:hover': { textDecoration: 'underline' } }}
                >
                  {product.product_name} {formatVariantName(product, true)}
                </Typography>
              </Link>

              <Typography fontSize={'10px'} mb="3px" color={colors.grey_text}>
                Category: {product.category}
              </Typography>
              <Typography>{product.sku}</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={1.5}
              sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
            >
              <Typography fontSize={'10px'} color={colors.grey_text}>
                Price
              </Typography>
              <Typography color={colors.accent} fontWeight="bold">
                {formatCurrency(product.price)}
              </Typography>
            </Grid>
            {product.made_to_order ? (
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={1.5}
                sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
              >
                <Typography fontSize={'10px'} color={colors.grey_text}>
                  Availability
                </Typography>
                <Chip label="Made to Order" variant="outlined" color="primary" sx={{ fontSize: '10px' }} size="small" />
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={1}
                sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
              >
                {parseInt(product.quantity_in_stock) <= 0 ? (
                  <Typography color={'#e74c3c'} fontSize={'10px'}>
                    Unavailable
                  </Typography>
                ) : parseInt(product.quantity_in_stock) > 0 && parseInt(product.quantity_in_stock) < 10 ? (
                  <Typography color={'#fe9801'} fontSize={'10px'}>
                    Low Stock
                  </Typography>
                ) : (
                  <Typography color={'#4CAF50'} fontSize={'10px'}>
                    Available
                  </Typography>
                )}
                <Typography>{formatNumber(product.quantity_in_stock, 0)}</Typography>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={1.5}
              sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
            >
              <Typography fontSize={'10px'} color={colors.grey_text}>
                Date Added
              </Typography>
              <Typography>{formatDate(product.created_at)}</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={2}
              lg={2}
              sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
            >
              <Typography fontSize={'10px'} color={colors.grey_text}>
                Supplier
              </Typography>
              <Typography>{product.exclusive_supplier_name ? product.exclusive_supplier_name : 'N/A'} </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={2}
              lg={1}
              sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
            >
              <Dropdown
                optionList={
                  product.made_to_order && !medicineAction
                    ? actions.filter((action) => ['Update Price', 'Update Details'].includes(action.label as string))
                    : actions
                }
                isIcon
                icon={<MoreHorizIcon sx={{ fontSize: '24px', color: colors.accent }} />}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                data={product}
              />
            </Grid>
          </Grid>
        ))}

        {/* {isLoading && (
          <Box textAlign="center" padding="10px">
            <CircularProgress color="inherit" size="1.3rem" />
          </Box>
        )} */}

        {!hasMore && products.length > itemsPerPage && (
          <Typography textAlign="center" padding="10px">
            No more data
          </Typography>
        )}

        {products.length === 0 && (
          <Typography textAlign="center" padding="10px">
            No results found
          </Typography>
        )}
      </Box>
    </Box>
  );
});

export default ClinicInventoryScroller;
