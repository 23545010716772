import TemplateForm from 'company/entities/Template/TemplateForm';

import { FacilityContext } from 'core/context/facility.context';
import { useContext } from 'react';

import { createNewNoteSummaryTemplate, updateNoteSummaryTemplate } from 'company/api/patient-note-summary-template';
import { createProductUOM, updateProductUOM } from 'company/api/product-uom';
import {
  createUOMSchema,
  productUnitOfMeasureFields,
  productUnitOfMeasureInitialValues,
  ProductUnitOfMeasureModel,
  productUnitOfMeasureTableColumns,
  PUOM_ENTITY,
} from './ProductUOMModel';
import { createConversion, updateConversion } from 'company/api/product-uom-conversion';
import {
  productUnitOfMeasurementConversionInitialValues,
  unitOfMeasurementConversionFields,
  unitOfMeasurementConversionFormFields,
  UnitOfMeasurementConversionModel,
  unitOfMeasurementConversionVisibleFormFields,
  UOMC_ENTITY,
} from './UnitConversionModel';
type Props = {
  readonly?: boolean;
  showSubmitAtTop?: boolean;
  callbackAfterSubmit?: (data: any) => void;
  fromUOM: ProductUnitOfMeasureModel;
  selectedUOM: number;
  selectedConversion?: UnitOfMeasurementConversionModel;
};

const UnitConversionForm = (props: Props) => {
  const { readonly, showSubmitAtTop, callbackAfterSubmit, fromUOM, selectedUOM, selectedConversion } = props;
  const { facility } = useContext(FacilityContext);
  async function handleSubmit(data: any) {
    if (!!selectedConversion) return updateConversion(facility.id, selectedConversion.id, data);
    return createConversion(facility.id, data);
  }

  return (
    <TemplateForm
      //   schema={createUOMSchema}
      callbackAfterSubmit={callbackAfterSubmit}
      entityName={UOMC_ENTITY}
      fields={unitOfMeasurementConversionFormFields}
      initialValues={
        !selectedConversion
          ? { ...productUnitOfMeasurementConversionInitialValues, from_uom_id: selectedUOM }
          : selectedConversion
      }
      visibleFields={
        !selectedConversion
          ? unitOfMeasurementConversionVisibleFormFields
          : unitOfMeasurementConversionVisibleFormFields.filter((field) => field === 'conversion_factor')
      }
      formSubmitApiFunction={handleSubmit}
    />
  );
};

export default UnitConversionForm;
