import { Box, Pagination, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';

import CustomLoadingIndicator from 'core/components/CustomLoadingIndicator';
import { FieldInput } from 'core/model/interface';
import { PatientModel } from 'company/entities/modules/ClinicManagement/Patient/PatientModel';
import { PatientNoteModel } from 'company/model/Entities';
import PrintablePatientNote from './components/PrintablePatientNote';
import { formatDateTime } from 'core/utils';
import { getManyNoteHistory } from 'company/api/patient-notes-update-history';
import { tokens } from 'theme/theme';
import { useTheme } from '@mui/material';

interface DocumentHistoryProps {
  note: PatientNoteModel;
  fields: FieldInput[];
  patient: PatientModel;
}

const ITEMS_PER_PAGE = 8;

const DocumentHistory: React.FC<DocumentHistoryProps> = ({ note, fields, patient }) => {
  const [history, setHistory] = useState<
    Array<{
      id: number;
      editor_name: string;
      created_at: string;
      responses: string;
    }>
  >([]);
  const [loading, setLoading] = useState(true);
  const [selectedEntry, setSelectedEntry] = useState<number | null>(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    if (note) {
      setLoading(true);
      getManyNoteHistory(note.id)
        .then((response) => {
          setHistory(response.data.data);
          setTotalPages(Math.ceil(response.data.data.length / ITEMS_PER_PAGE));
          if (response.data?.data?.length) setSelectedEntry(response.data.data[0].id);
        })
        .catch((error) => {
          console.error('Error fetching history:', error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [note]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    setSelectedEntry(null);
  };

  const getCurrentPageItems = () => {
    const startIndex = (page - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return history.slice(startIndex, endIndex);
  };

  const selectedHistory = useMemo(() => {
    const selected = history.find((entry) => entry.id === selectedEntry)?.responses;
    if (selected) {
      const parsed = JSON.parse(JSON.parse(selected));
      return parsed;
    }
    return {};
  }, [history, selectedEntry]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [selectedHistory]);

  return (
    <Box sx={{ backgroundColor: colors.light_blue_background, p: 3, borderRadius: '12px' }}>
      <Box display="grid" gridTemplateColumns="200px 1fr">
        {/* Left panel - History entries */}
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ flexGrow: 1, overflowY: 'auto', mb: 2 }}>
            {getCurrentPageItems().map((entry) => (
              <Box
                key={entry.id}
                sx={{
                  cursor: 'pointer',
                  backgroundColor: 'transparent',
                  borderRadius: '8px',
                  p: 2,
                  '&:hover': {
                    backgroundColor: colors.light_blue_background_2,
                  },
                }}
                onClick={() => setSelectedEntry(entry.id)}
              >
                <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
                  <Box>
                    <Typography
                      variant="subtitle1"
                      color={selectedEntry === entry.id ? colors.primary : 'textSecondary'}
                      fontWeight={700}
                      fontSize={'14px'}
                    >
                      {formatDateTime(entry.created_at)}
                    </Typography>
                    <Typography variant="caption" color={selectedEntry === entry.id ? colors.primary : 'inherit'}>
                      {entry.editor_name}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>

          {history.length > ITEMS_PER_PAGE && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                pt: 2,
                borderTop: `1px solid ${colors.background}`,
              }}
            >
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
                size="small"
                showFirstButton
                showLastButton
              />
            </Box>
          )}
        </Box>

        {/* Right panel - Selected entry content */}
        <Box
          sx={{
            pl: 2,
            borderLeft: `1px solid ${colors.background}`,
            overflowY: 'auto',
            height: '100%',
            width: '920px',
          }}
        >
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="200px">
              <CustomLoadingIndicator />
            </Box>
          ) : !history.length ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100px">
              <Typography variant="body1" color="textSecondary">
                No history available
              </Typography>
            </Box>
          ) : selectedEntry ? (
            <Box sx={{ p: 2, backgroundColor: 'white', borderRadius: '8px' }}>
              <PrintablePatientNote fields={fields} patient={patient} responses={selectedHistory} note={note} />
            </Box>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ backgroundColor: 'white', borderRadius: '8px', height: '100%' }}
            >
              <Typography variant="body1" color="textSecondary">
                Select an entry to view details
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default DocumentHistory;
