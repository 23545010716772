import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'product-unit-conversions';

export const getUnitConversions = (facility_id: number, uom_id:number, query: ApiQuery) => {
    return axios.get(`${baseURL}/${path}/${facility_id}/${uom_id}`, {params: query});
};

export const createConversion = (facility_id:number, data:any) => {
    return axios.post(`${baseURL}/${path}/${facility_id}`, data);
}

export const deleteConversion = (facility_id:number, id:number) => {
    return axios.delete(`${baseURL}/${path}/${facility_id}/${id}`);
}

export const updateConversion = (facility_id:number, id:number, data: any) => {
    return axios.patch(`${baseURL}/${path}/${facility_id}/${id}`, data);
}