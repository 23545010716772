import * as yup from 'yup';

import { DROPDOWN_FIELD, FieldInput } from 'core/model/interface';

import { EntityFields } from '../../../utils';

export const SERVICE_ENTITY_NAME = 'Service';

export const update_service_schema = yup.object().shape({
  service_name: yup.string().required('Name is required.'),
  sms_name: yup.string().required('SMS Name is required.'),
  duration: yup.number().required('Duration is required').typeError('Invalid Value'),
  slots: yup.number().required('Slots is required').typeError('Invalid Value'),
  buffer_time_value: yup.number().typeError('Invalid Value'),
  lead_time_value: yup.number().typeError('Invalid Value'),
  provider_id: yup.string(),
  facilities: yup.array().when('apply_to_all_clinics', {
    is: (value: boolean) => !value,
    then: yup.array().min(1, 'At least one clinic must be selected'),
    otherwise: yup.array().nullable(),
  }),
});

export const create_service_schema = update_service_schema.shape({
  price: yup.number().required('Price is required').typeError('Invalid Value'),
});

export interface ServiceModel {
  id: number;
  company_id: number;
  provider_id: number;
  service_name: string;
  sms_name: string;
  price: number;
  tax: string;
  clinic_price: number;
  description: string;
  banner_image: string;
  duration: string;
  slots: number;
  buffer_time_value: string;
  lead_time_value: string;
  is_active: boolean;
  full_name: string;
  service_id: number;
  status: string;
}
export interface ServiceInput {
  service_name: string;
  sms_name: string;
  price: string;
  tax: string;
  provider_id: string;
  banner_image: string;
  description: string;
  duration: number;
  slots: number;
  buffer_time_value: number;
  lead_time_value: number;
  facilities: any[];
  service_category_id: any[];
  apply_to_all_clinics: boolean;
  is_lab_test: boolean;
}
export interface ServiceFieldInput extends FieldInput {
  field_name?: keyof ServiceInput;
}

export const serviceInitialValues: ServiceInput = {
  service_name: '',
  sms_name: '',
  price: '',
  tax: 'non-vat',
  banner_image: process.env.PUBLIC_URL + '/assets/blank_banner.png',
  description: '',
  provider_id: '',
  facilities: [],
  service_category_id: [],
  duration: 0,
  slots: 0,
  buffer_time_value: 0,
  lead_time_value: 0,
  apply_to_all_clinics: true,
  is_lab_test: false,
};

// fieldName should be unique
export const serviceFields: EntityFields[] = [
  {
    fieldName: 'service_name',
    displayName: 'Service',
    flex: 3,
    onChange: (value, setFieldValue) => {
      setFieldValue && setFieldValue('sms_name', value);
    },
  },
  { fieldName: 'sms_name', displayName: 'SMS Name' },
  { fieldName: 'price', displayName: 'Price', type: 'currency' },
  {
    fieldName: 'tax',
    displayName: 'Tax',
    type: 'radiogroup',
    options: [
      { key: 'VAT', value: 'vat' },
      { key: 'Non-VAT', value: 'non-vat' },
    ],
  },
  {
    fieldName: 'service_category_id',
    displayName: 'Service Category',
    type: DROPDOWN_FIELD.SERVICE_CATEGORY,
    optional: true,
    multiple: true,
  },
  {
    fieldName: 'is_lab_test',
    displayName: 'Display in Lab Request Form',
    type: 'checkbox',
    span: 4,
  }, //
  { fieldName: 'clinic_price', displayName: 'Clinic Price', type: 'currency', optional: true },
  { fieldName: 'provider_id', displayName: 'Service Provider', type: DROPDOWN_FIELD.SERVICE_PROVIDER, optional: true },
  { fieldName: 'apply_to_all_clinics', displayName: 'Apply to All Clinics', type: 'checkbox' },
  {
    fieldName: 'facilities',
    displayName: 'Clinic',
    type: 'multiselect',
    options: [],
    span: 4,
    hiddenBasedOnOtherField: (data: any) => {
      return data.apply_to_all_clinics;
    },
  },
  { fieldName: 'full_name', displayName: 'Service Provider', optional: true },
  { fieldName: 'description', displayName: 'Description', multiline: true, rows: 3, span: 4, optional: true },
  {
    fieldName: 'banner_image',
    displayName: 'Banner',
    type: 'image_upload',
    isBanner: true,
    optional: true,
    hidden: true,
  },
  { fieldName: 'duration', displayName: 'Duration (minutes)' },
  { fieldName: 'slots', displayName: 'Slots' },
  { fieldName: 'buffer_time_value', displayName: 'Buffer Time (minutes)' },
  { fieldName: 'lead_time_value', displayName: 'Lead Time (minutes)' },
  // Components / Divider
  { fieldName: 'divider_1', type: 'divider' },
  {
    fieldName: 'component_booking_form',
    type: 'section_header',
    section_header: 'Booking Form',
  },
];

// Table
export const serviceTableColumns: string[] = ['service_name'];

// Forms
export const serviceFormFields: string[] = [
  'service_name',
  'sms_name',
  'price',
  'tax',
  // 'provider_id',
  // 'apply_to_all_clinics',
  'service_category_id',
  'is_lab_test',
  // 'facilities',
  'description',
  'banner_image',
  'divider_1',
  'component_booking_form',
  'duration',
  'slots',
  'buffer_time_value',
  'lead_time_value',
];

export const serviceCreateFormFields: string[] = [
  'service_name',
  'sms_name',
  'price',
  'tax',
  'service_category_id',
  'is_lab_test',
  // 'provider_id',
  'apply_to_all_clinics',
  'facilities',
  'description',
  'banner_image',
  'divider_1',
  'component_booking_form',
  'duration',
  'slots',
  'buffer_time_value',
  'lead_time_value',
];
