import React from 'react';
import { TopUpInput, create_topup_schema, topupFields, topupFormFields } from './TopUpModel';
import TemplateForm from 'company/entities/Template/TemplateForm';

type TopUpFormProps = {
  resourceType: string;
  callbackAfterSubmit: (data?: any) => void;
  defaultAmount?: number;
};

const TopUpForm = (props: TopUpFormProps) => {
  const { resourceType, callbackAfterSubmit, defaultAmount } = props;
  const initialValues = {
    resource_type: resourceType,
    amount: defaultAmount,
  };

  const handleSubmit = async (data: any) => {
    console.log('Top up data:');
  };

  return (
    <TemplateForm
      submitButtonId="top_up_submit"
      submitButtonText="Confirm Top Up"
      entityName="TopUp"
      fields={topupFields}
      visibleFields={topupFormFields}
      initialValues={initialValues}
      schema={create_topup_schema}
      formSubmitApiFunction={handleSubmit}
      hideDefaultSnackbar
      disabled={false}
      buttonPosition="center"
    />
  );
};

export default TopUpForm;
