import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'prescription-dispense';

export const getDispenseRecords = (facility_id: number, prescription_line_item_id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/${prescription_line_item_id}`, {
    params: query,
  });
};

export const getDispenseHistory = (facility_id: number, prescription_line_item_id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/history/${prescription_line_item_id}`, {
    params: query,
  });
};

export const createDispenseRecord = (facility_id: number, data: any) => {
  return axios.post(`${baseURL}/${path}/${facility_id}`, data);
};
