import * as yup from 'yup';

import { CustomForm, CustomModal } from 'core/components';
import { useContext, useState } from 'react';

import { ClinicModel } from '../../ClinicManagement/Clinic/ClinicModel';
import { DROPDOWN_FIELD } from 'core/model/interface';
import { FacilityContext } from 'core/context/facility.context';
import { FieldInput } from 'core/model/interface';
import { ProductVariantModel } from '../Product/ProductVariant/ProductVariantModel';
import { createOrUpdateVariantInventory } from 'company/api/inventories';
import { formatVariantName } from 'core/utils';
import { useSnackbar } from 'notistack';

const addQuantitySchema = yup.object().shape({
  transaction_date: yup.date().required('Transaction Date is required').typeError('Invalid Date'),
  supplier_id: yup.string().required('Supplier is required'),
  quantity_in_stock: yup
    .number()
    .required('Quantity is required.')
    .typeError('Must be a number.')
    .min(1, 'Quantity must be greater than 0'),
  product_cost: yup
    .number()
    .min(0, 'Amount should be positive')
    .max(999999, 'Maximum product cost is 999,999')
    .typeError('Must be a number')
    .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
    .test('decimal-places', 'Maximum of 2 decimal places allowed', (value) => {
      if (!value) return true;
      return /^\d+(\.\d{0,2})?$/.test(value.toString());
    }),
});

type Props = {
  variant: ProductVariantModel;
  open: boolean;
  setOpen: (open: boolean) => void;
  facilityDetails?: ClinicModel;
  callbackAfterSubmit?: (data?: any) => void;
  exclusiveSupplier?: number;
};

const AddStock: React.FC<Props> = ({
  variant,
  open,
  setOpen,
  facilityDetails,
  callbackAfterSubmit,
  exclusiveSupplier,
}) => {
  const { facility } = useContext(FacilityContext);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  const addQuantityFields: FieldInput[] = [
    {
      field_name: 'transaction_date',
      display_name: 'Transaction Date',
      type: 'datetime',
      disablePast: true,
      format: 'YYYY-MM-DD H:i:s',
      span: 2,
    },
    {
      field_name: 'supplier_id',
      display_name: 'Supplier',
      type: DROPDOWN_FIELD.SUPPLIER,
      span: 2,
      hidden: !!exclusiveSupplier,
    },
    {
      field_name: 'quantity_in_stock',
      display_name: 'Quantity',
      type: 'text',
      span: 2,
    },
    {
      field_name: 'product_cost',
      display_name: 'Cost Per Item',
      optional: true,
      type: 'text',
      span: 2,
    },
  ];

  const handleSubmit = async (data: any) => {
    const updateData = { ...data, add_quantity: true };

    setLoading(true);
    const clinicId = facilityDetails ? facilityDetails.id : facility.id;
    createOrUpdateVariantInventory(clinicId, variant.id, updateData).then(() => {
      enqueueSnackbar('Stock successfully added!', { variant: 'success' });
      setLoading(false);
      setOpen(false);
      callbackAfterSubmit && callbackAfterSubmit();
    });
  };

  return (
    <CustomModal
      open={open}
      setOpen={setOpen}
      header={`Restock - ${variant.product_name}${formatVariantName(variant, true)}`}
      subHeader={facilityDetails ? facilityDetails.facility_name : facility?.facility_name}
    >
      <CustomForm
        fields={addQuantityFields}
        schema={addQuantitySchema}
        initialValues={{
          product_id: variant.product_id,
          transaction_date: new Date(),
          product_cost: 0,
          quantity_in_stock: 0,
          supplier_id: exclusiveSupplier ?? '',
        }}
        onSubmit={handleSubmit}
        loading={loading}
      />
    </CustomModal>
  );
};

export default AddStock;
