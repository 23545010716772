import Dropdown, { DropdownOptionProps } from './Dropdown';
import { PopoverOrigin, useTheme } from '@mui/material';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { tokens } from 'theme/theme';

type RowActionProps = {
  actions: DropdownOptionProps[];
  data?: any;
  getRow?: React.Dispatch<any>;
  hidden?: boolean;
  tooltip?: string;
  tooltipPlacement?: any;
  notColored?: boolean;
  label?: string;
  iconFontSize?: string;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
};

const RowAction: React.FC<RowActionProps> = ({
  actions,
  data,
  getRow,
  hidden,
  tooltip = 'View Actions',
  tooltipPlacement,
  notColored,
  label,
  iconFontSize = '22px',
  anchorOrigin,
  transformOrigin,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  if (hidden) {
    return null;
  }

  return (
    <Dropdown
      isIcon={!label}
      buttonLabel={label || undefined}
      optionList={actions}
      data={data}
      icon={
        !label ? (
          <MoreHorizIcon sx={{ fontSize: iconFontSize, color: notColored ? undefined : colors.secondary }} />
        ) : undefined
      }
      tooltip={tooltip}
      tooltipPlacement={tooltipPlacement || 'left-end'}
      getData={getRow}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    />
  );
};

export default RowAction;
