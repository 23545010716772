import TemplateDropdown, { TemplateDropdownProps } from './TemplateDropdown';
import { getAllAppointmentLegends, getAllBatchTags, getLegend } from 'company/api/appointment-legends';

import { ApiQuery } from 'core/model/interface';
import CircleIcon from '@mui/icons-material/Circle';
import { FacilityContext } from 'core/context/facility.context';
import { useContext } from 'react';

interface AppointmentLegendSearchDropdownProps extends TemplateDropdownProps {
  fromBatch?: boolean;
}

const AppointmentLegendSearchDropdown: React.FC<AppointmentLegendSearchDropdownProps> = (props) => {
  const { facility } = useContext(FacilityContext);
  const { fromBatch } = props;
  const getData = (query: ApiQuery) => {
    return !fromBatch ? getAllAppointmentLegends(facility.id, query) : getAllBatchTags(facility.id, query);
  };

  const getSingleData = (id: number) => {
    return getLegend(facility.id, id);
  };
  return (
    <TemplateDropdown
      {...props}
      entity={!fromBatch ? 'Appointment Legend' : 'Batch Tag'}
      getData={(query: ApiQuery) => getData({ ...query, order_by: undefined })}
      getById={(id: number) => getSingleData(id)}
      processOption={(record) => ({
        key: record.tag_name,
        value: record.id,
        data: record,
        icon: <CircleIcon sx={{ color: record.tag_color, fontSize: '20px !important' }} />,
      })}
      // processNewRecord={(record) => record.appointment_legend}
    />
  );
};

export default AppointmentLegendSearchDropdown;
