import { Box, Button, Modal, Paper, Typography, useTheme } from '@mui/material';
import React, { Dispatch, useEffect, useState } from 'react';
import confetti from 'canvas-confetti';
import RegularButton from '../buttons/RegularButton';
import { tokens } from 'theme/theme';
import { SIZES } from 'theme/constants';
const handleClick2 = () => {
  const scalar = 2;
  const triangle = confetti.shapeFromPath({
    path: 'M0 10 L5 0 L10 10z',
  });
  const square = confetti.shapeFromPath({
    path: 'M0 0 L10 0 L10 10 L0 10 Z',
  });
  const coin = confetti.shapeFromPath({
    path: 'M5 0 A5 5 0 1 0 5 10 A5 5 0 1 0 5 0 Z',
  });
  const tree = confetti.shapeFromPath({
    path: 'M5 0 L10 10 L0 10 Z',
  });

  const defaults = {
    spread: 360,
    ticks: 60,
    gravity: 0,
    decay: 0.96,
    startVelocity: 20,
    shapes: [triangle, square, coin, tree],
    scalar,
  };

  const shoot = () => {
    confetti({
      ...defaults,
      particleCount: 30,
    });

    confetti({
      ...defaults,
      particleCount: 5,
    });

    confetti({
      ...defaults,
      particleCount: 15,
      scalar: scalar / 2,
      shapes: ['circle'],
    });
  };

  setTimeout(shoot, 0);
  setTimeout(shoot, 100);
  setTimeout(shoot, 200);
};
const handleFireSideCannon = () => {
  const end = Date.now() + 3 * 1000; // 3 seconds
  const colors = ['#a786ff', '#fd8bbc', '#eca184', '#f8deb1'];

  const frame = () => {
    if (Date.now() > end) return;

    confetti({
      particleCount: 2,
      angle: 60,
      spread: 55,
      startVelocity: 60,
      origin: { x: 0, y: 0.5 },
      colors: colors,
    });
    confetti({
      particleCount: 2,
      angle: 120,
      spread: 55,
      startVelocity: 60,
      origin: { x: 1, y: 0.5 },
      colors: colors,
    });

    requestAnimationFrame(frame);
  };

  frame();
};

export interface MilestoneProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  content: string;
  handleReadMileStone: () => void;
}

export default function MilestoneContragulatory({
  title,
  open,
  content,
  setOpen,
  handleReadMileStone,
}: MilestoneProps) {
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);
  useEffect(() => {
    open && handleFireSideCannon();
    const interval = setInterval(() => handleFireSideCannon(), 3000);
    if (!open) clearInterval(interval);
    return () => clearInterval(interval);
  }, [open]);

  function handleCloseModal() {
    handleClick2();
    handleReadMileStone();
    setOpen(false);
  }

  return (
    <Modal
      open={open}
      slotProps={{
        backdrop: {
          style: { backgroundColor: 'transparent' },
        },
      }}
      sx={{
        '& .MuiModal-root': {
          outline: 'none',
        },
        '& .MuiPaper-root': {
          outline: 'none',
          border: 'none',
        },
        '& .MuiBackdrop-root': {
          outline: 'none',
        },
      }}
    >
      <Paper
        elevation={4}
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          maxWidth: 500,
          backgroundColor: 'background.paper',
          boxShadow: '10px',
          borderRadius: 10,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            textAlign: 'center',
            gap: '10px',
            flexDirection: 'column',
            padding: '20px',
            border: 'none',
          }}
        >
          <Typography sx={{ fontSize: '20px', fontWeight: '600', color: colors.accent, alignSelf: 'left' }}>
            {title}
          </Typography>
          <img
            src="https://media3.giphy.com/media/wX7I4l8SfFyG8rqhsd/giphy.gif?cid=6c09b95242jg7m8bfibmtq8j0snrndergflxtnmyvwkmh22c&ep=v1_stickers_search&rid=giphy.gif&ct=s"
            style={{ width: '50%', height: '50%' }}
          />
          <Typography sx={{ mb: '10px', fontSize: SIZES.h3 }}>{content}</Typography>
          <RegularButton onClick={handleCloseModal} label="Continue" variant="outlined" color="success" />
        </Box>
      </Paper>
    </Modal>
  );
}
