import {
  PUOM_ENTITY,
  ProductUnitOfMeasureModel,
  productUnitOfMeasureFields,
  productUnitOfMeasureTableColumns,
} from './ProductUOMModel';
import {
  UOMC_ENTITY,
  UnitOfMeasurementConversionModel,
  unitOfMeasurementConversionFields,
} from './UnitConversionModel';
import { deleteConversion, getUnitConversions } from 'company/api/product-uom-conversion';
import { deleteProductUOM, getAllProductUOMs } from 'company/api/product-uom';
import { forwardRef, useContext, useEffect, useRef, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import { Box } from '@mui/material';
import { FacilityContext } from 'core/context/facility.context';
import ProductUOMForm from './ProductUOMForm';
import { TableActionProps } from 'core/PageBuilder/CareGoTable';
import TemplateTable from 'company/entities/Template/TemplateTable';
import UnitConversionForm from './UnitConversionForm';

type Props = {
  visibleColumns?: string[];
  tableRef?: any;
  tableAction?: TableActionProps;
  callBackAfterSubmitFromAppointment?: () => void;
  from_uom_id: number;
  selectedUOMDetails?: ProductUnitOfMeasureModel;
};

const UnitConversionTable = forwardRef((props: Props, ref) => {
  const { visibleColumns, tableRef, tableAction, callBackAfterSubmitFromAppointment, from_uom_id, selectedUOMDetails } =
    props;
  const [selectedRow, setSelectedRow] = useState<UnitOfMeasurementConversionModel>();

  const backupRef: any = useRef();
  const mainRef: any = tableRef ?? backupRef;
  const { facility } = useContext(FacilityContext);

  const getData = (query: ApiQuery) => {
    return getUnitConversions(facility.id, from_uom_id, query);
  };

  const refreshTable = () => {
    if (mainRef.current) {
      mainRef.current.refreshTable();
    } else {
      console.error('tableRef is not assigned correctly');
    }
  };

  useEffect(() => {
    refreshTable();
  }, [from_uom_id]);

  function callBackAfterSubmit() {
    refreshTable();
    callBackAfterSubmitFromAppointment && callBackAfterSubmitFromAppointment();
    mainRef.current?.closeFormModal();
  }

  const handleDeleteProductUOMConversion = (id: number) => {
    return deleteConversion(facility.id, id);
  };

  return (
    <Box>
      <TemplateTable
        // tableAction={{
        //   rowActions,
        // }}
        // hideAddUpdateDeleteAction
        handleDelete={(data) => handleDeleteProductUOMConversion(data)}
        // hideUpdateAction
        // hideDeleteAction
        useColoredTable
        ref={mainRef}
        entityName={UOMC_ENTITY}
        fields={unitOfMeasurementConversionFields}
        getData={getData}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        visibleColumns={visibleColumns ?? productUnitOfMeasureTableColumns}
        templateForm={
          selectedUOMDetails && (
            <UnitConversionForm
              selectedConversion={selectedRow}
              callbackAfterSubmit={callBackAfterSubmit}
              selectedUOM={from_uom_id}
              fromUOM={selectedUOMDetails}
            />
          )
        }
      />
    </Box>
  );
});

export default UnitConversionTable;
