import React, { forwardRef, useEffect, useRef, useState } from 'react';
import {
  SERVICE_PRODUCTS_ENTITY_NAME,
  serviceProductFields,
  serviceProductFormTableColumns,
  serviceProductTableColumns,
} from './PatientServiceProductModel';
import { TableActionProps, TableComponentProps } from 'core/PageBuilder/CareGoTable';
import { deleteServiceProduct, getServiceProducts } from 'company/api/service-products';

import { ApiQuery } from 'core/model/interface';
import { CompanyServiceModel } from 'company/model/Entities';
import TemplateTable from '../../../Template/TemplateTable';

type Props = {
  serviceId?: number;
  visibleColumns?: string[];
  setDrawerSelectedRow?: (row: any) => void;
  drawerSelectedRow?: any;
  tableRef?: React.RefObject<any>;
  hideAddUpdateDeleteAction?: boolean;
  tableAction?: TableActionProps;
  tableComponent?: TableComponentProps;
  hideAddButton?: boolean;
  overrideGetData?: (query: ApiQuery) => Promise<any>;
  fromAddProducts?: boolean;
};

const PatientServiceProductTable = forwardRef<any, Props>((props, ref) => {
  const {
    serviceId,
    visibleColumns,
    setDrawerSelectedRow,
    drawerSelectedRow,
    hideAddUpdateDeleteAction,
    tableAction,
    tableComponent,
    hideAddButton,
    tableRef,
    overrideGetData,
    fromAddProducts,
  } = props;

  const backupRef = useRef<any>(null);
  const mainRef = tableRef ?? backupRef;
  const [selectedRow, setSelectedRow] = useState<CompanyServiceModel | undefined>();

  const getData = (query: ApiQuery) => {
    if (overrideGetData) {
      return overrideGetData(query);
    }
    if (serviceId) {
      query = { ...query, service_id: serviceId };
    }
    return getServiceProducts(query);
  };

  const handleDelete = (data: any) => {
    return deleteServiceProduct(data.id);
  };

  const callbackAfterSubmit = () => {
    mainRef.current.refreshTable();
    mainRef.current.closeFormModal();
  };

  useEffect(() => {
    if (serviceId) mainRef.current.refreshTable();
  }, [serviceId]);

  return (
    <TemplateTable
      ref={mainRef}
      entityName={SERVICE_PRODUCTS_ENTITY_NAME}
      fields={serviceProductFields}
      visibleColumns={visibleColumns ?? (fromAddProducts ? serviceProductFormTableColumns : serviceProductTableColumns)}
      getData={getData}
      handleDelete={handleDelete}
      selectedRow={selectedRow}
      setSelectedRow={setSelectedRow}
      drawerSelectedRow={drawerSelectedRow}
      setDrawerSelectedRow={setDrawerSelectedRow}
      hideAddUpdateDeleteAction={hideAddUpdateDeleteAction}
      hideAddButton={hideAddButton}
      tableAction={tableAction}
      tableComponent={tableComponent}
      processFormInitialValues={(data) => data}
    />
  );
});

export default PatientServiceProductTable;
