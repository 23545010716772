import {
  BackButton,
  ConfirmationDialog,
  CustomAnimatedDrawer,
  CustomForm,
  CustomModal,
  Dropdown,
  RegularButton,
} from 'core/components';
import { Box, Button, ButtonGroup, Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ChangeEvent, forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { ContainerColumn, ContainerRow } from 'core/components/containers';
import { FORM_MODE, FieldInput, SIGNATORY_MODE } from 'core/model/interface';
import HideOrShowComponent, { isDevMode } from 'core/components/HideOrShowComponent';
import PatientNoteSignatories, { Signatory } from './components/PatientNoteSignatories';
import { createNote, getNote, updateNote } from 'company/api/patient-notes';
import {
  createNoteSignatory,
  deleteAllNoteSignatories,
  getNoteSignatories,
} from 'company/api/patient-note-signatories';
import {
  createNoteTemplateSignatory,
  deleteAllNoteTemplateSignatories,
  getNoteTemplateSignatories,
} from 'company/api/note-template-signatories';
import { evaluateExpression, formatDateTime, getFieldsAndResponses } from 'core/utils';
import { getNote as getCareGoNote, updateNotes } from 'carego-admin/api/carego-notes-templates';
import { getCareGoTemplate, getTemplate, updateTemplate } from 'company/api/note-templates';
import { useNavigate, useParams } from 'react-router-dom';

import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import BackToTopButton from 'core/components/BackToTopButton';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import { CompanyAppointmentModel } from 'company/entities/modules/ClinicManagement/Appointment/AppointmentModel';
import CustomDatePicker from 'core/components/CustomDatePicker';
import CustomLoadingIndicator from 'core/components/CustomLoadingIndicator';
import DocumentHeader from './components/DocumentHeader';
import DocumentHistory from './DocumentHistory';
import DownloadIcon from '@mui/icons-material/Download';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
// import * as excelJs from 'exceljs';
import ExcelJS from 'exceljs';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { FacilityContext } from 'core/context/facility.context';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import PatientField from '../PatientManagement/components/PatientField';
import { PatientModel } from 'company/entities/modules/ClinicManagement/Patient/PatientModel';
import { PatientNoteModel } from 'company/model/Entities';
import PatientRecordForm from 'core/components/forms/PatientRecordForm';
import PrintablePatientNote from './components/PrintablePatientNote';
import React from 'react';
import { SIZES } from 'theme/constants';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import { UserContext } from 'core/context/user.context';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { getAllPatientAppointments } from 'company/api/appointments';
import { getAppointment } from 'company/api/appointments';
import { getCodeOfPatientNote } from 'company/api/icdcode';
import { getPatient } from 'company/api/patient';
import { getPrintSettings } from 'company/api/print-settings';
import moment from 'moment';
import { tokens } from 'theme/theme';
import { useReactToPrint } from 'react-to-print';
import { useSnackbar } from 'notistack';

export type Components = {
  id: string;
  type: 'rich_text' | 'form' | 'chart';
  title?: string;
  description?: string;
  fields?: any[];
  content?: string; // richtext
  responses?: any; // forms
  chart_type?: string; // charts
};

type DocumentEditorProps = {
  source: string;
  isPreview?: boolean;
  template_id?: number;
  patient_id?: number;
  patient?: PatientModel;
  triggerPrint?: boolean;
  fromPatientRecords?: boolean;
  stickyTop?: string;
  refreshTimeline?: () => void;
  formMode?: FORM_MODE;
  hideClinicHeader?: boolean;
};

type PrintSettingsProps = {
  font: string;
  page_size: string;
  page_margin: string;
  orientation: string;
  hide_empty_fields?: boolean;
};

const DocumentEditor: React.FC<DocumentEditorProps> = ({
  source,
  isPreview = false,
  template_id,
  patient_id,
  triggerPrint,
  fromPatientRecords,
  stickyTop,
  refreshTimeline,
  formMode,
  hideClinicHeader = false,
}) => {
  const { initialMode } = useParams() as any;
  const { id } = useParams() as any;
  const { facility } = useContext(FacilityContext);
  const { user } = useContext(UserContext);
  const isMobilePhone = useMediaQuery('(max-width:768px)');
  const componentRef = useRef<any>(null);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { enqueueSnackbar } = useSnackbar();
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  let navigate = useNavigate();

  const [patient, setPatient] = useState<PatientModel>();
  const [appointment, setAppointment] = useState<any>();

  const [name, setName] = useState('');

  // const [components, setComponents] = useState<Components[]>([]);
  const [fields, setFields] = useState<FieldInput[]>([]);
  const [responses, setResponses] = useState<any>({});
  const [mode, setMode] = useState<FORM_MODE>(
    isPreview
      ? FORM_MODE.VIEW
      : [FORM_MODE.VIEW, FORM_MODE.EDIT, FORM_MODE.RESPOND].includes(initialMode)
      ? initialMode
      : fromPatientRecords || source === 'patient'
      ? formMode ?? FORM_MODE.VIEW
      : FORM_MODE.VIEW
  );

  const [patientAppointments, setPatientAppointments] = useState<any[]>([]);
  const [selectedAppointment, setSelectedAppointment] = useState<number>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [appointmentDetails, setAppointmentDetails] = useState<CompanyAppointmentModel>();
  const [selectedDateRecorded, setSelectedDateRecorded] = useState<any>();

  const [note, setNote] = useState<PatientNoteModel>();
  const [openAddConfirmModal, setOpenAddConfirmModal] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [initialValueCode, setInitialValueCodes] = useState<any[]>([]);
  const [loaded, setLoaded] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [backToTopButtonVisible, setBackToTopButtonVisible] = useState(false);
  const [openSettingsForm, setOpenSettingsForm] = useState<boolean>(false);
  const [openUpdateHistory, setOpenUpdateHistory] = useState<boolean>(false);
  const [printSettings, setPrintSettings] = useState<PrintSettingsProps>({
    font: '',
    page_size: 'A4',
    page_margin: '1cm',
    orientation: 'portrait',
  });
  const [hideEmptyFields, setHideEmptyFields] = useState<boolean | undefined>(false);
  const [loadingSavePrintSettings, setLoadingSavePrintSettings] = useState<boolean>(false);
  const [displayFields, setDisplayFields] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [noteSignatories, setNoteSignatories] = useState<any[]>([]);
  const [loadingSignatories, setLoadingSignatories] = useState(false);

  const idToUse = useMemo(() => template_id ?? id, [template_id, id]);

  const generateExcelWithExcelJS = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Document');

    worksheet.columns = [
      { key: 'A', width: 20 },
      { key: 'B', width: 20 },
      { key: 'C', width: 20 },
    ];

    fields.forEach((field: any) => {
      if (['divider', 'image_upload', 'table'].includes(field.type)) {
        return;
      }

      let startCell = 'A';
      let endCell = 'C';

      if (field.span === 12) {
        startCell = 'A';
        endCell = 'C';
      } else if (field.span === 4) {
        startCell = 'A';
        endCell = 'A';
      }

      // Add main field row
      const row = worksheet.addRow([field.display_name]);
      const cell = worksheet.getCell(`${startCell}${row.number}`);
      if (['section_header', 'subsection_header'].includes(field.type)) {
        cell.font = { bold: true, size: 14 };
      } else {
        cell.font = { size: 12 };
      }

      if (startCell !== endCell) {
        worksheet.mergeCells(`${startCell}${row.number}:${endCell}${row.number}`);
      }

      for (let i = 0; i < 1; i++) {
        const inputRow = worksheet.addRow([]);
        if (startCell !== endCell) {
          worksheet.mergeCells(`${startCell}${inputRow.number}:${endCell}${inputRow.number}`);
        }

        if (['radiogroup', 'checkbox_group', 'select', 'checkbox'].includes(field.type)) {
          const options = field.type === 'checkbox' ? ['Yes', 'No'] : field.options.map((opt: any) => opt.value);

          worksheet.getCell(`${startCell}${inputRow.number}`).dataValidation = {
            type: 'list',
            allowBlank: true,
            formulae: [`"${options.join(',')}"`],
          };
        }
      }
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${note?.header}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const DownloadButton = () => {
    return (
      <Button
        onClick={generateExcelWithExcelJS}
        startIcon={<DownloadIcon />}
        variant="outlined"
        sx={{
          textTransform: 'none',
          padding: '6px 18px',
          borderRadius: '8px',
          fontSize: '12px',
        }}
      >
        Download Template
      </Button>
    );
  };

  // Refactor - Start
  const getData = async () => {
    if (!idToUse) return;

    switch (source) {
      // Patient Note
      case 'patient':
        await getPatientNote();
        break;

      // Patient Note Template - Company
      case 'company':
        await getCompanyNoteTemplate();
        break;

      // Patient Note Template - CareGo Admin
      case 'admin':
        await getCareGoNoteTemplate();
        break;

      default:
        await getCareGoNoteTemplateCompanyView();
        break;
    }

    setTimeout(() => setLoaded(true), 1000);
  };

  const getCompanyNoteTemplate = async () => {
    if (facility) {
      try {
        setDisplayFields(true);
        setIsLoading(true);
        setLoadingSignatories(true);

        const { data } = await getTemplate(idToUse);
        setNote(data);
        setName(data.header);
        const [fields, responses] = getFieldsAndResponses(data);
        setFields(fields);
        setResponses(responses);

        // Get template signatories
        const res = await getNoteTemplateSignatories(facility.id, data.id);
        setNoteSignatories(res.data.data);
      } catch (error) {
      } finally {
        setIsLoading(false);
        setLoadingSignatories(false);
      }
    }
  };

  const getPatientNote = async () => {
    if (facility) {
      try {
        setDisplayFields(false);
        setFields([]);

        const noteResponse = await getNote(facility.id, idToUse);
        const noteData: PatientNoteModel = noteResponse.data;
        setNote(noteData);
        setName(noteData.header);
        setSelectedDateRecorded(noteData.date_recorded ?? '');

        const patientResponse = await getPatient(facility.id, noteData.patient_id);
        setPatient(patientResponse.data);

        const fields: FieldInput[] = noteData.template_body[0].fields;
        setFields(
          fields.map((field) => {
            field = { ...field };
            if (field.parent_field && field.condition) {
              field.hiddenBasedOnOtherField = (data: any) => {
                if (!data) return false;
                return field.condition === 'equal'
                  ? data[field.parent_field!] === field.condition_value
                  : data[field.parent_field!] !== field.condition_value;
              };
            }
            if (field.formula_text) {
              field.formula = (data: any) => {
                let formulatext = field.formula_text + '';
                if (
                  formulatext.startsWith('special_hematology_formula') ||
                  formulatext.startsWith('special_urinalysis_formula') ||
                  formulatext.startsWith('special_clinical_chemistry_formula') ||
                  formulatext.startsWith('special_eye_test_formula') ||
                  formulatext.startsWith('special_fecalysis_formula') ||
                  formulatext.startsWith('special_audiometry_formula') ||
                  formulatext.startsWith('special_audiometry_findings_formula') ||
                  formulatext.startsWith('special_physical_exam_formula')
                ) {
                  data.sex = patientResponse.data.sex;
                  return evaluateExpression(formulatext, data);
                }

                Object.keys(data).forEach((item) => {
                  if (!Array.isArray(data[item])) {
                    formulatext = formulatext.replaceAll(item, data[item] || 0);
                  }
                });

                return evaluateExpression(formulatext);
              };
            }
            return field;
          })
        );

        let patient_responses: any = {};
        if (noteData.responses && !Array.isArray(noteData.responses)) {
          patient_responses = noteData.responses;
        } else if (noteData.body) {
          if (noteData.body.length) patient_responses = noteData.body[0].responses;
        }

        // Get Table Responses
        const from_note_template = noteData.template_body;
        const template_responses: any = from_note_template[0].responses;
        fields.forEach((field) => {
          if (field.type === 'table') {
            const table_patient_responses = patient_responses[field.field_name!];
            const table_template_responses = template_responses[field.field_name!];

            patient_responses[field.field_name!] = !table_template_responses
              ? []
              : table_template_responses.map((response: any) => {
                  const existingResponse = table_patient_responses
                    ? table_patient_responses.find((item: any) => item.id === response.id)
                    : false;

                  if (existingResponse) {
                    field.columns?.forEach((col) => {
                      if (col.type === 'fixed_text') existingResponse[col.fieldName] = response[col.fieldName];
                    });
                    return existingResponse;
                  }
                  return response;
                });
          }
        });

        setResponses(patient_responses);

        // Get Signatories
        const signatoryResponse = await getNoteSignatories(facility.id, noteData.id);
        setNoteSignatories(signatoryResponse.data.data);

        // Get ICD Codes
        const codeResponse = await getCodeOfPatientNote(noteData.id);
        const formattedData = codeResponse.data.data.map((item: { id: number; code: string; description: string }) => ({
          key: item.code + ' - ' + item.description,
          value: item.code,
        }));

        setInitialValueCodes(formattedData ? formattedData : []);
      } catch (error) {
        console.error(error);
        enqueueSnackbar('Error fetching patient note', { variant: 'error' });
      } finally {
        setTimeout(() => {
          setIsLoading(false);
          setDisplayFields(true);
        }, 500);
      }
    }
  };

  const getCareGoNoteTemplate = async () => {
    try {
      setIsLoading(true);
      const { data } = await getCareGoNote(idToUse);
      setNote(data);
      setName(data.header);
      const [fields, responses] = getFieldsAndResponses(data);
      setFields(fields);
      setResponses(responses);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const getCareGoNoteTemplateCompanyView = async () => {
    try {
      setDisplayFields(true);
      const { data } = await getCareGoTemplate(idToUse);
      setNote(data);
      setName(data.header);
      const [fields, responses] = getFieldsAndResponses(data);
      setFields(fields);
      setResponses(responses);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleSavebuttonClicked = async (autoSave?: boolean) => {
    try {
      setIsSaving(true);
      if (source === 'patient') {
        const newNote = {
          ...note,
          responses: JSON.stringify(responses),
          header: name,
        };
        await updatePatientNote(newNote);
      } else {
        const newNote = {
          ...note,
          body: JSON.stringify([{ fields, responses }]),
          header: name,
        };
        if (source === 'company') await updateTemplate(idToUse, newNote);
        else await updateNotes(idToUse, newNote);
      }

      if (!autoSave) {
        enqueueSnackbar('Note successfully updated!', { variant: 'success' });
        getData();
      }
    } catch (error) {
      if (!autoSave) enqueueSnackbar('Error occurred! Unable to update note.', { variant: 'error' });
    } finally {
      setTimeout(() => setIsSaving(false), 500);
    }
  };

  const updatePatientNote = async (newNote: any) => {
    await updateNote(facility.id, idToUse, newNote);
  };

  // Refactor End

  const fetchNoteSignatories = async () => {
    if (!idToUse) return;
    setLoadingSignatories(true);
    try {
      const response =
        source === 'company'
          ? await getNoteTemplateSignatories(facility.id, idToUse)
          : await getNoteSignatories(facility.id, idToUse);

      setNoteSignatories(response.data.data);
    } catch (error) {
      console.error('Error fetching signatories:', error);
    } finally {
      setLoadingSignatories(false);
    }
  };

  useEffect(() => {
    async function getAllSettings() {
      if (user?.user_group_id === 3) return;
      const res = await getPrintSettings();
      const settings = res.data.data;
      if (!settings) {
        setPrintSettings({ font: '', page_size: 'A4', page_margin: '1cm', orientation: 'portrait' });
        return;
      }
      const toSet = {
        font: settings.patient_note_font_size,
        page_size: settings.patient_note_page_size,
        page_margin: settings.patient_note_margin,
        orientation: settings.patient_note_orientation,
      };
      setHideEmptyFields(!!settings.hide_empty_fields);
      setPrintSettings(toSet);
    }

    getAllSettings();
  }, [user]);

  const scrollRef = useRef();

  const notesNotFromCurrentFacility = useMemo(() => {
    if (note && facility && source === 'patient') return facility.id !== note.facility_id;
    return false;
  }, [facility, note, source]);

  useEffect(() => {
    if (notesNotFromCurrentFacility) {
      setMode(FORM_MODE.VIEW);
    } else if (formMode) {
      setMode(formMode);
    }
  }, [formMode, notesNotFromCurrentFacility]);

  useEffect(() => {
    if (idToUse && facility) {
      getData();
      fetchNoteSignatories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idToUse, facility]);

  useEffect(() => {
    if (patient && !hideClinicHeader && facility) {
      getAllPatientAppointments(facility.id, patient.patient_id).then((docs) => {
        const filteredAppointments = docs.data.filter(
          (doc: any) => doc.status !== 'cancelled' && doc.status !== 'no-show'
        );

        setPatientAppointments(
          filteredAppointments.map((u: any) => {
            return { key: `${formatDateTime(u.schedule)} - ${u.service_names}`, value: u.id, data: u };
          })
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient?.patient_id, facility]);

  useEffect(() => {
    if (selectedAppointment && patientAppointments.length) {
      const found = patientAppointments.find((appointment) => appointment.value === selectedAppointment);
      if (found) setAppointmentDetails(found.data);

      //auto-save when updating appointments
      if (note && selectedAppointment !== note?.appointment_id) {
        note.appointment_id = selectedAppointment;
        // handleSavebuttonClicked(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAppointment, patientAppointments]);

  useEffect(() => {
    if (note && facility) {
      if (note?.appointment_id && (!appointment || appointment.id !== note.appointment_id)) {
        getAppointment(facility.id, note.appointment_id).then((appointmentdoc) => {
          setAppointment(appointmentdoc.data);
        });
      }

      setSelectedAppointment(note.appointment_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [note, facility?.id]);

  useEffect(() => {
    if (triggerPrint) {
      componentRef.current.printNote();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerPrint]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSelectAppointment = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedAppointment(event.target.value as unknown as number);
  };

  const handleDateRecordedChange = (dateRecorded: string | null) => {
    if (note)
      updateNote(facility.id, note?.id, { ...note, date_recorded: dateRecorded }).then(() => {
        enqueueSnackbar(`Date recorded successfully updated!`, { variant: 'success' });
      });
  };

  const handleCreate = async () => {
    const data = {
      template_id: idToUse,
      source: 'clinic',
      appointment_id: appointment?.id,
    };

    return (
      facility &&
      patient_id &&
      createNote(facility.id, patient_id, data)
        .then((res) => {
          enqueueSnackbar(`Note successfully added!`, { variant: 'success' });
          // setRefreshForNotes && setRefreshForNotes((prev: number) => prev + 1);
          navigate(`/company/patient-notes/respond/${res.data.note.id}`);
        })
        .catch((error) => {
          console.error(error);
          enqueueSnackbar(`Error occured! Unable to add note.`, { variant: 'error' });
        })
    );
  };

  const autoSaveSignatures = async (signatoriesToSave: Signatory[]) => {
    if (note?.id) {
      setIsSaving(true);
      try {
        if (source === 'company') {
          await deleteAllNoteTemplateSignatories(note.id);
          if (signatoriesToSave.length > 0) {
            await Promise.all(
              signatoriesToSave.map(
                async (sig, index) =>
                  await createNoteTemplateSignatory({
                    note_template_id: note.id,
                    signatory_id: sig.signatory_id || sig.id,
                    facility_id: facility.id,
                    order: index + 1,
                  })
              )
            );
            const response = await getNoteTemplateSignatories(facility.id, note.id);
            setNoteSignatories(response.data.data);
          }
        } else {
          await deleteAllNoteSignatories(note.id);

          if (signatoriesToSave.length > 0) {
            await Promise.all(
              signatoriesToSave.map(
                async (sig, index) =>
                  await createNoteSignatory({
                    patient_note_id: note.id,
                    signatory_id: sig.signatory_id || sig.id,
                    facility_id: facility.id,
                    order: index + 1,
                  })
              )
            );
            const response = await getNoteSignatories(facility.id, note.id);
            setNoteSignatories(response.data.data);
          }
        }
      } finally {
        setTimeout(() => {
          setIsSaving(false);
        }, 500);
      }
    }
  };

  const autoSave = () => handleSavebuttonClicked(true);

  useEffect(() => {
    if (!fromPatientRecords) setBreadcrumb([{ label: 'Document Editor' }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (mode === FORM_MODE.EDIT) {
        if (loaded) autoSave();
      }
    }, 500);
    return () => clearTimeout(timeoutId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, fields, responses]);

  useEffect(() => {
    if (!loadingSavePrintSettings && componentRef.current) {
      componentRef.current.printNote();
    }
  }, [printSettings, loadingSavePrintSettings]);

  // useEffect(() => {
  //   const scrollRefCurrent = scrollRef.current as any;

  //   const handleScroll = (e: any) => {
  //     if (scrollRefCurrent.scrollTop > 100) {
  //       setBackToTopButtonVisible(true);
  //     } else {
  //       setBackToTopButtonVisible(false);
  //     }
  //   };

  //   if (scrollRefCurrent) {
  //     scrollRefCurrent.addEventListener('scroll', handleScroll);
  //   }

  //   return () => {
  //     if (scrollRefCurrent) scrollRefCurrent.removeEventListener('scroll', handleScroll);
  //   };
  // }, [scrollRef]);

  function handleUpdatePrintSettings(data: PrintSettingsProps) {
    setLoadingSavePrintSettings(true);
    setTimeout(() => {
      setPrintSettings(data); // This will trigger useEffect
      setLoadingSavePrintSettings(false);
      setOpenSettingsForm(false);
      setHideEmptyFields(data.hide_empty_fields);
    }, 800);
  }

  if (formMode === FORM_MODE.PRINT && note && patient) {
    return (
      <PrintedNote
        note={note}
        patient={patient}
        fields={fields}
        responses={responses}
        ref={componentRef}
        appointment={appointment}
        printSettings={printSettings}
        fromPrintOutside
      />
    );
  }

  if (isPreview) {
    return (
      <Box position="relative">
        <HideOrShowComponent hidden={!isLoading}>
          <Box height="400px" display="flex" justifyContent="center" alignItems="center">
            <CustomLoadingIndicator />
          </Box>
        </HideOrShowComponent>

        <Box sx={{ marginBottom: '20px' }}>
          {/* <Typography variant="h6">Preview of Note Template</Typography> */}
          <DocumentHeader name={name} mode={FORM_MODE.VIEW} note={note} patient={patient} />
          <CustomForm
            fields={fields}
            initialValues={responses}
            span={12}
            columnNumber={3}
            readOnly
            hideSubmitButton
            formMode={FORM_MODE.VIEW}
          />

          {noteSignatories && (
            <PatientNoteSignatories
              mode={SIGNATORY_MODE.VIEW}
              noteSignatories={noteSignatories}
              loading={loadingSignatories}
            />
          )}
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ position: 'relative' }}>
      {!fromPatientRecords && !isPreview && !responses && <BackButton />}
      {isDevMode() && source !== 'patient' && <DownloadButton />}
      {!isPreview && (
        <ContainerRow
          sx={{
            alignItems: 'flex-start',
            background: colors.secondary_background,
            paddingBlock: SIZES.paddingS,
          }}
        >
          <ContainerColumn gap={SIZES.paddingS} sx={{ paddingTop: SIZES.paddingS }}>
            {notesNotFromCurrentFacility && source !== 'company' && (
              <Typography variant="body2" fontWeight={600}>
                From: {note?.facility_name}
              </Typography>
            )}
            <Box display="flex" gap="10px">
              {!hideClinicHeader && note?.facility_id === facility?.id && (
                <Dropdown
                  // disabled={isMobilePhone}
                  buttonLabel={(mode === FORM_MODE.VIEW ? 'View' : 'Edit') + ' Mode'}
                  startIcon={mode === FORM_MODE.VIEW ? <VisibilityOutlinedIcon /> : <EditOutlinedIcon />}
                  optionList={[
                    {
                      label: 'Edit',
                      action: () => setMode(FORM_MODE.RESPOND),
                      hidden: source !== 'patient' || !user?.authorities?.includes('UPDATE_PATIENT_NOTE'),
                      icon: <EditOutlinedIcon />,
                    },
                    { label: 'View', action: () => setMode(FORM_MODE.VIEW), icon: <VisibilityOutlinedIcon /> },
                    {
                      label: 'Modify Template',
                      action: () => setMode(FORM_MODE.EDIT),
                      hidden:
                        !(source !== 'carego' && source !== 'patient') ||
                        !user?.authorities?.includes('UPDATE_PATIENT_NOTE'),
                      icon: <EditOutlinedIcon />,
                    },
                  ]}
                  notFullWidth
                  icon={<ExpandMoreOutlinedIcon />}
                  buttonColor={FORM_MODE.VIEW === mode ? 'warning' : 'success'}
                />
              )}

              {mode !== FORM_MODE.EDIT && !isMobilePhone && (
                <Box sx={{ display: 'flex', width: 'auto', justifyContent: 'center', gap: '15px' }}>
                  <ButtonGroup disableElevation variant="outlined" aria-label="Basic button group">
                    <Button
                      startIcon={<LocalPrintshopOutlinedIcon />}
                      variant="outlined"
                      onClick={() => componentRef.current.printNote()}
                      sx={{
                        textTransform: 'none',
                        padding: '6px 18px',
                        borderRadius: '8px',
                        fontSize: '12px',
                        border: `1px solid ${colors.primary}`,
                        '&:hover': {
                          borderRight: `1px solid ${colors.primary}`,
                        },
                      }}
                    >
                      Print
                    </Button>
                    <Button
                      startIcon={<SettingsIcon sx={{ marginLeft: '8px' }} />}
                      variant="outlined"
                      onClick={() => setOpenSettingsForm(true)}
                      sx={{
                        width: '20%',
                        height: '100%',
                        padding: '4.5px 18px',
                        borderRadius: '8px',
                        textTransform: 'none',
                        border: `1px solid ${colors.primary}`,
                        // borderLeft: 'none',

                        fontSize: '12px',
                      }}
                    ></Button>
                  </ButtonGroup>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <PatientField label={'Page Margin'} value={printSettings.page_margin}></PatientField>
                  </Box>
                  <PatientField label={'Page Size'} value={printSettings.page_size}></PatientField>
                </Box>
              )}

              {mode !== FORM_MODE.VIEW && isMobilePhone && (
                <Box width={200} display="flex" marginBottom={'-10px'}>
                  <RegularButton
                    startIcon={<SaveOutlinedIcon />}
                    label={'Save'}
                    onClick={() => handleSavebuttonClicked()}
                    disabled={isLoading}
                  />
                  <Typography
                    fontStyle="italic"
                    sx={{
                      visibility: isSaving ? 'visible' : 'hidden',
                      textAlign: 'center',
                      marginLeft: '10px',
                    }}
                  >
                    Saving updates ...
                  </Typography>
                </Box>
              )}
            </Box>
            {source === 'patient' && note && !hideClinicHeader && (
              <Box display="flex" gap="10px">
                {/* {isDevMode() && (
                  <Box width="100%" maxWidth="250px">
                    <CustomSelectField
                      options={patientAppointments}
                      label={'Appointments'}
                      fieldName={'appointments'}
                      value={note.appointment_id}
                      handleChange={handleSelectAppointment}
                    />
                  </Box>
                )} */}

                {/* <Box width="100%" maxWidth="250px">
                    <ICDCodeSearchDropdown
                      initialValue={initialValueCode}
                      limitTags={1}
                      handleChangeCallback={(data) => {
                        if (data && Array.isArray(data)) {
                          const values = data.map((item) => item.value);
                          return updateIcdCodesOfNote(facility.id, note.id, {
                            codes: values ? JSON.stringify(values) : [],
                          });
                        }
                      }}
                    /> 
                  </Box> */}
              </Box>
            )}

            <Box width="100%" maxWidth="250px">
              <CustomDatePicker
                fieldName=""
                label="Select Date Recorded"
                value={selectedDateRecorded}
                setFieldValue={(field: string, value: any) => {
                  const stringValue = value || value !== null ? moment(new Date(value)).format('YYYY-MM-DD') : null;
                  setSelectedDateRecorded(stringValue);
                  handleDateRecordedChange(stringValue);
                  if (note) setNote({ ...note, date_recorded: stringValue as string });
                }}
              />
            </Box>
          </ContainerColumn>

          <ContainerRow gap={SIZES.paddingS} sx={{ justifyContent: 'flex-end' }}>
            <Box>
              <RegularButton
                styles={{ width: '200px' }}
                variant="outlined"
                label="View Version History"
                onClick={() => setOpenUpdateHistory(true)}
                startIcon={<HistoryOutlinedIcon />}
              />
            </Box>

            {mode !== FORM_MODE.VIEW && !isMobilePhone && (
              <Box display="flex" flexDirection="column" justifyContent="flex-end" alignItems="flex-end" gap="10px">
                <RegularButton
                  startIcon={<SaveOutlinedIcon />}
                  label={'Save'}
                  onClick={() => handleSavebuttonClicked()}
                  disabled={isLoading}
                />
                <Typography fontSize="12px" fontStyle="italic" sx={{ visibility: isSaving ? 'visible' : 'hidden' }}>
                  Saving updates ...
                </Typography>
              </Box>
            )}

            {mode === FORM_MODE.VIEW && !isMobilePhone && isDevMode() && (
              <Box display="flex" flexDirection="column" justifyContent="flex-end" alignItems="flex-end" gap="10px">
                <RegularButton
                  startIcon={<AttachEmailIcon />}
                  label={'Email to Patient'}
                  onClick={() => {}}
                  disabled={isLoading}
                />
                <Typography fontSize="12px" fontStyle="italic" sx={{ visibility: isSaving ? 'visible' : 'hidden' }}>
                  Sending email ...
                </Typography>
              </Box>
            )}
          </ContainerRow>
        </ContainerRow>
      )}

      {isLoading && (
        <Box height="400px" display="flex" justifyContent="center" alignItems="center">
          <CustomLoadingIndicator />
        </Box>
      )}

      <Box
        ref={scrollRef}
        width="100%"
        className="hide-scrollbar"
        visibility={displayFields ? 'visible' : 'hidden'}
        sx={{
          backgroundColor: colors.secondary_background,
          height: 'auto',
          overflowY: 'auto',
          padding: isMobilePhone ? '5px' : '25px',
          paddingTop: 0,
          borderRadius: 2,
          ...(isMobilePhone && {
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            scrollbarWidth: 'none',
            '-ms-overflow-style': 'none',
          }),
        }}
      >
        {/* <RegularButton label={readOnly ? 'Edit' : 'View'} onClick={() => {setReadOnly(!readOnly)}} /> */}

        <BackToTopButton parentRef={scrollRef} visible={backToTopButtonVisible} position={{ bottom: 100, right: 45 }} />
        {!displayFields && (
          <Box
            height="400px"
            sx={{ backgroundColor: 'red' }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CustomLoadingIndicator />
          </Box>
        )}
        <Box sx={{ visibility: displayFields ? 'visible' : 'hidden' }}>
          {mode === FORM_MODE.VIEW ? (
            <>
              {note && patient && (
                <Box
                  sx={{
                    width: '100%',
                    maxWidth: '920px',
                    margin: '0 auto 50px',
                  }}
                >
                  <PrintablePatientNote
                    note={note}
                    patient={patient}
                    fields={fields}
                    responses={responses}
                    appointment={appointment}
                    hideEmptyFields={printSettings.hide_empty_fields}
                    formView
                  />
                  {noteSignatories && (
                    <Box mt={'40px'}>
                      <PatientNoteSignatories
                        mode={SIGNATORY_MODE.VIEW}
                        noteSignatories={noteSignatories}
                        loading={loadingSignatories}
                      />
                    </Box>
                  )}
                </Box>
              )}
            </>
          ) : (
            <Box
              sx={{
                width: '100%',
                maxWidth: '920px',
                margin: '0 auto',
                borderRadius: '10px',
                display: 'flex',
                flexDirection: 'column',
                gap: mode !== FORM_MODE.EDIT ? 0 : '10px',
                paddingY: mode !== FORM_MODE.EDIT ? '24px' : undefined,
              }}
            >
              <DocumentHeader
                name={name}
                setName={setName}
                mode={mode}
                note={note}
                autoSave={autoSave}
                patient={patient}
                hideClinicHeader={hideClinicHeader}
              />

              {mode === FORM_MODE.RESPOND ? (
                <HideOrShowComponent hidden={!patient}>
                  <PatientRecordForm
                    noteId={idToUse}
                    fields={fields}
                    responses={responses}
                    appointment={appointment!}
                    patient={patient!}
                  />
                </HideOrShowComponent>
              ) : (
                <CustomForm
                  fields={fields}
                  setFormFields={setFields}
                  initialValues={responses}
                  setResponses={setResponses}
                  hideSubmitButton
                  span={12}
                  columnNumber={3}
                  readOnly
                  formMode={mode}
                  columnGap={mode === FORM_MODE.EDIT ? '10px' : undefined}
                  appointment={appointment}
                  patient={patient}
                />
              )}

              {note && (
                <PatientNoteSignatories
                  mode={SIGNATORY_MODE.EDIT}
                  noteSignatories={noteSignatories}
                  onSignatoriesChange={autoSaveSignatures}
                  loading={loadingSignatories}
                  source={source}
                  template_id={note.id}
                  note_id={note.id}
                />
              )}
            </Box>
          )}
        </Box>

        {note && patient && (
          <PrintedNote
            note={note}
            patient={patient}
            fields={fields}
            responses={responses}
            ref={componentRef}
            appointment={appointment}
            printSettings={printSettings}
            noteSignatories={noteSignatories}
          />
        )}

        {formMode !== FORM_MODE.EDIT && !responses && (
          <Box
            display="grid"
            gridTemplateColumns="1fr 1fr "
            gap="10px"
            sx={{
              width: '100%',
              maxWidth: '920px',
              margin: '0 auto',
            }}
          >
            <Divider sx={{ gridColumn: 'span 2' }} />
            <PatientField fieldFontSize="11px" label="Created By:" value={note?.creator_name} />
            <PatientField fieldFontSize="11px" label="Date Created:" value={formatDateTime(note?.created_at)} />
            <PatientField fieldFontSize="11px" label="Last Updated By:" value={note?.last_editor_name} />
            <PatientField fieldFontSize="11px" label="Last Updated:" value={formatDateTime(note?.updated_at)} />
          </Box>
        )}
      </Box>

      <ConfirmationDialog
        content="Are you sure you want to use this template?"
        open={openAddConfirmModal}
        setOpen={setOpenAddConfirmModal}
        onConfirm={handleCreate}
        confirmButtonColor
      />

      <CustomModal setOpen={setOpenSettingsForm} open={openSettingsForm} header={'Customize Print'}>
        <CustomForm
          submitButtonText={'Print'}
          fields={[
            {
              field_name: 'page_margin',
              type: 'select',
              display_name: 'Page Margin',
              options: [
                { key: '1cm', value: '1cm' },
                { key: '2cm', value: '2cm' },
                { key: '3cm', value: '3cm' },
                { key: '4cm', value: '4cm' },
              ],
            },

            {
              field_name: 'page_size',
              type: 'select',
              display_name: 'Page Size',
              options: [
                { key: 'A0', value: 'A0' }, // 841 x 1189 mm
                { key: 'A1', value: 'A1' }, // 594 x 841 mm
                { key: 'A2', value: 'A2' }, // 420 x 594 mm
                { key: 'A3', value: 'A3' }, // 297 x 420 mm
                { key: 'A4', value: 'A4' }, // 210 x 297 mm (common for documents)
                { key: 'A5', value: 'A5' }, // 148 x 210 mm
                { key: 'A6', value: 'A6' }, // 105 x 148 mm
                { key: 'A7', value: 'A7' }, // 74 x 105 mm
                { key: 'A8', value: 'A8' }, // 52 x 74 mm
                { key: 'A9', value: 'A9' }, // 37 x 52 mm
                { key: 'Letter', value: 'Letter' }, // 8.5 x 11 inches (commonly used)
                { key: 'Legal', value: 'Legal' }, // 8.5 x 14 inches (for legal documents)
                { key: 'Executive', value: 'Executive' }, // 7.25 x 10.5 inches (often for memos)
                { key: 'Folio', value: 'Folio' }, // 8.5 x 13 inches (less common but still used)
              ],
            },

            {
              field_name: 'hide_empty_fields',
              type: 'checkbox',
              display_name: 'Hide Empty Fields',
            },
          ]}
          onSubmit={(data) => {
            handleUpdatePrintSettings(data);
          }}
          initialValues={{ ...printSettings, hide_empty_fields: hideEmptyFields }}
          loading={loadingSavePrintSettings}
        ></CustomForm>
        {/* <PageMarginDropDown /> */}
      </CustomModal>

      <CustomAnimatedDrawer
        open={openUpdateHistory}
        setOpen={setOpenUpdateHistory}
        header="Version History"
        width={1200}
      >
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
          {note && patient && user?.user_group_id !== 3 && (
            <>
              <Box sx={{ flex: '1' }}>
                <DocumentHistory
                  note={note}
                  fields={fields}
                  patient={patient}
                  // onRestore={(responses: any) => {
                  //   const restoredComponents = [...components];
                  //   if (restoredComponents[0]) {
                  //     restoredComponents[0].responses = responses;
                  //   }
                  //   setComponents(restoredComponents);
                  //   handleSavebuttonClicked();
                  //   setOpenUpdateHistory(false);
                  // }}
                />
              </Box>
            </>
          )}
        </Box>
      </CustomAnimatedDrawer>
    </Box>
  );
};

type Props = {
  note: PatientNoteModel;
  patient: PatientModel;
  fields: FieldInput[];
  responses: any;
  appointment: CompanyAppointmentModel;
  printSettings?: {
    font: string;
    page_size: string;
    page_margin: string;
    orientation: string;
    hide_empty_fields?: boolean;
  };
  fromPrintOutside?: boolean;
  noteSignatories?: any[];
};

export const PrintedNote = forwardRef(
  (
    {
      note,
      patient,
      fields,
      responses,
      appointment,
      fromPrintOutside,
      noteSignatories,
      printSettings = { font: '', page_size: 'A4', page_margin: '1cm', orientation: 'portrait' },
    }: Props,
    ref
  ) => {
    const componentRef = useRef<HTMLDivElement | null>(null);
    const [showPrint, setShowPrint] = useState(false);

    const printNote = () => {
      setShowPrint(true);
      document.title = `${note?.header} - ${patient?.full_name}`;
      setTimeout(() => handlePrint(), 1000);
      setTimeout(() => {
        setShowPrint(false);
        document.title = `CareGo Health Suite`;
      }, 3000);
    };

    const getFontSizes = (size: string) => {
      switch (size) {
        case 'xl':
          return { header: '15px', body: '14px' };
        case 'l':
          return { header: '14px', body: '13px' };
        case 's':
          return { header: '12px', body: '11px' };
        case 'm':
        default:
          return { header: '13px', body: '12px' };
      }
    };

    const fontSizes = getFontSizes(printSettings.font);

    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      pageStyle: `
      @page {
        size: ${printSettings.page_size} ${printSettings.orientation};
        margin: ${printSettings.page_margin};
      }
      body {
        margin: 0;
      }
    `,
    });

    useImperativeHandle(ref, () => ({ printNote }));

    return (
      <Box display={fromPrintOutside ? undefined : 'none'}>
        {(showPrint || fromPrintOutside) && (
          <PrintablePatientNote
            note={note}
            patient={patient}
            fields={fields}
            responses={responses}
            ref={componentRef}
            appointment={appointment}
            hideEmptyFields={printSettings.hide_empty_fields}
            // compact={fromPrintOutside}
            compact
            noteSignatories={noteSignatories}
            fontSizes={fontSizes}
          />
        )}
      </Box>
    );
  }
);

export default DocumentEditor;
