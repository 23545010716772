import { Box, FormControlLabel, Grid, Switch, Typography, useTheme } from '@mui/material';
import CustomFilter, { CheckboxOptionProps } from 'core/components/CustomFilter';
import POSLineItem, { LineItem } from './POSLineItem';
import { forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { getServicesAssigned, getServicesAssignedStoredProcedure } from 'company/api/services';

import { AttributeValueModel } from 'company/entities/modules/InventorySystem/AttributeValue/AttributeValueModel';
import { ContainerRow } from 'core/components/containers';
import { CustomIconButton } from 'core/components';
import CustomLoadingIndicator from 'core/components/CustomLoadingIndicator';
import { FacilityContext } from 'core/context/facility.context';
import { Link } from 'react-router-dom';
import { OrderProps } from '..';
import { SIZES } from 'theme/constants';
import SearchBar from './SearchBar';
import ViewListIcon from '@mui/icons-material/ViewList';
import WindowIcon from '@mui/icons-material/Window';
import { getAllServiceCategories } from 'company/api/service-categories';
import { getAllServicePackages } from 'company/api/service-packages';
import { getInventories } from 'company/api/inventories';
import { getVariantAttributeValues } from 'company/api/variant-attributes';
import { tokens } from 'theme/theme';

type Props = {
  addToCart: (item: any) => void;
  type: 'product' | 'service' | 'service-package';
  orders: OrderProps[];
  fromCreateInvoice?: boolean;
};
const ItemList = forwardRef((props: Props, ref) => {
  const { addToCart, type, orders, fromCreateInvoice } = props;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useImperativeHandle(ref, () => ({ refreshItems }));

  const { facility } = useContext(FacilityContext);
  const [items, setItems] = useState<LineItem[]>([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [hideZeroQuantityProducts, setHideZeroQuantityProducts] = useState(false);
  const [attributes, setAttributes] = useState<AttributeValueModel[]>([]);
  const [attributeFilters, setAttributeFilters] = useState<string[]>([]);
  const [serviceCategoryFilters, setServiceCategoryFilters] = useState<string[]>([]);
  const [servicePackageCategoryFilters, setServicePackageCategoryFilters] = useState<string[]>([]);

  const [serviceCategories, setServiceCategories] = useState<any>();

  const parentRef = useRef();

  const itemsPerPage = 12;

  const refreshItems = (orders: OrderProps[]) => {
    if (type === 'product') {
      orders.forEach((order) => {
        const item = items.find((item) => item.id === order.item_id);
        if (item) {
          item.quantity_in_stock = item.quantity_in_stock - order.quantity;
        }
      });
    }
    setItems([...items]);
  };

  const handleScroll = () => {
    const parentElement = parentRef.current as any;
    if (parentElement && parentElement.scrollHeight - parentElement.scrollTop - parentElement.clientHeight < 1) {
      setTimeout(() => {
        if (hasMore) setPage((prevPage) => prevPage + 1);
      }, 1000);
    }
  };

  const optionList: CheckboxOptionProps[] = useMemo(() => {
    let currentAttributeId = 0;

    return attributes.flatMap((attr) => {
      const value: CheckboxOptionProps[] = [];
      if (currentAttributeId !== attr.attribute_id) {
        currentAttributeId = attr.attribute_id;
        value.push({ label: attr.attribute_name, id: attr.attribute_name, isHeader: true });
      }
      value.push({ label: attr.value, id: attr.id + '' });
      return value;
    });
  }, [attributes]);
  const filteredItems: LineItem[] = useMemo(() => {
    if (type === 'service' || !hideZeroQuantityProducts) return items;
    else return items.filter((item) => item.quantity_in_stock > 0 || item.made_to_order);
  }, [items, type, hideZeroQuantityProducts]);

  const getPOSItems = async () => {
    if (hasMore && facility) {
      setIsLoading(true);
      try {
        let apiCall;
        switch (type) {
          case 'service':
            apiCall = getServicesAssignedStoredProcedure;
            break;
          case 'service-package':
            apiCall = getAllServicePackages;
            break;
          default:
            apiCall = getInventories;
        }

        const response = await apiCall(facility.id, {
          page: page,
          length: itemsPerPage,
          search: searchQuery,
          order_by: type === 'service' ? 'service_name' : type === 'service-package' ? 'package_name' : 'product_name',
          status: type === 'service' || type === 'service-package' ? 'visible' : undefined,
          attribute_values: type === 'product' ? attributeFilters : undefined,
          service_category: type === 'service' ? serviceCategoryFilters : undefined,
          service_package_category: type === 'service-package' ? servicePackageCategoryFilters : undefined,
        });

        const newItems: LineItem[] = response.data.data.map((item: any) => ({
          ...item,
          id: item.id,
          product_id: item.product_id || 0,
          service_name: type === 'service-package' ? item.package_name : item.service_name,
          price: type === 'service-package' ? item.price : item.variant_price || item.price,
        }));

        setHasMore(newItems.length === itemsPerPage);
        if (page === 1) {
          setItems(newItems);
        } else {
          const existing = items.map((item) => item.id);
          const toBeAdded = newItems.filter((item) => !existing.includes(item.id));
          setItems((prev) => [...prev, ...toBeAdded]);
        }
      } finally {
        setTimeout(() => {
          setIsLoading(false);
        }, 200);

        const parentElement = parentRef.current as any;
        if (parentElement && parentElement.scrollHeight === parentElement.clientHeight && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      }
    }
  };

  const refreshList = () => {
    setItems([]);
    if (page !== 1) {
      setHasMore(true);
      setPage(1);
    } else {
      getPOSItems();
    }
  };

  useEffect(() => {
    getPOSItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMore, page, facility?.id]);

  useEffect(() => {
    if (!firstLoad) refreshList();
    setFirstLoad(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, attributeFilters, serviceCategoryFilters, servicePackageCategoryFilters]);

  useEffect(() => {
    if (type === 'product')
      getVariantAttributeValues().then((res) => {
        setAttributes(res.data.data);
      });

    if (type === 'service' || type === 'service-package') {
      getAllServiceCategories(facility.id, { length: 1000 }).then((res) => {
        setServiceCategories(res.data.data);
      });
    }
  }, [facility.id, type]);

  const serviceCategoryOptions = useMemo(() => {
    if (!serviceCategories) return [];
    return serviceCategories.map((serviceCategory: any) => ({
      label: serviceCategory.service_category_name,
      id: serviceCategory.id.toString(),
    }));
  }, [serviceCategories]);

  return (
    <Box>
      <Box display="flex" width={'100%'} justifyContent={'space-between'} alignItems={'center'} gap="10px">
        {type === 'product' ? (
          <ContainerRow sx={{ justifyContent: 'flex-start', flex: 0.8, gap: '5px' }}>
            <FormControlLabel
              control={
                <Switch
                  checked={hideZeroQuantityProducts}
                  onChange={() => setHideZeroQuantityProducts(!hideZeroQuantityProducts)}
                  color="primary"
                />
              }
              label={
                <Typography variant="body1" fontSize="11px">
                  Hide unavailable products
                </Typography>
              }
            />

            <CustomFilter
              buttonLabel={'Attributes'}
              optionList={optionList}
              selected={attributeFilters}
              setSelected={setAttributeFilters}
              span={2}
              noOptionContent={
                <Box p="0.825rem" display="flex" gap="5px">
                  <Typography>No attribute filters available.</Typography>
                  <Link to="/company/inventory/settings?inventory_settings=0&tab=1" style={{ textDecoration: 'none' }}>
                    <Typography sx={{ color: colors.primary, ':hover': { textDecoration: 'underline' } }}>
                      Add Attributes
                    </Typography>
                  </Link>
                </Box>
              }
            />
          </ContainerRow>
        ) : (
          <Box></Box>
        )}

        {type === 'service' && (
          <CustomFilter
            buttonLabel="Service Category Filter"
            span={2}
            optionList={serviceCategoryOptions}
            selected={serviceCategoryFilters}
            setSelected={setServiceCategoryFilters}
            noOptionContent={
              <Box p="0.825rem" display="flex" gap="5px">
                <Typography>No categories added.</Typography>
                <Link to="/company/clinic-management?settings=2" style={{ textDecoration: 'none' }}>
                  <Typography sx={{ color: colors.primary, ':hover': { textDecoration: 'underline' } }}>
                    Add Categories
                  </Typography>
                </Link>
              </Box>
            }
          ></CustomFilter>
        )}

        {type === 'service-package' && (
          <CustomFilter
            buttonLabel="Service Package Category Filter"
            span={2}
            optionList={serviceCategoryOptions}
            selected={servicePackageCategoryFilters}
            setSelected={setServicePackageCategoryFilters}
            noOptionContent={
              <Box p="0.825rem" display="flex" gap="5px">
                <Typography>No categories added.</Typography>
                <Link to="/company/clinic-management?settings=2" style={{ textDecoration: 'none' }}>
                  <Typography sx={{ color: colors.primary, ':hover': { textDecoration: 'underline' } }}>
                    Add Categories
                  </Typography>
                </Link>
              </Box>
            }
          ></CustomFilter>
        )}

        <Box flex={1} display="flex" justifyContent="flex-end" alignItems="center" gap={SIZES.paddingS}>
          <SearchBar handleSearch={(searchKey) => setSearchQuery(searchKey)} />
          <Box>
            <CustomIconButton
              color={viewMode === 'list' ? 'primary' : undefined}
              icon={<ViewListIcon />}
              onClick={() => setViewMode('list')}
              tooltip="List View"
            />
            <CustomIconButton
              color={viewMode === 'grid' ? 'primary' : undefined}
              icon={<WindowIcon />}
              onClick={() => setViewMode('grid')}
              tooltip="Grid View"
            />
          </Box>
        </Box>
      </Box>

      <Box
        ref={parentRef}
        display="flex"
        flexDirection="column"
        gap="20px"
        marginTop="20px"
        overflow="auto"
        height={'calc(100vh - 290px)'}
        minHeight="400px"
        paddingRight="20px"
        onScroll={handleScroll}
      >
        {isLoading && firstLoad ? (
          // <Box textAlign="center" padding="10px">
          <CustomLoadingIndicator />
        ) : (
          // </Box>
          <>
            <Grid container spacing={2}>
              {filteredItems.map((service, idx) => (
                <Grid item sm={viewMode === 'grid' ? 6 : 12} lg={viewMode === 'grid' ? 4 : 12} key={service.id}>
                  <POSLineItem item={service} addToCart={addToCart} type={type} viewMode={viewMode} orders={orders} />
                </Grid>
              ))}
            </Grid>

            {!hasMore && (
              <Typography textAlign="center" padding="10px">
                No more data
              </Typography>
            )}
          </>
        )}
      </Box>
    </Box>
  );
});

export default ItemList;
