import { Box, Typography } from '@mui/material';

import PatientField from 'company/screens/PatientManagement/components/PatientField';
import RichTextEditor from 'core/components/RichTextEditor';
import { SIZES } from 'theme/constants';
import { ServiceResponse } from '../Services';
import { formatArray } from 'core/utils';

type Props = {
  services?: ServiceResponse;
};

const ServiceDetails: React.FC<Props> = ({ services }) => {
  return (
    <Box>
      {services && (
        <Box paddingBlock={SIZES.padding}>
          <Typography fontWeight={600} variant="h4">
            Service Details
          </Typography>
          <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" rowGap="15px" columnGap="50px" paddingTop={2}>
            <PatientField label="Service Name" value={services.service_name} />
            <PatientField label="SMS Name" value={services.sms_name} />
            <PatientField
              label="Category"
              value={formatArray(services.service_category_id?.map((cat) => cat.key) ?? [])}
            />
            <PatientField
              label="Description"
              valueIsComponent
              value={
                <Box>
                  <RichTextEditor content={services.description ?? ''} readOnly />
                </Box>
              }
            />
            <PatientField label="Duration" value={services.duration + ' min'} />
            <PatientField label="Tax" value={services.tax ?? ''} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ServiceDetails;
