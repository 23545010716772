import * as yup from 'yup';

import { EntityFields } from 'company/entities/utils';
import { DROPDOWN_FIELD } from 'core/model/interface';

export const UOMC_ENTITY = 'UOM Conversion';

export interface UnitOfMeasurementConversionModel {
  id: number;
  from_uom_id: number;
  to_uom_id: number;
  conversion_factor: number;
}

export const unitOfMeasurementConversionFields: EntityFields[] = [
  { fieldName: 'from_name', displayName: 'From' },
  { fieldName: 'to_name', displayName: 'To' },
  { fieldName: 'conversion_factor', displayName: 'Conversion Factor' },
  //   { fieldName: 'symbol', displayName: 'Symbol' },
];

export const unitOfMeasurementConversionFormFields: EntityFields[] = [
  {
    fieldName: 'from_uom_id',
    displayName: 'From',
    type: DROPDOWN_FIELD.PRODUCT_UOM,
    disabled: true,
  },
  { fieldName: 'to_uom_id', displayName: 'To', type: DROPDOWN_FIELD.PRODUCT_UOM },
  { fieldName: 'conversion_factor', displayName: 'Conversion Factor', type: 'text' },
];

export interface productUnitOfMeasurementConversionFieldInputs {
  conversion_factor: string;
}

export const productUnitOfMeasurementConversionInitialValues: any = {
  conversion_factor: '',
};

export const unitOfMeasurementConversionVisibleFormFields: string[] = ['from_uom_id', 'to_uom_id', 'conversion_factor'];
