import { Box, Button, Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AddIcon from '@mui/icons-material/Add';
import DaysOfWeekDropdown from './DaysOfWeekDropdown';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { RegularButton } from 'core/components';
import { tokens } from 'theme/theme';
import { updateClinicSchedules } from 'company/api/facility';
import { updateDoctorSchedules } from 'company/api/doctors';
import { updateServiceSchedules } from 'company/api/services';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

interface Day {
  day: string;
  week_day: string;
  hours: any[];
}

type WeeklyScheduleProps = {
  id: number;
  schedule: any;
  type: 'service' | 'clinic' | 'doctor';
  setSchedule?: (schedule: any) => void;
  refreshTable?: () => void;
  disabled?: boolean;
  clinic_id?: number;
  onSave?: (schedule: any) => void;
};

const WeeklySchedule: React.FC<WeeklyScheduleProps> = ({
  id,
  schedule,
  type,
  setSchedule,
  refreshTable,
  disabled,
  clinic_id,
  onSave,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery('(max-width:768px)');

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const initializeDays = (schedule: any) => {
    return schedule
      ? typeof schedule === 'string'
        ? JSON.parse(schedule)
        : schedule
      : [
          { day: 'SUN', week_day: 'Sunday', hours: [] },
          { day: 'MON', week_day: 'Monday', hours: [{ start: '09:00 AM', end: '05:00 PM' }] },
          { day: 'TUE', week_day: 'Tuesday', hours: [{ start: '09:00 AM', end: '05:00 PM' }] },
          { day: 'WED', week_day: 'Wednesday', hours: [{ start: '09:00 AM', end: '05:00 PM' }] },
          { day: 'THU', week_day: 'Thursday', hours: [{ start: '09:00 AM', end: '05:00 PM' }] },
          { day: 'FRI', week_day: 'Friday', hours: [{ start: '09:00 AM', end: '05:00 PM' }] },
          { day: 'SAT', week_day: 'Saturday', hours: [] },
        ];
  };

  const [days, setDays] = useState<Day[]>(initializeDays(schedule));

  const handleTimeChange = (index: number, value: Dayjs | null, timeSlotIndex: number, time: 'start' | 'end') => {
    const start = dayjs(value).format('h:mm A');
    const end = dayjs(value).format('h:mm A');
    const updatedDays = [...days];

    if (time === 'start') {
      updatedDays[index].hours[timeSlotIndex] = {
        ...updatedDays[index].hours[timeSlotIndex],
        start: start,
      };
    } else {
      updatedDays[index].hours[timeSlotIndex] = {
        ...updatedDays[index].hours[timeSlotIndex],
        end: end,
      };
    }

    setDays(updatedDays);
  };

  const handleAddTimeSlot = (index: number) => {
    const updatedDays = [...days];
    updatedDays[index].hours.push({ start: '09:00 AM', end: '05:00 PM' });
    setDays(updatedDays);
  };

  const handleRemoveTimeSlot = (dayIndex: number, timeIndex: number) => {
    const updatedDays = [...days];
    updatedDays[dayIndex].hours.splice(timeIndex, 1);
    setDays(updatedDays);
  };

  const handleApply = (dayToBeCopied: number, selectedDays: number[]) => {
    const updatedDays = [...days];
    const hours = updatedDays[dayToBeCopied].hours;

    selectedDays.forEach((dayIndex) => {
      hours.forEach((timeSlot) => {
        updatedDays[dayIndex].hours.push({ start: timeSlot.start, end: timeSlot.end });
        setDays(updatedDays);
      });
    });
  };

  const saveSchedule = (days: Day[]) => {
    setLoading(true);
    if (onSave) {
      if (disabled) {
        if (type === 'service' && clinic_id) {
          updateServiceSchedules(clinic_id, id, null).then(() => {
            enqueueSnackbar('Weekly hours successfully saved!', { variant: 'success' });
            setLoading(false);
            setSchedule && setSchedule(JSON.stringify(days));
            refreshTable && refreshTable();
            onSave(JSON.stringify(days));
          });
        } else if (type === 'doctor' && clinic_id) {
          updateDoctorSchedules(clinic_id, id, null).then(() => {
            enqueueSnackbar('Schedule successfully saved!', { variant: 'success' });
            setLoading(false);
            setSchedule && setSchedule(JSON.stringify(days));
            refreshTable && refreshTable();
            onSave(JSON.stringify(days));
          });
        }
      } else {
        onSave(JSON.stringify(days));
      }
      return;
    }

    if (disabled) {
      if (type === 'service' && clinic_id) {
        updateServiceSchedules(clinic_id, id, null).then(() => {
          enqueueSnackbar('Weekly hours successfully saved!', { variant: 'success' });
          setLoading(false);
          setSchedule && setSchedule(schedule);
          refreshTable && refreshTable();
        });
      } else if (type === 'doctor' && clinic_id) {
        updateDoctorSchedules(clinic_id, id, null).then(() => {
          enqueueSnackbar('Schedule successfully saved!', { variant: 'success' });
          setLoading(false);
          setSchedule && setSchedule(schedule);
          refreshTable && refreshTable();
        });
      }
    } else {
      if (type === 'clinic') {
        updateClinicSchedules(id, { schedule: JSON.stringify(days) }).then(() => {
          enqueueSnackbar('Weekly hours successfully saved!', { variant: 'success' });
          setLoading(false);
          setSchedule && setSchedule(JSON.stringify(days));
          refreshTable && refreshTable();
        });
      } else if (type === 'doctor' && clinic_id) {
        updateDoctorSchedules(clinic_id, id, { schedule: JSON.stringify(days) }).then(() => {
          enqueueSnackbar('Schedule successfully saved!', { variant: 'success' });
          setLoading(false);
          setSchedule && setSchedule(JSON.stringify(days));
          refreshTable && refreshTable();
        });
      } else if (type === 'service' && clinic_id) {
        updateServiceSchedules(clinic_id, id, { schedule: JSON.stringify(days) }).then(() => {
          enqueueSnackbar('Weekly hours successfully saved!', { variant: 'success' });
          setLoading(false);
          setSchedule && setSchedule(JSON.stringify(days));
          refreshTable && refreshTable();
        });
      }
    }
  };

  const TimePickerField = ({ label, dayIndex, timeIndex, value, time }: any) => (
    <TimePicker
      label={label}
      value={dayjs(value, 'h:mm A')}
      onChange={(newValue) => handleTimeChange(dayIndex, newValue, timeIndex, time)}
      disabled={disabled}
      sx={{
        width: isMobile ? '120px' : '150px',
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: theme.palette.primary.main,
          },
          '&:hover fieldset': {
            borderColor: theme.palette.primary.light,
          },
          '&.Mui-focused fieldset': {
            borderColor: theme.palette.primary.dark,
          },
        },
      }}
    />
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {days.map((day, dayIndex) => (
        <Box key={day.day} width="100%" mb={isMobile ? 3 : 0}>
          <Box
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
            justifyContent={isMobile ? 'center' : 'space-between'}
            alignItems={isMobile ? 'center' : 'flex-start'}
          >
            <Typography
              fontWeight="bold"
              variant={isMobile ? 'h6' : 'h5'}
              width={isMobile ? '100%' : '80px'}
              textAlign="center"
              mb={isMobile ? 1 : 0}
            >
              {day.day}
            </Typography>
            <Box
              display="flex"
              flexDirection={isMobile ? 'column' : 'row'}
              alignItems="center"
              width={isMobile ? '100%' : 'auto'}
            >
              {day.hours.length === 0 ? (
                <Typography variant={isMobile ? 'body2' : 'h5'} color="gray" py={1}>
                  Unavailable
                </Typography>
              ) : (
                <Box display="flex" flexDirection="column" gap={1} width={isMobile ? '100%' : 'auto'}>
                  {day.hours.map((timeSlot, timeIndex) => (
                    <Box
                      key={timeIndex}
                      display="flex"
                      flexDirection={isMobile ? 'column' : 'row'}
                      alignItems="center"
                      gap={1}
                      width={isMobile ? '100%' : 'auto'}
                    >
                      <Box display="flex" flexDirection={'row'} alignItems="center" margin={'10px'}>
                        <TimePickerField
                          label="Start Time"
                          dayIndex={dayIndex}
                          timeIndex={timeIndex}
                          value={timeSlot.start}
                          time="start"
                        />
                        <Typography fontWeight="bold" variant="h4" mx={1}>
                          -
                        </Typography>
                        <TimePickerField
                          label="End Time"
                          dayIndex={dayIndex}
                          timeIndex={timeIndex}
                          value={timeSlot.end}
                          time="end"
                        />

                        <Button
                          disabled={disabled}
                          onClick={() => handleRemoveTimeSlot(dayIndex, timeIndex)}
                          sx={{ color: 'gray', minWidth: 'auto' }}
                          size="small"
                        >
                          <DeleteOutlinedIcon />
                        </Button>
                      </Box>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
            <Box
              display="flex"
              flexDirection={isMobile ? 'column' : 'row'}
              alignItems={isMobile ? 'center' : 'flex-start'}
              width={isMobile ? '100%' : 'auto'}
              mt={isMobile ? 1 : 0}
            >
              <Button
                disabled={disabled}
                onClick={() => handleAddTimeSlot(dayIndex)}
                sx={{ color: colors.primary }}
                fullWidth={isMobile}
              >
                <AddIcon /> {isMobile && 'Add Time'}
              </Button>
              {!isMobile && <DaysOfWeekDropdown onApply={handleApply} selectedDay={day.week_day} disabled={disabled} />}
            </Box>
          </Box>
          {!isMobile && dayIndex < days.length - 1 && <Divider />}
        </Box>
      ))}
      <Box display="flex" justifyContent="center" mt={3}>
        <RegularButton onClick={() => saveSchedule(days)} loading={loading} label="Save" fullWidth></RegularButton>
      </Box>
    </LocalizationProvider>
  );
};

export default WeeklySchedule;
