import * as yup from 'yup';

import { EntityFields } from '../../../utils';
import { FieldInput } from 'core/model/interface';
import { Typography } from '@mui/material';
import { formatVariantName } from 'core/utils';

export const SERVICE_PRODUCTS_ENTITY_NAME = 'Service Product';

export const createServiceProductSchema = yup.object().shape({
  service_id: yup.number().required('Service is required.'),
  variant_id: yup.number().required('Product is required.'),
  quantity: yup.number().positive().integer().max(100, 'Maximum quantity is 100').required('Quantity is required'),
  price: yup
    .string()
    .matches(/^\d+(\.\d+)?$/, 'Price must be a valid number')
    .required('Price is required'),
  show_in_invoice: yup.boolean().required('Show in invoice is required'),
});

export const createServiceProductDetailsSchema = yup.object().shape({
  quantity: yup.number().positive().integer().max(100, 'Maximum quantity is 100').required('Quantity is required'),
  price: yup
    .string()
    .matches(/^\d+(\.\d+)?$/, 'Price must be a valid number')
    .required('Price is required'),
  show_in_invoice: yup.boolean().required('Show in invoice is required'),
});

export interface ServiceProductModel {
  id: number;
  company_id: number;
  service_id: number;
  variant_id: number;
  variant_name: string;
  product_name: string;
  sku: string;
  quantity: number;
  price: string;
  show_in_invoice: boolean;
  variant_price?: string;
}

export interface ServiceProductInput {
  service_id: number;
  variant_id: number;
  quantity: number;
  price: string;
  show_in_invoice: boolean;
}

export interface ServiceProductFieldInput extends FieldInput {
  field_name?: keyof ServiceProductInput;
}

export const serviceProductInitialValues: ServiceProductInput = {
  service_id: 0,
  variant_id: 0,
  quantity: 1,
  price: '0',
  show_in_invoice: false,
};

export const serviceProductFields: EntityFields[] = [
  { fieldName: 'service_id', displayName: 'Service', type: 'select', options: [] },
  {
    fieldName: 'product_name',
    displayName: 'Product',
    type: 'select',
    options: [],
    flex: 2,
    renderCell: ({ row }) => (
      <Typography>
        {row.product_name}
        {formatVariantName(row, true)}
      </Typography>
    ),
  },
  { fieldName: 'sku', displayName: 'SKU', type: 'text', flex: 1 },
  { fieldName: 'quantity', displayName: 'Quantity', type: 'number' },
  { fieldName: 'price', displayName: 'Price per Qty', type: 'text' },
  { fieldName: 'show_in_invoice', displayName: 'Show in Invoice', type: 'checkbox' },
];

export const serviceProductDetailsFields: EntityFields[] = [
  { fieldName: 'quantity', displayName: 'Quantity', type: 'number' },
  { fieldName: 'price', displayName: 'Price per Qty', type: 'text' },
  { fieldName: 'show_in_invoice', displayName: 'Show in Invoice', type: 'checkbox' },
];

export const serviceProductTableColumns: string[] = ['product_name', 'sku', 'quantity', 'price'];

export const serviceProductFormTableColumns: string[] = ['product_name', 'sku'];

export const createServiceProductDetailsVisibleFields: string[] = ['price', 'quantity', 'show_in_invoice'];
