import { CareGoPage } from 'core/PageBuilder';
import React, { useContext, useEffect, useState } from 'react';
import ProductUOMTable from './ProductUOMTable';
import { Box, Typography, useTheme } from '@mui/material';
import InfiniteScrollContainer from 'core/components/dataView/InfiniteScrollContainer';
import { FacilityContext } from 'core/context/facility.context';
import { ApiQuery } from 'core/model/interface';
import { deleteProductUOM, getAllProductUOMs, getProductUOM } from 'company/api/product-uom';
import { ProductUnitOfMeasureModel } from './ProductUOMModel';
import { ContainerRow } from 'core/components/containers';
import { tokens } from 'theme/theme';
import TemplateTable from 'company/entities/Template/TemplateTable';
import UnitConversionTable from './UnitConversionTable';
import { ConfirmationDialog, CustomModal, RegularButton } from 'core/components';
import AddIcon from '@mui/icons-material/Add';
import ProductUOMForm from './ProductUOMForm';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { UserContext } from 'core/context/user.context';
export default function ProductUOM() {
  const { user } = useContext(UserContext);
  const [selectedUOM, setSelectedUOM] = useState<number>();
  const [selectedUOMDetails, setSelectedUOMDetails] = useState<ProductUnitOfMeasureModel>();
  const [openProductUOMForm, setOpenProductUOMForm] = useState<boolean>(false);
  const [addedItem, setAddedItem] = useState<number>();
  const [updateForm, setUpdateForm] = useState<boolean>(false);
  const [updatedItem, setUpdatedItem] = useState<ProductUnitOfMeasureModel>();
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [deletedId, setDeletedId] = useState<number>();
  const { facility } = useContext(FacilityContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const getData = (query: ApiQuery) => {
    return getAllProductUOMs(facility.id, query);
  };

  const getSingleData = (id: number) => {
    return getProductUOM(facility.id, id);
  };

  const displayItem = (item: any) => {
    const isSelected = selectedUOM === item.id;
    return (
      <Typography color={isSelected ? colors.background : colors.text} fontWeight="600">
        {item.name}
      </Typography>
    );
  };

  useEffect(() => {
    async function getUOMDetails() {
      if (!selectedUOM) return;
      const res = await getSingleData(selectedUOM);
      setSelectedUOMDetails(res.data);
    }
    getUOMDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUOM, updatedItem]);

  const displayTitle = () => {
    if (!selectedUOMDetails) return <></>;
    return (
      <Typography variant="h3" fontWeight={700} color={colors.primary}>
        {selectedUOMDetails.name}
      </Typography>
    );
  };

  const handleDeleteProductUOM = async () => {
    if (!selectedUOM) return;
    await deleteProductUOM(facility.id, selectedUOM);
    setOpenDeleteDialog(false);
    setDeletedId(selectedUOM);
  };

  return (
    <Box sx={{ ml: '30px' }}>
      {/* <CareGoPage header="Product Unit of Measurements">
        <ProductUOMTable />
      </CareGoPage> */}
      <InfiniteScrollContainer
        getData={getData}
        addedNewItem={addedItem}
        getSingleData={getSingleData}
        setSelected={setSelectedUOM}
        selected={selectedUOM}
        urlKeyId="uom"
        renderItem={displayItem}
        title="List of Unit of Measurements (UOM)"
        displaySelectedTitle={displayTitle}
        updatedItem={updatedItem}
        deleteId={deletedId}
        actions={[
          {
            label: 'Update',
            action: (data) => {
              setUpdateForm(true);
              setOpenProductUOMForm(true);
            },
            icon: <EditOutlinedIcon />,
          },
          {
            label: 'Delete',
            action: () => setOpenDeleteDialog(true),
            icon: <DeleteOutlineIcon sx={{ color: 'red' }} />,
            color: 'error',
          },
        ]}
        titleComponents={
          <RegularButton
            size="small"
            startIcon={<AddIcon />}
            label="Add"
            onClick={() => {
              // setUpdateForm(false);
              setOpenProductUOMForm(true);
            }}
            disabled={user.remaining_storage <= 0}
          />
        }
      >
        {selectedUOM && (
          <UnitConversionTable
            from_uom_id={selectedUOM}
            selectedUOMDetails={selectedUOMDetails}
            visibleColumns={['from_name', 'to_name', 'conversion_factor']}
          />
        )}
      </InfiniteScrollContainer>

      <CustomModal
        open={openProductUOMForm}
        setOpen={setOpenProductUOMForm}
        header={`${updateForm ? 'Update' : 'Add'} Product Unit of Measurement`}
      >
        <ProductUOMForm
          uom={updateForm ? selectedUOMDetails : undefined}
          callbackAfterSubmit={(data) => {
            setOpenProductUOMForm(false);
            !updateForm && setAddedItem(data.data.id);
            updateForm && setUpdatedItem(data.data);
            setUpdateForm(false);
          }}
        />
      </CustomModal>

      <ConfirmationDialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        title={'Delete product UOM'}
        content={`Are you sure you want to delete ${selectedUOMDetails?.name}?`}
        onConfirm={handleDeleteProductUOM}
      ></ConfirmationDialog>
    </Box>
  );
}
