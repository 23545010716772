import * as Yup from 'yup';

export const signupInitialValues = {
  company_name: '',
  primary_language: 3,
  company_logo: process.env.PUBLIC_URL + '/assets/carego_health_suite_logo.png',
  company_logo_dark: process.env.PUBLIC_URL + '/assets/carego_health_suite_logo_white.png',
  plan: 'free',
  email: '',
  first_name: '',
  middle_name: '',
  last_name: '',
  mobile_number: '',
  user_group_id: 4,
  username: '',
  password: '',
  health_programs: [6],
};

export const signupSchema = Yup.object().shape({
  company_name: Yup.string().required('Company name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  mobile_number: Yup.string().required('Mobile number is required'),
  username: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required'),
});

export const signupFields = [
  {
    type: 'section_header',
    section_header: 'Company Information',
    span: 4,
  },
  {
    field_name: 'company_name',
    display_name: 'Company Name',
    type: 'text',
    span: 4,
  },
  {
    field_name: 'email',
    display_name: 'Email',
    type: 'text',
    span: 4,
  },
  {
    type: 'divider',
    span: 4,
  },
  {
    type: 'section_header',
    section_header: 'Personal Information',
    span: 4,
  },
  {
    field_name: 'first_name',
    display_name: 'First Name',
    type: 'text',
    span: 2,
  },
  {
    field_name: 'middle_name',
    display_name: 'Middle Name (Optional)',
    type: 'text',
    span: 2,
  },
  {
    field_name: 'last_name',
    display_name: 'Last Name',
    type: 'text',
    span: 2,
  },
  {
    field_name: 'mobile_number',
    display_name: 'Mobile Number',
    type: 'text',
    span: 2,
  },
  {
    field_name: 'username',
    display_name: 'Username',
    type: 'text',
    span: 2,
  },
  {
    field_name: 'password',
    display_name: 'Password',
    type: 'password_generator',
    span: 2,
  },
];
