import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'patient-notes-update-history';

export const getNoteHistory = (note_id: number, id: number) => {
  return axios.get(`${baseURL}/${path}/${note_id}/${id}`);
};

export const getManyNoteHistory = (note_id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${note_id}`, { params: query });
};
