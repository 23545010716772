import { Box, Button } from '@mui/material';
import { ConfirmationDialog, CustomGridCell, CustomTable, PrimaryButton } from 'core/components';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { calculateAge, formatDate } from 'core/utils';

import { ApiQuery } from 'core/model/interface';
import { enrollPatient } from 'lgu/api/program-schedule';
import { getPatientsNotEnrolledInSchedule } from 'lgu/api/patient';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { UserContext } from 'core/context/user.context';

type QuickEnrollProps = {
  scheduleID: number;
};

const QuickEnroll: React.FC<QuickEnrollProps> = ({ scheduleID }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);

  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const [selectedPatientIDs, setSelectedPatientIDs] = useState<any[]>([]);
  const [selectedPatientID, setSelectedPatientID] = useState<number>(0);
  const [enoughPatients, setEnoughPatients] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<string>('');
  const [openMultiplePatientEnrollDialog, setOpenMultiplePatientEnrollDialog] = useState<boolean>(false);

  const columns: GridColDef[] = [
    {
      field: 'full_name',
      headerName: 'Patient Name',
      flex: 1.5,
      renderCell: (params) => (
        <CustomGridCell>
          {params.row.first_name} {params.row.last_name}
        </CustomGridCell>
      ),
    },
    { field: 'mobile_number', headerName: 'Mobile Number', flex: 1 },
    {
      field: 'birthday',
      headerName: 'Birthday',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{formatDate(params.row.birthday)}</CustomGridCell>,
    },
    {
      field: 'age',
      headerName: 'Age',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{calculateAge(params.row.birthday)}</CustomGridCell>,
    },
    { field: 'sex', headerName: 'Sex Assigned At Birth', flex: 1 },
    { field: 'barangay_name', headerName: 'Barangay', flex: 1 },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" gap="5px">
          <Button
            variant="outlined"
            size="small"
            color="success"
            onClick={() => {
              setSelectedPatientID(params.row.patient_id);
              handlePatientEnroll(params.row.patient_id);
            }}
          >
            Enroll
          </Button>
        </Box>
      ),
    },
  ];

  const getPatientsList = (query: ApiQuery) => {
    setLoading(true);
    getPatientsNotEnrolledInSchedule(scheduleID, query)
      .then((res) => {
        setRows(res.data.data);
        setRowCount(res.data.meta.total);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const handleSelectPatients = (selected: GridRowSelectionModel) => {
    if (selected.length > 0) {
      setEnoughPatients(true);
      setSelectedPatientIDs(selected);
    } else {
      if (selected.length === 0) {
        setEnoughPatients(false);
      }
    }
  };

  const showEnrollPatientDialog = (content?: string) => {
    if ((selectedPatientIDs.length && !selectedPatientID) || selectedPatientID) {
      setOpenMultiplePatientEnrollDialog(true);
      setAlertContent(content!);
    }
  };

  const handlePatientEnroll = (selectedPatientID?: number) => {
    const patientList = JSON.stringify(selectedPatientID ? [selectedPatientID] : selectedPatientIDs);
    const data = { patients: patientList };
    enrollPatient(scheduleID, data)
      .then((res: any) => {
        enqueueSnackbar(
          selectedPatientIDs.length > 0 ? 'Patients successfully enrolled!' : 'Patient successfully enrolled!',
          { variant: 'success' }
        );
        getPatientsList({ page: 1, length: 10 });
        setSelectedPatientID(0);
        setSelectedPatientIDs([]);
      })
      .catch((error) => console.error(error));
  };

  return (
    <>
      <Box width="100%">
        <CustomTable
          columns={columns}
          rows={rows}
          rowCount={rowCount}
          loading={loading}
          rowId={'patient_id'}
          getData={getPatientsList}
          checkboxSelection
          handleSelectRow={handleSelectPatients}
          disableSelectionOnClick
          keepNonExistentRowsSelected={false}
          selectableRow
          headerComponent={
            <Box display="flex" justifyContent="start">
              <PrimaryButton
                label="Enroll Patients"
                onClick={() => {
                  if (enoughPatients) {
                    const patient = selectedPatientID ? ' patient' : ' patients';
                    showEnrollPatientDialog(
                      'Are you sure you want to enroll ' + selectedPatientIDs.length + patient + ' to this program?'
                    );
                  } else {
                    enqueueSnackbar('Invalid action. Select patients first!', { variant: 'error' });
                  }
                }}
                disabled={user.remaining_storage <= 0}
              />
            </Box>
          }
        />
      </Box>
      <ConfirmationDialog
        open={openMultiplePatientEnrollDialog}
        setOpen={setOpenMultiplePatientEnrollDialog}
        content={alertContent}
        onConfirm={handlePatientEnroll}
        confirmButtonColor
      />
    </>
  );
};

export default QuickEnroll;
