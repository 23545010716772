import { Box, Divider, Grid, Paper, Typography, useTheme } from '@mui/material';
import { useContext, useRef } from 'react';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { FacilityContext } from 'core/context/facility.context';
import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import PrintableSelfRegistration from './PrintableSelfRegistration';
import { QRCode } from 'react-qrcode-logo';
import { RegularButton } from 'core/components';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { tokens } from 'theme/theme';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

const SelfRegistrationManual = () => {
  const navigate = useNavigate();
  const { facility } = useContext(FacilityContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const printRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: `
      @page {
        size: A4;
        margin: 20mm;
      }
      @media print {
        body {
          margin: 0;
          padding: 0;
        }
      }
    `,
  });

  const steps = [
    {
      title: 'Access Registration',
      description: 'Scan the QR code or click the button above it to access the self-registration page',
      icon: <PhoneIphoneOutlinedIcon sx={{ fontSize: 40 }} />,
    },
    {
      title: 'Fill Information',
      description: 'Complete all required personal and medical information in the registration form',
      icon: <PersonAddOutlinedIcon sx={{ fontSize: 40 }} />,
    },
    {
      title: 'Submit & Wait',
      description: 'Submit your registration and wait for confirmation from our clinic staff',
      icon: <CheckCircleOutlineIcon sx={{ fontSize: 40 }} />,
    },
  ];

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="start"
      sx={{ minHeight: 'calc(100vh - 200px)' }}
    >
      <Box mb={4} display={'flex'} alignItems={'start'} justifyContent={'space-between'} width={'100%'} px={2}>
        <Typography variant="h1" fontWeight={1000} textAlign="left">
          It's super easy to <br />
          Self-Register!
        </Typography>
        <Box>
          <RegularButton
            startIcon={<LocalPrintshopOutlinedIcon />}
            variant="outlined"
            onClick={handlePrint}
            // sx={{
            //   textTransform: 'none',
            //   padding: '6px 18px',
            //   borderRadius: '8px',
            //   fontSize: '12px',
            //   border: `1px solid ${colors.primary}`,
            //   '&:hover': {
            //     borderRight: `1px solid ${colors.primary}`,
            //   },
            // }}
            label="Print"
            size="small"
          />
        </Box>
      </Box>

      <Grid container spacing={4} sx={{ maxWidth: '100%' }}>
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              p: 4,
              height: '100%',
              backgroundColor: colors.primary,
              color: 'white',
            }}
          >
            <Typography variant="h3" fontWeight={600} mb={4} sx={{ borderBottom: '1px solid whtie' }}>
              Self-Registration Instructions
            </Typography>

            <Box display="flex" flexDirection="column" gap={4}>
              {steps.map((step, index) => (
                <Box key={index} display="flex" gap={3} alignItems="flex-start">
                  <Box
                    sx={{
                      backgroundColor: 'rgba(255, 255, 255, 0.2)',
                      borderRadius: '12px',
                      p: 1,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexShrink: 0,
                    }}
                  >
                    {step.icon}
                  </Box>
                  <Box>
                    <Typography variant="h6" fontWeight={500}>
                      {index + 1}. {step.title}
                    </Typography>
                    <Typography variant="body1" mb={2}>
                      {step.description}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              p: 3,
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <RegularButton
              startIcon={<VisibilityOutlinedIcon />}
              onClick={() => navigate(`/self-registration/${facility?.id}`)}
              label="Go to Self-Registration page"
              size="large"
            />

            <Divider sx={{ width: '100%', my: 1 }}>
              <Typography variant="body2" color="text.secondary">
                or
              </Typography>
            </Divider>

            <Box display="flex" alignItems="center" justifyContent="center" gap={1} color={colors.primary}>
              <KeyboardDoubleArrowDownOutlinedIcon />
              <Typography variant="h3" fontWeight={700} color={colors.primary}>
                Scan Me
              </Typography>
              <KeyboardDoubleArrowDownOutlinedIcon />
            </Box>

            <Box
              sx={{
                p: 3,
                backgroundColor: colors.lightBackground,
                borderRadius: '12px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <QRCode
                logoImage={process.env.PUBLIC_URL + '/assets/carego_health_suite_logo_white.png'}
                removeQrCodeBehindLogo
                eyeRadius={[10, 10, 10]}
                fgColor={colors.primary}
                value={`${process.env.REACT_APP_BASE_URL}/self-registration/${facility?.id}`}
                size={125}
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <Box sx={{ display: 'none' }}>
        <PrintableSelfRegistration ref={printRef} />
      </Box>
    </Box>
  );
};

export default SelfRegistrationManual;
