import * as React from 'react';

import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { SIZES } from 'theme/constants';

type CustomRadioGroupProps = {
  options: any[];
  label: string;
  value?: any[];
  fieldName: string;
  span?: number;
  optionsColumn?: number;
  handleChange?: (value: any) => void;
  handleChangeCallback?: (value: any) => void;
  disabled?: boolean;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  row?: boolean;
  style?: React.CSSProperties;
  readOnly?: boolean;
  optional?: boolean;
};

const CustomRadioGroup: React.FC<CustomRadioGroupProps> = ({
  options,
  label,
  value = [],
  fieldName,
  handleChange,
  handleChangeCallback,
  disabled,
  setFieldValue,
  span,
  optionsColumn = 2,
  row,
  style,
  readOnly,
  optional,
}) => {
  const [selectedOption, setSelectedOption] = useState<any>(value);

  const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!readOnly) {
      const newValue = event.target.value;
      setSelectedOption(newValue);
      handleChange && handleChange(newValue);
      setFieldValue && setFieldValue(fieldName, newValue);
      handleChangeCallback && handleChangeCallback(newValue);
    }
  };

  useEffect(() => {
    if (value.length) setSelectedOption(value);
  }, [value]);

  return (
    <Box sx={{ gridColumn: 'span ' + span }}>
      <Typography> {label + (optional ? ' (optional)' : '')}</Typography>
      <RadioGroup
        row={row ? row : false}
        value={selectedOption}
        onChange={handleSelectChange}
        sx={{
          gridColumn: 'span ' + span,
          justifyContent: 'center',
          alignContent: 'center',
          display: 'grid',
          paddingLeft: SIZES.paddingS,
          gridTemplateColumns: `repeat(${optionsColumn}, 1fr)`,
          '& .MuiRadio-root': { padding: '5px' },
          ...style,
        }}
      >
        {options?.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option.value}
            control={<Radio />}
            label={option.key}
            disabled={disabled}
          />
        ))}
      </RadioGroup>
    </Box>
  );
};

export default CustomRadioGroup;
